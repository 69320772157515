<template>
  <div>
    <div class="sidebar_container">
      <div class="sidebar_header">
        <CRow class="m-0">
          <CCol cols="10" lg="10" md="10" sm="10" class="text-left">
            <span v-if="history.length > 1" class="pointer" @click="removeSidebarHistoryStep()">
              <i class="fas fa-arrow-circle-left mr-1"/>
            </span>
            <span class="sidebar_subject">
              {{onboardingPackage.onboarding_package_id_external === null || onboardingPackage.onboarding_package_id_external === undefined ? $t('learn.New_onboarding_package') : $t('learn.Edit_onboarding_package')}}
            </span>
          </CCol>
          <CCol cols="2" lg="2" md="2" sm="2" class="text-right">
            <span @click="closeSidebarRight();"><i class="fas fa-times"/></span>    
          </CCol>      
        </CRow>
      </div>
      <div class="sidebar_content">
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <quick-edit type="text" mode="ignore" :buttonOkText="$t('Save')" :buttonCancelText="$t('Dismiss')" v-model="onboardingPackage.package_name">                        
              <h2 class="m-0">{{onboardingPackage.package_name ? onboardingPackage.package_name : $t('common.Click_to_set_name')}}<i class="icon edit fas fa-pen ml-1"/></h2>
            </quick-edit>                         
          </CCol>
        </CRow>
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <quick-edit type="textarea" rows="5" mode="ignore" :buttonOkText="$t('Save')" :buttonCancelText="$t('Dismiss')" v-model="onboardingPackage.package_description">
              <span>{{onboardingPackage.package_description ? onboardingPackage.package_description : $t('common.Click_to_set_description')}}<i class="icon edit fas fa-pen ml-1"/></span>
            </quick-edit>            
          </CCol>         
        </CRow>
        <CRow class="m-0">           
          <CCol cols="12" lg="12" class="pt-0">
            <label>{{$t('common.Specified_for_groups')}}</label>
            <multiselect
              class="data_table"
              v-model="onboardingPackage.target_groups" 
              :options="targetGroups" 
              :multiple="true"
              :placeholder="$t('common.Select_group')" 
              :selectLabel="$t('common.Add_group')" 
              :selectedLabel="$t('Added')"
              :deselectLabel="$t('common.Remove_group')"
              track-by="group_id" 
              label="title">
              <span slot="noResult">{{ $t('common.no_groups_found') }}</span>
            </multiselect> 
          </CCol>
        </CRow>        
        <CRow class="m-0">          
          <CCol cols="12" lg="12" class="pt-0">
            <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="onboardingPackage.is_active" size="is-small">{{ $t('is_active') }}</b-switch>
          </CCol>            
        </CRow>
      </div>
      <div class="sidebar_footer">
        <CRow class="m-0">
          <CCol cols="12" lg="12">
						<div v-if="!onboardingPackageIdExternal">
							<CButton color="primary" @click="insertOnboardingPackage(onboardingPackage);"><i class="fas fa-check mr-1"/>{{$t('Save')}}</CButton>
							<CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{$t('Dismiss')}}</CButton>
						</div> 						   
						<div v-else>
							<CButton color="primary" @click="updateOnboardingPackage(onboardingPackage);"><i class="fas fa-check mr-1"/>{{$t('Save')}}</CButton>
							<CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{$t('Dismiss')}}</CButton>
          	</div>  
          </CCol>
        </CRow>    
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import QuickEdit from 'vue-quick-edit';

export default {
  name: 'onboardingPackageDetails',
  props: ['history', 'onboardingPackageIdExternal'],
  components: {
    Multiselect,
    QuickEdit
  },
  watch: {
    $props: {
      handler() {
        this.getOnboardingPackageDetails();
      },
      deep: true,
      immediate: true,
    }
  },
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null,
      onboardingPackage: {
        target_groups: []
      },
      targetGroups: []
    }
  },
  methods: {
    getOnboardingPackageDetails() {
      if(this.onboardingPackageIdExternal !== null) {
        axios.get(process.env.VUE_APP_API_URL + '/v1/learn/onboarding/package/' + this.onboardingPackageIdExternal)
        .then(res => {
          this.onboardingPackage = res.data.data;         
        })
        .catch(err => {
          console.error(err); 
        });
      } else {
        // Reset the onboarding package
        this.resetOnboardingPackageData();
      }
    }, 
    insertOnboardingPackage(data) {
      let params = {};
      params.onboardingPackageData = data;
      if(params.onboardingPackageData.package_description === '') params.onboardingPackageData.package_description = null;     
      (params.onboardingPackageData.is_active == true) ? params.onboardingPackageData.active = 'Y' : params.onboardingPackageData.active = 'N';

      let package_name = params.onboardingPackageData.package_name;
      let target_groups = params.onboardingPackageData.target_groups;

      if(package_name && target_groups.length > 0) {
        axios.post(process.env.VUE_APP_API_URL + '/v1/learn/onboarding/package', params)
        .then(res => {
          // Show a success notice
          this.$buefy.toast.open({ message: this.$t('learn.Onboarding_package_added'), type: 'is-success', duration: 3000 });
          // Emit the update_onboarding_packages event
          this.$bus.$emit('update_onboarding_packages'); 
          // Reset the data
          this.resetOnboardingPackageData();
          // Close the sidebar
          this.closeSidebarRight();          
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }
    },
    updateOnboardingPackage(data) {
      let params = {}
      params.onboardingPackageData = data;
      if(params.onboardingPackageData.package_description === '') params.onboardingPackageData.package_description = null;
      (params.onboardingPackageData.is_active == true) ? params.onboardingPackageData.active = 'Y' : params.onboardingPackageData.active = 'N';    
            
      let package_name = params.onboardingPackageData.package_name;
      let target_groups = params.onboardingPackageData.target_groups;

      if(package_name && target_groups.length > 0) {
        axios.put(process.env.VUE_APP_API_URL + '/v1/learn/onboarding/package/' + this.onboardingPackageIdExternal, params)
        .then(res => {
          // Show a success notice
          this.$buefy.toast.open({ message: this.$t('learn.Onboarding_package_updated'), type: 'is-success', duration: 2000 });
          // Emit the update_onboarding_packages event
          this.$bus.$emit('update_onboarding_package_details', this.onboardingPackageIdExternal);           
          // Reset the data
          this.resetOnboardingPackageData();
          // Close the sidebar
          this.closeSidebarRight();     
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }        
    },
    resetOnboardingPackageData() {
      this.onboardingPackage = {
        package_name: null,
        package_description: null,
        target_groups: []
      };
    },
    getTargetGroups() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/targetgroups/multiselect')
      .then(res => {
        this.targetGroups = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    }        
  },
  mounted: function() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');

    this.getTargetGroups();
  }  
}
</script>