<template>
  <div>
    <div class="sidebar_container">
      <div class="sidebar_header">
        <CRow class="m-0">
          <CCol cols="10" lg="10" md="10" sm="10" class="text-left">
            <span v-if="history.length > 1" class="pointer" @click="removeSidebarHistoryStep()">
              <i class="fas fa-arrow-circle-left mr-1"/>
            </span>            
            <span class="sidebar_subject">
							{{duplicateSignageScreen ? $t('signage.Duplicate_screen') : !screen.signage_screen_id_external ? $t('signage.New_screen') : $t('signage.Edit_screen')}}
            </span>
          </CCol>
          <CCol cols="2" lg="2" md="2" sm="2" class="text-right">
            <span @click="closeSidebarRight();"><i class="fas fa-times"/></span>        
          </CCol>
        </CRow>
      </div>
      <div class="sidebar_content">
        <CRow class="m-0">
					<CCol cols="12" lg="12" class="pt-0">
						<CInput type="text" :label="$t('signage.Screen_title')" class="mb-0" v-model="screen.screen_title" required was-validated/>
					</CCol>
					<CCol cols="12" lg="12" class="pt-0 pb-0">
						<CTextarea :label="$t('signage.Screen_description')" class="mb-0" rows="5" v-model="screen.screen_description"/>
					</CCol>
        </CRow>
      </div>
      <div class="sidebar_footer">
        <CRow class="m-0">
          <CCol cols="12" lg="12">
						<div v-if="duplicateSignageScreen">
							<CButton color="primary" @click="duplicateScreen(screen);"><i class="fas fa-clone mr-1"/>{{$t('Duplicate')}}</CButton>
							<CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{$t('Dismiss')}}</CButton>
						</div> 						   
						<div v-else>
							<CButton color="primary" @click="insertScreen(screen);"><i class="fas fa-check mr-1"/>{{$t('Save')}}</CButton>
							<CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{$t('Dismiss')}}</CButton>
          	</div>
          </CCol>
        </CRow>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import QuickEdit from 'vue-quick-edit';

export default {
  name: 'signageScreen',
  props: ['history', 'signageScreenIdExternal', 'duplicateSignageScreen'],
  components: {
    Multiselect,
    QuickEdit,
  },
  watch: {
    $props: {
      handler() {
        this.getScreenDetails();
      },
      deep: true,
      immediate: true,
    }
  }, 
  data() {
    return {
			screen: {}
    }
  },
  methods: {
    getScreenDetails() {
      if(this.signageScreenIdExternal !== null) {
				axios.get(process.env.VUE_APP_API_URL + '/v1/connect/signage/screen/' + this.signageScreenIdExternal)
				.then(res => {
					this.screen = res.data.data;   
				})
				.catch(err => {
					console.error(err); 
				});    
      } else {
        // Reset the screen
        this.resetScreenData();
      }
    },
    insertScreen(data) {
      let params = {};
      params = data;
      if(params.screen_description === '') params.screen_description = null;

      let screen_title = params.screen_title;    

      if(screen_title) {
        axios.post(process.env.VUE_APP_API_URL + '/v1/connect/signage/screen', params)
        .then(res => {
          this.$buefy.toast.open({ message: this.$t('signage.Screen_added'), type: 'is-success', duration: 2000 });
          // Emit the update_signage_screens event
          this.$bus.$emit('update_signage_screens');
          // Reset the screen data
          this.resetScreenData();
          // Close the sidebar
          this.closeSidebarRight();
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }
    },
    duplicateScreen(data) {
      let params = {};
      params = data;
      if(params.screen_description === '') params.screen_description = null;

      let screen_title = params.screen_title;    

      if(screen_title) {
        axios.post(process.env.VUE_APP_API_URL + '/v1/connect/signage/screen/duplicate', params)
        .then(res => {
          this.$buefy.toast.open({ message: this.$t('signage.Screen_duplicated'), type: 'is-success', duration: 2000 });
          // Emit the update_signage_screens event
          this.$bus.$emit('update_signage_screens');
          // Reset the screen data
          this.resetScreenData();
          // Close the sidebar
          this.closeSidebarRight();					        
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }
    },
    resetScreenData () {
      this.screen = {
        screen_title: '',
        screen_description: ''
      }
    },				
  }
}
</script>