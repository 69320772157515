<template>
  <div>
    <div class="sidebar_container">
      <div class="sidebar_header">
        <CRow class="m-0">
          <CCol cols="10" lg="10" md="10" sm="10" class="text-left">
            <span v-if="history.length > 1" class="pointer" @click="removeSidebarHistoryStep()">
              <i class="fas fa-arrow-circle-left mr-1"/>
            </span>            
            <span class="sidebar_subject">{{$t('admin.Reset_password_for_employee', { employee_name : employeeName } )}}</span>
          </CCol>
          <CCol cols="2" lg="2" md="2" sm="2" class="text-right">
            <span @click="closeSidebarRight();"><i class="fas fa-times"/></span>        
          </CCol>
        </CRow>
      </div>
      <div class="sidebar_content">
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <label>{{$t('admin.New_employee_password')}}</label>
            <div class="d-flex align-items-center">
              <div class="flex-grow-1 pr-2">
                <CInput :type="password1Type" v-model="passwordData.password1" class="m-0"></CInput>
              </div>
              <div class="pointer" @click="password1Type === 'password' ? password1Type = 'text' : password1Type = 'password'">
                <i v-if="password1Type === 'password'" class="fa-solid fa-eye"></i>
                <i v-if="password1Type === 'text'" class="fa-solid fa-eye-slash"></i>
              </div>              
            </div>
          </CCol>
        </CRow>
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <label>{{$t('admin.Repeat_new_employee_password')}}</label>
            <div class="d-flex align-items-center">
              <div class="flex-grow-1 pr-2">
                <CInput :type="password2Type" v-model="passwordData.password2" class="m-0"></CInput>
              </div>
              <div class="pointer" @click="password2Type === 'password' ? password2Type = 'text' : password2Type = 'password'">
                <i v-if="password2Type === 'password'" class="fa-solid fa-eye"></i>
                <i v-if="password2Type === 'text'" class="fa-solid fa-eye-slash"></i>
              </div>              
            </div>          
          </CCol>
        </CRow>
        <CRow v-if="errorMessage" class="m-0 error">
          <CCol cols="12" lg="12" class="pt-0">
            <span>{{errorMessage}}</span>
          </CCol>
        </CRow>                
      </div>
      <div class="sidebar_footer">
        <CRow class="m-0">
          <CCol cols="12" lg="12">
            <div>
              <CButton color="primary" @click="resetPassword();"><i class="fas fa-check mr-1"/>{{ $t('admin.Reset_password') }}</CButton>
              <CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{ $t('Dismiss') }}</CButton>
            </div>      
          </CCol>
        </CRow>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'resetEmployeePassword',
  props: ['history', 'userIdExternal', "employeeName"],
  data() {
    return {
      passwordData: {
        password1: null,
        password2: null
      },
      password1Type: 'password',
      password2Type: 'password',
      errorMessage: null,
    }
  },
  methods: {
    resetPassword() {
      // Set the params        
      let params = {};
      params.passwordData = this.passwordData;

      // Reset the errorMessage value
      this.errorMessage = null;
      // Update 
      axios.put(process.env.VUE_APP_API_URL + '/v1/superadmin/user/' + this.userIdExternal + '/resetpassword', params)
      .then(res => {
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('admin.Employee_password_successfully_reset'), type: 'is-success', duration: 2000 });        
        // Close the sidebar
        this.closeSidebarRight();
      })
      .catch(err => {;
        if(err.response.data.code === 'No matching passwords') {
          this.errorMessage = this.$t('admin.Passwords_do_not_match');
        } else if(err.response.data.code === 'Invalid password length') {
          this.errorMessage = this.$t('admin.Minimum_length');
        }
      });        
    },   
    resetPasswordData() {
      this.passwordData = {
        password1: null,
        password2: null
      };
    }
  },
  mounted: function(){
    this.resetPasswordData();    
  }  
}
</script>