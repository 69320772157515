<template>
  <CCard class="pointer navigation_card" @click="openSidebarRight('resource_details', { resource_id_external: resourceData.resource_id_external });">
    <CCardBody>
      <div class="d-flex align-items-center">
        <div class="item_icon d-inline-block align-middle mr-2">
          <i class="fas" v-bind:class="'fa-' + resourceData.icon_class"/>               
        </div>                        
        <div class="flex-grow-1">        
          <span><b>{{resourceData.resource_name}}</b></span>
        </div>
      </div>
    </CCardBody>
  </CCard>
</template>

<script>

export default {
  name: 'resourceCard',
  props: ['resource'],
  watch: {
    $props: {
      handler() {
        if(this.resource) this.resourceData = this.resource;
      },
      deep: true,
      immediate: true,
    }
  },
  data() {
    return {
      resourceData: {}
    }
  }  
}
</script>
