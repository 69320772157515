<template>
  <div>
    <div class="sidebar_container">
      <div class="sidebar_header">
        <CRow class="m-0">
          <CCol cols="10" lg="10" md="10" sm="10" class="text-left">
            <div class="d-flex align-items-center">
              <span v-if="history.length > 1" class="pointer" @click="removeSidebarHistoryStep()">
                <i class="fas fa-arrow-circle-left mr-1"/>
              </span>
              <span class="sidebar_subject mr-2">
                {{headcountType}} {{$t('common.headcount')}} <span v-if="details.headcount.headcount_total > 0">({{details.headcount.headcount_total}})</span>
              </span>
              <b-switch v-if="details.headcount.headcount_total > 0" class="mb-0" v-model="listViewEnabled" size="is-small">{{ $t('common.activate_list_view') }}</b-switch>
            </div>
          </CCol>
          <CCol cols="2" lg="2" md="2" sm="2" class="text-right">
            <span @click="closeSidebarRight();"><i class="fas fa-times"/></span>
          </CCol>
        </CRow>
      </div>
      <div class="sidebar_content">
        <CRow>
          <CCol cols="12" lg="12" class="pt-0 pb-0">
            <div v-if="listViewEnabled">
              <CRow>
                <CCol cols="12" sm="12" md="12" lg="12" class="pt-0" v-bind:class="{ 'pb-0' : listViewEnabled === true }">
                  <b-table ref="groupTable" class="data_table" :data="details.headcount.headcount_details" :striped="true" :hoverable="true" :mobile-cards="true" :paginated="isPaginated" :per-page="perPage" :current-page.sync="currentPage" :pagination-simple="isPaginationSimple" :pagination-position="paginationPosition">
                    <template slot-scope="props">
                      <b-table-column field="name" :label="$t('common.Name')">
                        <div @click="openSidebarRight('user_profile', { user_id_external:  props.row.user_id_external });" class="pointer">
                          <div v-if="props.row.profile_image" class="profile_image d-inline-block align-middle mr-2" v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + props.row.user_id_external + '/' + props.row.profile_image + '/' + clientToken + ')' }"></div>
                          <div v-else class="profile_icon d-inline-flex align-middle mr-2">
                            <userProfileAvatar :username="props.row.name" :size="25"/>
                          </div>
                          <b>{{props.row.name}}</b>
                        </div>
                      </b-table-column>
                      <b-table-column field="department_name" :label="$t('common.Department')">
                        <span>{{props.row.department_name ? props.row.department_name : '-'}}</span>
                      </b-table-column>                        
                      <b-table-column field="team_name" :label="$t('common.Team')">
                        <span>{{props.row.team_name ? props.row.team_name : '-'}}</span>
                      </b-table-column>                        
                      <b-table-column field="type" :label="$t('common.Type')">
                        <span>{{props.row.type}}</span>
                      </b-table-column>
                    </template>
                    <template slot="empty">
                      <div class="text-center">
                        {{ $t('common.No_headcount_users_for') }} {{headcountType}}
                      </div>
                    </template>                
                  </b-table>                    
                </CCol>
              </CRow>
            </div>
            <div v-else>
              <div v-if="details.headcount.headcount_details.length > 0">
                <CRow class="headcount_user_container m-0">
                  <CCol cols="4" xl="4" lg="4" md="4" sm="4" v-for="user in currentPageUsers[currentHeadcountPage - 1]" v-bind:key="user.user_id_external" class="user_column pt-0">
                    <CCard class="user mb-0">
                      <CCardBody>
                        <CRow>
                          <CCol cols="12" sm="12" md="12" lg="12" class="pt-0 pb-0">
                            <center>
                              <div v-if="user.profile_image" class="profile_image mb-2" v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + user.user_id_external + '/' + user.profile_image + '/' + clientToken + ')' }"></div>
                              <div v-else class="profile_icon mb-2">
                                <userProfileAvatar :username="user.name" :size="50"/>
                              </div>
                            </center>
                          </CCol>                      
                          <CCol cols="12" sm="12" md="12" lg="12" class="pt-0 pb-0 text-center">
                            <a @click="openSidebarRight('user_profile', { user_id_external:  user.user_id_external });" class="d-block"><b>{{user.name}}</b></a>
                            <p v-if="user.team_name" class="m-0">{{user.team_name}} ({{user.department_name}})</p>
                            <p class="m-0">{{user.type !== null && user.type !== '' ? user.type : '-'}}</p>
                          </CCol>                                                
                        </CRow>
                      </CCardBody>
                    </CCard>
                  </CCol>
                </CRow>
                <hr v-if="details.headcount.headcount_details.length > usersPerPage" class="m-0">
                <CRow v-if="details.headcount.headcount_details.length > usersPerPage" class="m-0">
                  <CCol cols="12" md="12">
                    <v-pagination class="justify-content-end" v-model="currentHeadcountPage" :length="userPages" :total-visible="9" prev-icon="mdi-chevron-left" next-icon="mdi-chevron-right"></v-pagination>
                  </CCol>
                </CRow>
              </div>
              <div v-else>
                <CRow class="m-0">
                  <CCol cols="12" lg="12" class="pt-0">
                    <div>
                      {{ $t('common.No_headcount_users_for') }} {{headcountType}}
                    </div>
                  </CCol>
                </CRow>                  
              </div>
            </div>
          </CCol>                      
        </CRow>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import userProfileAvatar from "@/components/common/userProfileAvatar.vue";

export default {
  name: 'headcountDetails',
  props: ['history', 'headcountType', 'headcountIdExternal'],
  components: {
    userProfileAvatar
  },
  watch: {
    $props: {
      handler() {
        if(this.headcountType === 'flow') {
          this.detailsPath = 'connect/flow';
        } else if(this.headcountType === 'campaign') {
          this.detailsPath = 'connect/deliver/email';
        } else if(this.headcountType === 'push notification') {
          this.detailsPath = 'connect/deliver/pushnotification';
        } else if(this.headcountType === 'burning matter') {
          this.detailsPath = 'measure/burning_matter';
        } else if(this.headcountType === 'knowledge category') {
          this.detailsPath = 'knowledge/category';
        } else if(this.headcountType === 'knowledge subcategory') {
          this.detailsPath = 'knowledge/subcategory';
        } else if(this.headcountType === 'knowledge item') {
          this.detailsPath = 'knowledge/item';
        } else if(this.headcountType === 'learn category') {
          this.detailsPath = 'learn/category';
        } else if(this.headcountType === 'learn course') {
          this.detailsPath = 'learn/course';
        } else if(this.headcountType === 'learn onboarding package') {
          this.detailsPath = 'learn/onboarding/package';
        } else if(this.headcountType === 'loyalty store category') {
          this.detailsPath = 'loyalty/store/category';
        } else if(this.headcountType === 'resource') {
          this.detailsPath = 'hub/resource';
        }
        // Get the headcount details
        this.getHeadcountDetails();
      },
      deep: true,
      immediate: true,
    }
  }, 
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null,
      detailsPath: null,
      details: {
        headcount: {
          headcount_total: 0,
          headcount_details: []
        }
      },
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 15,      
      listViewEnabled: false,
      currentHeadcountPage: 1,
      currentPageUsers: {},
      usersPerPage: 15,
      userPages: 0
    }
  },
  methods: {
    getHeadcountDetails() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/' + this.detailsPath + '/' + this.headcountIdExternal)
      .then(res => {
        this.details = res.data.data;
        // Set the pagination
        this.setPagination();        
      })
      .catch(err => {
        console.error(err); 
      });                  
    },
    setPagination() {
      // Set the pagination to the first page
      this.currentHeadcountPage = 1;
      // Reset the userPages value
      this.userPages = 0;
      // Get the headcountDetails
      let headcountDetails = this.details.headcount.headcount_details;
      // Loop througb the headcountDetails to divide the data in pages         
      for (let i = 0; i < headcountDetails.length; i = i + this.usersPerPage) {
        this.currentPageUsers[this.userPages] = headcountDetails.slice(i, i + this.usersPerPage);
        this.userPages++;
      }
    }
  },
  mounted: function() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');
  }
}
</script>