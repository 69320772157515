<template>
  <div>
    <div class="sidebar_container">
      <div class="sidebar_header">
        <CRow class="m-0">
          <CCol cols="10" lg="10" md="10" sm="10" class="text-left">
            <span v-if="history.length > 1" class="pointer" @click="removeSidebarHistoryStep()">
              <i class="fas fa-arrow-circle-left mr-1"/>
            </span>            
            <span class="sidebar_subject">
							{{!routeIdExternal ? $t('routes.New_route') : $t('routes.Edit_route')}}
            </span>
          </CCol>
          <CCol cols="2" lg="2" md="2" sm="2" class="text-right">
            <span @click="closeSidebarRight();"><i class="fas fa-times"/></span>        
          </CCol>
        </CRow>
      </div>
      <div class="sidebar_content">
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <quick-edit type="text" mode="ignore" :buttonOkText="$t('Save')" :buttonCancelText="$t('Dismiss')" v-model="route.route_name">                        
              <h2 class="m-0">{{route.route_name ? route.route_name : $t('common.Click_to_set_name')}}<i class="icon edit fas fa-pen ml-1"/></h2>
            </quick-edit>
          </CCol>           
        </CRow>
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <quick-edit type="textarea" rows="5" mode="ignore" :buttonOkText="$t('Save')" :buttonCancelText="$t('Dismiss')" v-model="route.route_description">
              <span>{{route.route_description ? route.route_description : $t('common.Click_to_set_description')}}<i class="icon edit fas fa-pen ml-1"/></span>
            </quick-edit>
          </CCol>
        </CRow>
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <label><b>{{$t('routes.Route_actions')}}</b></label>
            <div v-if="route.actions.length > 0">
              <div v-for="(action, index) in route.actions" v-bind:key="index" class="w-100">
                <CRow>
                  <CCol cols="12" lg="12" class="pt-0">
                    <label>{{$t('routes.Route_action_type')}}</label>
                    <multiselect class="data_table open_absolute"
                                v-model="action.action_type" 
                                :options="routeActionTypes" 
                                :multiple="false"
                                :placeholder="$t('routes.Select_action_type')" 
                                :selectLabel="$t('routes.Add_action_type')" 
                                :selectedLabel="$t('Selected')"
                                :deselectLabel="$t('routes.Remove_action_type')"
                                track-by="route_action_type_tag" 
                                label="route_action_type"
                                :custom-label="customActionTypeLabel"
                                :disabled="action.action_type !== null && action.action_type !== undefined"
                                @input="setRouteActionType(action.action_type.route_action_type_tag, index)">
                      <span slot="noResult">{{ $t('common.no_types_found') }}</span>
                      <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                    </multiselect>
                  </CCol>
                </CRow>

                <div v-if="action.action_type" class="mb-2_5">
                  <CRow v-if="action.action_type.route_action_type_tag === 'send_service_question'">
                    <CCol cols="12" lg="12" class="pt-0" v-bind:class="{'pb-0' : !action.service_question_folder}">
                      <label>{{$t('service.Set_folder_for_service_question')}}</label>
                      <multiselect class="data_table open_absolute"
                                  v-model="action.service_question_folder" 
                                  :options="serviceQuestionFolders" 
                                  :multiple="false"
                                  :hide-selected="true"
                                  :close-on-select="true"             
                                  :placeholder="$t('service.Select_folder')"
                                  :selectLabel="$t('service.Add_folder')"                                                                      
                                  track-by="service_question_folder_id_external" 
                                  label="folder_name"
                                  @input="$forceUpdate()">
                        <span slot="noResult">{{ $t('common.no_form_fields_found') }}</span>
                        <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                      </multiselect>
                    </CCol>
                    <CCol v-if="action.service_question_folder && action.service_question_folder.members" cols="12" lg="12" class="pt-0 pb-0">
                      <label>{{$t('service.Set_assignee_for_service_question')}}</label>                      
                      <multiselect class="data_table open_absolute"
                                  v-model="action.service_question_assignee" 
                                  :options="action.service_question_folder.members" 
                                  :multiple="false"
                                  :hide-selected="true"
                                  :close-on-select="true"             
                                  :placeholder="$t('service.Select_assignee')" 
                                  :selectLabel="$t('service.Set_as_assignee')"
                                  track-by="user_id_external" 
                                  label="label"
                                  @input="$forceUpdate()">
                      <span slot="noResult">{{ $t('common.no_users_found') }}</span>
                      <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                      </multiselect>
                    </CCol>
                  </CRow>

                  <CRow v-if="action.action_type.route_action_type_tag === 'send_email'">
                    <CCol cols="12" lg="12" class="pt-0">
                      <label>{{$t('common.Set_email_recipients')}}</label>                      
                      <multiselect class="data_table open_absolute"
                                   v-model="action.email_recipients" 
                                   :options="emailRecipients" 
                                   :multiple="true"
                                   :close-on-select="false"             
                                   :placeholder="$t('common.Select_recipients')"
                                   :selectLabel="$t('common.Add_recipient')" 
                                   :selectedLabel="$t('Selected')"
                                   :deselectLabel="$t('common.Remove_recipient')"
                                   track-by="user_id_external" 
                                   label="label"
                                   @search-change="asyncFindRecipients"
                                   @input="$forceUpdate()">
                      <span slot="noResult">{{ $t('common.no_users_found') }}</span>
                      <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                      </multiselect>
                    </CCol>
                    <CCol cols="12" lg="12" class="pt-0">
                      <CInput type="text" :label="$t('common.Subject')" class="mb-0" v-model="action.email_content.subject" required was-validated/>
                    </CCol>
                    <CCol cols="12" lg="12" class="pt-0 pb-0">            
                      <label><b>{{$t('kb.Content')}}</b></label>
                      <div class="wysiwyg">
                        <ckeditor :editor="editor" v-model="action.email_content.content" :config="editorConfig"></ckeditor>
                      </div>
                    </CCol>
                  </CRow>                  
                </div>

                <CButton class="m-0" color="primary" @click="removeRouteAction(route, index);">                  
                  <span><i class="fas fa-times mr-1"/>{{$t('routes.Remove_route_action')}}</span>
                </CButton>

                <hr class="mt-2_5 mb-2_5">
              </div>
            </div>
            <div v-else>
              <p class="mb-2">{{$t('routes.No_route_actions_set')}}</p>
            </div>
            <CRow>
              <CCol cols="12" lg="12" class="pt-0 pb-0">
                <CButton color="primary" @click="addRouteAction();">
                  <i class="fas fa-plus mr-1"/>
                  <span>{{ $t('routes.Add_route_action') }}</span>
                </CButton>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
        <CRow class="m-0">           
          <CCol cols="12" lg="12" class="pt-0">
            <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="route.is_active" size="is-small">{{ $t('is_active') }}</b-switch>            
          </CCol>            
        </CRow>
      </div>
      <div class="sidebar_footer">
        <CRow class="m-0">
          <CCol cols="12" lg="12">
						<div v-if="!routeIdExternal">
							<CButton color="primary" @click="insertRoute(route);"><i class="fas fa-check mr-1"/>{{$t('Save')}}</CButton>
							<CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{$t('Dismiss')}}</CButton>
						</div> 						   
						<div v-else>
							<CButton color="primary" @click="updateRoute(route);"><i class="fas fa-check mr-1"/>{{$t('Save')}}</CButton>
							<CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{$t('Dismiss')}}</CButton>
          	</div>
          </CCol>
        </CRow>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import QuickEdit from 'vue-quick-edit';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import loadingSpinner from '@/components/common/loadingSpinner.vue';
import userProfileAvatar from '@/components/common/userProfileAvatar.vue';

export default {
  name: 'routeDetails',
  props: ['history', 'routeIdExternal'],
  components: {
    Multiselect,
    QuickEdit,
    ClassicEditor,
    loadingSpinner,
    userProfileAvatar
  },
  watch: {
    $props: {
      handler() {
        this.getRouteDetails();
      },
      deep: true,
      immediate: true,
    }
  }, 
  data() {
    return {
      platformRoles: [],
      apiBaseUrl: null,
      clientToken: null,
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [ 'heading', '|', 'bold', 'italic', 'bulletedList', 'numberedList', '|', 'undo', 'redo' ]
      },      
			route: { 
        actions: []
      },
      routeActionTypes: [],
      emailRecipients: [],
      serviceQuestionFolders: [],
      company: {
        details: {
          company_name: null,
          brand_name: null,
          email_signatory: null
        }
      }    
    }
  },
  methods: {
    getRouteDetails() {
      if(this.routeIdExternal !== null) {
				axios.get(process.env.VUE_APP_API_URL + '/v1/core/route/' + this.routeIdExternal)
				.then(res => {
					this.route = res.data.data;        
				})
				.catch(err => {
					console.error(err); 
				});    
      } else {
        // Reset the data 
        this.resetRouteData();
      }
    },
    insertRoute(data) {
      let params = {};
      params.routeData = data;
      (params.routeData.is_active == true) ? params.routeData.active = 'Y' : params.routeData.active = 'N';
      
      // Loop through the route actions
      for(var a = 0; a < params.routeData.actions.length; a++) {
        let routeAction = params.routeData.actions[a];
        // Create a new actionData object
        let actionData = {};
        // Add data to the actionData object bases on the action type tag
        if(routeAction.action_type.route_action_type_tag === 'send_service_question') {
          // Set the service question assignee data
          actionData.service_question_assignee = routeAction.service_question_assignee;
          // Set the service question folder data
          actionData.service_question_folder = routeAction.service_question_folder;
          // Remove the members array from the service question folder data
          if(actionData.service_question_folder['members']) delete actionData.service_question_folder['members'];
        } else if(routeAction.action_type.route_action_type_tag === 'send_email') {
          // Set the email recipients data
          actionData.email_recipients = routeAction.email_recipients;
          // Set the email content data
          actionData.email_content = routeAction.email_content;          
        }
        // Add the actionData to the action
        params.routeData.actions[a].action_data = actionData;
      }
      
      let route_name = params.routeData.route_name;
      let actions = params.routeData.actions;

      if(route_name && actions.length > 0) {
        axios.post(process.env.VUE_APP_API_URL + '/v1/core/route', params)
        .then(res => {
          this.$buefy.toast.open({ message: this.$t('routes.Route_added'), type: 'is-success', duration: 2000 });
          // Emit the update_routes event
          this.$bus.$emit('update_routes');
          // Reset the data
          this.resetRouteData();
          // Close the sidebar
          this.closeSidebarRight();         
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }
    },
    updateRoute(data) {
      let params = {}
      params.routeData = data;
      (params.routeData.is_active == true) ? params.routeData.active = 'Y' : params.routeData.active = 'N';    
           
      // Loop through the route actions
      for(var a = 0; a < params.routeData.actions.length; a++) {
        let routeAction = params.routeData.actions[a];
        // Create a new actionData object
        let actionData = {};
        // Add data to the actionData object bases on the action type tag
        if(routeAction.action_type.route_action_type_tag === 'send_service_question') {
          // Set the service question assignee data
          actionData.service_question_assignee = routeAction.service_question_assignee;
          // Set the service question folder data
          actionData.service_question_folder = routeAction.service_question_folder;
          // Remove the members array from the service question folder data
          if(actionData.service_question_folder['members']) delete actionData.service_question_folder['members'];
        } else if(routeAction.action_type.route_action_type_tag === 'send_email') {
          // Set the email recipients data
          actionData.email_recipients = routeAction.email_recipients;
          // Set the email content data
          actionData.email_content = routeAction.email_content;
        }
        // Add the actionData to the action
        params.routeData.actions[a].action_data = actionData;
      }

      let route_name = params.routeData.route_name;
      let actions = params.routeData.actions;

      if(route_name && actions.length > 0) {
        axios.put(process.env.VUE_APP_API_URL + '/v1/core/route/' + this.routeIdExternal, params)
        .then(res => {
          this.$buefy.toast.open({ message: this.$t('routes.Route_updated'), type: 'is-success', duration: 2000 });
          // Emit the update_routes event
          this.$bus.$emit('update_routes');
          // Reset the data
          this.resetRouteData();
          // Close the sidebar
          this.closeSidebarRight();  
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }      
    },    
    resetRouteData() {
      this.route = {
        route_name: null,
        route_description: null,
        is_active: true,
        actions: []
      };      
    },
    getRouteActionTypes() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/routes/actions/types')
      .then(res => {
        this.routeActionTypes = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    addRouteAction() {
      this.route.actions.push({    
        route_action_id_external: null
      });
    },
    removeRouteAction(data, index) {
      let routeActionIdExternal = data.actions[index].route_action_id_external;
      
      if(routeActionIdExternal) {
        axios.delete(process.env.VUE_APP_API_URL + '/v1/core/route/' + this.routeIdExternal + '/action/' + routeActionIdExternal)
        .then(res => {
          this.$buefy.toast.open({ message: this.$t('routes.Route_action_removed'), type: 'is-success', duration: 2000 });        
          this.route.actions.splice(index, 1);
        })
        .catch(err => {
          // console.error(err);
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        });
      } else {
        this.route.actions.splice(index, 1);
      }
    },
    setRouteActionType(routeActionTypeTag, index) { 
      if(routeActionTypeTag === 'send_service_question') {
        this.route.actions[index].service_question_assignee = null;
        this.route.actions[index].service_question_folder = null;
      } else if(routeActionTypeTag === 'send_email') {      
        this.route.actions[index].email_recipients = [];
        this.route.actions[index].email_content = {
          brand_name: this.company.details.brand_name,
          subject: '',
          content: ''
        }
      } else if(routeActionTypeTag === 'trigger_api_endpoint') {
        this.route.actions[index].api_endpoint_url = null;
      }
    },        
    customActionTypeLabel({route_action_type_tag}) {
      return this.$t('routes.Action_' + route_action_type_tag);
    },
    getServiceQuestionFolders() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/service/questions/folders')
      .then(res => {
        this.serviceQuestionFolders = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    asyncFindRecipients(searchTerm) {
      this.emailRecipients = [];
      
      if(searchTerm.length >= 3) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/recipients/' + searchTerm)
        .then(res => {      
          this.emailRecipients = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },
    getCompanyDetails() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/company')
      .then(res => {      
        this.company = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    getPlatformRoles() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/roles')
      .then(res => {      
        this.platformRoles = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkRole(roleTag) {
      if(this.platformRoles.includes(roleTag)) {
        return true;
      } else{
        return false;
      }
    },       
  },
  mounted: function() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');

    this.getPlatformRoles();
    this.getCompanyDetails();
    this.getRouteActionTypes();
    this.getServiceQuestionFolders();
  }  
}
</script>