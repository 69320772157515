<template>
  <CCard class="pointer navigation_card" @click="openSidebarRight('knowledge_subcategory_details', { kb_intent_category: subcategoryData.category, kb_intent_subcategory_id_external: subcategoryData.kb_intent_subcategory_id_external })">
    <CCardBody>
      <div class="d-flex align-items-center">
        <div v-if="subcategoryData.image_id"
            class="item_image mr-2"
            v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/knowledge-subcategory/' + subcategoryData.kb_intent_subcategory_id_external + '/' + subcategoryData.image_id + '.jpg' + '/' + clientToken + ')' }">
        </div>
        <div v-else class="item_icon d-inline-block align-middle mr-2">
          <i class="fas fa-list"/>               
        </div>
        <div class="flex-grow-1">
          <span><b>{{subcategoryData.kb_intent_subcategory_name}} ({{subcategoryData.kb_intent_category_name}})</b></span>
          <div class="meta">
            <span>{{subcategoryData.nr_of_items}} {{subcategoryData.nr_of_items !== 1 ? $t('common.items') : $t('common.item')}}</span>
          </div>
        </div>
      </div>
    </CCardBody>
  </CCard>
</template>

<script>

export default {
  name: 'knowledgeCategoryCard',
  props: ['subcategory'],
  watch: {
    $props: {
      handler() {
        if(this.subcategory) this.subcategoryData = this.subcategory;
      },
      deep: true,
      immediate: true,
    }
  },
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null,
      subcategoryData: {}
    }
  },
  mounted: function() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');
  }  
}
</script>
