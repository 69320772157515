<template>
  <div class="form_submission">
    <div class="sidebar_container">
      <div class="sidebar_header">
        <CRow class="m-0">
          <CCol cols="10" lg="10" md="10" sm="10" class="text-left">
            <span v-if="history.length > 1" class="pointer" @click="removeSidebarHistoryStep()">
              <i class="fas fa-arrow-circle-left mr-1"/>
            </span>            
            <span class="sidebar_subject">{{$t('forms.Form_submission_details')}}</span>
          </CCol>
          <CCol cols="2" lg="2" md="2" sm="2" class="text-right">
            <span @click="closeSidebarRight();"><i class="fas fa-times"/></span>        
          </CCol>
        </CRow>
      </div>
      <div class="sidebar_content" v-bind:class="{'invisible' : !formSubmissionLoaded}">
        <CRow class="m-0">
          <CCol cols="6" lg="6" class="pt-0">
            <span class="d-block field_name">{{$t('forms.Submitted_by')}}</span>
            <div class="d-flex align-items-center">
              <userProfilePopover ref="userProfilePopover" mode="user" :userName="formSubmission.submitter" :userProfileImage="formSubmission.submitter_profile_image" :userIdExternal="formSubmission.submitter_user_id_external" :senderIdExternal="null" popoverPlacement="bottom"/>
              <span class="ml-1">{{formSubmission.submitter}}</span>
            </div>
          </CCol>
          <CCol cols="6" lg="6" class="pt-0">
            <span class="d-block field_name">{{$t('forms.Submitted_at')}}</span>  
            <span>{{formSubmission.submitted | moment("dddd DD-MM-YYYY HH:mm")}}</span>
          </CCol>          
        </CRow>
        <CRow class="m-0">
          <CCol cols="6" lg="6" class="pt-0">
            <span class="d-block field_name">{{$t('common.Department')}}</span>
            <span>{{formSubmission.department_name}}</span>
          </CCol>
          <CCol cols="6" lg="6" class="pt-0">
            <span class="d-block field_name">{{$t('common.Team')}}</span>            
            <span>{{formSubmission.team_name}}</span>
          </CCol>          
        </CRow>
        <CRow class="m-0">
          <CCol cols="6" lg="6" class="pt-0">
            <span class="d-block field_name">{{$t('common.Work_location')}}</span>
            <span>{{formSubmission.work_location ? formSubmission.work_location : '-'}}</span>
          </CCol>
          <CCol cols="6" lg="6" class="pt-0">
            <span class="d-block field_name">{{$t('common.Executives')}}</span>
            <div v-if="formSubmission.submitter_team_managers.length > 0" class="user_managers d-flex align-items-center">
              <userProfilePopover ref="userProfilePopover"
                                  v-for="manager in formSubmission.submitter_team_managers"
                                  v-bind:key="manager.user_id_external"
                                  mode="user"
                                  :userName="manager.name"
                                  :userProfileImage="manager.profile_image"
                                  :userIdExternal="manager.user_id_external"
                                  :senderIdExternal="null"
                                  popoverPlacement="bottom">
              </userProfilePopover>
            </div>
            <span v-else>-</span>
          </CCol>          
        </CRow>
        <CRow v-if="formSubmission.form_routes.length > 0" class="m-0">
          <CCol cols="6" lg="6" class="pt-0">
            <span class="d-block field_name">{{$t('forms.Routes_after_submission')}}</span>
            <b-tag v-for="route in formSubmission.form_routes" v-bind:key="route.form_route_id_external" class="mt-1 info">{{route.route_name}}</b-tag>
          </CCol>
        </CRow>        

        <hr class="m-0">

        <CRow class="m-0">
          <CCol cols="12" lg="12">            
            <h2 class="form_name">{{formSubmission.form_name}}</h2>
          </CCol>
        </CRow>
        <CRow v-for="(value, index) in formSubmission.values" v-bind:key="index" class="form_field">
          <CCol cols="12" lg="12" class="pt-0">
            <span class="field_name d-block">{{value.field_name}}</span>
            <span class="field_value">{{value.field_value}}</span>
          </CCol>
        </CRow>
      </div>
      <div class="sidebar_footer">
        <CRow class="m-0">
          <CCol cols="12" lg="12">
            <CButton v-if="formSubmission.service_question_id_external" color="primary" @click="openServiceQuestion(formSubmission.service_question_id_external);">
              <span><i class="fas fa-question-circle mr-1"/>{{$t('service.Show_service_question')}}</span>
            </CButton>            
            <CButton color="secondary" @click="closeSidebarRight();" class="m-0">
              <span><i class="fas fa-times mr-1"/>{{$t('close')}}</span>
            </CButton>
          </CCol>
        </CRow>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import userProfileAvatar from '@/components/common/userProfileAvatar.vue';
import userProfilePopover from '@/components/common/userProfilePopover.vue';

export default {
  name: 'submissionDetails',
  props: ['history', 'formSubmissionIdExternal'],
  components: {
    userProfileAvatar,
    userProfilePopover
  },
  watch: {
    $props: {
      handler() {
        this.getFormSubmission();
      },
      deep: true,
      immediate: true,
    }
  },  
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null,
			formSubmission: {
        form_routes: [],
        submitter_team_managers: []
      },
      formSubmissionLoaded: false
    }
  },
  methods: {
    getFormSubmission() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/service/forms/submission/' + this.formSubmissionIdExternal)
      .then(res => {
        this.formSubmission = res.data.data;
        // Update the formSubmissionLoaded value
        this.formSubmissionLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    openServiceQuestion(serviceQuestionIdExternal) {
      // Set serviceQuestionToActivate in localStorage
      localStorage.setItem('serviceQuestionToActivate', serviceQuestionIdExternal)
      // Navigate to service questions page
      this.$router.push({path: '/service/questions'});
    }
  },
  mounted: function() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');
  }
}
</script>