<template>
  <CCard class="kb mb-0" v-bind:class="{'preview' : preview === true}">
    <CCardBody>
      <CRow>
        <CCol cols="12" lg="12" class="pt-0">
          <div class="d-flex">
            <div class="flex-grow-1">
              <p class="m-0"><b>{{itemData.item_title}}</b></p>
              <span class="meta">{{itemData.kb_intent_category_name}} > {{itemData.kb_intent_subcategory_name}}</span>
            </div>
            <div class="actions pl-2">
              <span v-if="editable === true && itemData.from_marketplace === 'N'" class="pointer" @click="openSidebarRight('knowledge_item_details', { kb_intent_subcategory: itemData.subcategory, kb_intent_id_external: itemData.kb_intent_id_external, back_to_preview: true })">
               <i class="fas fa-pen"/></span>
            </div>          
          </div>
        </CCol>
      </CRow>
      <CRow>
        <CCol cols="12" lg="12" class="pt-0 pb-0">
          <div v-if="itemData.item_content" id="knowledgeItemContent" class="m-0 text_container" v-bind:class="{'scrollable' : scrollableContent === true}" v-html="itemData.item_content"></div>
          <span v-else>{{ $t('knowledge.No_content_yet') }}</span>
        </CCol>
      </CRow>
      <CRow v-if="itemData.attachments && itemData.attachments.length > 0">
        <CCol cols="12" lg="12" class="pb-0">
          <div class="w-100 attachments">
            <div class="attachment"
                 v-for="(attachment, index) in itemData.attachments"
                 v-bind:key="attachment.kb_intent_content_attachment_id"
                 v-bind:class="{'mb-2' : index < (itemData.attachments.length - 1)}">

              <div v-if="attachment.attachment_type_tag === 'video'">
                <p v-if="attachment.attachment_name !== null" class="mt-0 mb-2"><b>{{attachment.attachment_name}}</b></p>

                <div v-for="video in attachment.attachment_videos" v-bind:key="video.kb_intent_content_video_id">
                  <div v-if="video.video_available === 'Y'">
                    <div v-if="video.video_provider_tag === 'youtube'">
                      <youtube :video-id="video.provider_video_id" ref="youtube" :player-vars="playerVars" :fitParent="true" class="w-100"></youtube>            
                    </div>
                    <div v-if="video.video_provider_tag === 'dyntube'" class="dyntube_iframe_container" v-bind:style="{ paddingTop: video.video_container_padding_percentage + '%' }">
                      <iframe class="dyntube_responsive_iframe" webkitallowfullscreen mozallowfullscreen allowfullscreen :src="'https://play.dyntube.io/iframe/' + video.provider_video_private"></iframe>
                    </div>                    
                  </div>
                  <videoPlaceholder v-else emoji="📹" :content="$t('common.Video_almost_available')"/>                        
                </div>
              </div>
              
              <div v-if="attachment.attachment_type_tag === 'picture'">
                <p v-if="attachment.attachment_name !== null" class="mt-0 mb-2"><b>{{attachment.attachment_name}}</b></p>
                <div v-if="itemData.from_marketplace === 'N'">
                  <img :src="apiBaseUrl + '/v1/common/cdn/file/image/knowledge-item/' + itemData.kb_intent_id_external + '/' + attachment.attachment + '/' + clientToken" class="attachment_image"/>
                </div>
                <div v-else-if="itemData.from_marketplace === 'Y'">
                  <img :src="apiBaseUrl + '/v1/common/cdn/file/image/knowledge-item-mp/' + itemData.kb_intent_id_external + '/' + attachment.attachment + '/' + clientToken" class="attachment_image"/>
                </div>                
              </div>

              <div v-if="attachment.attachment_type_tag === 'document'">
                <div v-if="itemData.from_marketplace === 'N'">
                  <CButton class="icon_button m-0 w-100 d-flex align-items-center" color="primary" @click="openExternalLink(apiBaseUrl + '/v1/common/cdn/file/pdf/knowledge-item/' + itemData.kb_intent_id_external + '/' + attachment.attachment + '/' + clientToken)">
                    <i class="fa-solid fa-paperclip button_icon"></i>
                    <span v-line-clamp="1" class="button_text">{{attachment.attachment_name !== null ? attachment.attachment_name : $t('sw.pdf_attachment')}}</span>
                  </CButton>
                </div>
                <div v-if="itemData.from_marketplace === 'Y'">
                  <CButton class="icon_button m-0 w-100 d-flex align-items-center" color="primary" @click="openExternalLink(apiBaseUrl + '/v1/common/cdn/file/pdf/knowledge-item-mp/' + itemData.kb_intent_id_external + '/' + attachment.attachment + '/' + clientToken)">
                    <i class="fa-solid fa-paperclip button_icon"></i>
                    <span v-line-clamp="1" class="button_text">{{attachment.attachment_name !== null ? attachment.attachment_name : $t('sw.pdf_attachment')}}</span>
                  </CButton>
                </div>                
              </div>

              <div v-if="apiBaseUrl && clientToken && attachment.attachment_type_tag === 'audio'">
                <div v-if="itemData.from_marketplace === 'N'">
                  <audioPlayer :standalone="false"
                              :audioFileName="attachment.attachment_name"
                              :audioSourceUrl="apiBaseUrl + '/v1/common/cdn/file/mp3/knowledge-item/' + itemData.kb_intent_id_external + '/' + attachment.attachment + '/' + clientToken">
                  </audioPlayer>
                </div>
                <div v-else-if="itemData.from_marketplace === 'Y'">
                  <audioPlayer :standalone="false"
                              :audioFileName="attachment.attachment_name"
                              :audioSourceUrl="apiBaseUrl + '/v1/common/cdn/file/mp3/knowledge-item-mp/' + itemData.kb_intent_id_external + '/' + attachment.attachment + '/' + clientToken">
                  </audioPlayer>
                </div>                
              </div>         

              <div v-if="attachment.attachment_type_tag === 'link_external'">
                <CButton class="icon_button m-0 w-100 d-flex align-items-center" color="primary" @click="openExternalLink(attachment.attachment)">
                  <i class="fa-solid fa-link button_icon"></i>               
                  <span v-line-clamp="1" class="button_text">{{attachment.attachment_name !== null ? attachment.attachment_name : $t('sw.external_url')}}</span>
                </CButton>
              </div>

              <div v-if="attachment.attachment_type_tag === 'link_internal'">
                <CButton class="icon_button m-0 w-100 d-flex align-items-center" color="primary" @click="openAppPath(attachment.attachment)">
                  <i class="fa-solid fa-link button_icon"></i>              
                  <span v-line-clamp="1" class="button_text">{{attachment.attachment_name !== null ? attachment.attachment_name : $t('common.Redirect_in_experience')}}</span>
                </CButton>
              </div>              
            </div>
          </div>
        </CCol>
      </CRow>
      <CRow v-if="itemData.from_marketplace === 'N'">
        <CCol cols="12" lg="12" class="pb-0">
          <div class="disclaimer">
            <span class="meta">
              <div class="d-flex align-items-top author mb-2">
                <div class="updated" v-if="itemData.author" @click="openProfilePage(itemData.item_author, itemData.author_user_id_external);">                            
                  <userProfilePopover ref="userProfilePopover" mode="user" :userName="itemData.author" :userProfileImage="itemData.author_profile_image" :userIdExternal="itemData.author_user_id_external" :senderIdExternal="null" :popoverPlacement="preview === true ? 'bottom' : 'right'" class="user_image mr-2"/>
                </div>
                <span>{{$t('common.Item_created')}} <span v-if="itemData.author" @click="openProfilePage(itemData.item_author, itemData.editor_user_id_external);">{{$t('by') + ' ' + itemData.author + ' '}}</span>{{$t('on')}} {{itemData.created | moment("YYYY-MM-DD")}} {{$t('at')}} {{itemData.created | moment("HH:mm")}}.</span>              
              </div>
              <div v-if="itemData.last_update !== itemData.created" class="d-flex align-items-top editor">
                <div class="updated" v-if="itemData.editor">                            
                  <userProfilePopover ref="userProfilePopover" mode="user" :userName="itemData.editor" :userProfileImage="itemData.editor_profile_image" :userIdExternal="itemData.editor_user_id_external" :senderIdExternal="null" :popoverPlacement="preview === true ? 'bottom' : 'right'" class="user_image mr-2"/>
                </div>
                <span>{{$t('common.Last_item_update')}} <span v-if="itemData.editor" @click="openProfilePage(itemData.item_editor, itemData.editor_user_id_external);">{{$t('by') + ' ' + itemData.editor + ' '}}</span>{{$t('on')}} {{itemData.last_update | moment("YYYY-MM-DD")}} {{$t('at')}} {{itemData.last_update | moment("HH:mm")}}.</span>
              </div>
            </span>
          </div>
        </CCol>
      </CRow>

      <hr v-if="showLikes && itemData.likes" class="mt-2 mb-2">      

      <CRow v-if="showLikes && itemData.likes">
        <CCol cols="12" lg="12" class="pt-0 pb-0 item_likes">
          <div class="mr-2 type likes">
            <span class="mr-1" v-html="getTwemoji(itemData.likes.like_type)"></span>
            <span>{{ itemData.likes.like_count }}</span>                          
          </div>
          <div class="mr-2 type dislikes">
            <span class="mr-1" v-html="getTwemoji(itemData.dislikes.like_type)"></span>
            <span>{{ itemData.dislikes.like_count }}</span>    
          </div>
          <div class="mr-2 type waves">
            <span class="mr-1" v-html="getTwemoji(itemData.waves.like_type)"></span>
            <span>{{ itemData.waves.like_count }}</span>           
          </div>
          <div class="type hearts">
            <span class="mr-1" v-html="getTwemoji(itemData.hearts.like_type)"></span>
            <span>{{ itemData.hearts.like_count }}</span>
          </div>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import axios from 'axios'
import userProfilePopover from '@/components/common/userProfilePopover.vue';
import videoPlaceholder from '@/components/common/videoPlaceholder.vue';
import audioPlayer from '@/components/common/audioPlayer.vue';

export default {
  name: 'knowledgeItem',
  props: ['itemData', 'navigatable', 'editable', 'preview', 'showLikes', 'scrollableContent'],
  components: {
    userProfilePopover,
    videoPlaceholder,
    audioPlayer
  },  
  data() {
    return {
      modules: {
        social_kb: 0,
        employee_loyalty_programme: 0
      },
      apiBaseUrl: null,
      appBaseUrl: null,
      clientToken: null,      
      playerVars: { 
        autoplay: 0, 
        controls: 0,
        playsinline: 1
      }
    } 
  },
  methods: {
    openAppPath(path) {
      window.open(this.appBaseUrl + '/' + path);            
    },
    openExternalLink(url) {
      window.open(url, '_blank');
    },
    getTwemoji(emoji) {
      return twemoji.parse(emoji);
    },
    checkModules() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/modules')
      .then(res => {      
        this.modules = res.data.data;    
      })
      .catch(err => {
        console.error(err); 
      });
    }      
  },
  mounted: function() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.appBaseUrl = process.env.VUE_APP_URL;
    this.clientToken = localStorage.getItem('token');

    this.checkModules();
  }
}
</script>