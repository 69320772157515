<template>
  <span v-if="mode === 'icon'" class="pointer" @click="openExperience()">
    <i v-if="linkIcon" :class="'fas fa-' + linkIcon"/>
    <i v-else class="fas fa-external-link-alt"/>
  </span>
    
  <CButton v-else-if="mode === 'button'" class="m-0 w-100 d-flex align-items-center" color="primary" @click="openExperience()">
    <i v-if="linkIcon" :class="'fas fa-' + linkIcon"/>
    <i v-else class="fas fa-external-link-alt"/>
    <span v-line-clamp="1" class="ml-1">{{$t('common.Open_in_experience')}}</span>
  </CButton>
</template>

<script>

export default {
  name: 'openExperience',
  props: ['mode', 'experiencePath', 'linkIcon'],
  methods: {
    openExperience() {
      window.open(process.env.VUE_APP_URL + '/' + this.experiencePath, '_blank');
    }
  }
}
</script>