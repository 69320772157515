<template>
  <CFooter>
    <div class="mr-auto">
      <span v-if="environmentTag === 'harryhr'">© {{new Date() | moment("YYYY")}} - <a href="https://harryhr.com" target="_blank">Harry HR</a> - {{$t('footer.All_rights_reserved')}}.</span>
      <span v-if="environmentTag === 'employalty'">© {{new Date() | moment("YYYY")}} - <a href="https://employalty.app/" target="_blank">Employalty</a> - {{$t('footer.All_rights_reserved')}}.</span>
      <span v-if="environmentTag === 'vitaalbegrip'">© {{new Date() | moment("YYYY")}} - <a href="https://vitaalbegrip.nl/" target="_blank">VitaalBegrip</a> - {{$t('footer.All_rights_reserved')}}.</span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter',
  data() {
    return {
      environmentTag: null
    }
  },
  mounted: function() {
    if(localStorage.getItem('environmentTag') !== null) this.environmentTag = localStorage.getItem('environmentTag');
  }
}
</script>
