<template>
  <div>
    <div class="sidebar_container">
      <div class="sidebar_header">
        <CRow class="m-0">
          <CCol cols="10" lg="10" md="10" sm="10" class="text-left">
            <span v-if="history.length > 1" class="pointer" @click="removeSidebarHistoryStep()">
              <i class="fas fa-arrow-circle-left mr-1"/>
            </span>
            <span class="sidebar_subject">
              {{$t('communications.New_moment')}}
            </span>
          </CCol>
          <CCol cols="2" lg="2" md="2" sm="2" class="text-right">
            <span @click="closeSidebarRight();"><i class="fas fa-times"/></span>    
          </CCol>      
        </CRow>
      </div>
      <div class="sidebar_content">
        <CRow class="m-0">
          <CCol :cols="productTag === 'loyalty' ? 6 : 12" :lg="productTag === 'loyalty' ? 6 : 12" class="pt-0">
            <CInput type="text" :label="$t('communications.Custom_moment_name')" class="mb-0" v-model="moment.description" required was-validated/>
          </CCol>
          <CCol :cols="productTag === 'loyalty' ? 6 : 12" :lg="productTag === 'loyalty' ? 6 : 12" class="pt-0">
            <CInput type="text" :label="$t('communications.Custom_moment_tag')" class="mb-0" v-model="moment.tag" required was-validated/>
          </CCol>         
        </CRow>

        <div v-if="productTag === 'loyalty'">
          <!-- Icon -->
          <CRow class="m-0">
            <CCol cols="6" lg="6" class="pt-0">
              <label>
                <span>{{$t('communications.Upload_moment_icon')}}</span>
              </label>
              <image-uploader class="image_upload" :preview="true" :className="['fileinput', { 'fileinput--loaded': momentImageUploaded }]" capture="environment" :debug="0" doNotResize="gif" :autoRotate="true" outputFormat="verbose" @input="setMomentImage">
                <label for="fileInput" slot="upload-label" class="m-0">
                  <span class="upload-caption btn btn-primary m-0"><i class="fas fa-file-upload mr-1"/>{{momentImageUploaded ? $t('common.Replace_image') : $t('common.Upload_icon')}}</span>
                </label>
              </image-uploader>
            </CCol>
          </CRow>
          <!-- Countries -->
          <CRow class="m-0">
            <CCol cols="12" lg="12" class="pt-0 locale-changer">
              <label>{{$t('communications.Moment_countries')}}</label>
              <multiselect
                class="open_absolute"
                v-model="moment.countries" 
                :options="countries" 
                :multiple="true"
                :hideSelected="false"
                :closeOnSelect="true"
                track-by="country_id_external" 
                label="name"
                :show-labels="false">
                <span slot="noResult">{{$t('common.no_countries_found')}}</span>

                <template slot="singleLabel" slot-scope="country">
                  <div class="d-flex align-items-center">
                    <span v-if="country.option.flag" class="language_flag" v-html="getTwemoji(country.option.flag)"></span>
                    <span class="language_name">{{ country.option.name }}</span>
                  </div>
                </template>

                <template slot="option" slot-scope="country">
                  <div class="d-flex align-items-center">
                    <span v-if="country.option.flag" class="language_flag" v-html="getTwemoji(country.option.flag)"></span>
                    <span class="language_name">{{ country.option.name }}</span>
                  </div>
                </template>
              </multiselect>
            </CCol>
          </CRow>
          <!-- Loyalty points -->
          <CRow class="m-0">
            <CCol cols="12" lg="12" class="pt-0 pb-2">
              <span><b>{{$t('communications.Loyalty_points')}}</b></span>
            </CCol>
            <CCol cols="12" lg="12" class="pt-0">
              <CInput type="number" 
                      v-model="moment.points"
                      :label="$t('communications.points_received_for_moment')"
                      :required="productTag === 'loyalty'"
                      :was-validated="productTag === 'loyalty'"
                      class="mb-0">
              </CInput>
            </CCol>
          </CRow>
          <!-- Send on date -->
          <CRow class="m-0">
            <CCol cols="12" lg="12" class="pt-0 pb-2">
              <span><b>{{$t('communications.Send_on_date')}}</b></span>
            </CCol>        
            <CCol cols="12" lg="12" class="pt-0">
              <label>{{$t('communications.Send_communication_on_date')}}</label>
              <b-datetimepicker v-model="moment.send_on_date" :placeholder="$t('common.click_to_select')" editable :datepicker="datepickerOptions" :timepicker="timepickerOptions"></b-datetimepicker>
            </CCol>
          </CRow>
        </div>
      </div>
      <div class="sidebar_footer">
        <CRow class="m-0">
          <CCol cols="12" lg="12">
            <div>
              <CButton color="primary" @click="insertCustomMoment();"><i class="fas fa-check mr-1"/>{{ $t('Save') }}</CButton>
              <CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{ $t('Dismiss') }}</CButton>
            </div>
          </CCol>
        </CRow>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import QuickEdit from 'vue-quick-edit';
import ImageUploader from 'vue-image-upload-resize';

export default {
  name: 'customProductAutoMoment',
  props: ['history', 'productTag'],
  components: {
    Multiselect,
    QuickEdit,
    ImageUploader
  },
  data() {
    return {
      moment: {},
      momentImageUploaded: false,
      countries: [],
      datepickerOptions: {
        yearsRange: [0, 10],
        firstDayOfWeek: 1,
        showWeekNumber: true
      },
      timepickerOptions: {
        incrementHours: 1,
        incrementMinutes: 15
      }      
    }
  },
  methods: {
    insertCustomMoment() {
      let params = {};
      params.momentData = this.moment;
      
      if(params.momentData.send_on_date) {
        params.momentData.event_send_on_date = this.$luxon(params.momentData.send_on_date.toISOString(), "yyyy-MM-dd HH:mm:ss");
      } else {
        params.momentData.event_send_on_date = null;
      }

      if(this.validateMoment(params) === true) {
        axios.post(process.env.VUE_APP_API_URL + '/v1/core/event/' + this.productTag + '/moment/custom', params)
        .then(res => {
          // Show a success notice
          this.$buefy.toast.open({ message: this.$t('communications.Moment_added'), type: 'is-success', duration: 3000 });
          // Emit the custom_moment_updated event based on the product tag
          this.$bus.$emit('custom_' + this.productTag + '_moment_added');
          // Reset the data
          this.resetmomentData();
          // Close the sidebar
          this.closeSidebarRight();          
        })
        .catch(err => {
          if(err.response.data.code === 'Doublure Event Tag') {
            this.$buefy.toast.open({ message: this.$t('communications.Custom_moment_tag_already_exists'), type: 'is-danger', duration: 2000 });
          } else {
            this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
          }
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }
    },
    validateMoment(params) {
      let description = params.momentData.description;
      let tag = params.momentData.tag;              
      let points = params.momentData.points;
      let send_on_date = params.momentData.send_on_date;      

      // Check if base information for the moment is available
      if(description && tag) {
        // Check if the config settings are set correctly
        if(this.productTag === 'loyalty' && (!points || !send_on_date)) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },    
    getCountries() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/common/countries/multiselect')
      .then(res => {      
        this.countries = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    setMomentImage(output) {
      this.momentImageUploaded = true;
      this.moment.image = output.dataUrl;
    },    
    resetmomentData() {
      this.moment = {
        description: null,
        tag: null,
        image: null,
        points: null,
        send_on_date: null
      };

      if(this.productTag === 'loyalty') this.moment.points = 50;
    },
    getTwemoji(emoji) {
      return twemoji.parse(emoji);
    }    
  },
  mounted: function() {
    this.resetmomentData();
    this.getCountries();
  }  
}
</script>