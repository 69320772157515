<template>
  <div>
    <div class="sidebar_container">
      <div class="sidebar_header">
        <CRow class="m-0">
          <CCol cols="10" lg="10" md="10" sm="10" class="text-left">
            <span v-if="history.length > 1" class="pointer" @click="removeSidebarHistoryStep()">
              <i class="fas fa-arrow-circle-left mr-1"/>
            </span>            
            <span class="sidebar_subject">
							{{!bmQuestionIdExternal ? $t('sq.New_burning_matter') : $t('sq.Burning_matter_details')}}
            </span>
          </CCol>
          <CCol cols="2" lg="2" md="2" sm="2" class="text-right">
            <span @click="closeSidebarRight();"><i class="fas fa-times"/></span>        
          </CCol>
        </CRow>
      </div>
      <div class="sidebar_content">
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <div v-if="!bmQuestionIdExternal">
              <CInput class="mb-0" type="text" :label="$t('sq.Burning_matter_question')" v-model="burningMatter.question" required was-validated/>
            </div>
            <div v-else>
              <span class="d-block mb-1"><b>{{$t('common.Question')}}</b></span>
              <span>{{burningMatter.question}}</span>
            </div>
          </CCol>
        </CRow>
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <div>
              <span v-if="!bmQuestionIdExternal">{{$t('sq.Burning_matter_answer_options')}}</span>
              <span v-else><b>{{$t('common.Answer_options')}}</b></span>
            </div>
            <div class="answer_option mt-1" v-for="(answer_option, index) in burningMatter.answer_options" v-bind:key="answer_option.number">
              <span class="mb-2">{{$t('common.Answer_option')}} {{answer_option.number}}</span>
              <div class="answer_emoji text-center">
                <CTextarea class="mb-0" v-model="answer_option.emoji" :required="index < 2" :was-validated="index < 2" :disabled="bmQuestionIdExternal"/>
                <span>{{$t('common.Emoji')}}</span>
                <emoji-picker v-if="!bmQuestionIdExternal" :search="search">
                  <div class="emoji-invoker" slot="emoji-invoker" slot-scope="{events: {click: clickEvent}}" @click.stop="clickEvent">
                    <i class="icon fas fa-plus"/>
                  </div>
                  <div slot="emoji-picker" slot-scope="{ emojis }">
                    <div class="emoji-picker">
                      <div class="emoji-picker__search">
                        <input type="text" v-model="search" v-focus>
                      </div>
                        <div>
                        <div v-for="(emojiGroup, category) in emojis" :key="category">
                          <h5>{{ category }}</h5>
                          <div class="emojis">
                            <span v-for="(emoji, emojiName) in emojiGroup" :key="emojiName" @click="insertAnswerEmoji(emoji, index)" :title="emojiName">{{emoji}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </emoji-picker>
              </div>
              <div class="answer_score text-center">
                <CTextarea class="mb-0" v-model="answer_option.score" :required="index < 2" :was-validated="index < 2" :disabled="bmQuestionIdExternal"/>
                <span>{{$t('common.Score')}}</span>
              </div>
              <div class="answer_definition mt-1 text-center">
                <CInput class="mb-0" v-model="answer_option.definition" :disabled="bmQuestionIdExternal"/>
                <span>{{$t('common.Explanation')}}</span>
              </div>                               
            </div>
          </CCol>
        </CRow>
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0" v-bind:class="{'pb-0' : bmQuestionIdExternal && !burningMatter.subtopic}">
            <div v-if="!bmQuestionIdExternal">
              <label>{{$t('common.Select_subtopic')}}</label>
              <multiselect
                class="data_table"
                v-model="burningMatter.subtopic" 
                :options="subtopics"
                :multiple="false"
                :close-on-select="true"
                :placeholder="$t('common.Select_subtopic')" 
                :selectLabel="$t('common.Add_subtopic')" 
                :selectedLabel="$t('Added')"
                :deselectLabel="$t('common.Remove_subtopic')"
                track-by="subtopic_id_external" 
                label="label">
                <span slot="noResult">{{ $t('common.no_subtopics_found') }}</span>
              </multiselect>
            </div>
            <div v-else-if="bmQuestionIdExternal && burningMatter.subtopic">
              <span class="d-block mb-1"><strong>{{$t('common.Subtopic')}}</strong></span>
              <span>{{burningMatter.subtopic.label}}</span>
            </div>
          </CCol>            
        </CRow>
        <CRow class="m-0">      
          <CCol cols="12" lg="12" class="pt-0" v-bind:class="{'pb-0' : bmQuestionIdExternal && burningMatter.target_groups.length === 0}">
            <div v-if="!bmQuestionIdExternal">
              <label>{{$t('common.Specified_for_groups')}}</label>
              <multiselect
                class="data_table"
                v-model="burningMatter.target_groups" 
                :options="targetGroups" 
                :multiple="true"
                :placeholder="$t('common.Select_group')" 
                :selectLabel="$t('common.Add_group')" 
                :selectedLabel="$t('Added')"
                :deselectLabel="$t('common.Remove_group')"
                track-by="group_id" 
                label="title">
                <span slot="noResult">{{ $t('common.no_groups_found') }}</span>
              </multiselect>
            </div>
            <div v-else-if="bmQuestionIdExternal && burningMatter.target_groups.length > 0">
              <span class="d-block mb-1"><strong>{{$t('common.Groups')}}</strong></span>
              <div class="item_tags">
                <b-tag v-for="group in burningMatter.target_groups" v-bind:key="group.group_id" class="mt-1 info">{{group.title}}</b-tag>
              </div>
            </div>                      
          </CCol>            
        </CRow>
        <div v-if="!bmQuestionIdExternal">
          <CRow class="m-0">
            <CCol cols="6" lg="6" class="pt-0 pb-0">
              <CInput class="mb-0" type="date" :label="$t('sq.Ask_burning_matter_from')" v-model="burningMatter.start_date" required was-validated/>
            </CCol>
            <CCol cols="6" lg="6" class="pt-0 pb-0">
              <CInput class="mb-0" type="date" :label="$t('sq.Ask_burning_matter_till')" v-model="burningMatter.end_date" required was-validated/>
            </CCol>
          </CRow>
        </div>
        <div v-else>
          <CRow class="m-0">
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <span class="d-block mb-1"><strong>{{$t('measure.Measured_in_period')}}</strong></span>
              <span>{{burningMatter.start_date}} {{$t('till')}} {{burningMatter.end_date}}</span>
            </CCol>
          </CRow>
        </div>
      </div>
      <div class="sidebar_footer">
        <CRow class="m-0">
          <CCol cols="12" lg="12">
						<div v-if="!bmQuestionIdExternal">
							<CButton color="primary" @click="insertBurningMatter(burningMatter);"><i class="fas fa-check mr-1"/>{{$t('Save')}}</CButton>
							<CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{$t('Dismiss')}}</CButton>
						</div> 						   
						<div v-else>
              <!-- <CButton color="primary" @click="updateBurningMatter(burningMatter);"><i class="fas fa-check mr-1"/>{{$t('Save')}}</CButton> -->
							<CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{$t('close')}}</CButton>
          	</div>
          </CCol>
        </CRow>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import QuickEdit from 'vue-quick-edit';
import EmojiPicker from 'vue-emoji-picker';

export default {
  name: 'satisquestionDetails',
  props: ['history', 'bmQuestionIdExternal'],
  components: {
    Multiselect,
    QuickEdit,
    EmojiPicker
  },
  watch: {
    $props: {
      handler() {
        this.getBurningMatterDetails();
      },
      deep: true,
      immediate: true,
    }
  },
  directives: {
    focus: {
      inserted(el) {
        el.focus()
      },
    },
  },   
  data() {
    return {
			burningMatter: {
        target_groups: []
      },
      subtopics: [],
      targetGroups: [],
      input: '',
      search: ''
    }
  },
  methods: {
    getBurningMatterDetails() {
      if(this.bmQuestionIdExternal !== null) {
				axios.get(process.env.VUE_APP_API_URL + '/v1/measure/burning_matter/' + this.bmQuestionIdExternal)
				.then(res => {
					this.burningMatter = res.data.data;   
				})
				.catch(err => {
					console.error(err); 
				});    
      } else {
        // Reset the data 
        this.resetBurningMatterData();
      }
    },
    insertBurningMatter(data) {
      let params = {};
      params.burningMatterData = data;
      
      let question = params.burningMatterData.question;
      let answer1 = params.burningMatterData.answer_options[0];
      let answer2 = params.burningMatterData.answer_options[1];
      let startDate = params.burningMatterData.start_date;
      let endDate = params.burningMatterData.end_date;
      let target_groups = params.burningMatterData.target_groups;

      if(question && answer1.emoji && answer1.score && answer2.emoji && answer2.score && startDate && endDate && target_groups.length > 0) {
        axios.post(process.env.VUE_APP_API_URL + '/v1/measure/burning_matter', params)
        .then(res => {
          this.$buefy.toast.open({ message: this.$t('sq.Burning_matter_added'), type: 'is-success', duration: 2000 });
          // Emit the update_burning_matters event
          this.$bus.$emit('update_burning_matters');
          // Reset the data
          this.resetBurningMatterData();
          // Close the sidebar
          this.closeSidebarRight();
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }
    },
    updateBurningMatter(data) {
      let params = {}
      params.burningMatterData = data;
    
      let question = params.burningMatterData.question;
      let startDate = params.burningMatterData.start_date;
      let endDate = params.burningMatterData.end_date;
      let target_groups = params.burningMatterData.target_groups;

      if(question && startDate && endDate && target_groups.length > 0) {
        // Set the answer definitions
        for(var i = 1; i <= params.burningMatterData.answer_options.length; i++) {        
          params.burningMatterData['answer_' + i + '_definition'] = params.burningMatterData.answer_options[i-1].definition;
        }

        axios.put(process.env.VUE_APP_API_URL + '/v1/measure/burning_matter/' + this.bmQuestionIdExternal, params)
        .then(res => {
          this.$buefy.toast.open({ message: this.$t('sq.Burning_matter_updated'), type: 'is-success', duration: 2000 });
          // Emit the update_burning_matter_details event
          this.$bus.$emit('update_burning_matter_details', this.bmQuestionIdExternal);
          // Reset the data
          this.resetBurningMatterData();
          // Close the sidebar
          this.closeSidebarRight();
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }      
    },
    insertAnswerEmoji(emoji, index) {
      this.burningMatter.answer_options[index].emoji = emoji;
    },
    getSubtopics() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/connect/subtopics/multiselect')
      .then(res => {
        this.subtopics = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getTargetGroups() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/targetgroups/multiselect')
      .then(res => {
        this.targetGroups = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },      
    resetBurningMatterData() {
      this.burningMatter = {
        question: null,
        answer_options: [
          {number: 1, emoji: null, score: null, definition: null},
          {number: 2, emoji: null, score: null, definition: null},
          {number: 3, emoji: null, score: null, definition: null},
          {number: 4, emoji: null, score: null, definition: null}
        ],        
        target_groups: [],
        start_date: null,
        end_date: null
      }
    }				
  },
  mounted: function() {
    this.getSubtopics();
    this.getTargetGroups();
  }  
}
</script>