<template>
  <div>
    <div class="sidebar_container">
      <div class="sidebar_header">
        <CRow class="m-0">
          <CCol cols="10" lg="10" md="10" sm="10" class="text-left">
            <span v-if="history.length > 1" class="pointer" @click="removeSidebarHistoryStep()">
              <i class="fas fa-arrow-circle-left mr-1"/>
            </span>            
            <span class="sidebar_subject">
              {{!resource.resource_id_external ? $t('resources.New_resource') : $t('resources.Edit_resource')}}          
            </span>
          </CCol>
          <CCol cols="2" lg="2" md="2" sm="2" class="text-right">
            <span @click="closeSidebarRight();"><i class="fas fa-times"/></span>
          </CCol>
        </CRow>
      </div>
      <div class="sidebar_content">
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <quick-edit type="text" mode="ignore" maxLength="25" :buttonOkText="$t('Save')" :buttonCancelText="$t('Dismiss')" v-model="resource.resource_name">                        
              <h2 class="m-0">{{resource.resource_name ? resource.resource_name : $t('common.Click_to_set_name')}}<i class="icon edit fas fa-pen ml-1"/></h2>
            </quick-edit> 
          </CCol>           
        </CRow>

        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <quick-edit type="textarea" rows="5"  maxLength="100" mode="ignore" :buttonOkText="$t('Save')" :buttonCancelText="$t('Dismiss')" v-model="resource.description">
              <span>{{resource.description ? resource.description : $t('common.Click_to_set_description')}}<i class="icon edit fas fa-pen ml-1"/></span>
            </quick-edit>            
          </CCol>            
        </CRow>
        
        <CRow class="m-0">       
          <CCol cols="12" lg="12" class="pt-0">            
            <label>{{ $t('resources.Select_icon') }} <span v-if="resource.icon_class !== null">({{ $t('resources.Current_icon') }}: <i :class="'fas fa-' + resource.icon_class"></i>)</span></label>
            <font-awesome-picker  class="w-100" v-on:selectIcon="setIconClass" :seachbox="$t('resources.Search_icon')"></font-awesome-picker>
          </CCol>                   
        </CRow>

        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <CInput type="text" :label="$t('resources.content')" class="mb-0" v-model="resource.content" required was-validated/>
          </CCol>                   
        </CRow>        

        <CRow class="m-0">      
          <CCol cols="12" lg="12" class="pt-0">
            <label>{{ $t('common.Specified_for_groups') }}</label>
            <multiselect
              class="data_table"
              v-model="resource.groups" 
              :options="targetGroups" 
              :multiple="true"
              :placeholder="$t('common.Select_group')" 
              :selectLabel="$t('common.Add_group')" 
              :selectedLabel="$t('Added')"
              :deselectLabel="$t('common.Remove_group')"
              track-by="group_id" 
              label="title">
              <span slot="noResult">{{ $t('common.no_groups_found') }}</span>
            </multiselect>          
          </CCol>            
        </CRow>

        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="resource.is_active" size="is-small">{{ $t('is_active') }}</b-switch>
          </CCol>
        </CRow>

      </div>
      <div class="sidebar_footer">
        <CRow class="m-0">
          <CCol cols="12" lg="12">
            <div v-if="!resource.resource_id_external">
              <CButton color="primary" @click="insertResource();"><i class="fas fa-check mr-1"/>{{$t('Save')}}</CButton>
              <CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{$t('Dismiss')}}</CButton>
            </div>
            <div v-else>
              <CButton color="primary" @click="updateResource(resource);"><i class="fas fa-check mr-1"/>{{$t('Update')}}</CButton>
              <CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{ $t('Dismiss') }}</CButton>
            </div>      
          </CCol>
        </CRow>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import QuickEdit from 'vue-quick-edit';
import Multiselect from 'vue-multiselect';
import { fontAwesomePicker } from 'font-awesome-picker';

export default {
  name: 'resourceDetails',
  props: ['history', 'resourceIdExternal'],
  components: {
    QuickEdit,
    Multiselect,
    fontAwesomePicker
  },
  watch: {
    $props: {
      handler() {
        this.getResourceDetails();
      },
      deep: true,
      immediate: true,
    }
  }, 
  data() {
    return {
      resource: {
        headcount: {
          headcount_total: 0
        }
      },
      targetGroups: []
    }
  },
  methods: {
    getResourceDetails() {
      if(this.resourceIdExternal !== null) {
        axios.get(process.env.VUE_APP_API_URL + '/v1/hub/resource/' + this.resourceIdExternal)
        .then(res => {
          this.resource = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });                  
      } else {
        // Reset the resource data
        this.resetResourceData();
      }
    },
    insertResource() {
      let params = {};
      params.resourceData = this.resource;
      if(params.resourceData.description === '') params.resourceData.description = null;

      let resource_name = params.resourceData.resource_name;
      let content = params.resourceData.content; 
      let target_groups = params.resourceData.groups;

      if(resource_name && content && target_groups.length > 0) {
        axios.post(process.env.VUE_APP_API_URL + '/v1/hub/resource', params)
        .then(res => {
          // Show a success notice
          this.$buefy.toast.open({ message: this.$t('resources.Resource_added'), type: 'is-success', duration: 2000 });
          // Emit the update_resources event
          this.$bus.$emit('update_resources');          
          // Reset the resource data
          this.resetResourceData();
          // Close the sidebar
          this.closeSidebarRight();
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }
    },
    updateResource(data) {
      let params = {}
      params.resourceData = data;
      if(params.resourceData.description === '') params.resourceData.description = null;
      (params.resourceData.is_active === true) ? params.resourceData.active = 'Y' : params.resourceData.active = 'N';

      let resource_name = params.resourceData.resource_name;
      let content = params.resourceData.content;
      let target_groups = params.resourceData.groups;

      if(resource_name && content && target_groups.length > 0) {
        axios.put(process.env.VUE_APP_API_URL + '/v1/hub/resource/' + this.resourceIdExternal, params)
        .then(res => {
          // Show a success notice
          this.$buefy.toast.open({ message: this.$t('resources.Resource_updated'), type: 'is-success', duration: 2000 });
          // Emit the update_resource_details event
          this.$bus.$emit('update_resource_details', this.resourceIdExternal);  
          // Reset the resource data
          this.resetResourceData();
          // Close the sidebar
          this.closeSidebarRight();
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }            
    },
    getTargetGroups() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/targetgroups/multiselect')
      .then(res => {
        this.targetGroups = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },   
    resetResourceData () {
      this.resource = {
        resourse_name: '',
        description: '',
        content: '',
        icon_class: null,
        groups: []
      }
    },
    setIconClass(selectedIcon) {
      this.resource.icon_class = selectedIcon.className;
    }    
  },
  mounted: function() {
    this.getTargetGroups();
  }
}
</script>