<template>
  <div @click.native="resetSearchResults()" class="search_employee">
    <multiselect v-model="selectedUser"
                 label="label" 
                 track-by="user_id_external" 
                 :placeholder="$t('Search_for_an') + ' ' + $t('common.employee')"
                 open-direction="bottom" 
                 :options="users" 
                 :multiple="false" 
                 :searchable="true" 
                 :loading="false" 
                 :internal-search="false"
                 :clear-on-select="true"
                 :close-on-select="true"
                 :options-limit="300"
                 :max-height="600"
                 :show-no-results="true"
                 :hide-selected="false"
                 @search-change="asyncFindUser"
                 @input="openUserProfileSidebar">
      <span slot="noResult">{{ $t('common.no_employees_found') }}</span>
      <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>                
    </multiselect>
  </div>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect'

export default {
  name: 'TheHeaderSearchEmployee',
  components: {
    Multiselect
  },  
  data () {
    return {
      selectedUser: null,
      users: []
    }
  },
  methods: {
    openUserProfileSidebar(e) {
      // Open the edit sidebar for the selected user
      this.openSidebarRight('user_profile', { user_id_external: e.user_id_external});
      // Reset the search results
      this.resetSearchResults();
    },    
    resetSearchResults() {
      // Reset the users array
      this.users = [];
      // Reset the selectedUser value
      this.selectedUser = null;
    },
    asyncFindUser(query) {
      let searchTerm = query;
      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/users/' + searchTerm)
        .then(res => {      
          this.users = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }    
    }    
  },
  mounted: function() {
    this.resetSearchResults();
  }
}
</script>