<template>
  <CCard class="mb-0 pointer">
    <CCardBody>
      <div class="text-center">
        <div class="store_product_image mb-2">
          <img v-if="productData.image_url"
               :src="productData.image_url"
               onError="this.onerror=null; this.src='img/loyalty/product_image_fallback.png';"/>
          <img v-else src="img/loyalty/product_image_fallback.png"/>
        </div>
        <div class="store_product_info">
          <span v-line-clamp="1">{{productData.name}}</span>
          <div class="d-flex align-items-center justify-content-center">
            <div class="skus mr-2">
              <partnerProductPopover v-if="productData.variations.length > 1" popoverType="variations" :popoverData="productData.variations" popoverPlacement="left"/>
              <span v-else v-html="productData.variations[0].name"></span>
            </div>
            <div class="currencies mr-2">              
              <span>{{productData.currency}}</span>
            </div>
            <div class="countries">
              <span v-html="getTwemoji(productData.country.flag)"></span>
            </div>
          </div>
        </div>                    
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
import partnerProductPopover from '@/components/loyalty/partnerProductPopover';

export default {
  name: 'partnerProductCard',
  props: ['productData'],
  components: {
    partnerProductPopover
  },
  methods: {
    getTwemoji(emoji) {
      return twemoji.parse(emoji);
    }   
  }
}
</script>