<template>
  <div>
    <div class="sidebar_container">
      <div class="sidebar_header">
        <CRow class="m-0">
          <CCol cols="10" lg="10" md="10" sm="10" class="text-left">
            <span v-if="history.length > 1" class="pointer" @click="removeSidebarHistoryStep()">
              <i class="fas fa-arrow-circle-left mr-1"/>
            </span>            
            <span class="sidebar_subject">
              {{!subtopic.subtopic_id_external ? $t('topics.New_subtopic') : $t('topics.Edit_subtopic')}}          
            </span>
          </CCol>
          <CCol cols="2" lg="2" md="2" sm="2" class="text-right">
            <span @click="closeSidebarRight();"><i class="fas fa-times"/></span>        
          </CCol>
        </CRow>
      </div>
      <div class="sidebar_content">
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <CSelect :label="$t('topics.Topic')" class="mb-0" :value.sync="subtopic.topic_id_external" :options="topics" :placeholder="$t('select_a') + $t('topics.topic')" required was-validated disabled/>
          </CCol>                               
          <CCol cols="12" lg="12" class="pt-0">            
            <quick-edit type="text" mode="ignore" :buttonOkText="$t('Save')" :buttonCancelText="$t('Dismiss')" v-model="subtopic.title">                        
              <h2 class="m-0">{{subtopic.title ? subtopic.title : $t('common.Click_to_set_name')}}<i class="icon edit fas fa-pen ml-1"/></h2>
            </quick-edit>              
          </CCol>
        </CRow>
        <CRow class="m-0">           
          <CCol cols="12" lg="12" class="pt-0">
            <quick-edit type="textarea" rows="5" mode="ignore" :buttonOkText="$t('Save')" :buttonCancelText="$t('Dismiss')" v-model="subtopic.description">
              <span>{{subtopic.description ? subtopic.description : $t('common.Click_to_set_description')}}<i class="icon edit fas fa-pen ml-1"/></span>
            </quick-edit>             
          </CCol>            
        </CRow>
        <CRow class="m-0">           
          <CCol cols="12" lg="12" class="pt-0">
            <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="subtopic.is_active" size="is-small">{{ $t('is_active') }}</b-switch>            
          </CCol>            
        </CRow>        
      </div>
      <div class="sidebar_footer">
        <CRow class="m-0">
          <CCol cols="12" lg="12">
            <div v-if="!subtopic.subtopic_id_external">
              <CButton color="primary" @click="insertSubtopic();"><i class="fas fa-check mr-1"/>{{$t('Save')}}</CButton>
              <CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{ $t('Dismiss') }}</CButton>
            </div>
            <div v-else>
              <CButton color="primary" @click="updateSubtopic(subtopic);"><i class="fas fa-check mr-1"/>{{$t('Update')}}</CButton>
              <CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{ $t('Dismiss') }}</CButton>
            </div>      
          </CCol>
        </CRow>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import QuickEdit from 'vue-quick-edit';

export default {
  name: 'subtopicDetails',
  props: ['history', 'subtopicIdExternal', 'topicIdExternal'],
  components: {
    QuickEdit,
  },
  watch: {
    $props: {
      handler() {
        this.getSubtopicDetails();
      },
      deep: true,
      immediate: true,
    }
  }, 
  data() {
    return {
      subtopic: {},
      topics: []
    }
  },
  methods: {
    getSubtopicDetails() {
      if(this.subtopicIdExternal !== null) {
        axios.get(process.env.VUE_APP_API_URL + '/v1/connect/subtopic/' + this.subtopicIdExternal)
        .then(res => {
          this.subtopic = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });                  
      } else {
        // Reset the subtopic data
        this.resetSubtopicData();
      }
    },
    insertSubtopic () {
      let params = {};
      params = this.subtopic;
      (params.is_active === true) ? params.active = 'Y' : params.active = 'N';

      let title = params.title;    

      if(title) {
        axios.post(process.env.VUE_APP_API_URL + '/v1/connect/subtopic', params)
        .then(res => {
          // Show a success notice
          this.$buefy.toast.open({ message: this.$t('topics.Subtopic_added'), type: 'is-success', duration: 2000 });
          // Emit the update_topics event
          this.$bus.$emit('update_topics');
          // Reset the subtopic data
          this.resetSubtopicData();
          // Close the sidebar
          this.closeSidebarRight();	
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }
    },    
    updateSubtopic(data) {
      let params = {}
      params = data;
      (params.is_active === true) ? params.active = 'Y' : params.active = 'N';

      let title = params.title;      

      if(title) {
        axios.put(process.env.VUE_APP_API_URL + '/v1/connect/subtopic/' + this.subtopicIdExternal, params)
        .then(res => {
          // Show a success notice
          this.$buefy.toast.open({ message: this.$t('topics.Topic_updated'), type: 'is-success', duration: 2000 });
          // Emit the update_topic_details event
          this.$bus.$emit('update_subtopic_details', this.subtopicIdExternal);
          // Reset the subtopic data
          this.resetSubtopicData();          
          // Close the sidebar
          this.closeSidebarRight();                   
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }            
    },
    getTopics() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/connect/topics/select')
      .then(res => {
        this.topics = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },     
    resetSubtopicData () {
      this.subtopic = {
        topic_id_external: this.topicIdExternal,
        title: '',
        description: ''
      }
    }
  },
  mounted: function() {
    this.getTopics();
  }
}
</script>