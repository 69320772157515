<template>
  <div>
    <div class="sidebar_container">
      <div class="sidebar_header">
        <CRow class="m-0">
          <CCol cols="10" lg="10" md="10" sm="10" class="text-left">
            <span v-if="history.length > 1" class="pointer" @click="removeSidebarHistoryStep()">
              <i class="fas fa-arrow-circle-left mr-1"/>
            </span>            
            <span class="sidebar_subject">
							{{!stepIdExternal ? $t('lms.New_step') : $t('lms.Edit_step')}}
            </span>
          </CCol>
          <CCol cols="2" lg="2" md="2" sm="2" class="text-right">
            <span @click="closeSidebarRight();"><i class="fas fa-times"/></span>        
          </CCol>
        </CRow>
      </div>
      <div class="sidebar_content">
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <quick-edit type="text" mode="ignore" :buttonOkText="$t('Save')" :buttonCancelText="$t('Dismiss')" v-model="step.step_name">                        
              <h2 class="m-0">{{step.step_name ? step.step_name : $t('common.Click_to_set_name')}}<i class="icon edit fas fa-pen ml-1"/></h2>
            </quick-edit>
          </CCol>
        </CRow> 
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <label><b>{{$t('common.Chapter')}}</b></label>
            <multiselect
              class="data_table"
              v-model="step.chapter" 
              :options="chapters" 
              :multiple="false"
              :hide-selected="true"
              :close-on-select="true"
              :placeholder="$t('common.Select_chapter')" 
              :selectLabel="$t('common.Add_chapter')"
              :deselectLabel="$t('common.Remove_chapter')"
              track-by="lms_chapter_id_external" 
              label="label"
              :disabled="stepIdExternal !== null">
              <span slot="noResult">{{ $t('common.no_chapters_found') }}</span>
            </multiselect>
          </CCol>
        </CRow>
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <label><b>{{ $t('lms.duration') }}</b></label>
            <CInput type="number" step="1" min="0" max="120" class="mb-0" v-model="step.duration" required was-validated/>
          </CCol>            
        </CRow>

        <div v-if="!stepIdExternal">
          <CRow class="m-0">
            <CCol cols="12" lg="12" class="pt-0">
              <label><b>{{$t('lms.Step_type')}}</b></label>
              <multiselect
                class="data_table"
                v-model="step.lms_step_type" 
                :options="stepTypes" 
                :multiple="false"
                :hide-selected="true"
                :close-on-select="true"
                :placeholder="$t('common.Select_type')" 
                :selectLabel="$t('common.Add_type')"
                :deselectLabel="$t('common.Remove_type')"
                track-by="type_tag" 
                label="type_label">
                <span slot="noResult">{{ $t('common.no_types_found') }}</span>
              </multiselect>
            </CCol>
          </CRow>
        </div>                       

        <div v-if="stepIdExternal">
          <CRow class="m-0">
            <CCol cols="12" lg="12" class="pt-0">
              <label><b>{{ $t('lms.Step_explanation') }}</b></label>
              <div class="wysiwyg">
                <ckeditor :editor="editor" v-model="step.description" :config="editorConfig" :placeholder="$t('lms.Step_explanation')"></ckeditor>
              </div>
            </CCol>
          </CRow>

          <div v-if="step.lms_step_type.type_tag === 'video'">
            <div v-if="step.step_videos.length === 0">
              <CRow class="m-0">
                <CCol cols="12" lg="12" class="pt-0">
                  <label><b>{{ $t('common.Youtube_id') }}</b></label>
                  <CInput type="text" class="mb-0" v-model="step.video_id" required was-validated/>
                  <p class="explainer mt-1 mb-0">{{ $t('common.youtube_video_id_input_explainer') }}</p>
                </CCol>
              </CRow>
              <CRow class="m-0">
                <CCol cols="6" lg="6" class="pt-0">
                  <div class="videos_preview">
                    <div v-for="(video, index) in step.new_step_videos" v-bind:key="index" class="video">
                      <vue-plyr ref="plyr_preview">
                        <video controls crossorigin playsinline>
                          <source :src="video.video_url"/>
                        </video>
                      </vue-plyr>              
                      <i class="fas fa-times" @click="removeStepVideo(index)"/>
                    </div>
                  </div>
                  <div v-if="step.new_step_videos.length == 0">
                    <input id="videoUpload" type="file" accept="video/*" :value="step.uploaded_step_videos" @change="onStepVideosChange" hidden>                                                            
                    <CButton color="primary" @click="pickStepVideo();"><i class="fas fa-video mr-1"/>{{$t('sw.upload_video')}}</CButton>
                  </div>
                </CCol>
              </CRow>
            </div>
            <div v-else>
              <CRow class="m-0">
                <CCol cols="12" lg="12" class="pt-0 pb-0">
                  <label>{{ $t('common.current_video') }}</label>
                  <div class="current_videos" v-bind:class="{ 'mb-2' : step.step_videos.length > 0 }">
                    <div v-for="video in step.step_videos" v-bind:key="video.lms_step_video_id" class="video">                        
                      <div v-if="video.video_available === 'Y'">
                        <div v-if="video.video_provider_tag === 'youtube'">
                          <youtube :video-id="video.provider_video_id" ref="youtube" :player-vars="playerVars" :fitParent="true" class="w-100"></youtube>            
                        </div>
                        <div v-if="video.video_provider_tag === 'dyntube'" class="dyntube_iframe_container" v-bind:style="{ paddingTop: video.video_container_padding_percentage + '%' }">
                          <iframe class="dyntube_responsive_iframe" webkitallowfullscreen mozallowfullscreen allowfullscreen :src="'https://play.dyntube.io/iframe/' + video.provider_video_private"></iframe>
                        </div>                    
                      </div>
                      <videoPlaceholder v-else emoji="📹" :content="$t('common.Video_almost_available')"/>
                    </div>
                  </div>
                </CCol>
              </CRow>
            </div>
          </div>

          <div v-if="step.lms_step_type.type_tag === 'text'">
            <CRow class="m-0">
              <CCol cols="12" lg="12" class="pt-0">
                <label><b>{{ $t('lms.Text_to_read') }}</b></label>
                <div class="wysiwyg">
                  <ckeditor :editor="editor" v-model="step.content" :config="editorConfig" :placeholder="$t('lms.Text_to_read')"></ckeditor>
                </div>                
              </CCol>
            </CRow>
          </div>

          <div v-if="step.lms_step_type.type_tag === 'questions'">
            <CRow class="m-0">
              <CCol cols="12" lg="12" class="pt-0">
                <label><b>{{ $t('lms.Question') }}</b></label>
                <CInput type="text" v-model="step.question.question" class="m-0" required was-validated/>
              </CCol>
            </CRow>
            <CRow class="m-0">
              <CCol cols="12" lg="12" class="pt-0">
                <label><b>{{ $t('lms.Answers') }}</b></label>
                <CRow v-for="(option, index) in step.question.answer_options" v-bind:key="index">
                  <CCol cols="10" lg="10" class="pt-0">
                    <CInput type="text" v-model="option.answer" :placeholder="$t('lms.Answer') + ' ' + (index + 1)" class="m-0" required was-validated/>
                  </CCol>
                  <CCol cols="2" lg="2" class="pt-0">
                    <b-switch class="mb-0 mt-1 mt-xl-0 enable_search" v-model="option.is_correct" size="is-small">{{ $t('lms.Correct') }}</b-switch>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol cols="12" lg="12" class="pt-0 pb-0">
                    <CButton color="primary" @click="addAnswerOption();">
                      <i class="fas fa-plus mr-1"/>
                      <span>{{ $t('lms.Add_more_answers') }}</span>
                    </CButton>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <CRow class="m-0">
              <CCol cols="12" lg="12" class="pt-0">
                <label><b>{{ $t('lms.Message_at_wrong_answer') }}</b></label>
                <CTextarea @input="countdown(step.question.wrong_answer_notice)" rows="5" maxlength="2500" class="m-0" v-model="step.question.wrong_answer_notice" required was-validated/>                  
                <p class="countdown mt-1 mb-0" v-if="remainingCount.wrong_answer_notice > 0">{{remainingCount.wrong_answer_notice}} {{ $t('lms.characters_remaining') }}</p>                              
              </CCol>
            </CRow>
          </div>

          <div v-if="step.lms_step_type.type_tag === 'qr'">
            <CRow class="m-0">
              <CCol cols="6" lg="6" class="pt-0">
                <label><b>{{ $t('lms.QR_code_contents') }}</b></label>
                <CInput type="text" class="mb-2" v-model="step.content" required was-validated/>
                <CButton color="primary"
                         @click="updateQRCode(step.content);"
                         :disabled="!step.content">
                  <i class="fas fa-qrcode mr-1"/>{{ $t('lms.Update_qr_code') }}
                </CButton>
              </CCol>
              <CCol cols="6" lg="6" class="pt-0">
                <label class="mb-0"><b>{{ $t('lms.QR_code') }}</b></label>                  
                <p v-if="stepQRData === null" class="mt-1 mb-0">{{ $t('lms.No_qr_code_yet') }}</p>
                <div v-else class="qr_code_container mt-2">
                  <vrcode v-if="hideQRcode === false"
                          :download="{ text: $t('Download'), class: 'btn btn-primary mt-1', filename: step.step_name + '-' + step.content, visible: true, type: 'image/png' }"
                          :value="stepQRData"
                          :options="{ size: 200, level: 'Q', padding: 0 }">
                  </vrcode>
                  <p v-else class="m-0">{{ $t('lms.Generating_qr_code') }}</p>
                </div>                    
              </CCol>
            </CRow>            
          </div>

          <div v-if="step.lms_step_type.type_tag === 'h5p'">
            <CRow class="m-0">
              <CCol cols="6" lg="6" class="pt-0">
                <label><b>{{ $t('lms.h5p_content_name') }}</b></label>
                <CInput type="text" v-model="step.h5p.content_name" class="m-0" required was-validated/>
              </CCol>
              <CCol cols="6" lg="6" class="pt-0">
                <label><b>{{ $t('lms.passive_h5p_content') }}</b></label>
                <b-checkbox v-model="step.is_passive_h5p">{{ $t('lms.add_next_step_button') }}</b-checkbox>
              </CCol>
            </CRow>
            <CRow class="m-0">
              <CCol cols="12" lg="12" class="pt-0">
                <label><b>{{ $t('lms.Upload_h5p_file') }}</b></label>
                <H5PUpload ref="upload" :courseIdExternal="courseIdExternal" @h5p_uploaded="setH5PSource"/>                                  
              </CCol>
            </CRow>
            <CRow class="m-0">
              <CCol cols="12" lg="12" class="pt-0">
                <label><b>{{ $t('lms.Message_at_wrong_answer') }}</b></label>
                <CTextarea @input="countdown()" rows="5" maxlength="2500" class="mb-0" v-model="step.h5p.wrong_answer_notice" required was-validated/>                  
                <p class="countdown mt-1 mb-0" v-if="remainingCount.h5p_wrong_answer_notice > 0">{{remainingCount.h5p_wrong_answer_notice}} {{ $t('lms.characters_remaining') }}</p>                                   
              </CCol>
            </CRow>                        
          </div>

          <div v-if="step.lms_step_type.type_tag === 'external_link'">
            <CRow class="m-0">
              <CCol cols="12" lg="12" class="pt-0">
                <label><b>{{ $t('lms.external_link') }}</b></label>
                <CInput type="text" class="mb-0" v-model="step.content" required was-validated/>
              </CCol>
            </CRow>
          </div>

          <div v-if="step.lms_step_type.type_tag === 'audio'">
            <CRow class="m-0">
              <CCol cols="12" lg="12" class="pt-0">
                <div v-if="apiBaseUrl && clientToken && step.step_audio_file_id">
                  <audioPlayer :standalone="true"
                               :audioFileName="null"                
                               :audioSourceUrl="apiBaseUrl + '/v1/common/cdn/file/mp3/learn-course/' + courseIdExternal + '/' + step.step_audio_file_id + '/' + clientToken">
                  </audioPlayer>
                </div>
                <div v-else>
                  <div v-if="step.new_step_audio.length > 0">
                    <audioPlayer v-for="(audio, index) in step.new_step_audio" 
                                 v-bind:key="index" 
                                 :standalone="true"
                                 :audioFileName="null"
                                 :audioSourceUrl="audio.audio_url">
                    </audioPlayer>             
                  </div>
                  <div v-else>
                    <input id="audioUpload" type="file" accept="audio/mpeg" :value="step.uploaded_step_audio" @change="onStepAudioChange" hidden>                                                            
                    <CButton color="primary" @click="pickStepAudio();"><i class="fa-solid fa-volume-low mr-1"/>{{$t('common.Upload_audio_file')}}</CButton>
                  </div>                
                </div>
              </CCol>
            </CRow>                       
          </div>          
        </div>

        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="step.is_active" size="is-small">{{ $t('is_active') }}</b-switch>
          </CCol>            
        </CRow>      
      </div>
      <div class="sidebar_footer">
        <CRow class="m-0">
          <CCol cols="12" lg="12">
						<div v-if="!stepIdExternal">
							<CButton color="primary" @click="insertStep(step);"><i class="fas fa-check mr-1"/>{{$t('Save')}}</CButton>
							<CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{$t('Dismiss')}}</CButton>
						</div> 						   
						<div v-else>
							<CButton color="primary" @click="updateStep(step);"><i class="fas fa-check mr-1"/>{{$t('Save')}}</CButton>
							<CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{$t('Dismiss')}}</CButton>
          	</div>
          </CCol>
        </CRow>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import QuickEdit from 'vue-quick-edit';
import vrcode from '@ispa.io/vrcode'
import H5PUpload from '@/components/upload/H5PUpload.vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { UploadAdapter } from '@/components/upload/upload-adapter';
import videoPlaceholder from '@/components/common/videoPlaceholder.vue';
import audioPlayer from '@/components/common/audioPlayer.vue';

export default {
  name: 'courseDetails',
  props: ['history', 'lmsCourse', 'lmsChapter', 'lmsStepIdExternal'],
  components: {
    Multiselect,
    QuickEdit,
    vrcode,
    H5PUpload,
    videoPlaceholder,
    audioPlayer
  },
  watch: {
    $props: {
      handler() {
        if(this.lmsStepIdExternal) {
          this.stepIdExternal = this.lmsStepIdExternal;
          this.editorConfig.upload.externalId = this.stepIdExternal; // Set the external ID for the wysiwyg uploader
        }
        // Set the courseIdExternal value if the lmsCourse is set
        if(this.lmsCourse) this.courseIdExternal = this.lmsCourse.lms_course_id_external;
        // Get the step details
        this.getStepDetails();
        // Get the chapters available for the step
        this.getChapters();        
      },
      deep: true,
      immediate: true,
    }
  }, 
  data() {
    return {
      apiBaseUrl: null,
      cdnBaseUrl: null,
      companyIdExternal: null,
      clientToken: null,      
      courseIdExternal: null,
      stepIdExternal: null,
			step: {
        lms_step_type: {
          type_tag: null
        },
        step_videos: [],
        new_step_videos: [],
        new_step_audio: []
      },
      chapters: [],
      stepTypes: [],
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [ 'heading', '|', 'bold', 'italic', 'bulletedList', 'numberedList', '|', 'imageUpload', '|', 'undo', 'redo' ],
        extraPlugins: [this.wysiwygUpload],
        upload: { externalId: null, source: 'lms' }        
      },
      maxCount: {
        wrong_answer_notice: 2500,
        h5p_wrong_answer_notice: 2500
      },
      remainingCount: {
        wrong_answer_notice: 2500,
        h5p_wrong_answer_notice: 2500
      },
      stepQRData: null,
      hideQRcode: false,
      playerVars: { 
        autoplay: 0, 
        controls: 0,
        playsinline: 1
      },
      maxVideoFilesize: 5368709120,
      maxAudioFilesize: 5368709120
    }
  },
  methods: {
    getStepDetails() {
      if(this.stepIdExternal !== null) {
				axios.get(process.env.VUE_APP_API_URL + '/v1/learn/step/' + this.stepIdExternal)
				.then(res => {
					this.step = res.data.data;
          // Trigger the countdown function
          this.countdown();
				})
				.catch(err => {
					console.error(err); 
				});    
      } else {
        // Reset the data 
        this.resetStepData();
      }
    },
    insertStep(data) {
      let params = {};
      params.stepData = data;
      (params.stepData.is_active == true) ? params.stepData.active = 'Y' : params.stepData.active = 'N';
      (params.stepData.passive_h5p == true) ? params.stepData.passive_h5p = 1 : params.stepData.passive_h5p = 0;
      
      let step_name = params.stepData.step_name;
      let chapter = params.stepData.chapter;
      let duration = params.stepData.duration;
      let lms_step_type = params.stepData.lms_step_type;

      if(step_name && chapter && duration && lms_step_type) {
        axios.post(process.env.VUE_APP_API_URL + '/v1/learn/step', params)
        .then(res => {
          this.stepIdExternal = res.data.data.lms_step_id_external;
          // Set the external ID for the wysiwyg uploader
          this.editorConfig.upload.externalId = this.stepIdExternal;          
          // Show a success notice
          this.$buefy.toast.open({ message: this.$t('lms.Step_added'), type: 'is-success', duration: 2000 });                  
          // Emit the update_learn_chapter_steps event
          if(this.lmsChapter && this.lmsChapter.lms_chapter_id_external && this.lmsChapter.chapter_name) {           
            this.$bus.$emit('update_learn_chapter_steps', this.lmsChapter);
          }    
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }
    },
    updateStep(data) {
      let params = {}
      params.stepData = data;
      (params.stepData.is_active == true) ? params.stepData.active = 'Y' : params.stepData.active = 'N';
      (params.stepData.is_passive_h5p == true) ? params.stepData.passive_h5p = 1 : params.stepData.passive_h5p = 0;

      if(params.stepData.lms_step_type.type_tag === 'questions' && params.stepData.question) {        
        for(var o = 0; o < params.stepData.question.answer_options.length; o++) {
          (params.stepData.question.answer_options[o].is_correct === true) ? params.stepData.question.answer_options[o].correct = 'Y' : params.stepData.question.answer_options[o].correct = 'N';
        }
      }

      if(this.validateStep(params) === true) {
        // Check if the step is new and the type tag is 'video'
        if(params.stepData.lms_step_id_external && params.stepData.lms_step_type.type_tag === 'video') {
          // Set the insert_video param
          params.stepData.insert_video = 1;
          // Set the video_provider_tag value to 'dyntube if the step contains new videos
          if(params.stepData.new_step_videos.length > 0) {
            params.stepData.video_provider_tag = 'dyntube';
            params.stepData.video_id = null;
          } else {
            params.stepData.video_provider_tag = 'youtube';
          }
        }

        axios.put(process.env.VUE_APP_API_URL + '/v1/learn/step/' + this.stepIdExternal, params)
        .then(res => {
          let updateStepTypeTag = res.data.data.updated_step_type_tag;
          // Video upload start after step is updated and the video is not active (so it should be uploaded first.)
          if(params.stepData.lms_step_id_external && updateStepTypeTag === 'video' && res.data.data.video.active === 'N') {
            // Emit the new_learn_video_uploading event
            this.$bus.$emit('new_learn_video_uploading', res.data.data.video.external_id);
            // Create new formdata              
            const videoFormData = new FormData();
            // Loop through the new step videos and append them to the video formdata
            for(var i = 0; i < params.stepData.new_step_videos.length; i++) {
              videoFormData.append("video_" + i, params.stepData.new_step_videos[i]);
            }            
            // Upload the videos
            axios.post('v1/common/upload/videos/learn/' + res.data.data.video.external_id, videoFormData, {
              headers: { 'Content-Type': 'multipart/form-data' }
            })
            .then(res => {
              // Emit the learn_video_upload_finished event
              this.$bus.$emit('learn_video_upload_finished', res.data.data.uploaded_lms_step_video_id_external);
            })
            .catch(err => {
              console.error(err); 
            });
          }

          // Audio upload start after step is updated
          if(params.stepData.lms_step_id_external && updateStepTypeTag === 'audio') {
            // Create new formdata              
            const audioFormData = new FormData();
            // Append the audio to the audio formdata
            audioFormData.append(0, params.stepData.new_step_audio[0]);
            // Upload the audio
            axios.post('v1/common/upload/audio/learn/step/' + this.stepIdExternal, audioFormData, {
              headers: { 'Content-Type': 'multipart/form-data' }
            })
            .catch(err => {
              console.error(err); 
            });            
          }

          this.$buefy.toast.open({ message: this.$t('lms.Step_updated'), type: 'is-success', duration: 2000 });
          // Emit the update_learn_chapter_steps event
          if(this.lmsChapter && this.lmsChapter.lms_chapter_id_external && this.lmsChapter.chapter_name) {           
            this.$bus.$emit('update_learn_chapter_steps', this.lmsChapter);
          }
          // Reset the data
          this.resetStepData();
          // Close the sidebar
          this.closeSidebarRight();  
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }      
    },
    validateStep(params) {
      let step_name = params.stepData.step_name;
      let chapter = params.stepData.chapter;
      let duration = params.stepData.duration;
      let lms_step_type = params.stepData.lms_step_type;
      let content = params.stepData.content;                  
      let question = params.stepData.question;
      let h5p = params.stepData.h5p;  
      let correctAnswerAmount = 0;

      let video_id = params.stepData.video_id;    
      let step_videos = params.stepData.step_videos;
      let new_step_videos = params.stepData.new_step_videos;
      let new_step_audio = params.stepData.new_step_audio;
      let step_audio_file_id = params.stepData.step_audio_file_id;

      // Check if base information for step is available
      if(step_name && chapter && duration && lms_step_type) {
        // If type is 'video', 'text', 'qr' or 'external_link', check if the content is available
        // If type is 'questions', check if the question and the answer options for multiple choice are available
        // If type is 'h5p', check if the H5P elements are available

        if((lms_step_type.type_tag === 'text' || lms_step_type.type_tag === 'qr' || lms_step_type.type_tag === 'external_link') && content) {
          return true;
        } else if(lms_step_type.type_tag === 'video') {
          if(video_id || step_videos.length > 0 || (step_videos.length == 0 && new_step_videos.length > 0)) {
            return true
          } else {
            return false;
          }
        } else if(lms_step_type.type_tag === 'audio') {
          if(step_audio_file_id || new_step_audio.length > 0) {
            return true
          } else {
            return false;
          }
        } else if(lms_step_type.type_tag === 'questions') {          
          // Check if the question or wrong answer notice are empty of null
          if(question.question && question.wrong_answer_notice) {
            // Loop through the answer options
            for(var o = 0; o < question.answer_options.length; o++) {
              // Check if one of the answer options is empty of null
              if(!question.answer_options[o].answer) return false;
              // Check the number of correct answers
              if(question.answer_options[o].is_correct === true) correctAnswerAmount++;              
            }
            // Check if there is a correct answer available
            if(correctAnswerAmount === 0) return false;                        
            // Return true if the check is completed
            return true; 
          } else {
            return false;
          }
        } else if(lms_step_type.type_tag === 'h5p') {                
          if(h5p.content_name && h5p.source && h5p.wrong_answer_notice) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    resetStepData() {
      this.step = {
        step_name: null,
        description: "",
        content: "",
        duration: null,
        is_passive_h5p: false,
        is_active: false,
        video_id: null,
        step_videos: [],
        new_step_videos: [],
        new_step_audio: [],
        question: {
          question: '',
          wrong_answer_notice: '',
          answer_options: [
            { answer: '', is_correct: false },
            { answer: '', is_correct: false }
          ]          
        },
        h5p: {
          source: null,
          content_name: '',
          wrong_answer_notice: '',
        }             
      };

      if(this.lmsChapter && this.lmsChapter.lms_chapter_id_external && this.lmsChapter.chapter_name) {
        this.step.chapter = {
          lms_chapter_id_external: this.lmsChapter.lms_chapter_id_external,
          label: this.lmsChapter.chapter_name
        }
      }       
    },
    addAnswerOption() {
      this.step.question.answer_options.push({ answer: '', is_correct: false });
    },     
    updateQRCode(content) {
      this.hideQRcode = true;

      setTimeout(function(){
        this.stepQRData = content;
        this.hideQRcode = false;
      }.bind(this), 500);
    },
    setH5PSource(source) {
      this.step.h5p.source = source;
    },       
    getChapters() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/learn/course/' + this.courseIdExternal + '/chapters/multiselect')
      .then(res => {
        this.chapters = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getStepTypes () {
      axios.get(process.env.VUE_APP_API_URL + '/v1/learn/steps/types/multiselect')
      .then(res => {      
        this.stepTypes = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },   
    countdown() {
      if(this.step.lms_step_type.type_tag === 'questions') {        
        this.remainingCount.wrong_answer_notice = this.maxCount.wrong_answer_notice - this.step.question.wrong_answer_notice.length;
      } else if(this.step.lms_step_type.type_tag === 'h5p') {
        this.remainingCount.h5p_wrong_answer_notice = this.maxCount.h5p_wrong_answer_notice - this.step.h5p.wrong_answer_notice.length;        
      }
    },     
    wysiwygUpload(editor) {
      var args = editor.config._config.upload;
      editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
        return new UploadAdapter({ loader, args });
      };
    },
    pickStepVideo() {
      document.getElementById("videoUpload").click()
    },  
    removeStepVideo(index) {
      this.step.new_step_videos.splice(index, 1);
    },
    onStepVideosChange(e) {
      // Get the files
      let files = e.target.files;
      // Loop through the files
      for(var i = 0; i < files.length; i++) {                
        // Create an video URL for the file
        files[i].video_url = URL.createObjectURL(files[i]);
        // Check the filesize exceeds the maximum
        let filesize = files[i].size;
        if(filesize > this.maxVideoFilesize) {
          // If so, notify the user
          this.$buefy.toast.open({ message: files[i].name + ' (' + this.formatFileSize(filesize) + ') ' + this.$t('upload.exceeds_maximum_filesize_of') + ' ' + this.formatFileSize(this.maxVideoFilesize) + '.', type: 'is-danger', duration: 2000 });
        } else {
          // If not, add the file to the new_step_videos array of the postVideos
          this.step.new_step_videos.push(files[i]);
        }
      }
    },
    pickStepAudio() {
      document.getElementById("audioUpload").click()
    },
    onStepAudioChange(e) {
      // Get the files
      let files = e.target.files;
      // Loop through the files
      for(var i = 0; i < files.length; i++) {
        // Create an audio URL for the file
        files[i].audio_url = URL.createObjectURL(files[i]);
        // Check the filesize exceeds the maximum
        let filesize = files[i].size;               
        if(filesize > this.maxAudioFilesize) {            
          // If so, notify the user
          this.$buefy.toast.open({ message: file.name + ' (' + this.formatFileSize(filesize) + ') ' + this.$t('upload.exceeds_maximum_filesize_of') + ' ' + this.formatFileSize(this.maxAudioFilesize) + '.', type: 'is-danger', duration: 2000 });
        } else {
          // If not, add the file to the new_step_videos array of the postVideos
          this.step.new_step_audio.push(files[i]);
        }  
      }
    },       
    formatFileSize(bytes) {
      const sufixes = ['B', 'kB', 'MB', 'GB', 'TB'];
      const i = Math.floor(Math.log(bytes) / Math.log(1024));
      return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sufixes[i]}`;
    }           
  },
  mounted: function() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.cdnBaseUrl = process.env.VUE_APP_CDN_URL;
    if(localStorage.getItem('companyIdExternal') !== null) this.companyIdExternal = localStorage.getItem('companyIdExternal');    
    this.clientToken = localStorage.getItem('token');

    this.getStepTypes();    
  }  
}
</script>