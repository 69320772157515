<template>
  <div>
    <div class="sidebar_container">
      <div class="sidebar_header">
        <CRow class="m-0">
          <CCol cols="10" lg="10" md="10" sm="10" class="text-left">
            <span v-if="history.length > 1" class="pointer" @click="removeSidebarHistoryStep()">
              <i class="fas fa-arrow-circle-left mr-1"/>
            </span>            
            <span class="sidebar_subject">
              {{ $t('sq.New_target') }} {{targetTypeTag === 'category' ? $t('sq.pillar') : $t('sq.subpillar')}}
            </span>
          </CCol>
          <CCol cols="2" lg="2" md="2" sm="2" class="text-right">
            <span @click="closeSidebarRight();"><i class="fas fa-times"/></span>        
          </CCol>
        </CRow>
      </div>
      <div class="sidebar_content">
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">            
            <div v-if="targetTypeTag === 'category'">
              <label>{{$t('sq.Which_pillar')}}</label>                    
              <multiselect
                class="data_table"
                v-model="target.category" 
                :options="categories" 
                :multiple="false"
                :hide-selected="true"
                :close-on-select="true"
                :placeholder="$t('measure.Search_pillar')" 
                :selectLabel="$t('measure.Select_pillar')" 
                track-by="sq_category_id" 
                label="label">
                <span slot="noResult">{{$t('sq.no_pillars_found')}}</span>
              </multiselect>
            </div>
            <div v-if="targetTypeTag === 'subcategory'">
              <label>{{$t('sq.Which_subpillar')}}</label>                    
              <multiselect
                class="data_table"
                v-model="target.subcategory" 
                :options="subcategories" 
                :multiple="false"
                :hide-selected="true"
                :close-on-select="true"
                :placeholder="$t('measure.Search_subpillar')" 
                :selectLabel="$t('measure.Select_subpillar')" 
                track-by="sq_subcategory_id" 
                label="label">
                <span slot="noResult">{{$t('sq.no_subpillars_found')}}</span>
              </multiselect>
            </div>
          </CCol>
        </CRow>
        <CRow class="m-0">        
          <CCol cols="12" lg="12" class="pt-0 pb-0">            
            <CInput class="mb-0" type="number" step="0.1" :label="$t('common.Score')" min="0" max="10" v-model="target.value" required was-validated/>
          </CCol>
        </CRow>
      </div>
      <div class="sidebar_footer">
        <CRow class="m-0">
          <CCol cols="12" lg="12">
						<div>
              <CButton color="primary" @click="insertTarget(target);"><i class="fas fa-check mr-1"/>{{ $t('Save') }}</CButton>
							<CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{$t('Dismiss')}}</CButton>
						</div>
          </CCol>
        </CRow>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect'

export default {
  name: 'newTarget',
  props: ['history', 'targetTypeTag'],
  components: {
    Multiselect
  },  
  watch: {
    $props: {
      handler() {
        // Get the (sub)categories based on the targetTypeTag value
        if(this.targetTypeTag === 'category') {
          this.getCategories();
        } else if(this.targetTypeTag === 'subcategory') {
          this.getSubcategories(); 
        }
        // Reset the data
        this.resetTargetData();
      },
      deep: true,
      immediate: true,
    }
  },   
  data() {
    return {
			target: {},
      categories: [],
      subcategories: [],      
    }
  },
  methods: {
    insertTarget(data) {
      let params = {};
                  
      let value = parseFloat(this.target.value).toFixed(1);
      
      params.targetData = data;
      params.targetData.value = value.toString();
      params.targetData.target_type_tag = this.targetTypeTag;
      
      axios.post(process.env.VUE_APP_API_URL + '/v1/measure/target', params)
      .then(res => {
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('sq.Target_added'), type: 'is-success', duration: 2000 });
        // Emit the update_targets event
        this.$bus.$emit('update_targets');
        // Reset the screen data
        this.resetTargetData();
        // Close the sidebar
        this.closeSidebarRight();
      })
      .catch(err => {
        console.error(err); 
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      })
    },
    getCategories () {
      axios.get(process.env.VUE_APP_API_URL + '/v1/measure/targets/categories')
      .then(res => {
        this.categories = res.data.data;
      })
      .catch(err => {
        console.error(err);
      });
    },
    getSubcategories () {
      axios.get(process.env.VUE_APP_API_URL + '/v1/measure/targets/subcategories')
      .then(res => {
        this.subcategories = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });  
    },
    resetTargetData () {  
      this.target = {
        target_type_tag: null,
        value: null
      };
    }				
  }
}
</script>