<template>
  <div>
    <div class="sidebar_container">
      <div class="sidebar_header">
        <CRow class="m-0">
          <CCol cols="10" lg="10" md="10" sm="10" class="text-left">
            <span v-if="history.length > 1" class="pointer" @click="removeSidebarHistoryStep()">
              <i class="fas fa-arrow-circle-left mr-1"/>
            </span>            
            <span class="sidebar_subject">
							{{!chapterIdExternal ? $t('lms.New_chapter') : $t('lms.Edit_chapter')}}
            </span>
          </CCol>
          <CCol cols="2" lg="2" md="2" sm="2" class="text-right">
            <span @click="closeSidebarRight();"><i class="fas fa-times"/></span>        
          </CCol>
        </CRow>
      </div>
      <div class="sidebar_content">
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <quick-edit type="text" mode="ignore" :buttonOkText="$t('Save')" :buttonCancelText="$t('Dismiss')" v-model="chapter.chapter_name">                        
              <h2 class="m-0">{{chapter.chapter_name ? chapter.chapter_name : $t('common.Click_to_set_name')}}<i class="icon edit fas fa-pen ml-1"/></h2>
            </quick-edit>
          </CCol>
        </CRow>
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <label><b>{{$t('common.Course')}}</b></label>
            <multiselect
              class="data_table"
              v-model="chapter.course" 
              :options="courses" 
              :multiple="false"
              :hide-selected="true"
              :close-on-select="true"
              :placeholder="$t('common.Select_course')" 
              :selectLabel="$t('common.Add_course')"
              :deselectLabel="$t('common.Remove_course')"
              track-by="lms_course_id_external"
              label="label"
              :disabled="chapterIdExternal !== null">
              <span slot="noResult">{{ $t('common.no_courses_found') }}</span>
            </multiselect>
          </CCol>
        </CRow>        

        <div v-if="chapterIdExternal">
          <CRow class="m-0">
            <CCol cols="12" lg="12" class="pt-0">
              <label><b>{{ $t('lms.chapter_explanation') }}</b></label>
              <div class="wysiwyg">
                <ckeditor :editor="editor" v-model="chapter.description" :config="editorConfig" :placeholder="$t('lms.chapter_explanation')"></ckeditor>
              </div>
            </CCol>
          </CRow>         
        </div>

        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="chapter.is_active" size="is-small">{{ $t('is_active') }}</b-switch>
          </CCol>            
        </CRow>      
      </div>
      <div class="sidebar_footer">
        <CRow class="m-0">
          <CCol cols="12" lg="12">
						<div v-if="!chapterIdExternal">
							<CButton color="primary" @click="insertChapter(chapter);"><i class="fas fa-check mr-1"/>{{$t('Save')}}</CButton>
							<CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{$t('Dismiss')}}</CButton>
						</div> 						   
						<div v-else>
							<CButton color="primary" @click="updateChapter(chapter);"><i class="fas fa-check mr-1"/>{{$t('Save')}}</CButton>
							<CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{$t('Dismiss')}}</CButton>
          	</div>
          </CCol>
        </CRow>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import QuickEdit from 'vue-quick-edit';
import ImageUploader from 'vue-image-upload-resize';

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { UploadAdapter } from '@/components/upload/upload-adapter';

export default {
  name: 'courseDetails',
  props: ['history', 'lmsCourse', 'lmsChapterIdExternal'],
  components: {
    Multiselect,
    QuickEdit,
    ImageUploader
  },
  watch: {
    $props: {
      handler() {
        if(this.lmsChapterIdExternal) {
          this.chapterIdExternal = this.lmsChapterIdExternal;
          this.editorConfig.upload.externalId = this.chapterIdExternal; // Set the external ID for the wysiwyg uploader
        }
        // Get the chapter details
        this.getChapterDetails();
      },
      deep: true,
      immediate: true,
    }
  }, 
  data() {
    return {
      chapterIdExternal: null,
			chapter: {},
      courses: [],
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [ 'heading', '|', 'bold', 'italic', 'bulletedList', 'numberedList', '|', 'imageUpload', '|', 'undo', 'redo' ],
        extraPlugins: [this.wysiwygUpload],
        upload: { externalId: null, source: 'lms' }        
      }      
    }
  },
  methods: {
    getChapterDetails() {
      if(this.chapterIdExternal !== null) {
				axios.get(process.env.VUE_APP_API_URL + '/v1/learn/chapter/' + this.chapterIdExternal)
				.then(res => {
					this.chapter = res.data.data;
				})
				.catch(err => {
					console.error(err); 
				});    
      } else {
        // Reset the data 
        this.restChapterData();
      }
    },
    insertChapter(data) {
      let params = {};
      params.chapterData = data;
      (params.chapterData.is_active == true) ? params.chapterData.active = 'Y' : params.chapterData.active = 'N';
      
      let chapter_name = params.chapterData.chapter_name;
      let course = params.chapterData.course;

      if(chapter_name && course) {
        axios.post(process.env.VUE_APP_API_URL + '/v1/learn/chapter', params)
        .then(res => {
          this.chapterIdExternal = res.data.data.lms_chapter_id_external;
          // Set the external ID for the wysiwyg uploader
          this.editorConfig.upload.externalId = this.chapterIdExternal;          
          // Show a success notice
          this.$buefy.toast.open({ message: this.$t('lms.Chapter_added'), type: 'is-success', duration: 2000 });                  
          // Emit the update_learn_course_chapter event
          if(this.lmsCourse && this.lmsCourse.lms_course_id_external && this.lmsCourse.course_name) {           
            this.$bus.$emit('update_learn_course_chapters', this.lmsCourse);
          }    
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }
    },
    updateChapter(data) {
      let params = {}
      params.chapterData = data;
      (params.chapterData.is_active == true) ? params.chapterData.active = 'Y' : params.chapterData.active = 'N';

      let chapter_name = params.chapterData.chapter_name;
      let course = params.chapterData.course;

      if(chapter_name && course) {
        axios.put(process.env.VUE_APP_API_URL + '/v1/learn/chapter/' + this.chapterIdExternal, params)
        .then(res => {
          this.$buefy.toast.open({ message: this.$t('lms.Chapter_updated'), type: 'is-success', duration: 2000 });
          // Emit the update_learn_course_chapter event
          if(this.lmsCourse && this.lmsCourse.lms_course_id_external && this.lmsCourse.course_name) {           
            this.$bus.$emit('update_learn_course_chapters', this.lmsCourse);
          }   
          // Reset the data
          this.restChapterData();
          // Close the sidebar
          this.closeSidebarRight();  
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }      
    },
    restChapterData() {
      this.chapter = {
        chapter_name: null,
        description: "",
        is_active: false
      };

      if(this.lmsCourse && this.lmsCourse.lms_course_id_external && this.lmsCourse.course_name) {
        this.chapter.course = {
          lms_course_id_external: this.lmsCourse.lms_course_id_external,
          label: this.lmsCourse.course_name
        }
      }       
    },
    getCourses() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/learn/courses/multiselect')
      .then(res => {
        this.courses = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    wysiwygUpload(editor) {
      var args = editor.config._config.upload;
      editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
        return new UploadAdapter({ loader, args });
      };
    }    
  },
  mounted: function() {
    this.getCourses();
  }  
}
</script>