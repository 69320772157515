  <template>
  <div class="events">
    <div class="sidebar_container">
      <div class="sidebar_header">
        <CRow class="m-0">
          <CCol cols="10" lg="10" md="10" sm="10" class="text-left pb-0">
            <span v-if="history.length > 1" class="pointer" @click="removeSidebarHistoryStep()">
              <i class="fas fa-arrow-circle-left mr-1"/>
            </span>            
            <span class="sidebar_subject">
							{{$t('communications.Add_auto_moments_for', { product_tag: productTag })}}
            </span>
          </CCol>
          <CCol cols="2" lg="2" md="2" sm="2" class="text-right pb-0">
            <span @click="closeSidebarRight();"><i class="fas fa-times"/></span>        
          </CCol>
        </CRow>
      </div>
      <div class="sidebar_content">
        <div class="auto_moment_catalog">
          <CRow v-if="showFilters === true" class="m-0 auto_moment_filters">
            <CCol cols="6" lg="6" class="pb-0">
              <label><b>{{$t('communications.Search_for_moments')}}</b></label>
              <CInput type="text" class="mb-0 searchbar" v-model="momentFilters.search_term" v-debounce:1s="getProductMoments"></CInput>
            </CCol>
            <CCol cols="6" lg="6" class="pb-0 locale-changer">
              <label><b>{{$t('common.filter_by_country')}}</b></label>
              <multiselect
                class="open_absolute"
                v-model="momentFilters.country" 
                :options="momentsCountries" 
                :multiple="false"
                :hideSelected="false"
                :closeOnSelect="true"
                track-by="code" 
                label="name"
                :show-labels="false"
                @input="getProductMoments();">
                <span slot="noResult">{{$t('common.no_countries_found')}}</span>

                <template slot="singleLabel" slot-scope="country">
                  <span v-if="country.option.flag" class="language_flag" v-html="getTwemoji(country.option.flag)"></span>
                  <span class="language_name">{{ country.option.name }}</span>
                </template>

                <template slot="option" slot-scope="country">
                  <span v-if="country.option.flag" class="language_flag" v-html="getTwemoji(country.option.flag)"></span>
                  <span class="language_name">{{ country.option.name }}</span>
                </template>
              </multiselect>
            </CCol>
          </CRow>
          
          <div v-if="momentsLoading === true">
            <CRow class="m-0">
              <CCol cols="12" lg="12" class="pt-3 pb-3">
                <loadingSpinner mode="auto" :content="$t('common.Loading')"/>
              </CCol>
            </CRow>          
          </div>
          <div v-else>
            <CRow v-if="momentsTypeTags.length > 0" class="m-0">
              <CCol cols="12" lg="12" class="pb-0">
                <label class="m-0"><b>{{$t('communications.Filter_by_tag')}}</b></label>
                <div class="filter_tags" v-bind:class="{'show_more': showAllMomentTypeTags}">
                  <b-tag v-for="tag in momentsTypeTags" 
                        v-bind:key="tag.platform_event_type_tag"
                        @click.native="setSelectedMomentTypeTag(tag.platform_event_type_tag)"
                        class="pointer"
                        v-bind:class="{'selected' : momentFilters.moment_type_tags.includes(tag.platform_event_type_tag)}">
                    {{ tag.platform_event_type_tag }} ({{ tag.event_types_with_tag }})
                  </b-tag>
                </div>
                <div v-if="momentsTypeTags.length > 10" class="mt-2">
                  <span class="pointer" @click="toggleMomentTypeTags()">{{!showAllMomentTypeTags ? $t('communications.Show_more_tags') : $t('communications.Show_less_tags')}}</span>
                </div>
              </CCol>
            </CRow>

            <CRow v-if="showInstructions" class="m-0">
              <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="pb-0">
                <span>{{$t('communications.Add_' + productTag + '_moments_instructions')}}</span>
              </CCol>
            </CRow>

            <div v-if="moments.length > 0">
              <CRow class="moments m-0">
                <CCol cols="4" xl="4" lg="4" md="4" sm="4" v-for="moment in currentPageMoments[currentMomentPage - 1]" v-bind:key="moment.platform_event_type_id_external" class="p-0">
                  <AutoMoment @click.native="!moment.moment_active ? setSelectedMoment(moment) : null" :momentData="moment" parent="sidebar" v-bind:class="{'selected' : selectedMomentsTags.includes(moment.tag)}"/>
                </CCol>
              </CRow>
              <CRow v-if="moments.length > momentsPerPage" class="m-0">
                <CCol cols="12" md="12" class="pt-0">
                  <v-pagination class="justify-content-end" v-model="currentMomentPage" :length="momentPages" :total-visible="9" prev-icon="mdi-chevron-left" next-icon="mdi-chevron-right"></v-pagination>
                </CCol>
              </CRow>            
            </div>
            <div v-else>
              <CRow class="m-0">
                <CCol cols="12" md="12">
                  {{$t('communications.No_moments_found')}}
                </CCol>
              </CRow>
            </div>
          </div>
        </div>
      </div>
      <div class="sidebar_footer">
        <CRow class="m-0">
          <CCol cols="12" lg="12">						   
            <CButton color="primary" @click="checkLoyaltyMomentsCount();" :disabled="selectedMoments.length === 0">
              <span><i class="fas fa-check mr-1"/>{{$t('communications.Add_moments')}} <span v-if="selectedMoments.length > 0">({{selectedMoments.length}})</span></span>
            </CButton>
            <CButton color="secondary" @click="closeSidebarRight();">
              <span><i class="fas fa-times mr-1"/>{{$t('Dismiss')}}</span>
            </CButton>
          </CCol>
        </CRow>
      </div>
    </div>

    <b-modal class="position-absolute" :can-cancel="['x']" :active.sync="pointExceedsModal" :width="600" scroll="keep">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">
          <CRow>
            <CCol cols="12" xl="12" class="pt-0 pb-0">
              <span>{{$t('communications.Total_points_exceeds')}}</span>              
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <span>{{$t('communications.The_total_points_per_employee')}}</span>
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter>
          <CButton color="primary" @click="addProductMoments(); pointExceedsModal = false;"><i class="fas fa-check mr-1"/>{{ $t('common.I_understand_and_accept') }}</CButton>
          <CButton color="secondary" @click="pointExceedsModal = false;"><i class="fas fa-times mr-1"/>{{ $t('Dismiss') }}</CButton>
        </CCardFooter>
      </CCard>
    </b-modal>    
  </div>
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue';
import Multiselect from 'vue-multiselect'

import AutoMoment from '@/components/core/AutoMoment.vue';

export default {
  name: 'addProductAutoMoments',
  props: ['history', 'productTag', 'showInstructions'],
  components: {
    loadingSpinner,
    Multiselect,
    AutoMoment
  },
  watch: {
    $props: {
      handler() {
        this.getProductMoments();
        if(this.productTag === 'loyalty') this.getLoyaltyMomentsCount();
      },
      deep: true,
      immediate: true,
    }
  }, 
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null,
      momentFilters: {
        search_term: '',
        country: null,
        moment_type_tags: [],
      },
      moments: [],
			momentsCatalog: [],
      momentsCountries: [],
      momentsTypeTags: [],
      momentsTypeTagsLoaded: false,
      showAllMomentTypeTags: false,
      selectedMoments: [],
      selectedMomentsTags: [],
      pointExceedsModal: false,
      momentsCount: {
        total_events: 0,
        total_points_gifted: 0
      },
      momentsLoading: false,
      showFilters: false,      
      currentMomentPage: 1,
      currentPageMoments: {},
      momentsPerPage: 9,
      momentPages: 0      
    }
  },  
  methods: {
    getProductMoments() {
      let params = {};
      params.momentFilters = this.momentFilters;
      // Start the loader
      this.momentsLoading = true;
      // Get the moments      
      axios.post(process.env.VUE_APP_API_URL + '/v1/core/events/' + this.productTag + '/moments/catalog', params)
      .then(res => {
        // Update the moments
        this.momentsCatalog = res.data.data;
        // Set the moments array
        this.moments = this.momentsCatalog;
        // Set the pagination
        this.setProductMomentsPagination();
        // Set the countries filter
        this.setProductMomentsCountries();
        // Set the available tags
        this.setProductMomentsTypeTags();
        // Stop the loader
        this.momentsLoading = false;
        // Update the showFilters value
        this.showFilters = true;        
      })
      .catch(err => {
        console.error(err); 
      });
    },
    setProductMomentsPagination() {
      // Set the pagination to the first page
      this.currentMomentPage = 1;        
      // Reset the momentPages value
      this.momentPages = 0;        
      // Loop through the moments to set the pagination
      for (let i = 0; i < this.moments.length; i = i + this.momentsPerPage) {
        this.currentPageMoments[this.momentPages] = this.moments.slice(i, i + this.momentsPerPage);
        this.momentPages++;
      }
    },
    setProductMomentsCountries() {
      // Create array for countries
      let countries = [];
      // Loop through the moments
      for(var m = 0; m < this.moments.length; m++) {
        // If available, add the country of the moment to the countries array
        countries = countries.concat(this.moments[m].countries);
      }
      // Set the momentsCountries array with only unique countries
      this.momentsCountries = [... new Set(countries.map(JSON.stringify))].map(JSON.parse);
    },
    setProductMomentsTypeTags() {
      if(this.momentsTypeTagsLoaded === false) {
        axios.get(process.env.VUE_APP_API_URL + '/v1/core/events/' + this.productTag + '/moments/tags')
        .then(res => {
          // Update the moments
          this.momentsTypeTags = res.data.data;
          // Update the momentsTypeTagsLoaded value
          this.momentsTypeTagsLoaded = true;        
        })
        .catch(err => {
          console.error(err); 
        });
      } else {
        // Create array for eventTypeTags
        let eventTypeTags = [];
        // Loop through the moments array
        for(var m = 0; m < this.moments.length; m++) {
          eventTypeTags = eventTypeTags.concat(this.moments[m].event_type_tags);
        }
        // eventTypeTagsCounts array with counts of remaining tags
        var eventTypeTagsCounts = eventTypeTags.reduce((tagCount, tag) => {
          if(tagCount.hasOwnProperty(tag) === false) tagCount[tag] = 0;
          tagCount[tag]++;
          return tagCount;
        }, {});
        // Update the momentsTypeTags array
        this.momentsTypeTags = Object.keys(eventTypeTagsCounts).map(tag => {
          return {platform_event_type_tag: tag, event_types_with_tag: eventTypeTagsCounts[tag]}; 
        });
        // Sort the momentsTypeTags
        this.momentsTypeTags.sort((a,b)=> (b.event_types_with_tag - a.event_types_with_tag || a.platform_event_type_tag.localeCompare(b.platform_event_type_tag)));
      }
    },    
    setSelectedMoment(moment) {
      // Get the index of the moment tag in the selectedMomentsTags array
      let momentTagIndex = this.selectedMomentsTags.findIndex(x => x === moment.tag);
      // Remove or add the moment tag based on the index
      (momentTagIndex >= 0) ? this.selectedMomentsTags.splice(momentTagIndex, 1) : this.selectedMomentsTags.push(moment.tag);      
      // Get the index of the moment in the selectedMoments array
      let momentIndex = this.selectedMoments.findIndex(x => x.tag === moment.tag);
      // Remove or add the moment based on the index
      (momentIndex >= 0) ? this.selectedMoments.splice(momentIndex, 1) : this.selectedMoments.push(moment);
    },
    setSelectedMomentTypeTag(typeTag) {
      // Get the index of the moment type tag in the moment_type_tags array of the momentFilters
      let momentTypeTagIndex = this.momentFilters.moment_type_tags.findIndex(x => x === typeTag);
      // Remove or add the moment tag based on the index
      (momentTypeTagIndex >= 0) ? this.momentFilters.moment_type_tags.splice(momentTypeTagIndex, 1) : this.momentFilters.moment_type_tags.push(typeTag);      
      // Get the product moments
      this.getProductMoments();
    },
    toggleMomentTypeTags() {
      (this.showAllMomentTypeTags === false) ? this.showAllMomentTypeTags = true : this.showAllMomentTypeTags = false;
    },      
    checkLoyaltyMomentsCount() {
      // Set default selectedMomentPoints value
      let selectedMomentPoints = 0;
      // Loop through the selected Moments
      for(var m = 0; m < this.selectedMoments.length; m++) {        
        // Add up the points to the selectedMomentPoints value if available
        if(this.selectedMoments[m].points) {
          selectedMomentPoints = selectedMomentPoints + parseInt(this.selectedMoments[m].points);
          continue;
        }
        
        // Add up the default_points to the selectedMomentPoints value if available
        if(this.selectedMoments[m].default_points) {
          selectedMomentPoints = selectedMomentPoints + parseInt(this.selectedMoments[m].default_points);
          continue;
        }        
      }

      // Check if the selectedMomentPoints let the total points gifted exceed 1000
      if(selectedMomentPoints + this.momentsCount.total_points_gifted > 1000) {
        this.pointExceedsModal = true;
      } else {
        this.addProductMoments();
      }      
    },
    addProductMoments() {
      let params = {};
      params.selectedMoments = this.selectedMoments;

      axios.post(process.env.VUE_APP_API_URL + '/v1/core/event/' + this.productTag + '/moments', params)
      .then(res => {
        if(this.productTag === 'loyalty') {
          this.$buefy.toast.open({ message: this.selectedMoments.length === 1 ? this.$t('communications.Loyalty_moment_added') : this.$t('communications.Loyalty_moments_added'), type: 'is-success', duration: 2000 });
        } else {
          this.$buefy.toast.open({ message: this.selectedMoments.length === 1 ? this.$t('communications.Moment_added') : this.$t('communications.Moments_added'), type: 'is-success', duration: 2000 });
        }
        // Emit the moments_updated event based on the product tag
        this.$bus.$emit(this.productTag + '_moments_updated');        
        // Close the sidebar
        this.closeSidebarRight();         
      })
      .catch(err => {
        console.error(err); 
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      })
    },
    getLoyaltyMomentsCount() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/events/loyalty/count')
      .then(res => {
        this.momentsCount = res.data.data;
        // Make sure values are numbers
        this.momentsCount.total_events = parseInt(this.momentsCount.total_events);
        this.momentsCount.total_points_gifted = parseInt(this.momentsCount.total_points_gifted);
      })
      .catch(err => {
        console.error(err); 
      }); 
    },
    getTwemoji(emoji) {
      return twemoji.parse(emoji);
    }      
  }, 
  mounted: function() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');
  }  
}
</script>