<template>
  <CHeader fixed light :class="{'hide_background' : showHeaderBackground === false}">
    <CToggler in-header class="ml-3 d-lg-none" v-c-emit-root-event:toggle-sidebar-mobile/>
    <CToggler in-header class="ml-3 d-md-down-none" v-c-emit-root-event:toggle-sidebar @click="toggleSidebar()"/>
    
    <CHeaderBrand v-if="!companyImageId && environmentTag === 'harryhr'" :src="cdnBaseUrl + '/base/dashboard/harry_hr_logo.png'" :class="{'show_header_logo' : showHeaderLogo}"/>
    <CHeaderBrand v-else-if="!companyImageId && environmentTag === 'employalty'" :src="cdnBaseUrl + '/base/dashboard/employalty_logo.png'" :class="{'show_header_logo' : showHeaderLogo}"/>
    <CHeaderBrand v-else-if="!companyImageId && environmentTag === 'vitaalbegrip'" :src="cdnBaseUrl + '/base/dashboard/vitaal_begrip_logo.png'" :class="{'show_header_logo' : showHeaderLogo}"/>    
    <CHeaderBrand v-else :src="apiBaseUrl + '/v1/common/cdn/file/image/base-dashboard/' + companyIdExternal + '/' + companyImageId + '.png' + '/' + clientToken" :class="{'show_header_logo' : showHeaderLogo}"/>

    <CHeaderNav class="d-md-down-none mr-auto header_nav_left">
      <!-- <CBreadcrumbRouter class="border-0 m-0"/> -->
    </CHeaderNav>
    <CHeaderNav class="mr-4 ml-auto header_nav_right">
      <!-- <TheHeaderFilters/> -->
      <TheHeaderSearchEmployee/>
      <TheHeaderOpenExperience/>
      <TheHeaderTodo v-if="modules.tasks == 1"/>
      <TheHeaderActions/>
      <TheHeaderAccount/>
    </CHeaderNav>
  </CHeader>
</template>

<script>
import TheHeaderFilters from './TheHeaderFilters'
import TheHeaderTodo from './TheHeaderTodo'
import TheHeaderActions from './TheHeaderActions'
import TheHeaderAccount from './TheHeaderAccount'
import TheHeaderOpenExperience from './TheHeaderOpenExperience'
import TheHeaderSearchEmployee from './TheHeaderSearchEmployee'

import axios from 'axios'

export default {
  name: 'TheHeader',
  props: ['showHeaderLogo', 'showHeaderBackground'],
  components: {
    TheHeaderFilters,
    TheHeaderTodo,
    TheHeaderActions,
    TheHeaderAccount,
    TheHeaderOpenExperience,
    TheHeaderSearchEmployee
  },
  data() {
    return {
      modules: {
        tasks: 0
      },
      apiBaseUrl: null,
      cdnBaseUrl: null,
      clientToken: null,
      companyIdExternal: null,
      companyImageId: null,
      environmentTag: null
    }
  },
  methods: {
    toggleSidebar() {
      this.$bus.$emit('toggle_sidebar_menu');
    },
    openHowTo() {
      var url = 'https://harryhr.com/how-to';
      var win = window.open(url, '_blank');
      win.focus();
    },
    checkModules() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/modules')
      .then(res => {      
        this.modules = res.data.data;      
      })
      .catch(err => {
        console.error(err); 
      });
    }    
  },
  mounted: function() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.cdnBaseUrl = process.env.VUE_APP_CDN_URL;
    this.clientToken = localStorage.getItem('token');    
    if(localStorage.getItem('companyIdExternal') !== null) this.companyIdExternal = localStorage.getItem('companyIdExternal');
    if(localStorage.getItem('companyImageId') !== null) this.companyImageId = localStorage.getItem('companyImageId');
    if(localStorage.getItem('environmentTag') !== null) this.environmentTag = localStorage.getItem('environmentTag');
    // Check the modules
    this.checkModules();
    
    this.$bus.$on('theming_updated', (e) => {
      if(localStorage.getItem('companyImageId') !== null) this.companyImageId = localStorage.getItem('companyImageId');
    });
  },
  beforeDestroy() {
    this.$bus.$off('theming_updated');
  }  
}
</script>
