import * as axios from 'axios';

class UploadAdapter {
  constructor({loader, args}) {
    this.loader = loader;
    this.externalId = args.externalId;
    this.source = args.source;
  } 
  
  upload() {
    return this.loader.file
    .then(uploadedFile => {
      return new Promise((resolve, reject) => {
        const data = new FormData();
        data.append('upload', uploadedFile);

        axios({
          url: 'v1/common/upload/image/' + this.source + '/' + this.externalId,
          method: 'post',
          data,
          headers: { 'Content-Type': 'multipart/form-data;' },
          withCredentials: false
        }).then(response => {
          if (response.data.status == 'Success') {
            resolve({
              default: response.data.data
            });
          } else {
            reject(response.data.message);
          }
        }).catch(response => {
          reject('Upload failed');
        });
      });
    });
  }

  abort() { }
}

export { UploadAdapter }