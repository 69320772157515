<template>
  <div class="position-relative">
    <div class="sidebar_container">
      <div class="sidebar_header">
        <CRow class="m-0">
          <CCol cols="10" lg="10" md="10" sm="10" class="text-left">
            <span v-if="history.length > 1" class="pointer" @click="removeSidebarHistoryStep()">
              <i class="fas fa-arrow-circle-left mr-1"/>
            </span>            
            <span class="sidebar_subject">
							{{!kbIntentIdExternal ? $t('kb.new_item') : $t('kb.edit_item')}}
            </span>
          </CCol>
          <CCol cols="2" lg="2" md="2" sm="2" class="text-right">
            <span @click="closeSidebarRight();"><i class="fas fa-times"/></span>        
          </CCol>
        </CRow>
      </div>
      <div class="sidebar_content">
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <quick-edit type="text" mode="ignore" :buttonOkText="$t('Save')" :buttonCancelText="$t('Dismiss')" v-model="item.item_title">                        
              <h2 class="m-0">{{item.item_title ? item.item_title : $t('common.Click_to_set_title')}}<i class="icon edit fas fa-pen ml-1"/></h2>
            </quick-edit> 
          </CCol>           
        </CRow>       
        <!-- <CRow class="m-0">           
          <CCol cols="12" lg="12" class="pt-0">
            <quick-edit type="textarea" rows="5" mode="ignore" :buttonOkText="$t('Save')" :buttonCancelText="$t('Dismiss')" v-model="item.item_description">
              <span>{{item.item_description ? item.item_description : $t('common.Click_to_set_description')}}<i class="icon edit fas fa-pen ml-1"/></span>
            </quick-edit>             
          </CCol>
        </CRow> -->
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <quick-edit type="text" mode="ignore" :buttonOkText="$t('Save')" :buttonCancelText="$t('Dismiss')" v-model="item.code">                        
              <span>{{item.code ? item.code : $t('common.Click_to_set_code')}}<i class="icon edit fas fa-pen ml-1"/></span>
            </quick-edit>
          </CCol>           
        </CRow>         
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <label><b>{{$t('common.Which_subcategory')}}</b></label>                    
            <multiselect
              class="data_table"
              v-model="item.subcategory"
              :options="subcategories" 
              :multiple="false"
              :hide-selected="true"
              :close-on-select="true"
              :selectLabel="$t('common.Add_subcategory')"
              :deselectLabel="$t('common.Remove_subcategory')"
              track-by="kb_intent_subcategory_id_external" 
              label="label">
              <span slot="noResult">{{$t('common.no_subcategories_found')}}</span>
            </multiselect>
          </CCol>
        </CRow>

        <div v-if="itemIdExternal">
          <CRow class="m-0">
            <CCol cols="12" lg="12" class="pt-0" v-bind:class="{'pb-0' : item.translations}">
              <label><b>{{$t('kb.Content')}}</b></label>
              <div class="wysiwyg">
                <ckeditor :editor="editor" v-model="item.item_content" :config="editorConfig" @ready="onEditorReady"></ckeditor>
              </div>            
            </CCol>
          </CRow>

          <CRow v-if="item.translations" class="m-0">
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <hr class="mt-3 mb-3">
              <label><b>{{$t('common.Translations')}}</b></label>
              <b-tabs class="content_translation_tabs no_borders mb-0" type="is-boxed" :animated="false">
                <b-tab-item v-for="translation in item.translations" v-bind:key="translation.translation_kb_intent_id_external">
                  <template slot="header">
                    <span v-html="getTwemoji(translation.flag)"></span>
                  </template>
                  <template>
                    <CRow>
                      <CCol cols="12" lg="12" class="pb-0">
                        <label><b>{{$t('knowledge.Translated_title')}}</b></label>
                        <CInput type="text" v-model="translation.translated_title" class="m-0" required was-validated/>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol cols="12" lg="12" class="pb-0">
                        <div class="wysiwyg">
                          <label><b>{{$t('knowledge.Translated_content')}}</b></label>
                          <ckeditor :editor="editor" v-model="translation.translated_content" :config="editorConfig"></ckeditor>
                        </div>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol cols="12" lg="12" class="pb-0">
                        <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="translation.translation_verified" size="is-small">{{ $t('common.Translation_verified') }}</b-switch>            
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol cols="12" lg="12" class="pb-0">
                        <CButton color="primary" @click="updateTranslation(translation);">{{$t('common.Update_translation')}}</CButton>
                      </CCol>                      
                    </CRow>
                  </template>
                </b-tab-item>
              </b-tabs>
              <hr class="mt-3 mb-3">
            </CCol>
          </CRow>

          <CRow class="m-0">
            <CCol cols="12" lg="12" class="pt-0">
              <label><b>{{$t('kb.Evaluation_date')}}</b></label>
              <div v-if="!showEvaluationDateField">
                <div @click="setEvaluationDate()" class="pointer">
                  <span v-if="!item.evaluation_date && !item.new_evaluation_date">{{$t('common.Click_to_set_date')}}<i class="icon edit fas fa-pen ml-1"/></span>
                  <span v-else-if="item.new_evaluation_date">{{item.new_evaluation_date | moment('DD-MM-YYYY')}}<i class="icon edit fas fa-pen ml-1"/></span>
                  <span v-else-if="item.evaluation_date">{{item.evaluation_date | moment('DD-MM-YYYY')}}<i class="icon edit fas fa-pen ml-1"/></span>
                </div>
              </div>
              <div v-else>
                <b-datepicker v-model="item.new_evaluation_date"
                              icon="calendar-day"
                              icon-pack="fas"
                              :first-day-of-week="1"
                              :show-week-number="true"
                              :years-range="[0, 10]"
                              :min-date="minEvaluationDate"
                              :placeholder="$t('common.click_to_select')"
                              class="d-inline-block align-middle mb-2"
                              @input="showEvaluationDateField = false;">
                </b-datepicker>
              </div>
            </CCol>
          </CRow>
          <CRow class="m-0">
            <CCol cols="12" lg="12" class="pt-0">
              <label><b>{{$t('kb.Evaluator')}}</b></label>
              <div v-if="!showEvaluatorField">
                <div @click="setEvaluator()" class="pointer">                  
                  <span v-if="!item.new_evaluator && !item.evaluator">{{$t('knowledge.Click_to_set_evaluator')}}<i class="icon edit fas fa-pen ml-1"/></span>
                  <span v-else-if="item.new_evaluator">{{item.new_evaluator.label}}<i class="icon edit fas fa-pen ml-1"/></span>
                  <span v-else-if="item.evaluator">{{item.evaluator.label}}<i class="icon edit fas fa-pen ml-1"/></span>
                </div>
              </div>
              <div v-else>
                <multiselect                        
                  class="data_table"
                  v-model="item.new_evaluator" 
                  :options="users" 
                  :multiple="false"                
                  :placeholder="$t('common.Select_user')" 
                  :selectLabel="$t('common.Add_user')" 
                  :selectedLabel="$t('Added')"
                  :deselectLabel="$t('common.Remove_user')"
                  track-by="user_id_external" 
                  label="label"                        
                  @search-change="asyncFindUser"
                  @input="showEvaluatorField = false;">
                  <span slot="noResult">{{ $t('common.no_users_found') }}</span>
                  <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                </multiselect>
              </div>          
            </CCol>
          </CRow>
          <CRow class="m-0">
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <label><b>{{$t('kb.Attachments')}}</b></label>
              <div v-if="item.attachments.length > 0">
                <CRow>
                  <div v-for="(attachment, index) in item.attachments" v-bind:key="index" class="w-100 attachment">
                    <CCol cols="12" lg="12" class="pt-0 pb-0">                                            
                      <label>{{$t('kb.Attachment_type')}}</label>
                      <multiselect
                        class="data_table mb-3"
                        v-model="attachment.attachment_type" 
                        :options="attachmentTypes" 
                        :multiple="false"
                        :hide-selected="true"
                        :close-on-select="true"
                        :placeholder="$t('common.Select_attachment_type')"
                        track-by="attachment_type_tag" 
                        label="attachment_type"
                        :show-labels="false"
                        :disabled="attachment.kb_intent_content_attachment_id_external !== null">>
                        <span slot="noResult">{{ $t('common.no_courses_found') }}</span>
                      </multiselect>
                                            
                      <CInput type="text" :label="$t('kb.Attachment_name')" v-model="attachment.attachment_name"/>
                         
                      <div v-if="attachment.attachment_type">
                        <div v-if="attachment.attachment_type.attachment_type_tag === 'video'">
                          <div v-if="attachment.attachment_videos.length === 0">
                            <CRow>
                              <CCol cols="12" lg="12" class="pt-0">
                                <CInput type="text" :label="$t('common.Youtube_id')" v-model="attachment.video_id" class="mb-0" required was-validated/>
                                <p class="countdown mt-1 mb-0">{{ $t('common.youtube_video_id_input_explainer') }}</p>
                              </CCol>
                            </CRow>
                            <CRow>
                              <CCol cols="6" lg="6" class="pt-0">
                                <div class="videos_preview">
                                  <div v-for="(video, videoIndex) in attachment.new_attachment_videos" v-bind:key="videoIndex" class="video">
                                    <vue-plyr ref="plyr_preview">
                                      <video controls crossorigin playsinline>
                                        <source :src="video.video_url"/>
                                      </video>
                                    </vue-plyr>              
                                    <i class="fas fa-times" @click="removeAttachmentVideo(index)"/>
                                  </div>
                                </div>
                                <div v-if="attachment.new_attachment_videos.length == 0">
                                  <input id="videoUpload" type="file" accept="video/*" :value="attachment.uploaded_attachment_videos" @change="onAttachmentVideosChange" hidden>                                                            
                                  <CButton color="primary" @click="pickAttachmentVideo(index);"><i class="fas fa-video mr-1"/>{{$t('sw.upload_video')}}</CButton>
                                </div>
                              </CCol>
                            </CRow>
                          </div>
                          <div v-else>
                            <CRow>
                              <CCol cols="12" lg="12" class="pt-0 pb-0">
                                <label>{{ $t('common.current_video') }}</label>
                                <div class="current_videos" v-bind:class="{ 'mb-2' : attachment.attachment_videos.length > 0 }">
                                  <div v-for="video in attachment.attachment_videos" v-bind:key="video.kb_intent_content_video_id" class="video">
                                    <div v-if="video.video_available === 'Y'">
                                      <div v-if="video.video_provider_tag === 'youtube'">
                                        <youtube :video-id="video.provider_video_id" ref="youtube" :player-vars="playerVars" :fitParent="true" class="w-100"></youtube>            
                                      </div>
                                      <div v-if="video.video_provider_tag === 'dyntube'" class="dyntube_iframe_container" v-bind:style="{ paddingTop: video.video_container_padding_percentage + '%' }">
                                        <iframe class="dyntube_responsive_iframe" webkitallowfullscreen mozallowfullscreen allowfullscreen :src="'https://play.dyntube.io/iframe/' + video.provider_video_private"></iframe>
                                      </div>                    
                                    </div>
                                    <videoPlaceholder v-else emoji="📹" :content="$t('common.Video_almost_available')"/>                        
                                  </div>
                                </div>
                              </CCol>
                            </CRow>
                          </div>
                        </div>

                        <div v-if="['picture', 'document', 'audio'].includes(attachment.attachment_type.attachment_type_tag)">
                          <b-switch v-if="['document', 'audio'].includes(attachment.attachment_type.attachment_type_tag)" v-model="attachment.available_for_download" size="is-small" class="mb-3">{{ $t('common.Available_for_download') }}?</b-switch>

                          <div v-if="!attachment.kb_intent_content_attachment_id_external">
                            <label v-if="attachment.attachment_type.attachment_type_tag === 'picture'">{{$t('kb.Upload_an_image')}}</label>
                            <label v-if="attachment.attachment_type.attachment_type_tag === 'document'">{{$t('kb.Upload_a_document')}}</label>
                            <label v-if="attachment.attachment_type.attachment_type_tag === 'audio'">{{$t('kb.Upload_an_audio_file')}}</label>
                            <FileUpload ref="upload" :intentIdExternal="itemIdExternal" :attachmentTypeId="attachment.attachment_type.kb_attachment_type_id" :attachmentIndex="index" :answerIndex="0" @file_uploaded="setAttachmentFile"/>
                          </div>
                          <div v-else>
                            <div v-if="attachment.attachment_type.attachment_type_tag === 'picture'">
                              <img :src="apiBaseUrl + '/v1/common/cdn/file/image/knowledge-item/' + item.kb_intent_id_external + '/' + attachment.attachment + '/' + clientToken" class="attachment_image"/>
                            </div>
                            <div v-if="attachment.attachment_type.attachment_type_tag === 'document'">
                              <CButton class="mb-2 d-block link" color="primary" @click="openPDF(apiBaseUrl + '/v1/common/cdn/file/pdf/knowledge-item/' + item.kb_intent_id_external + '/' + attachment.attachment + '/' + clientToken);">
                                <i class="fas fa-external-link-alt mr-1"/>
                                <span>{{$t('kb.Open_pdf')}}</span>
                              </CButton>
                            </div>
                            <div v-if="attachment.attachment_type.attachment_type_tag === 'audio'" class="mb-2">
                              <audioPlayer v-if="apiBaseUrl && clientToken && attachment.attachment"
                                           :standalone="true"
                                           :audioFileName="attachment.attachment_name"
                                           :audioSourceUrl="apiBaseUrl + '/v1/common/cdn/file/mp3/knowledge-item/' + item.kb_intent_id_external + '/' + attachment.attachment + '/' + clientToken">
                              </audioPlayer>                              
                            </div>
                          </div>
                        </div>

                        <div v-if="attachment.attachment_type.attachment_type_tag === 'link_external'">
                          <CInput type="text" :label="$t('kb.External_url')" v-model="attachment.attachment" required was-validated/>
                        </div>              
                        
                        <div v-if="attachment.attachment_type.attachment_type_tag === 'link_internal'">
                          <CInput type="text" :label="$t('kb.Path_in_app')" v-model="attachment.attachment" required was-validated/>
                        </div>
                      </div>
                  
                      <CButton v-if="attachment.kb_intent_content_attachment_id_external !== null && attachment.kb_intent_content_attachment_id_external !== undefined" color="primary" @click="deleteAttachment(attachment.kb_intent_content_attachment_id_external, index);">
                        <i class="fas fa-trash mr-1"/>
                        <span>{{$t('kb.Remove_attachment')}}</span>
                      </CButton>
                      <CButton v-else color="secondary" @click="item.attachments.splice(index, 1);">
                        <i class="fas fa-times mr-1"/>{{$t('Dismiss')}}
                      </CButton>
                      <hr class="mt-3 mb-3">
                    </CCol>
                  </div>
                </CRow>
              </div>
              <div v-else>
                <p class="mb-2">{{$t('kb.No_attachments_added')}}</p>
              </div>
            </CCol>
            <CCol cols="12" lg="12" class="pt-0">
              <CButton color="primary" @click="addAttachment();">
                <i class="fas fa-plus mr-1"/>
                <span>{{$t('kb.Add_attachment')}}</span>
              </CButton>
            </CCol>
          </CRow>
          <CRow class="m-0">
            <CCol cols="12" lg="12" class="pt-0">
              <label class="d-block"><b>{{$t('kb.QR_code')}}</b></label>
              <div v-if="itemQRData" class="qr_code_container">
                <div v-if="hideQRcode === false">
                  <vrcode :download="{ text: $t('kb.Download_qr'), class: 'btn btn-primary mt-1', filename: item.external_code, visible: true, type: 'image/png' }" :value="itemQRData" :options="{ size: 200, level: 'Q', padding: 0 }"></vrcode>
                </div>
                <p v-else>{{$t('kb.Generating_qr')}}</p>
              </div>
              <CButton v-if="!itemQRData" color="primary" @click="generateQRCode(item);"><i class="fas fa-qrcode mr-1"/>{{$t('kb.Generate_qr')}}</CButton>
            </CCol>
          </CRow>  
        </div>

        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <label><b>{{$t('common.Select_subtopic')}}</b></label>
            <multiselect
              class="data_table"
              v-model="item.subtopic" 
              :options="subtopics"
              :multiple="false"
              :close-on-select="true"
              :placeholder="$t('common.Select_subtopic')" 
              :selectLabel="$t('common.Add_subtopic')" 
              :selectedLabel="$t('Added')"
              :deselectLabel="$t('common.Remove_subtopic')"
              track-by="subtopic_id_external" 
              label="label">
              <span slot="noResult">{{ $t('common.no_subtopics_found') }}</span>
            </multiselect>
          </CCol>            
        </CRow>        
      </div>
      <div class="sidebar_footer">
        <CRow class="m-0">
          <CCol cols="12" lg="12">
						<div v-if="!itemIdExternal">
							<CButton color="primary" @click="insertItem(item);"><i class="fas fa-check mr-1"/>{{$t('Save')}}</CButton>
							<CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{$t('Dismiss')}}</CButton>
						</div> 						   
						<div v-else>
							<CButton color="primary" @click="((item.item_title !== currentItemTitle || item.item_content !== currentItemContent) && item.translations && item.translations.length > 0) ? contentUpdatedModal = true : updateItem(item);">
                <i class="fas fa-check mr-1"/>{{$t('Save')}}
              </CButton>
							<CButton color="secondary" @click="closeSidebarRight();">
                <i class="fas fa-times mr-1"/>{{$t('Dismiss')}}
              </CButton>
          	</div>
          </CCol>
        </CRow>
      </div>
    </div>

    <b-modal :can-cancel="['x']" :active.sync="contentUpdatedModal" :width="600" scroll="keep" class="position-absolute">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">
          <span>{{$t('knowledge.Content_updated')}}</span>
        </CCardHeader>        
        <CCardBody class="pt-2">
          <span>{{$t('knowledge.Content_has_been_updated')}}</span>
        </CCardBody>
        <CCardFooter>
          <CButton color="primary" @click="updateContentTranslations = true; updateItem(item); contentUpdatedModal = false;">
            <span>{{$t('knowledge.Translate_automatically')}}</span>
          </CButton>
          <CButton color="secondary" @click="updateContentTranslations = false; updateItem(item); contentUpdatedModal = false;">
            <span>{{$t('knowledge.Translate_manually')}}</span>
          </CButton>
        </CCardFooter>
      </CCard>
    </b-modal>      
  </div>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import QuickEdit from 'vue-quick-edit';
import vrcode from '@ispa.io/vrcode'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { UploadAdapter } from '@/components/upload/upload-adapter';
import { v4 as uuidv4 } from 'uuid';

import FileUpload from '@/components/upload/FileUpload.vue';
import videoPlaceholder from '@/components/common/videoPlaceholder.vue';
import audioPlayer from '@/components/common/audioPlayer.vue';

export default {
  name: 'itemDetails',
  props: ['history', 'kbSubcategory', 'kbIntentIdExternal', 'backToPreview'],
  components: {
    Multiselect,
    QuickEdit,
    FileUpload,
    vrcode,
    videoPlaceholder,
    audioPlayer
  },
  watch: {
    $props: {
      handler() {
        if(this.kbIntentIdExternal) {
          this.itemIdExternal = this.kbIntentIdExternal;
          // Set the external ID for the wysiwyg uploader
          this.editorConfig.upload.externalId = this.itemIdExternal;
        }
        // Get the item details
        this.getItemDetails();
      },
      deep: true,
      immediate: true,
    }
  }, 
  data() {
    return {
      apiBaseUrl: null,
      cdnBaseUrl: null,
      companyIdExternal: null,
      clientToken: null,
			item: {
        attachments: [],
      },
      itemIdExternal: null,
      currentItemTitle: null,
      currentItemContent: null,
      contentUpdatedModal: false,
      updateContentTranslations: false,
      subcategories: [],
      subtopics: [],
      statuses: [],
      attachmentTypes: [],
      users: [],
      itemQRData: null,
      hideQRcode: false,
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [ 'heading', '|', 'bold', 'italic', 'bulletedList', 'numberedList', '|', 'imageUpload', '|', 'undo', 'redo' ],
        extraPlugins: [this.wysiwygUpload],
        upload: { externalId: null, source: 'kb' }        
      },
      playerVars: { 
        autoplay: 0, 
        controls: 0,
        playsinline: 1
      },
      maxVideoFilesize: 5368709120,
      attachmentIndex: null,
      showEvaluationDateField: false,
      minEvaluationDate: new Date(),
      showEvaluatorField: false
    }
  },
  methods: {
    setContentImageUrls() {
      // Create a new div element
      let el = document.createElement('div');
      // Add the content to the new div
      el.innerHTML = this.item.item_content;
      // Gather the img elements within the DOM element       
      var images = el.querySelectorAll('img'); 
      // Check if there are img elements available
      if(images && images.length > 0) {
        // Loop through the array with img elements
        for (var i = 0; i < images.length; i++) {
          // Get the filename of the image
          let imageFileName = images[i].src.substring((images[i].src.lastIndexOf("/")) + 1);
          // Update the source for the necessary images
          if(imageFileName.endsWith(".png") || imageFileName.endsWith(".jpg") || imageFileName.endsWith(".jpeg")) {
            images[i].src = this.apiBaseUrl + '/v1/common/cdn/file/image/knowledge-item/' + this.itemIdExternal + '/' + imageFileName + '/' + this.clientToken;
          }
        }
      }
      // Update the content
      this.item.item_content = el.outerHTML;      
    },
    getItemDetails() {
      if(this.itemIdExternal !== null) {
				axios.get(process.env.VUE_APP_API_URL + '/v1/knowledge/item/' + this.itemIdExternal)
				.then(res => {
					this.item = res.data.data;
          // Store the current Knowledge item title and content into variables
          this.currentItemTitle = this.item.item_title;
          this.currentItemContent = this.item.item_content;          
          // Set the item_evaluation_date value for the item
          if(this.item.item_evaluation_date) this.item.evaluation_date = new Date(this.item.item_evaluation_date);
          // Set the content image URL's
          this.setContentImageUrls();
				})
				.catch(err => {
					console.error(err); 
				});    
      } else {
        // Reset the data 
        this.resetItemData();
      }
    },
    insertItem(data) {
      let params = {};
      params.itemData = data;
      // (params.itemData.is_active == true) ? params.itemData.active = 'Y' : params.itemData.active = 'N';
      
      let item_title = params.itemData.item_title;
      let code = params.itemData.code;

      if(item_title && code) {
        axios.post(process.env.VUE_APP_API_URL + '/v1/knowledge/item', params)
        .then(res => {
          this.itemIdExternal = res.data.data.kb_intent_id_external;
          // Set the external ID for the wysiwyg uploader
          this.editorConfig.upload.externalId = this.itemIdExternal;           
          // Get the item details
          this.getItemDetails();
          // Reset the content data
          this.resetContentData();
          // Show a success notice
          this.$buefy.toast.open({ message: this.$t('common.Item_added'), type: 'is-success', duration: 2000 });
          // Emit the update_kb_subcategory_items event
          if(this.kbSubcategory && this.kbSubcategory.kb_intent_subcategory_id_external && this.kbSubcategory.kb_intent_subcategory_name) {           
            this.$bus.$emit('update_kb_subcategory_items', this.kbSubcategory);
          }
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }
    },
    updateItem(data) {
      let params = {}
      params.itemData = data;
      params.itemData.update_content_translations = this.updateContentTranslations;
      // (params.itemData.is_active == true) ? params.itemData.active = 'Y' : params.itemData.active = 'N';   
      if(params.itemData.new_evaluation_date) params.itemData.new_item_evaluation_date = this.$luxon(params.itemData.new_evaluation_date.toISOString(), "yyyy-MM-dd");

      if(this.validateItem(params) === true) {
        for(var a = 0; a < params.itemData.attachments.length; a++) {
          (params.itemData.attachments[a].available_for_download == false) ? params.itemData.attachments[a].attachment_downloadable = 0 : params.itemData.attachments[a].attachment_downloadable = 1;

          if(!params.itemData.attachments[a].kb_intent_content_attachment_id_external && params.itemData.attachments[a].attachment_type.attachment_type_tag === 'video') {
            // Set the insert_video param
            params.itemData.attachments[a].insert_video = 1;
            // Set the video_provider_tag value to 'dyntube if the attachment contains new videos
            if(params.itemData.attachments[a].new_attachment_videos.length > 0) {
              params.itemData.attachments[a].video_provider_tag = 'dyntube';
              params.itemData.attachments[a].video_id = null;
              params.itemData.attachments[a].content_video_id_external = uuidv4();
            } else {
              params.itemData.attachments[a].video_provider_tag = 'youtube';
            }
          }        
        }

        // Create a new div element
        let el = document.createElement('div');
        // Add the item content to the new div
        el.innerHTML = params.itemData.item_content;
        // Gather the img elements within the DOM element       
        var images = el.querySelectorAll('img'); 
        // Check if there are img elements available
        if(images && images.length > 0) {
          // Loop through the array with img elements
          for (var i = 0; i < images.length; ++i) {
            let imageFileName = null;
            // Convert image URL to fragments array
            let imageUrlFragments = images[i].src.split('/');
            // Check the fragments array on images
            var pngImageIndex = imageUrlFragments.findIndex(i => i.includes('.png'));
            var jpgImageIndex = imageUrlFragments.findIndex(i => i.includes('.jpg'));
            var jpegImageIndex = imageUrlFragments.findIndex(i => i.includes('.jpg'));
            // Get the image filename from the fragments
            if(pngImageIndex >= 0) imageFileName = imageUrlFragments[pngImageIndex];
            else if(jpgImageIndex >= 0) imageFileName = imageUrlFragments[jpgImageIndex];
            else if(jpegImageIndex >= 0) imageFileName = imageUrlFragments[jpegImageIndex];          
            // Update the source for the necessary images
            images[i].src = this.cdnBaseUrl + '/kb/' + this.companyIdExternal + '/' + this.itemIdExternal + '/' + imageFileName;
          }
          // Update the content
          params.itemData.item_content = el.outerHTML;
        }        

        axios.put(process.env.VUE_APP_API_URL + '/v1/knowledge/item/' + this.itemIdExternal, params)
        .then(res => {
          let setAttachments = res.data.data.set_attachments;
          
          for(var a = 0; a < setAttachments.length; a++) {
            let setAttachmentTypeTag = setAttachments[a].attachment_type_tag;
            // Video upload start after item is updated and the video is not active (so it should be uploaded first.)
            if(setAttachmentTypeTag === 'video' && setAttachments[a].video && setAttachments[a].video.active === 'N') {
              // Emit the new_knowledge_video_uploading event
              this.$bus.$emit('new_knowledge_video_uploading', setAttachments[a].video.external_id);
              // Get the attachmentIndex
              var attachmentIndex = params.itemData.attachments.findIndex(i => i.content_video_id_external == setAttachments[a].video.external_id);
              // Create new formdata              
              const videoFormData = new FormData();
              // Loop through the new videos and append them to the video formdata
              for(var i = 0; i < params.itemData.attachments[attachmentIndex].new_attachment_videos.length; i++) {
                videoFormData.append("video_" + i, params.itemData.attachments[attachmentIndex].new_attachment_videos[i]);
              }
              // Upload the videos
              axios.post('v1/common/upload/videos/knowledge/' + setAttachments[a].video.external_id, videoFormData, {
                headers: { 'Content-Type': 'multipart/form-data' }
              })
              .then(res => {
                // Emit the knowledge_video_upload_finished event
                this.$bus.$emit('knowledge_video_upload_finished', res.data.data.uploaded_kb_intent_content_video_id_external);
              })
              .catch(err => {
                console.error(err); 
              });              
            }
          }

          this.$buefy.toast.open({ message: this.$t('common.Item_updated'), type: 'is-success', duration: 2000 });        
          // Emit the update_kb_subcategory_items event
          if(this.kbSubcategory && this.kbSubcategory.kb_intent_subcategory_id_external && this.kbSubcategory.kb_intent_subcategory_name) {           
            this.$bus.$emit('update_kb_subcategory_items', this.kbSubcategory);
          } else {
            this.$bus.$emit('update_kb_item', this.itemIdExternal);
          }
                  
          if(this.backToPreview === true) {
            this.removeSidebarHistoryStep();
          } else {
            // Reset the data
            this.resetItemData();
            this.resetContentData();            
            // Close the sidebar
            this.closeSidebarRight();
          }
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }      
    },        
    validateItem(params) {
      let item_title = params.itemData.item_title;
      let item_content = params.itemData.item_content;
      let attachments = params.itemData.attachments;

      // Check if base information for step is available
      if(item_title && item_content) {
        // Loop through the attachments
        for(var a = 0; a < attachments.length; a++) {
          let attachmentTypeTag = attachments[a].attachment_type.attachment_type_tag;

          if(!attachmentTypeTag) {
            return false;
          } else if((['picture', 'document', 'link_external', 'link_internal', 'audio'].includes(attachmentTypeTag)) && !attachments[a].attachment) {
            return false;
          } else if(attachmentTypeTag === 'video' && !attachments[a].video_id && attachments[a].attachment_videos.length === 0 && attachments[a].new_attachment_videos.length === 0) {
            return false;
          }           
        }
        // Return true if the check is completed
        return true;         
      } else {
        return false;
      }
    },
    resetItemData() {
      this.item = {
        item_title: null,
        item_description: null,
        code: null,
        kb_intent_status_id: 1,
      };

      if(this.kbSubcategory && this.kbSubcategory.kb_intent_subcategory_id_external && this.kbSubcategory.kb_intent_subcategory_name) {
        this.item.subcategory = {
          kb_intent_subcategory_id_external: this.kbSubcategory.kb_intent_subcategory_id_external,
          label: this.kbSubcategory.kb_intent_subcategory_name
        }
      }      
    },
    resetContentData () {
      this.item.content = '';      
      this.item.attachments = [];
      this.itemQRData = null;
      this.hideQRcode = true;
    },
    getSubcategories() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/knowledge/subcategories/multiselect')
      .then(res => {
        this.subcategories = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    getSubtopics() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/connect/subtopics/multiselect')
      .then(res => {
        this.subtopics = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getAttachmentTypes() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/knowledge/attachments/types/dashboard/multiselect')
      .then(res => {
        this.attachmentTypes = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getStatuses() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/knowledge/items/statuses')
      .then(res => {
        this.statuses = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    resetContent() {
      setTimeout(function(){    
        if(this.$refs.upload !== null && this.$refs.upload !== undefined) {
          this.$refs.upload[0].setAcceptedTypes();      
        }
      }.bind(this), 100);
    },
    addAttachment() {
      this.item.attachments.push({ 
        kb_intent_content_attachment_id: null,
        kb_intent_content_attachment_id_external: null,
        attachment: '',
        attachment_name: null,
        attachment_videos: [],
        new_attachment_videos: [],
        available_for_download: false
      });
    },
    setAttachmentFile(answerIndex, attachmentIndex, attachmentTypeId, attachmentFile) {
      this.item.attachments[attachmentIndex].attachment = attachmentFile;
    },
    deleteAttachment(attachmentIdExternal) {   
      axios.delete(process.env.VUE_APP_API_URL + '/v1/knowledge/attachment/' + attachmentIdExternal)
      .then(res => {
        this.$buefy.toast.open({ message: this.$t('kb.Removed_attachment'), type: 'is-success', duration: 2000 });
        this.getItemDetails();
      })
      .catch(err => {
        console.error(err);
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      })
    },    
    generateQRCode(data) {
      this.hideQRcode = true;    
      setTimeout(function() {
        this.itemQRData = data.kb_intent_id_external;
        this.hideQRcode = false;
      }.bind(this), 500);
    },
    asyncFindUser(query) {
      let searchTerm = query;
      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/users/' + searchTerm)
        .then(res => {      
          this.users = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }    
    },
    openPDF(url) {
      window.open(url);
    },
    onEditorReady(editor){
      let vm = this;

      editor.model.document.on('change', () => {
        const changes = editor.model.document.differ.getChanges();
        
        for(const change of changes) {          
          if(change.attributeKey == 'uploadStatus' && change.attributeOldValue == 'uploading' && change.attributeNewValue == 'complete') {
            vm.setContentImageUrls();
          }
        }
      })
    },       
    wysiwygUpload(editor) {
      var args = editor.config._config.upload;

      editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
        return new UploadAdapter({ loader, args });
      };
    },
    pickAttachmentVideo(index) {
      document.getElementById("videoUpload").click();
      this.attachmentIndex = index;
    },    
    removeAttachmentVideo(index) {
      this.item.attachments[index].new_attachment_videos = [];
    },
    onAttachmentVideosChange(e) {
      // Get the files
      let files = e.target.files;
      // Loop through the files
      for(var i = 0; i < files.length; i++) {                
        // Create an video URL for the file
        files[i].video_url = URL.createObjectURL(files[i]);
        // Check the filesize exceeds the maximum
        let filesize = files[i].size;
        if(filesize > this.maxVideoFilesize) {
          // If so, notify the user
          this.$buefy.toast.open({ message: files[i].name + ' (' + this.formatFileSize(filesize) + ') ' + this.$t('upload.exceeds_maximum_filesize_of') + ' ' + this.formatFileSize(this.maxVideoFilesize) + '.', type: 'is-danger', duration: 2000 });
        } else {
          // If not, add the file to the new_attachment_videos array of the postVideos
          this.item.attachments[this.attachmentIndex].new_attachment_videos.push(files[i]);
        }
      }
      // Reset the attachmentIndex
      this.attachmentIndex = null;
    },
    formatFileSize(bytes) {
      const sufixes = ['B', 'kB', 'MB', 'GB', 'TB'];
      const i = Math.floor(Math.log(bytes) / Math.log(1024));
      return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sufixes[i]}`;
    },
    setEvaluationDate() {
      this.showEvaluationDateField = true;

      if(!this.item.new_evaluation_date) {
        if(this.item.evaluation_date) {
          this.item.new_evaluation_date = this.item.evaluation_date;
        } else {
          let newEvaluationDate = new Date().setFullYear(new Date().getFullYear() + 1);
          this.item.new_evaluation_date = new Date(newEvaluationDate);
        }
      }
    },
    setEvaluator() {
      this.showEvaluatorField = true;
    },
    getTwemoji(emoji) {
      return twemoji.parse(emoji);
    },
    updateTranslation(data) {
      let itemTranslationIdExternal = data.translation_kb_intent_id_external;
      let params = {};
      params.translationData = data;
      params.translationData.kb_intent_id_external = this.itemIdExternal;
      (params.translationData.translation_verified == true) ? params.translationData.verified = 'Y' : params.translationData.verified = 'N';

      let translated_title = params.translationData.translated_title;
      let translated_content = params.translationData.translated_content;

      if(translated_title && translated_content) {
        axios.put(process.env.VUE_APP_API_URL + '/v1/common/translator/knowledge/translation/' + itemTranslationIdExternal, params)
        .then(res => {
          this.$buefy.toast.open({ message: this.$t('common.Translation_updated'), type: 'is-success', duration: 2000 });
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }
    } 
  },
  mounted: function() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.cdnBaseUrl = process.env.VUE_APP_CDN_URL;
    if(localStorage.getItem('companyIdExternal') !== null) this.companyIdExternal = localStorage.getItem('companyIdExternal');    
    this.clientToken = localStorage.getItem('token');

    let minEvaluationDate = new Date().setMonth(new Date().getMonth() + 2);
    this.minEvaluationDate = new Date(minEvaluationDate);

    this.getSubcategories();
    this.getSubtopics();
    this.getStatuses();
    this.getAttachmentTypes();
  }  
}
</script>

<style>
  #fileInput {
    display: none;
  }
</style>