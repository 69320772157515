<template>
  <div>
    <div class="sidebar_container">
      <div class="sidebar_header">
        <CRow class="m-0">
          <CCol cols="10" lg="10" md="10" sm="10" class="text-left">
            <span v-if="history.length > 1" class="pointer" @click="removeSidebarHistoryStep()">
              <i class="fas fa-arrow-circle-left mr-1"/>
            </span>            
            <span class="sidebar_subject">
							{{!serviceQuestionFolderIdExternal ? $t('service.New_folder') : $t('service.Edit_folder')}}
            </span>
          </CCol>
          <CCol cols="2" lg="2" md="2" sm="2" class="text-right">
            <span @click="closeSidebarRight();"><i class="fas fa-times"/></span>        
          </CCol>
        </CRow>
      </div>
      <div class="sidebar_content">
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <quick-edit type="text" mode="ignore" :buttonOkText="$t('Save')" :buttonCancelText="$t('Dismiss')" v-model="folder.folder_name">                        
              <h2 class="m-0">{{folder.folder_name ? folder.folder_name : $t('common.Click_to_set_name')}}<i class="icon edit fas fa-pen ml-1"/></h2>
            </quick-edit>
          </CCol>           
        </CRow>
        <CRow class="m-0">           
          <CCol cols="12" lg="12" class="pt-0">
            <quick-edit type="textarea" rows="5" mode="ignore" :buttonOkText="$t('Save')" :buttonCancelText="$t('Dismiss')" v-model="folder.folder_description">
              <span>{{folder.folder_description ? folder.folder_description : $t('common.Click_to_set_description')}}<i class="icon edit fas fa-pen ml-1"/></span>
            </quick-edit>            
          </CCol>            
        </CRow>        
        <CRow class="m-0">           
          <CCol cols="12" lg="12" class="pt-0">
            <label>{{$t('common.Specified_for_groups')}}</label>
            <multiselect class="data_table"
                         v-model="folder.target_groups" 
                         :options="targetGroups" 
                         :multiple="true"
                         :placeholder="$t('common.Select_group')" 
                         :selectLabel="$t('common.Add_group')" 
                         :selectedLabel="$t('Added')"
                         :deselectLabel="$t('common.Remove_group')"
                         track-by="group_id" 
                         label="title"
                         @input="getFolderMembers();">
              <span slot="noResult">{{ $t('common.no_groups_found') }}</span>
            </multiselect> 
          </CCol>
        </CRow>
        <CRow class="m-0">           
          <CCol cols="12" lg="12" class="pt-0">
            <label>{{$t('service.Folder_targets')}}</label>
            <CRow v-for="(target, index) in folder.targets" v-bind:key="index">
              <CCol cols="12" lg="12" class="pt-0 pb-0">
                <div class="mb-2 d-flex filters">
                  <div v-bind:class="{'mr-2' : target.target_type}">
                    <multiselect class="data_table open_absolute"
                                 v-model="target.target_type" 
                                 :options="folderTargetTypes" 
                                 :multiple="false"                
                                 :placeholder="$t('service.Select_target_type')" 
                                 :selectLabel="$t('service.Add_target_type')" 
                                 :selectedLabel="$t('Added')"
                                 :deselectLabel="$t('service.Remove_target_type')"
                                 track-by="service_question_folder_target_type_tag" 
                                 label="service_question_folder_target_type"
                                 :custom-label="customTargetTypeLabel"
                                 :disabled="target.target_type !== null && target.target_type !== undefined">
                      <span slot="noResult">{{ $t('common.no_types_found') }}</span>
                      <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                    </multiselect>
                  </div>
                  <div v-if="target.target_type">
                    <div class="d-flex align-items-center">
                      <div class="flex-grow-1 mr-2">
                        <CInput type="text" class="attribute m-0" v-model="target.target_value"></CInput>
                      </div>
                      <div>
                        <span>{{$t('common.hours')}}</span>
                      </div>                      
                    </div>
                  </div>
                  
                  <CButton class="ml-2 mr-0 d-inline-block" color="primary" @click="removeFolderTarget(folder, index);">
                    <i class="fas fa-times"/>
                  </CButton>
                </div>
              </CCol>
            </CRow>
            <CRow>
              <CCol cols="12" lg="12" class="pt-0 pb-0">
                <CButton color="primary" @click="addFolderTarget();">
                  <i class="fas fa-plus mr-1"/>
                  <span>{{ $t('service.Add_folder_target') }}</span>
                </CButton>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
        <CRow class="m-0">           
          <CCol cols="12" lg="12" class="pt-0">
            <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="folder.is_active" size="is-small">{{ $t('is_active') }}</b-switch>            
          </CCol>            
        </CRow>   
        <CRow v-if="checkRole('super_admin')" class="m-0">           
          <CCol cols="12" lg="12" class="pt-0">
            <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="folder.is_globally_available" size="is-small">{{ $t('common.Globally_available') }}</b-switch>            
          </CCol>            
        </CRow>

        <hr class="m-0">
        <div class="group_members">
          <CRow class="m-0">
            <CCol cols="6" lg="6" class="text-left" v-bind:class="{'pb-0' : !listViewEnabled}">
              <span class="sidebar_subject">
                {{ $t("service.Folder_available_for") }} <span v-if="folderMemberTotal > 0">({{folderMemberTotal}})</span>
              </span>
            </CCol>
            <CCol cols="6" lg="6" class="text-right" v-bind:class="{'pb-0' : !listViewEnabled}">
              <b-switch class="mb-0 mt-1 mt-xl-0 enable_search" v-model="listViewEnabled" @input="getFolderMembers()" size="is-small">{{ $t('common.activate_list_view') }}</b-switch>
            </CCol>
          </CRow>          
          <CRow>
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <div v-if="listViewEnabled">
                <CRow>
                  <CCol cols="12" sm="12" md="12" lg="12" class="pt-0" v-bind:class="{ 'pb-0' : listViewEnabled === true }">
                    <loadingSpinner mode="auto" v-if="listMembersLoading" :content="$t('common.Loading')"/>
                    <b-table v-else
                             ref="groupTable"
                             class="data_table"
                             :data="folderMembers"
                             :striped="true"
                             :hoverable="true"
                             :mobile-cards="true"
                             :paginated="listMembersPaginated"
                             :per-page="listMembersPerPage"
                             :current-page.sync="listMembersCurrentPage"
                             :pagination-simple="isPaginationSimple"
                             :pagination-position="paginationPosition"
                             :total="listMembersTotal"
                             backend-pagination
                             @page-change="onListPageChange">
                      
                      <template slot-scope="props">
                        <b-table-column field="name" :label="$t('common.Name')">
                          <div @click="openSidebarRight('user_profile', { user_id_external:  props.row.user_id_external });" class="pointer">
                            <div v-if="props.row.profile_image" class="profile_image d-inline-block align-middle mr-2" v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + props.row.user_id_external + '/' + props.row.profile_image + '/' + clientToken + ')' }"></div>
                            <div v-else class="profile_icon d-inline-flex align-middle mr-2">
                              <userProfileAvatar :username="props.row.name" :size="25"/>
                            </div>
                            <b>{{props.row.name}}</b>
                          </div>                          
                        </b-table-column>
                        <b-table-column field="department_name" :label="$t('common.Department')">
                          <span>{{props.row.department_name ? props.row.department_name : '-'}}</span>
                        </b-table-column>                        
                        <b-table-column field="team_name" :label="$t('common.Team')">
                          <span>{{props.row.team_name ? props.row.team_name : '-'}}</span>
                        </b-table-column>                        
                        <b-table-column field="function" :label="$t('common.Function')">
                          <span>{{props.row.function !== null && props.row.function !== '' ? props.row.function : '-'}}</span>
                        </b-table-column>
                      </template>
                      <template slot="empty">
                        <div class="text-center">
                          <div v-if="folder.target_groups.length > 0">
                            {{ $t('groups.No_users_based_on_filters') }}
                          </div>
                          <div v-else>
                            {{ $t('groups.Add_filters_to_show_members') }}
                          </div>
                        </div>                
                      </template>                
                    </b-table>                    
                  </CCol>
                </CRow>
              </div>
              <div v-else>
                <loadingSpinner mode="auto" v-if="gridMembersLoading" :content="$t('common.Loading')"/>
                <div v-if="!gridMembersLoading && folderMembers.length > 0">
                  <CRow class="group_member_container tile-container m-0">
                    <CCol cols="4" xl="4" lg="4" md="4" sm="4" v-for="member in folderMembers" v-bind:key="member.user_id_external" class="member_column p-0">
                      <CCard class="member">
                        <CCardBody>
                          <CRow>
                            <CCol cols="12" sm="12" md="12" lg="12" class="pt-0 pb-0">
                              <center>
                                <div v-if="member.profile_image" class="profile_image mb-2" v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + member.user_id_external + '/' + member.profile_image + '/' + clientToken + ')' }"></div>
                                <div v-else class="profile_icon mb-2">
                                  <userProfileAvatar :username="member.name" :size="50"/>
                                </div>
                              </center>
                            </CCol>                      
                            <CCol cols="12" sm="12" md="12" lg="12" class="pt-0 pb-0 text-center">
                              <a @click="openSidebarRight('user_profile', { user_id_external:  member.user_id_external });" class="d-block"><b>{{member.name}}</b></a>                                                            
                              <p v-line-clamp="1" class="m-0">{{member.team_name}} ({{member.department_name}})</p>
                              <p v-if="member.function" class="m-0">{{member.function}}</p>
                            </CCol>                                                
                          </CRow>
                        </CCardBody>
                      </CCard>
                    </CCol>
                  </CRow>
                  <CRow v-if="gridMembersPaginated" class="m-0">
                    <CCol cols="12" md="12" class="pt-0">
                      <v-pagination class="justify-content-end"
                                    v-model="gridMembersCurrentPage"
                                    @input="onGridPageChange"
                                    :length="gridMemberPages"
                                    :total-visible="6"
                                    prev-icon="mdi-chevron-left"
                                    next-icon="mdi-chevron-right">
                      </v-pagination>
                    </CCol>
                  </CRow>
                </div>
                <div v-if="!gridMembersLoading && folderMembers.length === 0">
                  <CRow class="m-0">
                    <CCol cols="12" lg="12">
                      <div v-if="folder.target_groups.length > 0">
                        {{ $t('learn.No_members_based_on_targets') }}
                      </div>
                      <div v-else>
                        {{ $t('learn.Add_targets_to_show_members') }}
                      </div>
                    </CCol>
                  </CRow>                  
                </div>
              </div>
            </CCol>                      
          </CRow>             
        </div>
      </div>
      <div class="sidebar_footer">
        <CRow class="m-0">
          <CCol cols="12" lg="12">
						<div v-if="!serviceQuestionFolderIdExternal">
							<CButton color="primary" @click="insertFolder(folder);"><i class="fas fa-check mr-1"/>{{$t('Save')}}</CButton>
							<CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{$t('Dismiss')}}</CButton>
						</div> 						   
						<div v-else>
							<CButton color="primary" @click="updateFolder(folder);"><i class="fas fa-check mr-1"/>{{$t('Save')}}</CButton>
							<CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{$t('Dismiss')}}</CButton>
          	</div>
          </CCol>
        </CRow>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import QuickEdit from 'vue-quick-edit';

import loadingSpinner from '@/components/common/loadingSpinner.vue';
import userProfileAvatar from '@/components/common/userProfileAvatar.vue';

export default {
  name: 'folderDetails',
  props: ['history', 'serviceQuestionFolderIdExternal'],
  components: {
    Multiselect,
    QuickEdit,
    loadingSpinner,
    userProfileAvatar
  },
  watch: {
    $props: {
      handler() {
        this.getFolderDetails();
      },
      deep: true,
      immediate: true,
    }
  }, 
  data() {
    return {
      platformRoles: [],
      apiBaseUrl: null,
      clientToken: null,
			folder: {
        target_groups: [],
        targets: []
      },
      folderTargetTypes: [],
      folderMembers: [],
      folderMemberTotal: 0,      
      targetGroups: [],
      defaultTargetGroup: {},
      isPaginationSimple: false,      
      paginationPosition: 'bottom',
      listViewEnabled: false,
      listMembersPaginated: false,
      listMembersCurrentPage: 1,
      listMembersTotal: 0,
      listMembersPerPage: 6,
      listMembersLoaded: false,
      listMembersLoading: false,
      listMembersStartIndex: 0,
      gridMembersPaginated: false,
      gridMembersCurrentPage: 1,
      gridMembersPerPage: 6,
      gridMemberPages: 0,          
      gridMembersLoading: false,
      gridMembersLoaded: false,
      gridMembersStartIndex: 0        
    }
  },
  methods: {
    getFolderDetails() {
      if(this.serviceQuestionFolderIdExternal !== null) {
				axios.get(process.env.VUE_APP_API_URL + '/v1/service/questions/folder/' + this.serviceQuestionFolderIdExternal)
				.then(res => {
					this.folder = res.data.data;
          // Get the folder members
          this.getFolderMembers();            
				})
				.catch(err => {
					console.error(err); 
				});    
      } else {
        // Reset the data 
        this.resetFolderData();
        this.getDefaultTargetGroup();
        this.getFolderMembers();
      }
    },
    insertFolder(data) {
      let params = {};
      params.folderData = data;
      (params.folderData.is_active == true) ? params.folderData.active = 'Y' : params.folderData.active = 'N';
      (params.folderData.is_globally_available == true) ? params.folderData.globally_available = 'Y' : params.folderData.globally_available = 'N';
      
      let folder_name = params.folderData.folder_name;
      let target_groups = params.folderData.target_groups;
      let is_globally_available = params.folderData.is_globally_available;

      if(folder_name && ((is_globally_available && target_groups.length === 0) || (!is_globally_available && target_groups.length > 0))) {
        axios.post(process.env.VUE_APP_API_URL + '/v1/service/questions/folder', params)
        .then(res => {
          this.$buefy.toast.open({ message: this.$t('service.Folder_added'), type: 'is-success', duration: 2000 });
          // Emit the update_service_question_folders event
          this.$bus.$emit('update_service_question_folders');
          // Reset the data
          this.resetFolderData();
          // Close the sidebar
          this.closeSidebarRight();         
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }
    },
    updateFolder(data) {
      let params = {}
      params.folderData = data;
      (params.folderData.is_active == true) ? params.folderData.active = 'Y' : params.folderData.active = 'N';
      (params.folderData.is_globally_available == true) ? params.folderData.globally_available = 'Y' : params.folderData.globally_available = 'N';
           
      let folder_name = params.folderData.folder_name;
      let target_groups = params.folderData.target_groups;
      let is_globally_available = params.folderData.is_globally_available;

      if(folder_name && ((is_globally_available && target_groups.length === 0) || (!is_globally_available && target_groups.length > 0))) {
        axios.put(process.env.VUE_APP_API_URL + '/v1/service/questions/folder/' + this.serviceQuestionFolderIdExternal, params)
        .then(res => {
          this.$buefy.toast.open({ message: this.$t('service.Folder_updated'), type: 'is-success', duration: 2000 });
          // Emit the update_service_question_folders event
          this.$bus.$emit('update_service_question_folders');
          // Reset the data
          this.resetFolderData();
          // Close the sidebar
          this.closeSidebarRight();  
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }      
    },   
    resetFolderData() {
      this.folder = {
        folder_name: null,
        folder_description: null,
        is_globally_available: false,
        is_active: true,
        target_groups: [],
        targets: [
          {
            target_type: { 
              service_question_folder_target_type_tag: "solved_within", 
              service_question_folder_target_type: "Solved within" 
            },
            target_value: 24
          },
          {
            target_type: { 
              service_question_folder_target_type_tag: "warning", 
              service_question_folder_target_type: "Warning" 
            },
            target_value: 36
          },
          {
            target_type: { 
              service_question_folder_target_type_tag: "overdue", 
              service_question_folder_target_type: "Overdue" 
            },
            target_value: 48
          }                    
        ]
      };      
    },
    getTargetGroups() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/targetgroups/multiselect')
      .then(res => {
        this.targetGroups = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getDefaultTargetGroup() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/targetgroups/default')
      .then(res => {
        this.defaultTargetGroup = res.data.data;

        if(this.folder.target_groups.length === 0) {
          this.folder.target_groups.push(this.defaultTargetGroup);
        }
      })
      .catch(err => {
        console.error(err); 
      });   
    },
    getFolderTargetTypes() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/service/questions/folders/targets/types/multiselect')
      .then(res => {
        this.folderTargetTypes = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    addFolderTarget() {
      this.folder.targets.push({});
    },
    removeFolderTarget(data, index) {
      let serviceQuestionFolderTargetIdExternal = data.targets[index].service_question_folder_target_id_external;
      
      if(serviceQuestionFolderTargetIdExternal) {
        axios.delete(process.env.VUE_APP_API_URL + '/v1/service/questions/folder/' + this.serviceQuestionFolderIdExternal + '/target/' + serviceQuestionFolderTargetIdExternal)
        .then(res => {
          this.$buefy.toast.open({ message: this.$t('service.Folder_target_removed'), type: 'is-success', duration: 2000 });        
          this.folder.targets.splice(index, 1);
        })
        .catch(err => {
          // console.error(err);
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        });
      } else {
        this.folder.targets.splice(index, 1);
      }
    },    
    customTargetTypeLabel({service_question_folder_target_type_tag}) {
      return this.$t('service.Target_' + service_question_folder_target_type_tag);
    },
    getFolderMembers() {
      // Check if the list view is enabled
      if(this.listViewEnabled) {
        // Reset the listMembersStartIndex value
        this.listMembersStartIndex = 0;
        // Get the members for the list
        this.getListFolderMembers();
      } else {
        // Reset the gridMembersStartIndex value
        this.gridMembersStartIndex = 0;
        // Get the members for the grid
        this.getGridFolderMembers();
      }
    },    
    onGridPageChange(page) {
      // Set the gridMembersStartIndex value
      (page > 1) ? this.gridMembersStartIndex = (page - 1) * this.gridMembersPerPage : this.gridMembersStartIndex = 0;
      // Get the members for onboarding package
      this.getGridFolderMembers();
    },    
    getGridFolderMembers() {
      // Start the loader
      if(!this.gridMembersLoaded) this.gridMembersLoading = true;
      // Set the params  
      let params = {};
      params.target_groups = this.folder.target_groups;
      params.member_limit = this.gridMembersPerPage;

      axios.post(process.env.VUE_APP_API_URL + '/v1/service/questions/folder/members/' + this.gridMembersStartIndex, params)
      .then(res => {
        this.folderMembers = [];
        // Update the folderMemberTotal value
        this.folderMemberTotal = res.data.data.total_members;
        // Set the default currentTotal value
        let currentTotal = res.data.data.total_members;
        // Update the currentTotal value
        if(currentTotal / this.gridMembersPerPage > 1000) currentTotal = this.gridMembersPerPage * 1000;        
        // Add the members to the folderMembers array
        this.folderMembers = res.data.data.members;
        // Check if the pagination should be activated
        (this.folderMemberTotal > this.gridMembersPerPage) ? this.gridMembersPaginated = true : this.gridMembersPaginated = false;        
        // Reset the gridMemberPages value
        this.gridMemberPages = 0;
        // Define the number of items of the pagination
        for (let i = 0; i < currentTotal; i = i + this.gridMembersPerPage) {
          this.gridMemberPages++;
        }
        // Update the gridMembersLoaded value
        this.gridMembersLoaded = true;
        // Stop the loader
        this.gridMembersLoading = false;        
      })
      .catch(err => {
        console.error(err); 
      });
    },
    onListPageChange(page) {
      // Set the listMembersStartIndex value
      (page > 1) ? this.listMembersStartIndex = (page - 1) * this.listMembersPerPage : this.listMembersStartIndex = 0;
      // Get the members for the list view   
      this.getListFolderMembers();
    },
    getListFolderMembers() {
      // Start the loader
      if(!this.listMembersLoaded) this.listMembersLoading = true;
      // Set the params
      let params = {};
      params.target_groups = this.folder.target_groups;
      params.member_limit = this.listMembersPerPage;
      // Get the posts
      axios.post(process.env.VUE_APP_API_URL + '/v1/service/questions/folder/members/' + this.listMembersStartIndex, params)
      .then(res => {
        this.folderMembers = [];
        // Update the folderMemberTotal value
        this.folderMemberTotal = res.data.data.total_members;
        // Set the default currentTotal value
        let currentTotal = res.data.data.total_members;
        // Update the currentTotal value
        if(currentTotal / this.listMembersPerPage > 1000) currentTotal = this.listMembersPerPage * 1000;
        // Update the listMembersTotal value
        this.listMembersTotal = currentTotal;
        // Check if the pagination should be activated
        (this.listMembersTotal > this.listMembersPerPage) ? this.listMembersPaginated = true : this.listMembersPaginated = false;         
        // Add the members to the folderMembers array
        this.folderMembers = res.data.data.members;
        // Update the listMembersLoaded value
        this.listMembersLoaded = true;
        // Stop the loader
        this.listMembersLoading = false;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getPlatformRoles() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/roles')
      .then(res => {      
        this.platformRoles = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkRole(roleTag) {
      if(this.platformRoles.includes(roleTag)) {
        return true;
      } else{
        return false;
      }
    },       
  },
  mounted: function() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');

    this.getFolderTargetTypes();
    this.getPlatformRoles();
    this.getTargetGroups();    
  }  
}
</script>