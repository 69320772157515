<template>
  <div>
    <div class="sidebar_container">
      <div class="sidebar_header">
        <CRow class="m-0">
          <CCol cols="10" lg="10" md="10" sm="10" class="text-left">
            <span v-if="history.length > 1" class="pointer" @click="removeSidebarHistoryStep()">
              <i class="fas fa-arrow-circle-left mr-1"/>
            </span>            
            <span class="sidebar_subject">
              {{$t('connect.New_chat')}}
            </span>
          </CCol>
          <CCol cols="2" lg="2" md="2" sm="2" class="text-right">
            <span @click="closeSidebarRight();"><i class="fas fa-times"/></span>        
          </CCol>
        </CRow>
      </div>
      <div class="sidebar_content wizard connect_chat">
        <CRow v-if="!chat.thread_id_external" class="h-100 m-0">
          <CCol class="pt-0">
            <b-steps v-model="activeWizardStep"
                      size="is-small"
                      class="wizard_steps h-100"
                      v-bind:class="{'hide_labels' : chat.thread_type_tag !== 'connect_group_chat'}"
                      :animated="isAnimated"
                      :rounded="isRounded"
                      :has-navigation="hasNavigation"
                      :icon-prev="prevIcon"
                      :icon-next="nextIcon"
                      :label-position="labelPosition"
                      :mobile-mode="mobileMode">

              <b-step-item :label="$t('common.Participants')" :clickable="isStepsClickable" class="wizard_step_item p-0">
                <CRow>
                  <CCol cols="12" lg="12">
                    <div class="d-flex align-items-center justify-content-center flex-column wizard_step_content">            
                      <h2 class="step_title m-0" v-bind:class="{'large' : chat.thread_type_tag === null}"><b>{{$t('connect.Who_do_you_want_to_chat_with')}}</b></h2>
                      <div class="d-flex align-items-center justify-content-center step_option_cards">
                        <CCard @click="setThreadType('connect_chat');" class="pointer" v-bind:class="{'selected' : chat.thread_type_tag === 'connect_chat'}">
                          <CCardBody class="d-flex align-items-center justify-content-center">
                            <i class="fa-solid fa-person mr-2"></i>
                            <span>{{$t('communications.Just_a_single_recipient')}}</span>
                          </CCardBody>
                        </CCard>
                        <CCard @click="setThreadType('connect_group_chat');" class="pointer" v-bind:class="{'selected' : chat.thread_type_tag === 'connect_group_chat'}">
                          <CCardBody class="d-flex align-items-center justify-content-center">
                            <i class="fa-solid fa-people-group mr-2"></i>
                            <span>{{$t('communications.Multiple_recipients')}}</span>
                          </CCardBody>
                        </CCard>
                      </div>

                      <div v-if="chat.thread_type_tag === 'connect_chat'" class="w-100 mt-4">
                        <CRow class="m-0">
                          <CCol cols="12" lg="12" class="pt-0">
                            <label class="mb-2"><b>{{$t('connect.Who_do_you_want_to_chat_with')}}</b></label>
                            <multiselect ref="chatEmployee"
                                         class="data_table open_absolute"
                                         v-model="chat.employee" 
                                         :options="employees" 
                                         :multiple="false"                
                                         :placeholder="$t('common.Set_employee')" 
                                         :selectLabel="$t('common.Select_employee')"
                                         :selectedLabel="$t('Selected')"
                                         :deselectLabel="$t('common.Deselect_employee')"
                                         track-by="user_id_external" 
                                         label="label"                        
                                         @search-change="asyncFindParticipant">
                              <span slot="noResult">{{$t('common.no_users_found')}}</span>
                              <span slot="noOptions">{{$t('common.start_typing_to_search')}}</span>
                            </multiselect>
                          </CCol>
                        </CRow>
                      </div>
                      <div v-if="chat.thread_type_tag === 'connect_group_chat'" class="w-100 mt-4">                        
                        <CRow class="m-0">
                          <CCol cols="12" lg="12" class="pt-0">
                            <label class="mb-2_5"><b>{{$t('connect.Specify_chat_employees')}}</b></label>
                            <multiselect ref="chatEmployees"
                                         class="data_table open_absolute"
                                         v-model="chat.employees" 
                                         :options="employees" 
                                         :multiple="true"                
                                         :placeholder="$t('common.Select_user')" 
                                         :selectLabel="$t('common.Add_user')" 
                                         :selectedLabel="$t('Added')"
                                         :deselectLabel="$t('common.Remove_user')"
                                         track-by="user_id_external" 
                                         label="label"                        
                                         @search-change="asyncFindParticipant"
                                         @input="getChatParticipants();">
                              <span slot="noResult">{{$t('common.no_users_found')}}</span>
                              <span slot="noOptions">{{$t('common.start_typing_to_search')}}</span>
                            </multiselect>
                          </CCol>
                        </CRow>
                        <CRow class="m-0">
                          <CCol cols="12" lg="12" class="pt-0">
                            <label class="mb-2_5"><b>{{$t('connect.Specify_chat_target_groups')}}</b></label>         
                            <multiselect ref="chatTargetGroups"
                                         class="data_table open_absolute"
                                         v-model="chat.target_groups" 
                                         :options="targetGroups" 
                                         :multiple="true"                
                                         :placeholder="$t('common.Select_group')" 
                                         :selectLabel="$t('common.Add_group')" 
                                         :selectedLabel="$t('Added')"
                                         :deselectLabel="$t('common.Remove_group')"
                                         track-by="group_id" 
                                         label="title"
                                         @search-change="asyncFindTargetGroup"
                                         @input="getChatParticipants();">
                              <span slot="noResult">{{$t('common.no_groups_found')}}</span>
                              <span slot="noOptions">{{$t('common.start_typing_to_search')}}</span>
                            </multiselect>
                          </CCol>
                        </CRow>
                        <div v-if="(chat.employees.length > 0 || chat.target_groups.length > 0) && chatParticipants.length > 0">
                          <CRow class="group_members m-0">
                            <CCol cols="12" lg="12" class="pt-0 pb-0">
                              <span><b>{{$t("common.Participants") }} <span>({{chatParticipants.length}})</span></b></span>
                            </CCol>
                          </CRow>          
                          <CRow class="group_member_container tile-container pt-0 m-0">
                            <CCol cols="4" xl="4" lg="4" md="4" sm="12" v-for="employee in currentPageEmployees[currentParticipantPage - 1]" v-bind:key="employee.user_id_external" class="p-0">
                              <CCard class="member">
                                <CCardBody>
                                  <div class="d-flex align-items-center">
                                    <div v-if="employee.profile_image" class="profile_image mr-2_5" v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + employee.user_id_external + '/' + employee.profile_image + '/' + clientToken + ')' }"></div>
                                    <div v-else class="profile_icon mr-2_5">
                                      <userProfileAvatar :username="employee.name" :size="40"/>
                                    </div>
                                    <div>
                                      <span v-line-clamp="1"><b>{{employee.name}}</b></span>
                                      <span v-line-clamp="1" class="meta">{{employee.team_name}} ({{employee.department_name}})</span>
                                    </div>
                                  </div>
                                </CCardBody>
                              </CCard>
                            </CCol>
                          </CRow>
                          <CRow v-if="chatParticipants.length > participantsPerPage" class="m-0">
                            <CCol cols="12" md="12" class="pt-0 pb-0">
                              <v-pagination class="justify-content-end" v-model="currentParticipantPage" :length="participantPages" :total-visible="9" prev-icon="mdi-chevron-left" next-icon="mdi-chevron-right"></v-pagination>
                            </CCol>
                          </CRow>
                        </div>
                        <div v-else-if="(chat.employees.length > 0 || chat.target_groups.length > 0) && chatParticipants.length === 0">
                          <CRow class="m-0">
                            <CCol cols="12" lg="12" class="pt-0 pb-0">
                              <span>{{$t('common.No_participants_found')}}</span>
                            </CCol>
                          </CRow>  
                        </div>
                      </div>
                    </div>
                  </CCol>
                </CRow>
              </b-step-item>

              <b-step-item :label="$t('common.Details')" :clickable="isStepsClickable" :visible="chat.thread_type_tag === 'connect_group_chat' ? true : false" class="wizard_step_item p-0">
                <CRow>
                  <CCol cols="12" lg="12" class="pt-0">
                    <image-uploader class="image_upload d-flex justify-content-center"
                                    v-bind:class="{'uploaded' : chat.image}"
                                    :preview="false"
                                    :className="['fileinput', { 'fileinput--loaded': chatImageUploaded }]" 
                                    capture="environment" 
                                    :debug="0" 
                                    doNotResize="gif" 
                                    :autoRotate="true" 
                                    outputFormat="verbose" 
                                    @input="setChatImage">

                      <label for="fileInput" slot="upload-label" class="m-0 position-relative">
                        <div v-if="chat.image" v-bind:style="{ backgroundImage: 'url(' + chat.image + ')' }" class="item_image top pointer"></div>
                        <div v-else class="pointer item_icon top pointer">
                          <i class="fa-solid fa-people-group"></i>
                        </div>
                        <div class="image_upload_notice pointer d-flex align-items-center justify-content-center flex-column">
                          <i class="fa-solid fa-camera mb-1"></i>
                          <span>{{chatImageUploaded ? $t('common.Replace_image') : $t('common.Upload_image')}}</span>
                        </div>
                      </label>
                    </image-uploader>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol cols="12" lg="12" class="pt-0">
                    <div class="w-75 m-auto">
                      <label><b>{{$t('connect.Group_chat_subject')}}</b></label>
                      <CInput @input="countdown()" maxlength="100" class="mb-0" v-model="chat.subject"/>
                      <p class="countdown mt-1 mb-0" v-if="remainingCount.chat_subject > 0">{{remainingCount.chat_subject}} {{ $t('common.characters_remaining') }}</p>
                    </div>
                  </CCol>
                </CRow>                
                <CRow>
                  <CCol cols="12" lg="12" class="pt-0 pb-0">
                    <div class="w-75 m-auto">
                      <label><b>{{$t('connect.Group_chat_description')}}</b></label>
                      <CTextarea @input="countdown()" rows="3" maxlength="250" class="mb-0" v-model="chat.description"/>
                      <p class="countdown mt-1 mb-0" v-if="remainingCount.chat_description > 0">{{remainingCount.chat_description}} {{ $t('common.characters_remaining') }}</p>
                    </div>
                  </CCol>
                </CRow>
              </b-step-item>
            
              <template v-if="customNavigation" slot="navigation" slot-scope="{previous, next}">
                <div class="step_navigation p-0">
                  <CButton v-if="!previous.disabled" color="secondary" class="previous" :disabled="previous.disabled" @click.prevent="previous.action">
                    <span><i class="fa-solid fa-chevron-left mr-1"/>{{$t('common.Previous')}}</span>
                  </CButton>
                  <CButton v-if="!next.disabled" 
                           color="secondary"
                           class="next m-0"
                           :disabled="next.disabled || (chat.thread_type_tag === 'connect_group_chat' && (chat.employees.length == 0 && chat.target_groups.length == 0))"
                           @click.prevent="next.action">
                    <span>{{$t('common.Next')}}<i class="fa-solid fa-chevron-right ml-1"/></span>
                  </CButton>
                  <CButton v-if="(activeWizardStep === 0 && chat.thread_type_tag === 'connect_chat') ||
                                 (activeWizardStep === 1 && chat.thread_type_tag === 'connect_group_chat')"
                           color="primary"
                           class="next m-0"
                           :disabled="(chat.thread_type_tag === 'connect_chat' && !chat.employee) || 
                                      (chat.thread_type_tag === 'connect_group_chat' && !chat.subject)"
                           @click="createChat();">
                    <span>{{chat.thread_type_tag === 'connect_group_chat' ? $t('connect.Create_group_chat') : $t('connect.Create_chat')}}<i class="fa-solid fa-chevron-right ml-1"/></span>
                  </CButton>                  
                </div>
              </template>   
            </b-steps>
          </CCol>
        </CRow>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import QuickEdit from 'vue-quick-edit';
import ImageUploader from 'vue-image-upload-resize';
import userProfileAvatar from "@/components/common/userProfileAvatar.vue";

export default {
  name: 'chatDetails',
  props: ['history', 'threadIdExternal'],
  components: {
    Multiselect,
    QuickEdit,
    ImageUploader,
    userProfileAvatar
  }, 
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null,
      chat: {
        thread_type_tag: null,
        image: null,
        subject: '',
        description: '',
        employee: null,
        employees: [],
        target_groups: []
      },
      chatImageUploaded: false,
      chatParticipants: [],
      employees: [],
      targetGroups: [],
      activeWizardStep: 0,
      isAnimated: false,
      isRounded: true,
      isStepsClickable: false,
      hasNavigation: true,
      customNavigation: true,
      prevIcon: 'chevron-left',
      nextIcon: 'chevron-right',
      labelPosition: 'bottom',
      mobileMode: 'minimalist',
      currentParticipantPage: 1,
      currentPageEmployees: {},
      participantsPerPage: 9,
      participantPages: 0,
      maxCount: {
        chat_subject: 100,
        chat_description: 250
      },
      remainingCount: {
        chat_subject: 100,
        chat_description: 250
      },            
    }
  },
  methods: {
    createChat() {
      let params = {};
      params.chatData = this.chat;
      if(params.chatData.subject === "") params.chatData.subject = null;
      if(params.chatData.description === "") params.chatData.description = null;

      if(this.validateChat(params) === true) {
        axios.post(process.env.VUE_APP_API_URL + '/v1/connect/chat', params)
        .then(res => {
          // Show a success notice
          this.$buefy.toast.open({ message: this.chat.thread_type_tag === 'connect_group_chat' ? this.$t('connect.Group_chat_created') : this.$t('connect.Chat_created'), type: 'is-success', duration: 2000 });
          // Emit the update_connect_chats event
          this.$bus.$emit('update_connect_chats');
          // Emit the open_connect_chat event
          this.$bus.$emit('open_connect_chat', res.data.data.thread_id_external);          
          // Reset the group data
          this.resetChatData();
          // Close the sidebar
          this.closeSidebarRight();
        })
        .catch(err => {
          if(err.response.data.code === 'Chat already exists') {
            // Emit the open_connect_chat event
            this.$bus.$emit('open_connect_chat', err.response.data.data);
            // Reset the group data
            this.resetChatData();
            // Close the sidebar
            this.closeSidebarRight();                     
          } else {
            console.error(err);
            this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
          }
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }
    },
    validateChat(params) {
      let thread_type_tag = params.chatData.thread_type_tag;
      let subject = params.chatData.subject;
      let employee = params.chatData.employee;
      let employees = params.chatData.employees;
      let target_groups = params.chatData.target_groups;      

      if((thread_type_tag === 'connect_chat' && employee) || 
         (thread_type_tag === 'connect_group_chat' && (employees.length > 0 || target_groups.length > 0) && subject)) {
        return true;
      } else {
        return false;
      }
    },
    getChatParticipants() {
      let params = {};
      params.employees = this.chat.employees;
      params.target_groups = this.chat.target_groups;
      
      if(params.employees.length > 0 || params.target_groups.length > 0) {
        // Get the recipients if there are filters available
        axios.post(process.env.VUE_APP_API_URL + '/v1/connect/chat/participants', params)
        .then(res => {
          this.chatParticipants = res.data.data;
          // Set the pagination to the first page
          this.currentParticipantPage = 1;
          // Reset the participantPages value
          this.participantPages = 0;
          // Loop through the participants to set the pagination            
          for (let i = 0; i < this.chatParticipants.length; i = i + this.participantsPerPage) {
            this.currentPageEmployees[this.participantPages] = this.chatParticipants.slice(i, i + this.participantsPerPage);
            this.participantPages++;
          }
        })
        .catch(err => {
          console.error(err); 
        });
      } else {
        // Reset the recipients array if there are no filters available
        this.chatParticipants = [];
      }
    },    
    setThreadType(threadTypeTag) {
      this.chat.thread_type_tag = threadTypeTag;
      this.resetChatData();

      if(threadTypeTag === 'connect_chat') {
        setTimeout(function() {
          if(this.$refs.chatEmployee) this.$refs.chatEmployee.activate();
        }.bind(this), 250);
      } 

      if(threadTypeTag === 'connect_group_chat') {
        setTimeout(function() {
          if(this.$refs.chatEmployees) this.$refs.chatEmployees.activate();
        }.bind(this), 250);
      }       
    },
    getUserTeamMembers() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/user/team')
      .then(res => {
        this.employees = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    resetChatData() {
      let threadTypeTag = this.chat.thread_type_tag;
          
      this.chat = {
        thread_type_tag: threadTypeTag,       
        subject: '',
        description: '',
        employee: null,
        employees: [],
        target_groups: []
      }      
    },       
    asyncFindParticipant(query) {
      let searchTerm = query;
      this.employees = [];
      
      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/users/' + searchTerm)
        .then(res => {      
          this.employees = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },
    asyncFindTargetGroup(query) {
      let searchTerm = query;
      this.targetGroups = [];
      
      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/targetgroup/' + searchTerm)
        .then(res => {      
          this.targetGroups = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },
    setChatImage(output) {
      this.chatImageUploaded = false;
      this.chat.image = null;

      setTimeout(function() {
        this.chatImageUploaded = true;
        this.chat.image = output.dataUrl;
      }.bind(this), 50);
    },        
    countdown() {
      this.remainingCount.chat_subject = this.maxCount.chat_subject - this.chat.subject.length;
      this.remainingCount.chat_description = this.maxCount.chat_description - this.chat.description.length;
    },     
  },
  mounted: function(){
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');
    
    this.resetChatData();
    this.getUserTeamMembers();
  }
}
</script>