<template>
  <div>
    <div class="sidebar_container">
      <div class="sidebar_header">
        <CRow class="m-0">
          <CCol cols="10" lg="10" md="10" sm="10" class="text-left">
            <span v-if="history.length > 1" class="pointer" @click="removeSidebarHistoryStep()">
              <i class="fas fa-arrow-circle-left mr-1"/>
            </span>            
            <span class="sidebar_subject">
							{{!sqSubcategoryIdExternal ? $t('sq.New_subpillar') : $t('sq.Edit_subpillar')}}
            </span>
          </CCol>
          <CCol cols="2" lg="2" md="2" sm="2" class="text-right">
            <span @click="closeSidebarRight();"><i class="fas fa-times"/></span>        
          </CCol>
        </CRow>
      </div>
      <div class="sidebar_content">
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <quick-edit type="text" mode="ignore" :buttonOkText="$t('Save')" :buttonCancelText="$t('Dismiss')" v-model="subcategory.subcategory_name">                        
              <h2 class="m-0">{{subcategory.subcategory_name ? subcategory.subcategory_name : $t('common.Click_to_set_name')}}<i class="icon edit fas fa-pen ml-1"/></h2>
            </quick-edit>
          </CCol>           
        </CRow>   
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <label>{{$t('sq.Which_pillar')}}</label>                    
            <multiselect
              class="data_table"
              v-model="subcategory.category"
              :options="categories" 
              :multiple="false"
              :hide-selected="true"
              :close-on-select="true"
              :placeholder="$t('measure.Search_pillar')" 
              :selectLabel="$t('measure.Select_pillar')" 
              track-by="sq_category_id_external" 
              label="label">
              <span slot="noResult">{{$t('sq.no_pillars_found')}}</span>
            </multiselect>
          </CCol>
        </CRow>
        <CRow class="m-0">           
          <CCol cols="12" lg="12" class="pt-0 pb-0">
            <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="subcategory.is_active" size="is-small">{{ $t('is_active') }}</b-switch>            
          </CCol>            
        </CRow>          
      </div>
      <div class="sidebar_footer">
        <CRow class="m-0">
          <CCol cols="12" lg="12">
						<div v-if="!sqSubcategoryIdExternal">
							<CButton color="primary" @click="insertSubcategory(subcategory);"><i class="fas fa-check mr-1"/>{{$t('Save')}}</CButton>
							<CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{$t('Dismiss')}}</CButton>
						</div> 						   
						<div v-else>
							<CButton color="primary" @click="updateSubcategory(subcategory);"><i class="fas fa-check mr-1"/>{{$t('Update')}}</CButton>
							<CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{$t('Dismiss')}}</CButton>
          	</div>
          </CCol>
        </CRow>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import QuickEdit from 'vue-quick-edit';

export default {
  name: 'subcategoryDetails',
  props: ['history', 'sqCategory', 'sqSubcategoryIdExternal'],
  components: {
    Multiselect,
    QuickEdit,
  },
  watch: {
    $props: {
      handler() {
        this.getSubcategoryDetails();
      },
      deep: true,
      immediate: true,
    }
  }, 
  data() {
    return {
			subcategory: {},
      categories: []
    }
  },
  methods: {
    getSubcategoryDetails() {
      if(this.sqSubcategoryIdExternal !== null) {
				axios.get(process.env.VUE_APP_API_URL + '/v1/measure/satisquestions/subcategory/' + this.sqSubcategoryIdExternal)
				.then(res => {
					this.subcategory = res.data.data;   
				})
				.catch(err => {
					console.error(err); 
				});    
      } else {
        // Reset the data 
        this.resetSubcategoryData();
      }
    },
    insertSubcategory(data) {
      let params = {};
      params.subcategoryData = data;
      (params.subcategoryData.is_active == true) ? params.subcategoryData.active = 'Y' : params.subcategoryData.active = 'N';

      let subcategory_name = params.subcategoryData.subcategory_name;
      let category = params.subcategoryData.category;

      if(subcategory_name && category) {
        axios.post(process.env.VUE_APP_API_URL + '/v1/measure/satisquestions/subcategory', params)
        .then(res => {
          this.$buefy.toast.open({ message: this.$t('sq.Subpillar_added'), type: 'is-success', duration: 2000 });
          // Emit the update_sq_category_subcategories event
          if(this.sqCategory.sq_category_id_external && this.sqCategory.category_name) {
            this.$bus.$emit('update_sq_category_subcategories', this.sqCategory);
          }          
          // Reset the data
          this.resetSubcategoryData();
          // Close the sidebar
          this.closeSidebarRight();                      
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }
    }, 
    updateSubcategory(data) {
      let params = {}
      params.subcategoryData = data;
      (params.subcategoryData.is_active == true) ? params.subcategoryData.active = 'Y' : params.subcategoryData.active = 'N';
                 
      let subcategory_name = params.subcategoryData.subcategory_name;
      let category = params.subcategoryData.category;

      if(subcategory_name && category) {
        axios.put(process.env.VUE_APP_API_URL + '/v1/measure/satisquestions/subcategory/' + this.sqSubcategoryIdExternal, params)
        .then(res => {
          this.$buefy.toast.open({ message: this.$t('sq.Subpillar_updated'), type: 'is-success', duration: 2000 });
          // Emit the update_sq_category_subcategories event
          if(this.sqCategory.sq_category_id_external && this.sqCategory.category_name) {
            this.$bus.$emit('update_sq_category_subcategories', this.sqCategory);
          }
          // Reset the data
          this.resetSubcategoryData();
          // Close the sidebar
          this.closeSidebarRight();       
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }      
    },    
    getCategories () {
      axios.get(process.env.VUE_APP_API_URL + '/v1/measure/satisquestions/categories/multiselect')
      .then(res => {
        this.categories = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    resetSubcategoryData() {
      this.subcategory = {
        subcategory_name: '',
        is_active: true
      }

      if(this.sqCategory.sq_category_id_external && this.sqCategory.category_name) {
        this.subcategory.category = {
          sq_category_id_external: this.sqCategory.sq_category_id_external,
          label: this.sqCategory.category_name
        }
      }      
    }				
  },
  mounted: function() {
    this.getCategories();
  }  
}
</script>