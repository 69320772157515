<template>
  <div class="w-100 reply">            
    <!-- Meta -->
    <div class="d-flex align-items-center">
      <div class="pr-1 user_image pointer" @click="openSidebarRight('user_profile', { user_id_external:  replyData.user_id_external });">
        <div v-if="reply.profile_image" class="profile_pic" v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + replyData.user_id_external + '/' + replyData.profile_image + '/' + clientToken + ')' }"></div>
        <userProfileAvatar v-else :username="replyData.posted_by_name" :size="20"/>
      </div>                  
      <div class="replied_by pointer" @click="openSidebarRight('user_profile', { user_id_external:  replyData.user_id_external });">
        <b>{{replyData.posted_by_name}}</b>
        <momentsAgo :date="replyData.created"></momentsAgo>
      </div>
      <div class="pl-2 delete_reply pointer">
        <i class="fas fa-trash" @click="deleteReply(replyData.post_id_external, replyData.post_reply_id);"/>
      </div>      
    </div>
    
    <!-- Content -->
    <div class="mt-1 mb-1 pr-3">
      <p class="m-0" v-html="replyData.content"></p>
    </div>

    <!-- Action -->
    <div class="d-flex align-items-center">
      <div v-if="showLikes" class="mr-2 reply_likes">
        <span v-html="getTwemoji(replyData.likes.emoji)" class="mr-1"></span>{{ replyData.likes.likes }}
        <span v-html="getTwemoji(replyData.dislikes.emoji)" class="mr-1"></span>{{ replyData.dislikes.dislikes }}
        <span v-html="getTwemoji(replyData.waves.emoji)" class="mr-1"></span>{{ replyData.waves.waves }}
        <span v-html="getTwemoji(replyData.hearts.emoji)" class="mr-1"></span>{{ replyData.hearts.hearts }}                    
      </div>
    </div>

    <!-- Child Replies -->
    <div v-if="showChildReplies && replyData.replies && replyData.replies.length > 0" class="mt-3 child-replies">
      <swPostReply v-for="childReply in replyData.replies" 
                   v-bind:key="childReply.post_reply_id_external"
                   :reply="childReply"
                   :showChildReplies="false"
                   :modules="modules"
                   :showLikes="true"/>
    </div>
  </div>

</template>

<script>
import axios from 'axios'
import userProfileAvatar from "@/components/common/userProfileAvatar.vue";
import swPostReply from "@/components/connect/swPostReply.vue";
import momentsAgo from "@/components/common/momentsAgo.vue";

export default {
  name: 'swPostReply',
  props: ['reply', 'modules', 'showLikes', 'showChildReplies'],
  components: {
    userProfileAvatar,
    swPostReply,
    momentsAgo
  },
  watch: {
    $props: {
      handler() {
        if(this.reply) this.replyData = this.reply;
      },
      deep: true,
      immediate: true
    }
  },   
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null,
      replyData: {},
      selectedReplyLikeType: null
    }
  },
  methods: {
    deleteReply(postIdExternal, replyId) {
      axios.delete(process.env.VUE_APP_API_URL + '/v1/connect/socialwall/reply/' + replyId)
      .then(res => {      
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('sw.Reply_deleted'), type: 'is-success', duration: 2000 });
        // Emit the sw_reply_deleted event
        this.$bus.$emit('sw_reply_deleted', postIdExternal);
      })
      .catch(err => {
        console.error(err); 
      });      
    },    
    getTwemoji(emoji) {
      return twemoji.parse(emoji);
    }
  },
  mounted: function() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');
  }  
}
</script>