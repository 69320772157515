<template>
  <CCard class="pointer navigation_card" @click="openSidebarRight('learn_course_details', { lms_category: courseData.category, lms_course_id_external: courseData.lms_course_id_external })">
    <CCardBody>
      <div class="d-flex align-items-center">
        <div v-if="courseData.image_id && courseData.from_marketplace === 'N'"
             class="item_image mr-2"
             v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/learn-course/' + courseData.lms_course_id_external + '/' + courseData.image_id + '.jpg' + '/' + clientToken +')' }">
        </div>
        <div v-else-if="courseData.image_id && courseData.from_marketplace === 'Y'"
             class="item_image mr-2"
             v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/learn-course-mp/' + courseData.lms_course_id_external + '/' + courseData.image_id + '.jpg' + '/' + clientToken +')' }">
        </div>        
        <div v-else class="item_icon d-inline-block align-middle mr-2">
          <i class="fas fa-list"/>               
        </div>
        <div class="flex-grow-1">
          <span><b>{{courseData.course_name}}</b></span>
          <div class="meta">
            <span>
              {{courseData.nr_of_chapters}} {{courseData.nr_of_chapters !== 1 ? $t('common.chapters') : $t('common.chapter')}}
            </span>
            <span v-if="courseData.duration">
              | {{courseData.duration}} {{courseData.duration !== 1 ? $t('common.minutes') : $t('common.minute')}}
            </span>
          </div>
        </div>
      </div>
    </CCardBody>
  </CCard>
</template>

<script>

export default {
  name: 'learnCourseCard',
  props: ['course'],
  watch: {
    $props: {
      handler() {
        if(this.course) this.courseData = this.course;
      },
      deep: true,
      immediate: true,
    }
  },
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null,
      courseData: {}
    }
  },
  mounted: function() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');
  }  
}
</script>
