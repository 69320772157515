<template>
  <div>
    <div class="sidebar_container">
      <div class="sidebar_header">
        <CRow class="m-0">
          <CCol cols="10" lg="10" md="10" sm="10" class="text-left">
            <span v-if="history.length > 1" class="pointer" @click="removeSidebarHistoryStep()">
              <i class="fas fa-arrow-circle-left mr-1"/>
            </span>            
            <span class="sidebar_subject">
							{{$t('common.Save_email_template')}}
            </span>
          </CCol>
          <CCol cols="2" lg="2" md="2" sm="2" class="text-right">
            <span @click="closeSidebarRight();"><i class="fas fa-times"/></span>        
          </CCol>
        </CRow>
      </div>
      <div class="sidebar_content">
        <CRow class="m-0">
					<CCol cols="12" lg="12" class="pt-0">
            <CInput type="text" :label="$t('common.Name')" class="mb-0" v-model="template.name" required was-validated/>
					</CCol>
        </CRow>
        <CRow class="m-0">
					<CCol cols="12" lg="12" class="pt-0 pb-0">
            <CTextarea @input="countdown()" :maxlength="maxCount" :label="$t('common.Description')" rows="5" class="mb-0" v-model="template.description"/>
            <p class="countdown mt-1 mb-0" v-if="remainingCount > 0">{{remainingCount}} {{ $t('common.characters_remaining') }}</p>
					</CCol>
        </CRow>
      </div>
      <div class="sidebar_footer">
        <CRow class="m-0">
          <CCol cols="12" lg="12">
						<div>
              <CButton color="primary" @click="insertTemplate();"><i class="fas fa-check mr-1"/>{{ $t('common.Save_as_template') }}</CButton>              
							<CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{$t('Dismiss')}}</CButton>
						</div>
          </CCol>
        </CRow>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import QuickEdit from 'vue-quick-edit';

export default {
  name: 'saveCampaignTemplate',
  props: ['history', 'templateType', 'templateSourceIdExternal', 'productTag'],
  components: {
    Multiselect,
    QuickEdit,
  },
  watch: {
    $props: {
      handler() {
        // Set the templatePostPath value based on the templateType value
        if(['email', 'moment-email', 'benefits-moment-email', 'benefits-moment-template-email'].includes(this.templateType)) {
          this.templatePostPath = 'core/email/template';
        } else if(this.templateType === 'deliver') {
          this.templatePostPath = 'connect/deliver/template';
        }

        // Set the templateSourcePath value based on the templateType value                  
        if(this.templateType === 'deliver') {
          this.templateSourcePath = 'connect/deliver/email/' + this.templateSourceIdExternal;          
        } else if(this.templateType === 'email') {
          this.templateSourcePath = 'core/event/' + this.productTag + '/' + this.templateSourceIdExternal;          
        } else if(this.templateType === 'moment-email') {
          this.templateSourcePath = 'loyalty/moments/template' + '/' + this.templateSourceIdExternal;          
        } else if(this.templateType === 'benefits-moment-email') {
          this.templateSourcePath = 'loyalty/benefits/moment/' + '/' + this.templateSourceIdExternal;          
        } else if(this.templateType === 'benefits-moment-template-email') {
          this.templateSourcePath = 'loyalty/benefits/moment/template';          
        }

        // Reset the data
        this.resetTemplateData();
        // Get the template source data
        this.getTemplateSourceData();
      },
      deep: true,
      immediate: true,
    }
  }, 
  data() {
    return {
			template: {},
      templateSourceData: {},
      templateSourcePath: null,
      templatePostPath: null,
      maxCount: 1000,
      remainingCount: 1000      
    }
  },
  methods: {
    getTemplateSourceData() {
      // Get the template source data
      axios.get(process.env.VUE_APP_API_URL + '/v1/' + this.templateSourcePath)
      .then(res => {
        this.templateSourceData = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    insertTemplate() {
      let params = {};
      params.templateData = this.template;

      // Deliver
      if(this.templateType === 'deliver') params.templateData.status = { status_tag: 'draft' };      
      // Email
      else if(this.templateType === 'email') params.templateData.json = this.templateSourceData.email_content.json;
      // Deliver | Moment | Benefits Moment | Benefits Moment Template
      else if(['deliver', 'moment-email', 'benefits-moment-email', 'benefits-moment-template-email'].includes(this.templateType)) {
        params.templateData.json = this.templateSourceData.json;
      }

      let name = params.templateData.name;
      let description = params.templateData.description;

      // Set 500ms timeout to make sure that the JSON data is added to the templateData
      setTimeout(function(){
        // Check if the necessary params are available
        if(name && description) {
          axios.post(process.env.VUE_APP_API_URL + '/v1/' + this.templatePostPath, params)
          .then(res => {
            // Show a success notice
            this.$buefy.toast.open({ message: this.$t('common.Template_added'), type: 'is-success', duration: 3000 });
            // Emit the email_template_added event
            this.$bus.$emit('email_template_added');            
            // Reset the data
            this.resetTemplateData();
            // Close the sidebar
            this.closeSidebarRight();
          })
          .catch(err => {
            console.error(err); 
            this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
          })
        } else {
          this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
        }
      }.bind(this), 500);
    },
    resetTemplateData() {
      this.template = {
        deliver_email_template_id_external: null,
        name: null,      
        description: '',
        json: null
      }
    },
    countdown() {
      this.remainingCount = this.maxCount - this.template.description.length;
    }       
  }
}
</script>