<template>
  <div>
    <div class="sidebar_container">
      <div class="sidebar_header">
        <CRow class="m-0">
          <CCol cols="10" lg="10" md="10" sm="10" class="text-left">
            <span v-if="history.length > 1" class="pointer" @click="removeSidebarHistoryStep()">
              <i class="fas fa-arrow-circle-left mr-1"/>
            </span>            
            <span class="sidebar_subject">
							{{!marketplaceSetIdExternal ? $t('common.New_marketplace_set') : $t('common.Edit_marketplace_set')}}
            </span>
          </CCol>
          <CCol cols="2" lg="2" md="2" sm="2" class="text-right">
            <span @click="closeSidebarRight();"><i class="fas fa-times"/></span>        
          </CCol>
        </CRow>
      </div>
      <div class="sidebar_content">
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <quick-edit type="text" mode="ignore" :buttonOkText="$t('Save')" :buttonCancelText="$t('Dismiss')" v-model="marketplaceSet.title">                        
              <h2 class="m-0">{{marketplaceSet.title ? marketplaceSet.title : $t('common.Click_to_set_name')}}<i class="icon edit fas fa-pen ml-1"/></h2>
            </quick-edit>
          </CCol>           
        </CRow>
        <CRow class="m-0">           
          <CCol cols="12" lg="12" class="pt-0">
            <quick-edit type="textarea" rows="5" mode="ignore" :buttonOkText="$t('Save')" :buttonCancelText="$t('Dismiss')" v-model="marketplaceSet.description">
              <span>{{marketplaceSet.description ? marketplaceSet.description : $t('common.Click_to_set_description')}}<i class="icon edit fas fa-pen ml-1"/></span>
            </quick-edit>            
          </CCol>            
        </CRow>
        <!-- Loyalty -->
        <CRow v-if="productTag === 'loyalty'" class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <label>{{$t('admin.Loyalty_store_categories_to_include')}}</label>
            <multiselect class="data_table"
                         v-model="marketplaceSet.categories"
                         :options="categories" 
                         :multiple="true"
                         :hide-selected="false"
                         :close-on-select="true"
                         :selectLabel="$t('common.Add_category')"
                         :deselectLabel="$t('common.Remove_category')"
                         track-by="loyalty_store_category_id_external" 
                         label="label">
              <span slot="noResult">{{$t('common.no_categories_found')}}</span>
            </multiselect>
          </CCol>
        </CRow>
        <!-- Measure -->
        <CRow v-if="productTag === 'measure'" class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <label>{{$t('admin.Measure_pillars_to_include')}}</label>
            <multiselect class="data_table"
                         v-model="marketplaceSet.categories"
                         :options="categories" 
                         :multiple="true"
                         :hide-selected="false"
                         :close-on-select="true"
                         :selectLabel="$t('measure.Select_pillar')"
                         :deselectLabel="$t('measure.Remove_pillar')"
                         track-by="sq_category_id_external" 
                         label="label">
              <span slot="noResult">{{$t('sq.no_pillars_found')}}</span>
            </multiselect>
          </CCol>
        </CRow>
        <!-- Knowledge -->
        <CRow v-if="productTag === 'knowledge'" class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <label>{{$t('admin.Knowledge_base_categories_to_include')}}</label>
            <multiselect class="data_table"
                         v-model="marketplaceSet.categories"
                         :options="categories" 
                         :multiple="true"
                         :hide-selected="false"
                         :close-on-select="true"
                         :selectLabel="$t('common.Add_category')"
                         :deselectLabel="$t('common.Remove_category')"
                         track-by="kb_intent_category_id_external" 
                         label="label">
              <span slot="noResult">{{$t('common.no_categories_found')}}</span>
            </multiselect>
          </CCol>
        </CRow>
        <!-- Learn -->
        <CRow v-if="productTag === 'learn'" class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <label>{{$t('admin.Learn_categories_to_include')}}</label>
            <multiselect class="data_table"
                         v-model="marketplaceSet.categories"
                         :options="categories" 
                         :multiple="true"
                         :hide-selected="false"
                         :close-on-select="true"
                         :selectLabel="$t('common.Add_category')"
                         :deselectLabel="$t('common.Remove_category')"
                         track-by="lms_course_category_id_external" 
                         label="label">
              <span slot="noResult">{{$t('common.no_categories_found')}}</span>
            </multiselect>
          </CCol>
        </CRow>  
        <!-- Service -->
        <CRow v-if="productTag === 'service'" class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <label>{{$t('admin.Learn_categories_to_include')}}</label>
            <multiselect class="data_table"
                         v-model="marketplaceSet.categories"
                         :options="categories" 
                         :multiple="true"
                         :hide-selected="false"
                         :close-on-select="true"
                         :selectLabel="$t('common.Add_category')"
                         :deselectLabel="$t('common.Remove_category')"
                         track-by="form_category_id_external" 
                         label="label">
              <span slot="noResult">{{$t('common.no_categories_found')}}</span>
            </multiselect>
          </CCol>
        </CRow>                
        <!-- Hub -->
        <CRow v-if="productTag === 'hub'" class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <label>{{$t('admin.Resources_to_include')}}</label>
            <multiselect class="data_table"
                         v-model="marketplaceSet.resources"
                         :options="resources" 
                         :multiple="true"
                         :hide-selected="false"
                         :close-on-select="true"
                         :selectLabel="$t('resources.Add_resource')"
                         :deselectLabel="$t('resources.Remove_resource')"
                         track-by="resource_id_external" 
                         label="label">
              <span slot="noResult">{{$t('resources.no_resources_found')}}</span>
            </multiselect>
          </CCol>
        </CRow>

        <div v-if="marketplaceSetIdExternal">
          <hr class="m-0">
          <CRow class="m-0">
            <CCol cols="12" lg="12" class="pb-0">
              <label class="m-0"><b>{{$t('common.Accessible_for_companies')}}</b></label>
            </CCol>
          </CRow>
          <CRow class="m-0">
            <CCol cols="12" lg="12" class="pb-0">
              <label>{{$t('admin.Add_company_with_access')}}</label>
              <multiselect class="data_table open_absolute single_value"
                           label="company_name" 
                           track-by="company_id_external" 
                           :placeholder="$t('Search_for_a') + ' ' + $t('common.company')"
                           open-direction="bottom" 
                           :options="companies"
                           :multiple="false" 
                           :searchable="true" 
                           :loading="false" 
                           :internal-search="false"
                           :clear-on-select="true"
                           :close-on-select="true"
                           :options-limit="300"
                           :max-height="600"
                           :show-no-results="true"
                           :hide-selected="false"
                           @search-change="asyncFindCompany"
                           @input="addMarketplaceSetCompany">
                <span slot="noResult">{{ $t('common.No_companies_found') }}</span>
                <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>                
              </multiselect>
            </CCol>
          </CRow>
          <CRow class="m-0">
            <CCol cols="12" lg="12">              
              <div class="company_filters">
                <CRow class="m-0" :gutters="false">
                  <CCol class="p-0 search">
                    <label>{{$t('core.Search_for_company')}}</label>
                    <CInput type="text" class="mb-0 searchbar" v-model="companyFilters.company_name" v-debounce:1s="filterCompanies"></CInput>
                  </CCol>
                </CRow>
              </div>                
            </CCol>
          </CRow>
          <CRow class="m-0">
            <CCol cols="12" lg="12" class="p-0">
              <b-table ref="companiesTable"
                       class="data_table sidebar"
                       :data="marketplaceSetCompanies"
                       :striped="true"
                       :hoverable="true"
                       :mobile-cards="true"
                       :paginated="isPaginated"
                       :per-page="perPage"
                       :current-page.sync="currentPage"
                       :pagination-simple="isPaginationSimple"
                       :pagination-position="paginationPosition"
                       :total="totalItems"
                       backend-pagination
                       @page-change="onPageChange">
                
                <template slot-scope="props">
                  <b-table-column field="company_name" :label="$t('core.company_name')" :searchable="false" :sortable="false" width="80%">
                    <span>{{props.row.company_name}}</span>
                  </b-table-column>
                  <b-table-column field="actions" label="">
                    <div class="d-flex justify-content-lg-end">
                      <CButton color="outline-danger" @click="removeMarketplaceSetCompany(props.row)" class="m-0 btn_small text_only">
                        <span><i class="fa-solid fa-ban mr-1"/>{{$t('admin.Revoke_access')}}</span>
                      </CButton>                      
                    </div>
                  </b-table-column>
                </template>
                <template slot="empty">
                  <div class="text-center">
                    <span>{{$t('admin.No_companies_with_access_found')}}</span>
                  </div>                
                </template>                
              </b-table>
            </CCol>
          </CRow>
        </div>
      </div>
      <div class="sidebar_footer">
        <CRow class="m-0">
          <CCol cols="12" lg="12">
						<div v-if="!marketplaceSetIdExternal">
							<CButton color="primary" @click="insertMarketplaceSet(marketplaceSet);"><i class="fas fa-check mr-1"/>{{$t('Save')}}</CButton>
							<CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{$t('Dismiss')}}</CButton>
						</div> 						   
						<div v-else>
							<CButton color="primary" @click="updateMarketplaceSet(marketplaceSet);"><i class="fas fa-check mr-1"/>{{$t('Save')}}</CButton>
							<CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{$t('Dismiss')}}</CButton>
          	</div>
          </CCol>
        </CRow>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import QuickEdit from 'vue-quick-edit';

export default {
  name: 'marketplaceSetDetails',
  props: ['history', 'marketplaceSetIdExternal', 'productTag'],
  components: {
    Multiselect,
    QuickEdit
  },
  watch: {
    $props: {
      handler() {
        if(this.marketplaceSetIdExternal) {
          this.getMarketplaceSetDetails();        
          this.getMarketplaceSetCompanies();
        } else {
          this.resetMarketplaceSetData();
        }
        // Set the categoriesApiPath for Loyalty Store categories
        if(this.productTag === 'loyalty') this.categoriesApiPath = 'loyalty/categories/multiselect';
        // Set the categoriesApiPath for Measure categories
        if(this.productTag === 'measure') this.categoriesApiPath = 'measure/satisquestions/categories/multiselect';
        // Set the categoriesApiPath for Knowledge Base categories
        if(this.productTag === 'knowledge') this.categoriesApiPath = 'knowledge/categories/multiselect';
        // Set the categoriesApiPath for Learn categories
        if(this.productTag === 'learn') this.categoriesApiPath = 'learn/categories/multiselect';
        // Set the categoriesApiPath for Service Form categories
        if(this.productTag === 'service') this.categoriesApiPath = 'service/forms/categories/multiselect';        
        // Get the categories for Loyalty, Measure or Knowledge Marketplace sets
        if(['loyalty', 'measure', 'knowledge', 'learn', 'service'].includes(this.productTag)) this.getCategories();
        // Get the resources for Hub Marketplace sets
        if(this.productTag === 'hub') this.getResources();
      },
      deep: true,
      immediate: true,
    }
  }, 
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null,
			marketplaceSet: {},
      marketplaceSetCompanies: [],
      companies: [],
      companyFilters: {
        company_name: ''
      },
      categoriesApiPath: null,
      categories: [],
      resources: [],
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 10,
      searchEnabled: false,
      totalItems: 0,
      startIndex: 0      
    }
  },
  methods: {
    getMarketplaceSetDetails() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/superadmin/marketplace/' + this.productTag + '/set/' + this.marketplaceSetIdExternal)
      .then(res => {
        this.marketplaceSet = res.data.data;          
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getMarketplaceSetCompanies() {
      // Set the params
      let params = {};
      params.company_limit = this.perPage;
      params.company_filters = this.companyFilters;
      // Get the companies with access
      axios.post(process.env.VUE_APP_API_URL + '/v1/superadmin/marketplace/' + this.productTag + '/set/' + this.marketplaceSetIdExternal + '/companies/' + this.startIndex, params)
      .then(res => {
        this.marketplaceSetCompanies = [];
        // Set the default currentTotal value
        let currentTotal = res.data.data.total_companies;
        // Update the currentTotal value
        if(currentTotal / this.perPage > 1000) currentTotal = this.perPage * 1000;
        // Update the totalItems value
        this.totalItems = currentTotal;
        // Add the companies to the marketplaceSetCompanies array
        this.marketplaceSetCompanies = res.data.data.companies;
        // Enable the pagination if necessary
        (this.totalItems > this.perPage) ? this.isPaginated = true : this.isPaginated = false;
      })
      .catch(err => {
        console.error(err); 
      });    
    },
    addMarketplaceSetCompany(e) {
      // Set company variables
      let companyIdExternal = e.company_id_external;
      let companyName = e.company_name;
      // Give company access to set
      axios.post(process.env.VUE_APP_API_URL + '/v1/superadmin/marketplace/' + this.productTag + '/set/' + this.marketplaceSetIdExternal + '/company/' + companyIdExternal)
      .then(res => {
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('admin.Access_given_to_company', { company_name : companyName }), type: 'is-success', duration: 2000 });
        // Reset the pagination
        this.resetPagination();
        // Reset the companies list
        this.resetCompanies();
        // Update the companies with access
        this.getMarketplaceSetCompanies();
      })
      .catch(err => {
          if(err.response.data.code === 'Set already accessible') {
            this.$buefy.toast.open({ message: this.$t('admin.Set_already_accessible_to_company', { company_name : companyName }), type: 'is-danger', duration: 2000 });
          } else {
            this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
          }
      }); 
    },
    removeMarketplaceSetCompany(data) {
      // Set company variables
      let companyIdExternal = data.company_id_external;
      let companyName = data.company_name;
      // Remove company access for set
      axios.delete(process.env.VUE_APP_API_URL + '/v1/superadmin/marketplace/' + this.productTag + '/set/' + this.marketplaceSetIdExternal + '/company/' + companyIdExternal)
      .then(res => {
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('admin.Access_revoked_for_company', { company_name : companyName }), type: 'is-success', duration: 2000 });
        // Reset the pagination
        this.resetPagination();
        // Update the companies with access
        this.getMarketplaceSetCompanies();
      })
      .catch(err => {
        console.error(err); 
      });
    },
    insertMarketplaceSet(data) {
      let params = {};
      params.marketplaceSetData = data;

      if(this.validateMarketplaceSet(params) === true) {
        axios.post(process.env.VUE_APP_API_URL + '/v1/superadmin/marketplace/' + this.productTag + '/set', params)
        .then(res => {
          this.$buefy.toast.open({ message: this.$t('common.Marketplace_set_added'), type: 'is-success', duration: 2000 });
          // Emit the update_marketplace_sets event based on the product tag
          this.$bus.$emit('update_' + this.productTag + '_marketplace_sets');
          // Reset the data
          this.resetMarketplaceSetData();
          // Close the sidebar
          this.closeSidebarRight();         
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }
    },
    updateMarketplaceSet(data) {
      let params = {}
      params.marketplaceSetData = data;
           
      if(this.validateMarketplaceSet(params) === true) {
        axios.put(process.env.VUE_APP_API_URL + '/v1/superadmin/marketplace/' + this.productTag + '/set/' + this.marketplaceSetIdExternal, params)
        .then(res => {
          this.$buefy.toast.open({ message: this.$t('common.Marketplace_set_updated'), type: 'is-success', duration: 2000 });
          // Emit the update_marketplace_sets event based on the product tag
          this.$bus.$emit('update_' + this.productTag + '_marketplace_sets');
          // Reset the data
          this.resetMarketplaceSetData();
          // Close the sidebar
          this.closeSidebarRight();  
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }      
    },
    validateMarketplaceSet(params) {
      let title = params.marketplaceSetData.title;
      let categories = params.marketplaceSetData.categories;
      let resources = params.marketplaceSetData.resources;

      // Check if base information for the set is available
      if(title) {
        // Check if the content settings are set correctly
        if(['loyalty', 'measure', 'knowledge', 'learn'].includes(this.productTag) && categories.length == 0) {
          return false;
        } else if(this.productTag === 'hub' && resources.length == 0) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    resetMarketplaceSetData() {
      this.marketplaceSet = {
        title: null,
        description: null,
        categories: [],
        resources: [],
      };
    },
    getCategories() {
      // Reset the categories array
      this.categories = [];
      // Get the Loyalty Store categories
      axios.get(process.env.VUE_APP_API_URL + '/v1/' + this.categoriesApiPath)
      .then(res => {
        this.categories = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getResources() {
      // Reset the resources array
      this.resources = [];
      // Get the resources      
      axios.get(process.env.VUE_APP_API_URL + '/v1/hub/resources/multiselect')
      .then(res => {
        this.resources = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    onPageChange(page) {
      // Set the startIndex value
      (page > 1) ? this.startIndex = (page - 1) * this.perPage : this.startIndex = 0;
      // Get the companies with access
      this.getMarketplaceSetCompanies();
    },   
    filterCompanies() {
      // Reset the pagination;
      this.resetPagination();
      // Get the companies with access
      this.getMarketplaceSetCompanies();
    },
    resetPagination() {
      this.currentPage = 1;
      this.startIndex = 0;
    },
    resetCompanies() {
      this.companies = [];
    },    
    asyncFindCompany(searchTerm) {
      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/companies/' + searchTerm)
        .then(res => {      
          this.companies = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }    
    }    
  },
  mounted: function() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');      
  }  
}
</script>