<template>
  <div>
    <div class="sidebar_container">
      <div class="sidebar_header">
        <CRow class="m-0">
          <CCol cols="10" lg="10" md="10" sm="10" class="text-left">
            <span v-if="history.length > 1" class="pointer" @click="removeSidebarHistoryStep()">
              <i class="fas fa-arrow-circle-left mr-1"/>
            </span>
            <span class="sidebar_subject">
              {{$t('loyalty.New_benefits_moment')}}
            </span>
          </CCol>
          <CCol cols="2" lg="2" md="2" sm="2" class="text-right">
            <span @click="closeSidebarRight();"><i class="fas fa-times"/></span>    
          </CCol>      
        </CRow>
      </div>
      <div class="sidebar_content">
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">            
            <label>{{$t('common.Month')}}</label>
            <b-datepicker v-model="benefitsMoment.month_year" type="month" :placeholder="$t('common.click_to_select')"></b-datepicker>
          </CCol>          
        </CRow>    
      </div>
      <div class="sidebar_footer">
        <CRow class="m-0">
          <CCol cols="12" lg="12">
            <div>
              <CButton color="primary" @click="insertBenefitsMoment();"><i class="fas fa-check mr-1"/>{{ $t('Save') }}</CButton>
              <CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{ $t('Dismiss') }}</CButton>
            </div>
          </CCol>
        </CRow>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'benefitsMomentScheduleDetails',
  props: ['history'],
  data() {
    return {
      benefitsMoment: {},            
      datepickerOptions: {
        yearsRange: [0, 10],
        firstDayOfWeek: 1,
        showWeekNumber: true
      }         
    }
  },
  methods: {
    insertBenefitsMoment() {
      let params = {};
      if(this.benefitsMoment.month_year) params.month_year = this.$luxon(this.benefitsMoment.month_year.toISOString(), "MM-yyyy");
       
      let month_year = params.month_year;

      if(month_year) {
        axios.post(process.env.VUE_APP_API_URL + '/v1/loyalty/benefits/moment', params)
        .then(res => {
          // Show a success notice
          this.$buefy.toast.open({ message: this.$t('loyalty.Benefits_moment_added'), type: 'is-success', duration: 3000 });
          // Emit the loyalty_benefits_moment_added event
          this.$bus.$emit('loyalty_benefits_moment_added');
          // Reset the data
          this.resetBenefitsMomentData();
          // Close the sidebar
          this.closeSidebarRight();
        })
        .catch(err => {
          if(err.response.data.code === 'Benefits Moment Already Exists') {
            this.$buefy.toast.open({ message: this.$t('loyalty.Benefits_moment_already_exists', { month_year: params.month_year }), type: 'is-danger', duration: 2000 });
          } else {

            this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
          }
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }
    },
    resetBenefitsMomentData() {
      this.benefitsMoment = {
        month_year: null
      };      
    }  
  },
  mounted: function() { 
    this.resetBenefitsMomentData();
  }  
}
</script>