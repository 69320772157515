<template>
  <CCard class="pointer navigation_card" @click="openSidebarRight('learn_category_details', { lms_course_category_id_external: categoryData.lms_course_category_id_external })">
    <CCardBody>
      <div class="d-flex align-items-center">
        <div v-if="categoryData.image_id"
            class="item_image mr-2"
            v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/learn-category/' + categoryData.lms_course_category_id_external + '/' + categoryData.image_id + '.jpg' + '/' + clientToken + ')' }">
        </div>
        <div v-else class="item_icon d-inline-block align-middle mr-2">
          <i class="fas fa-list"/>               
        </div>
        <div class="flex-grow-1">
          <span><b>{{categoryData.course_category_name}}</b></span>
          <div class="meta">
            <span>{{categoryData.nr_of_courses}} {{categoryData.nr_of_courses !== 1 ? $t('common.courses') : $t('common.course')}}</span>
          </div>
        </div>
      </div>
    </CCardBody>
  </CCard>
</template>

<script>

export default {
  name: 'learnCategoryCard',
  props: ['category'],
  watch: {
    $props: {
      handler() {
        if(this.category) this.categoryData = this.category;
      },
      deep: true,
      immediate: true,
    }
  },
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null,
      categoryData: {}
    }
  },
  mounted: function() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');
  }  
}
</script>
