<template>
  <div>
    <div class="sidebar_container">
      <div class="sidebar_header">
        <CRow class="m-0">
          <CCol cols="10" lg="10" md="10" sm="10" class="text-left">
            <span v-if="history.length > 1" class="pointer" @click="removeSidebarHistoryStep()">
              <i class="fas fa-arrow-circle-left mr-1"/>
            </span>            
            <span class="sidebar_subject">
							{{!sqCategoryIdExternal ? $t('sq.New_pillar') : $t('sq.Edit_pillar')}}
            </span>
          </CCol>
          <CCol cols="2" lg="2" md="2" sm="2" class="text-right">
            <span @click="closeSidebarRight();"><i class="fas fa-times"/></span>        
          </CCol>
        </CRow>
      </div>
      <div class="sidebar_content">
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <quick-edit type="text" mode="ignore" :buttonOkText="$t('Save')" :buttonCancelText="$t('Dismiss')" v-model="category.category_name">                        
              <h2 class="m-0">{{category.category_name ? category.category_name : $t('common.Click_to_set_name')}}<i class="icon edit fas fa-pen ml-1"/></h2>
            </quick-edit>
          </CCol>           
        </CRow>
        <CRow class="m-0">           
          <CCol cols="12" lg="12" class="pt-0">
            <label>{{$t('common.Specified_for_groups')}}</label>
            <multiselect
              class="data_table"
              v-model="category.target_groups" 
              :options="targetGroups" 
              :multiple="true"
              :placeholder="$t('common.Select_group')" 
              :selectLabel="$t('common.Add_group')" 
              :selectedLabel="$t('Added')"
              :deselectLabel="$t('common.Remove_group')"
              track-by="group_id" 
              label="title">
              <span slot="noResult">{{ $t('common.no_groups_found') }}</span>
            </multiselect> 
          </CCol>
        </CRow>        
        <CRow class="m-0">           
          <CCol cols="12" lg="12" class="pt-0 pb-0">
            <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="category.is_active" size="is-small">{{ $t('is_active') }}</b-switch>            
          </CCol>            
        </CRow>   
      </div>
      <div class="sidebar_footer">
        <CRow class="m-0">
          <CCol cols="12" lg="12">
						<div v-if="!sqCategoryIdExternal">
							<CButton color="primary" @click="insertCategory(category);"><i class="fas fa-check mr-1"/>{{$t('Save')}}</CButton>
							<CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{$t('Dismiss')}}</CButton>
						</div> 						   
						<div v-else>
							<CButton color="primary" @click="updateCategory(category);"><i class="fas fa-check mr-1"/>{{$t('Save')}}</CButton>
							<CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{$t('Dismiss')}}</CButton>
          	</div>
          </CCol>
        </CRow>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import QuickEdit from 'vue-quick-edit';

export default {
  name: 'categoryDetails',
  props: ['history', 'sqCategoryIdExternal'],
  components: {
    Multiselect,
    QuickEdit,
  },
  watch: {
    $props: {
      handler() {
        this.getCategoryDetails();
      },
      deep: true,
      immediate: true,
    }
  }, 
  data() {
    return {
			category: {},
      targetGroups: [],
    }
  },
  methods: {
    getCategoryDetails() {
      if(this.sqCategoryIdExternal !== null) {
				axios.get(process.env.VUE_APP_API_URL + '/v1/measure/satisquestions/category/' + this.sqCategoryIdExternal)
				.then(res => {
					this.category = res.data.data;   
				})
				.catch(err => {
					console.error(err); 
				});    
      } else {
        // Reset the data 
        this.resetCategoryData();
      }
    },
    insertCategory(data) {
      let params = {};
      params.categoryData = data;
      (params.categoryData.is_active == true) ? params.categoryData.active = 'Y' : params.categoryData.active = 'N';
      
      let category_name = params.categoryData.category_name;
      let target_groups = params.categoryData.target_groups;

      if(category_name && target_groups.length > 0) {
        axios.post(process.env.VUE_APP_API_URL + '/v1/measure/satisquestions/category', params)
        .then(res => {
          this.$buefy.toast.open({ message: this.$t('sq.Pillar_added'), type: 'is-success', duration: 2000 });
          // Emit the update_sq_categories event
          this.$bus.$emit('update_sq_categories');
          // Reset the data
          this.resetCategoryData();
          // Close the sidebar
          this.closeSidebarRight();         
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }
    },
    updateCategory(data) {
      let params = {}
      params.categoryData = data;
      (params.categoryData.is_active == true) ? params.categoryData.active = 'Y' : params.categoryData.active = 'N';
           
      let category_name = params.categoryData.category_name;
      let target_groups = params.categoryData.target_groups;

      if(category_name && target_groups.length > 0) {
        axios.put(process.env.VUE_APP_API_URL + '/v1/measure/satisquestions/category/' + this.sqCategoryIdExternal, params)
        .then(res => {
          this.$buefy.toast.open({ message: this.$t('sq.Pillar_updated'), type: 'is-success', duration: 2000 });
          // Emit the update_sq_categories event
          this.$bus.$emit('update_sq_categories');
          // Reset the data
          this.resetCategoryData();
          // Close the sidebar
          this.closeSidebarRight();  
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }      
    },
    getTargetGroups() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/targetgroups/multiselect')
      .then(res => {
        this.targetGroups = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    resetCategoryData() {
      this.category = {
        category_name: '',
        is_active: true,
        target_groups: []
      }
    }				
  },
  mounted: function() {
    this.getTargetGroups();
  }  
}
</script>