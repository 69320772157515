<template>
  <div>
    <div class="sidebar_container">
      <div class="sidebar_header">
        <CRow class="m-0">
          <CCol cols="10" lg="10" md="10" sm="10" class="text-left">
            <span v-if="history.length > 1" class="pointer" @click="removeSidebarHistoryStep()">
              <i class="fas fa-arrow-circle-left mr-1"/>
            </span>            
            <span class="sidebar_subject">
              {{department.department_id === null || department.department_id === undefined ? $t('organisation.New_department') : $t('organisation.Edit_department')}}          
            </span>
          </CCol>
          <CCol cols="2" lg="2" md="2" sm="2" class="text-right">
            <span @click="closeSidebarRight();"><i class="fas fa-times"/></span>        
          </CCol>
        </CRow>
      </div>
      <div class="sidebar_content">
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <CInput type="text" class="mb-0" :label="$t('organisation.Department_name')" v-model="department.department_name" required was-validated/>
          </CCol>
          <CCol cols="12" lg="12" class="pt-0">
            <label>{{$t('organisation.Department_managers')}}</label>
            <multiselect
              class="data_table"
              v-model="department.department_managers"                       
              label="label" 
              track-by="user_id_external" 
              :placeholder="$t('Search_for_a') + ' ' + $t('common.manager')"
              open-direction="bottom" 
              :options="users" 
              :multiple="true" 
              :searchable="true" 
              :loading="false" 
              :internal-search="false"
              :clear-on-select="true"
              :close-on-select="true"
              :options-limit="300"
              :max-height="600"
              :show-no-results="true"
              :hide-selected="false"
              @search-change="asyncFindUser">
              <span slot="noResult">{{ $t('common.no_users_found') }}</span>
              <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>                
            </multiselect>
          </CCol>
        </CRow>
      </div>
      <div class="sidebar_footer">
        <CRow class="m-0">
          <CCol cols="12" lg="12">
            <div v-if="department.department_id === null || department.department_id === undefined">
              <CButton color="primary" @click="insertDepartment();"><i class="fas fa-check mr-1"/>{{ $t('Save') }}</CButton>
              <CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{ $t('Dismiss') }}</CButton>
            </div>
            <div v-else>
              <CButton color="primary" @click="updateDepartment(department.department_id, department);"><i class="fas fa-check mr-1"/>{{ $t('Update') }}</CButton>
              <CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{ $t('Dismiss') }}</CButton>
            </div>      
          </CCol>
        </CRow>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import QuickEdit from 'vue-quick-edit';

export default {
  name: 'departmentDetailsPane',
  props: ['history', 'departmentId'],
  components: {
    Multiselect,
    QuickEdit,
  },
  watch: {
    $props: {
      handler() {
        this.getDepartmentDetails();
      },
      deep: true,
      immediate: true,
    }
  }, 
  data() {
    return {
      department: {},
      users: []
    }
  },
  methods: {
    getDepartmentDetails() {
      if(this.departmentId !== null) {
        axios.get(process.env.VUE_APP_API_URL + '/v1/core/organisation/department/' + this.departmentId)
        .then(res => {
          this.department = res.data.data;
          // Reset the users
          this.resetUsersData();
        })
        .catch(err => {
          console.error(err); 
        });                  
      } else {
        // Reset the department
        this.resetDepartmentData();
        // Reset the users
        this.resetUsersData();
      }
    },
    insertDepartment () {
      let params = {};
      params.departmentData = this.department;
      
      let department_name = params.departmentData.department_name;

      if(department_name) {
        axios.post(process.env.VUE_APP_API_URL + '/v1/core/organisation/department', params)
        .then(res => {
          this.$buefy.toast.open({ message: this.$t('organisation.Department_added'), type: 'is-success', duration: 3000 });
          // Emit the update_departments event
          this.$bus.$emit('update_departments');
          // Reset the department
          this.resetDepartmentData();
          // Reset the users
          this.resetUsersData();
          // Close the sidebar
          this.closeSidebarRight();          
        })
        .catch(err => {
          // Show correct error message according to error
          if(err.response.data.error === 'Department already exists') {          
            this.$buefy.toast.open({ message: this.$t('organisation.Department_already_exists'), type: 'is-danger', duration: 2000 });
          } else {
            this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
          }
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }
    },    
    updateDepartment(departmentId, data) {
      let params = {}
      params.departmentData = data;     
            
      axios.put(process.env.VUE_APP_API_URL + '/v1/core/organisation/department/' + departmentId, params)
      .then(res => {
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('organisation.Department_updated'), type: 'is-success', duration: 2000 });
        // Emit the update_departments event
        this.$bus.$emit('update_departments');        
        // Reset the users
        this.resetUsersData();
        // Close the sidebar
        this.closeSidebarRight();        
      })
      .catch(err => {
        // Show correct error message according to error
        if(err.response.data.error === 'Department already exists') {          
          this.$buefy.toast.open({ message: this.$t('organisation.Department_already_exists'), type: 'is-danger', duration: 2000 });
        } else {
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        }
      })
    },
    asyncFindUser (query) {
      let searchTerm = query;
      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/users/' + searchTerm)
        .then(res => {      
          this.users = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }    
    },
    resetDepartmentData() {
      this.department = {
        department_id: null,
        department_name: null,
        department_managers: []
      };
    },
    resetUsersData() {
      this.users = [];
    }
  }
}
</script>