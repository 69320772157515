<template>
  <div @click="openExperience()" class="d-flex align-items-center pointer open_experience">
    <i class="fa-solid fa-up-right-from-square mr-1"></i>
    <span>{{$t('common.Employee_app')}}</span>
  </div>
</template>

<script>

export default {
  name: 'TheHeaderOpenExperience',
  data () {
    return {
      appBaseUrl: null
    }
  },
  methods: {
    openExperience() {
      window.open(this.appBaseUrl, '_blank');
    },
  },
  mounted: function() {
    this.appBaseUrl = process.env.VUE_APP_URL;
  }
}
</script>