<template>
  <div>
    <div class="sidebar_container">
      <div class="sidebar_header">
        <CRow class="m-0">
          <CCol cols="10" lg="10" md="10" sm="10" class="text-left">
            <span v-if="history.length > 1" class="pointer" @click="removeSidebarHistoryStep()">
              <i class="fas fa-arrow-circle-left mr-1"/>
            </span>            
            <span class="sidebar_subject">
							{{!kbSubcategoryIdExternal ? $t('kb.new_subcategory') : $t('kb.edit_subcategory')}}
            </span>
          </CCol>
          <CCol cols="2" lg="2" md="2" sm="2" class="text-right">
            <span @click="closeSidebarRight();"><i class="fas fa-times"/></span>        
          </CCol>
        </CRow>
      </div>
      <div class="sidebar_content">
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <quick-edit type="text" mode="ignore" :buttonOkText="$t('Save')" :buttonCancelText="$t('Dismiss')" v-model="subcategory.kb_intent_subcategory_name">                        
              <h2 class="m-0">{{subcategory.kb_intent_subcategory_name ? subcategory.kb_intent_subcategory_name : $t('common.Click_to_set_name')}}<i class="icon edit fas fa-pen ml-1"/></h2>
            </quick-edit>
          </CCol>           
        </CRow>
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <label>{{$t('common.Which_category')}}</label>                    
            <multiselect
              class="data_table"
              v-model="subcategory.category"
              :options="categories" 
              :multiple="false"
              :hide-selected="true"
              :close-on-select="true"
              :selectLabel="$t('common.Add_category')"
              :deselectLabel="$t('common.Remove_category')"
              track-by="kb_intent_category_id_external" 
              label="label">
              <span slot="noResult">{{$t('common.no_categories_found')}}</span>
            </multiselect>
          </CCol>
        </CRow>        
        <CRow class="m-0">           
          <CCol cols="12" lg="12" class="pt-0">
            <label>{{$t('common.Specified_for_groups')}}</label>
            <multiselect
              class="data_table"
              v-model="subcategory.target_groups" 
              :options="targetGroups" 
              :multiple="true"
              :placeholder="$t('common.Select_group')" 
              :selectLabel="$t('common.Add_group')" 
              :selectedLabel="$t('Added')"
              :deselectLabel="$t('common.Remove_group')"
              track-by="group_id" 
              label="title">
              <span slot="noResult">{{ $t('common.no_groups_found') }}</span>
            </multiselect> 
          </CCol>
        </CRow>
        <CRow class="m-0">
          <CCol cols="6" lg="6" class="pt-0">
            <label>{{$t('common.Upload_an_image_for_subcategory')}}</label>
            <image-uploader class="image_upload" :preview="true" :className="['fileinput', { 'fileinput--loaded': subcategoryImageUploaded }]" capture="environment" :debug="0" doNotResize="gif" :autoRotate="true" outputFormat="verbose" @input="setSubcategoryImage">
              <label for="fileInput" slot="upload-label" class="m-0">
                <span class="upload-caption btn btn-primary m-0"><i class="fas fa-file-upload mr-1"/>{{subcategoryImageUploaded ? $t('common.Replace_image') : $t('common.Upload_image')}}</span>
              </label>
            </image-uploader>
          </CCol>
          <CCol v-if="kbSubcategoryIdExternal && subcategory.subcategory_image_id !== null" cols="6" lg="6" class="pt-0">
            <label>{{$t('common.Current_image')}}</label>
            <img :src="apiBaseUrl + '/v1/common/cdn/file/image/knowledge-subcategory/' + subcategory.kb_intent_subcategory_id_external + '/' + subcategory.subcategory_image_id + '.jpg' + '/' + clientToken" class="current_image"/>
          </CCol>                                    
        </CRow>               
        <CRow class="m-0">           
          <CCol cols="12" lg="12" class="pt-0 pb-0">
            <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="subcategory.is_active" size="is-small">{{ $t('is_active') }}</b-switch>            
          </CCol>            
        </CRow>   
      </div>
      <div class="sidebar_footer">
        <CRow class="m-0">
          <CCol cols="12" lg="12">
						<div v-if="!kbSubcategoryIdExternal">
							<CButton color="primary" @click="insertSubcategory(subcategory);"><i class="fas fa-check mr-1"/>{{$t('Save')}}</CButton>
							<CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{$t('Dismiss')}}</CButton>
						</div> 						   
						<div v-else>
							<CButton color="primary" @click="updateSubcategory(subcategory);"><i class="fas fa-check mr-1"/>{{$t('Save')}}</CButton>
							<CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{$t('Dismiss')}}</CButton>
          	</div>
          </CCol>
        </CRow>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import QuickEdit from 'vue-quick-edit';
import ImageUploader from 'vue-image-upload-resize';

export default {
  name: 'categoryDetails',
  props: ['history', 'kbCategory', 'kbSubcategoryIdExternal'],
  components: {
    Multiselect,
    QuickEdit,
    ImageUploader
  },
  watch: {
    $props: {
      handler() {
        this.getSubcategoryDetails();
      },
      deep: true,
      immediate: true,
    }
  }, 
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null,
			subcategory: {},
      subcategoryImageUploaded: false,
      categories: [],
      targetGroups: [],
    }
  },
  methods: {
    getSubcategoryDetails() {
      if(this.kbSubcategoryIdExternal !== null) {
				axios.get(process.env.VUE_APP_API_URL + '/v1/knowledge/subcategory/' + this.kbSubcategoryIdExternal)
				.then(res => {
					this.subcategory = res.data.data;
				})
				.catch(err => {
					console.error(err); 
				});    
      } else {
        // Reset the data 
        this.resetSubcategoryData();
      }
    },
    insertSubcategory(data) {
      let params = {};
      params.subcategoryData = data;
      (params.subcategoryData.is_active == true) ? params.subcategoryData.active = 'Y' : params.subcategoryData.active = 'N';

      let kb_intent_subcategory_name = params.subcategoryData.kb_intent_subcategory_name;
      let category = params.subcategoryData.category;      
      let target_groups = params.subcategoryData.target_groups;

      if(kb_intent_subcategory_name && category && target_groups.length > 0) {
        axios.post(process.env.VUE_APP_API_URL + '/v1/knowledge/subcategory', params)
        .then(res => {
          this.$buefy.toast.open({ message: this.$t('common.Subcategory_added'), type: 'is-success', duration: 2000 });                    
          // Emit the update_kb_category_subcategories event
          if(this.kbCategory.kb_intent_category_id_external && this.kbCategory.kb_intent_category_name) {
            this.$bus.$emit('update_kb_category_subcategories', this.kbCategory);
          }
          // Reset the data
          this.resetSubcategoryData();
          // Close the sidebar
          this.closeSidebarRight();                      
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }
    }, 
    updateSubcategory(data) {
      let params = {}
      params.subcategoryData = data;
      (params.subcategoryData.is_active == true) ? params.subcategoryData.active = 'Y' : params.subcategoryData.active = 'N';
                 
      let kb_intent_subcategory_name = params.subcategoryData.kb_intent_subcategory_name;
      let category = params.subcategoryData.category;      
      let target_groups = params.subcategoryData.target_groups;

      if(kb_intent_subcategory_name && category && target_groups.length > 0) {
        axios.put(process.env.VUE_APP_API_URL + '/v1/knowledge/subcategory/' + this.kbSubcategoryIdExternal, params)
        .then(res => {
          this.$buefy.toast.open({ message: this.$t('common.Subcategory_updated'), type: 'is-success', duration: 2000 });
          // Emit the update_kb_category_subcategories event
          if(this.kbCategory.kb_intent_category_id_external && this.kbCategory.kb_intent_category_name) {            
            this.$bus.$emit('update_kb_category_subcategories', this.kbCategory);
          }
          // Reset the data
          this.resetSubcategoryData();
          // Close the sidebar
          this.closeSidebarRight();       
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }      
    }, 
    setSubcategoryImage(output) {
      this.subcategoryImageUploaded = true;
      this.subcategory.image = output.dataUrl; 
    },  
    resetSubcategoryData() {
      this.subcategory = {
        kb_intent_subcategory_name: null,
        image: null,
        is_active: true,
        target_groups: []
      }

      if(this.kbCategory.kb_intent_category_id_external && this.kbCategory.kb_intent_category_name) {
        this.subcategory.category = {
          kb_intent_category_id_external: this.kbCategory.kb_intent_category_id_external,
          label: this.kbCategory.kb_intent_category_name
        }
      }
    },
    getCategories() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/knowledge/categories/multiselect')
      .then(res => {
        this.categories = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    getTargetGroups() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/knowledge/category/' + this.kbCategory.kb_intent_category_id_external + '/targetgroups')
      .then(res => {
        this.targetGroups = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    }
  },
  mounted: function() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');

    this.getCategories();
    this.getTargetGroups();
  }  
}
</script>