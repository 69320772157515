<template>
  <CCard class="pointer navigation_card" @click="openSidebarRight('sq_category_details', { sq_category_id_external: categoryData.sq_category_id_external });">
    <CCardBody>
      <div class="d-flex align-items-center">
        <div class="item_icon d-inline-block align-middle mr-2">
          <i class="fas fa-list"/>               
        </div>                        
        <div class="flex-grow-1">        
          <span><b>{{categoryData.category_name}}</b></span>
          <div class="meta">
            <span>
              {{categoryData.nr_of_subcategories}} {{categoryData.nr_of_subcategories !== 1 ? $t('common.subcategories') : $t('common.subcategory')}} |
              {{categoryData.nr_of_satisquestions}} {{categoryData.nr_of_satisquestions !== 1 ? $t('sq.satisquestions') : $t('sq.satisquestion')}}
            </span>
          </div>
        </div>
      </div>
    </CCardBody>
  </CCard>
</template>

<script>

export default {
  name: 'measureCategoryCard',
  props: ['category'],
  watch: {
    $props: {
      handler() {
        if(this.category) this.categoryData = this.category;
      },
      deep: true,
      immediate: true,
    }
  },
  data() {
    return {
      categoryData: {}
    }
  }  
}
</script>
