<template>
  <div>
    <div class="sidebar_container">
      <div class="sidebar_header">
        <CRow class="m-0">
          <CCol cols="10" lg="10" md="10" sm="10" class="text-left">
            <span v-if="history.length > 1" class="pointer" @click="removeSidebarHistoryStep()">
              <i class="fas fa-arrow-circle-left mr-1"/>
            </span>            
            <span class="sidebar_subject">
							{{$t('learn.Assign_course')}}
            </span>
          </CCol>
          <CCol cols="2" lg="2" md="2" sm="2" class="text-right">
            <span @click="closeSidebarRight();"><i class="fas fa-times"/></span>        
          </CCol>
        </CRow>
      </div>
      <div class="sidebar_content">
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <label><b>{{$t('common.Course')}}</b></label>
            <multiselect class="data_table"
                         v-model="assignment.course" 
                         :options="courses" 
                         :multiple="false"
                         :hide-selected="true"
                         :close-on-select="true"
                         :placeholder="$t('common.Select_course')" 
                         :selectLabel="$t('common.Add_course')"
                         :deselectLabel="$t('common.Remove_course')"
                         track-by="lms_course_id_external" 
                         label="label">
              <span slot="noResult">{{ $t('common.no_courses_found') }}</span>
            </multiselect>
          </CCol>
        </CRow>
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <label><b>{{ $t('common.Specify_employees') }}</b></label>
            <CRow v-for="(filter, index) in assignment.filters" v-bind:key="index">
              <CCol cols="12" lg="12" class="pt-0 pb-0">
                <div class="mb-2 d-flex filters">
                  <div>
                    <multiselect class="data_table open_absolute"
                                 v-model="filter.type" 
                                 :options="groupFilterTypes" 
                                 :multiple="false"                
                                 :placeholder="$t('common.Select_filter_type')" 
                                 :selectLabel="$t('common.Add_filter_type')" 
                                 :selectedLabel="$t('Added')"
                                 :deselectLabel="$t('common.Remove_filter_type')"
                                 track-by="type_tag" 
                                 label="type_name"
                                 :custom-label="customFilterTypeLabel"
                                 :disabled="filter.type !== null && filter.type !== undefined"
                                 @input="getTypeFilterConditions(filter.type.type_tag);">
                      <span slot="noResult">{{ $t('common.no_types_found') }}</span>
                      <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                    </multiselect>
                  </div>

                  <div v-if="filter.type" class="ml-2" v-bind:class="{'mr-2' : filter.condition}">
                    <multiselect class="data_table open_absolute"
                                  v-model="filter.condition" 
                                  :options="typeFilterConditions" 
                                  :multiple="false"                
                                  :placeholder="$t('common.Select_filter_condition')" 
                                  :selectLabel="$t('common.Add_filter_condition')" 
                                  :selectedLabel="$t('Added')"
                                  :deselectLabel="$t('common.Remove_filter_condition')"
                                  track-by="condition_tag" 
                                  label="condition_name"
                                  :custom-label="customFilterConditionLabel"
                                  :disabled="filter.condition !== null && filter.condition !== undefined">
                      <span slot="noResult">{{ $t('common.no_conditions_found') }}</span>
                      <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                    </multiselect>
                  </div>
                  
                  <div v-if="filter.type && filter.condition">
                    <div v-if="filter.type.type_tag == 'department'">
                      <multiselect class="data_table open_absolute"
                                   v-model="filter.value" 
                                   :options="groupFilterDepartments" 
                                   :multiple="false"                
                                   :placeholder="$t('common.Select_department')"
                                   :selectLabel="$t('common.Add_department')" 
                                   :selectedLabel="$t('Added')"
                                   :deselectLabel="$t('common.Remove_department')"
                                   track-by="department_id" 
                                   label="department_name"
                                   @search-change="asyncFindDepartment"
                                   @input="getGroupMembers();">
                        <span slot="noResult">{{ $t('common.no_departments_found') }}</span>
                        <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                      </multiselect>
                    </div>
                    <div v-if="filter.type.type_tag == 'team'">
                      <multiselect class="data_table open_absolute"
                                   v-model="filter.value" 
                                   :options="groupFilterTeams" 
                                   :multiple="false"                
                                   :placeholder="$t('common.Select_team')" 
                                   :selectLabel="$t('common.Add_team')" 
                                   :selectedLabel="$t('Added')"
                                   :deselectLabel="$t('common.Remove_team')"
                                   track-by="team_id" 
                                   label="team_name"
                                   @search-change="asyncFindTeam"
                                   @input="getGroupMembers();">
                        <span slot="noResult">{{ $t('common.no_teams_found') }}</span>
                        <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                      </multiselect>
                    </div>
                    <div v-if="filter.type.type_tag == 'user'">
                      <multiselect class="data_table open_absolute"
                                   v-model="filter.value" 
                                   :options="groupFilterUsers" 
                                   :multiple="false"                
                                   :placeholder="$t('common.Select_user')" 
                                   :selectLabel="$t('common.Add_user')" 
                                   :selectedLabel="$t('Added')"
                                   :deselectLabel="$t('common.Remove_user')"
                                   track-by="user_id_external" 
                                   label="label"
                                   @search-change="asyncFindUser"
                                   @input="getGroupMembers();">
                        <span slot="noResult">{{ $t('common.no_users_found') }}</span>
                        <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                      </multiselect>  
                    </div>
                    <div v-if="filter.type.type_tag == 'target_group'">
                      <multiselect class="data_table open_absolute"
                                   v-model="filter.value" 
                                   :options="groupFilterTargetGroups" 
                                   :multiple="false"                
                                   :placeholder="$t('common.Select_group')" 
                                   :selectLabel="$t('common.Add_group')" 
                                   :selectedLabel="$t('Added')"
                                   :deselectLabel="$t('common.Remove_group')"
                                   track-by="group_id" 
                                   label="title"                      
                                   @search-change="asyncFindTargetGroup"
                                   @input="getGroupMembers();">
                        <span slot="noResult">{{ $t('common.no_groups_found') }}</span>
                        <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                      </multiselect>  
                    </div>
                    <div v-if="filter.type.type_tag == 'date_started' || filter.type.type_tag == 'date_left'">
                      <multiselect class="data_table open_absolute"
                                   v-model="filter.value" 
                                   :options="groupFilterValues" 
                                   :multiple="false"                
                                   :placeholder="$t('common.Select_filter_value')" 
                                   :selectLabel="$t('common.Add_filter_value')" 
                                   :selectedLabel="$t('Added')"
                                   :deselectLabel="$t('common.Remove_filter_value')"
                                   track-by="type_value_tag" 
                                   label="type_value_name"
                                   :custom-label="customFilterTypeValueLabel"
                                   @input="getGroupMembers();">
                        <span slot="noResult">{{ $t('common.no_values_found') }}</span>
                      </multiselect>
                    </div>
                    <div v-if="userAttributes.includes(filter.type.type_tag)">
                      <CInput 
                        v-debounce:1s="getGroupMembers"
                        type="text" 
                        class="attribute mb-0"
                        v-model="filter.value"
                      ></CInput>
                    </div>
                  </div>

                  <CButton class="ml-2 mr-0 d-inline-block" color="primary" @click="removeFilterOption(index);">
                    <i class="fas fa-times"/>
                  </CButton>
                </div>
              </CCol>
            </CRow>
            <CRow>
              <CCol cols="12" lg="12" class="pt-0 pb-0">
                <CButton color="primary" @click="addFilterOption();">
                  <i class="fas fa-plus mr-1"/>
                  <span>{{ $t('common.Add_filter') }}</span>
                </CButton>
              </CCol>
            </CRow>                    
          </CCol>
        </CRow>
        <hr class="m-0">
        <div class="group_members">
          <CRow class="m-0">
            <CCol cols="6" lg="6" class="text-left" v-bind:class="{'pb-0' : !listViewEnabled}">
              <span class="sidebar_subject">
                {{ $t("common.Employees") }} <span v-if="assignment.users.length > 0">({{assignment.users.length}})</span>
              </span>
            </CCol>
            <CCol cols="6" lg="6" class="text-right" v-bind:class="{'pb-0' : !listViewEnabled}">
              <b-switch class="mb-0 mt-1 mt-xl-0 enable_search" v-model="listViewEnabled" size="is-small">{{ $t('common.activate_list_view') }}</b-switch>
            </CCol>
          </CRow>          
          <CRow>
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <div v-if="listViewEnabled">
                <CRow>
                  <CCol cols="12" sm="12" md="12" lg="12" class="pt-0" v-bind:class="{ 'pb-0' : listViewEnabled === true }">
                    <b-table ref="groupTable" class="data_table" :data="assignment.users" :striped="true" :hoverable="true" :mobile-cards="true" :paginated="isPaginated" :per-page="perPage" :current-page.sync="currentPage" :pagination-simple="isPaginationSimple" :pagination-position="paginationPosition">
                      <template slot-scope="props">
                        <b-table-column field="name" :label="$t('common.Name')">
                          <div @click="openSidebarRight('user_profile', { user_id_external:  props.row.user_id_external });" class="pointer">
                            <div v-if="props.row.profile_image" class="profile_image d-inline-block align-middle mr-2" v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + props.row.user_id_external + '/' + props.row.profile_image + '/' + clientToken + ')' }"></div>
                            <div v-else class="profile_icon d-inline-flex align-middle mr-2">
                              <userProfileAvatar :username="props.row.name" :size="25"/>
                            </div>
                            <b>{{props.row.name}}</b>
                          </div>
                        </b-table-column>
                        <b-table-column field="department_name" :label="$t('common.Department')">
                          <span>{{props.row.department_name ? props.row.department_name : '-'}}</span>
                        </b-table-column>                        
                        <b-table-column field="team_name" :label="$t('common.Team')">
                          <span>{{props.row.team_name ? props.row.team_name : '-'}}</span>
                        </b-table-column>                        
                        <b-table-column field="function" :label="$t('common.Function')">
                          <span>{{props.row.function !== null && props.row.function !== '' ? props.row.function : '-'}}</span>
                        </b-table-column>
                      </template>
                      <template slot="empty">
                        <div class="text-center">
                          <div v-if="assignment.filters.length > 0">
                            {{ $t('groups.No_users_based_on_filters') }}
                          </div>
                          <div v-else>
                            {{ $t('groups.Add_filters_to_show_members') }}
                          </div>
                        </div>                
                      </template>                
                    </b-table>                    
                  </CCol>
                </CRow>
              </div>
              <div v-else>
                <div v-if="assignment.users.length > 0">
                  <CRow class="group_member_container tile-container m-0">
                    <CCol cols="4" xl="4" lg="4" md="4" sm="4" v-for="employee in currentPageEmployees[currentEmployeePage - 1]" v-bind:key="employee.user_id_external" class="member_column p-0">
                      <CCard class="member">
                        <CCardBody>
                          <CRow>
                            <CCol cols="12" sm="12" md="12" lg="12" class="pt-0 pb-0">
                              <center>
                                <div v-if="employee.profile_image" class="profile_image mb-2" v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + employee.user_id_external + '/' + employee.profile_image + '/' + clientToken + ')' }"></div>
                                <div v-else class="profile_icon mb-2">
                                  <userProfileAvatar :username="employee.name" :size="50"/>
                                </div>
                              </center>
                            </CCol>                      
                            <CCol cols="12" sm="12" md="12" lg="12" class="pt-0 pb-0 text-center">
                              <a @click="openSidebarRight('user_profile', { user_id_external:  employee.user_id_external });" class="d-block"><b>{{employee.name}}</b></a>
                              <p v-if="employee.team_name" class="m-0">{{employee.team_name}} ({{employee.department_name}})</p>
                              <p class="m-0">{{employee.function !== null && employee.function !== '' ? employee.function : '-'}}</p>
                            </CCol>                                                
                          </CRow>
                        </CCardBody>
                      </CCard>
                    </CCol>
                  </CRow>
                  <hr v-if="assignment.users.length > membersPerPage" class="m-0">
                  <CRow v-if="assignment.users.length > membersPerPage" class="m-0">
                    <CCol cols="12" md="12">
                      <v-pagination class="justify-content-end" v-model="currentEmployeePage" :length="employeePages" :total-visible="9" prev-icon="mdi-chevron-left" next-icon="mdi-chevron-right"></v-pagination>
                    </CCol>
                  </CRow>
                </div>
                <div v-else>
                  <CRow class="m-0">
                    <CCol cols="12" lg="12" class="pt-0">
                      <div v-if="assignment.filters.length > 0">
                        {{ $t('common.No_employees_based_on_filters') }}
                      </div>
                      <div v-else>
                        {{ $t('learn.Add_filters_to_show_assignees') }}
                      </div>
                    </CCol>
                  </CRow>                  
                </div>
              </div>
            </CCol>                      
          </CRow>             
        </div>
      </div>
      <div class="sidebar_footer">
        <CRow class="m-0">
          <CCol cols="12" lg="12">
            <CButton color="primary" @click="assignCourses(assignment);"><i class="fas fa-check mr-1"/>{{$t('Assign')}}</CButton>
            <CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{$t('Dismiss')}}</CButton>
          </CCol>
        </CRow>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect'

import userProfileAvatar from "@/components/common/userProfileAvatar.vue";

export default {
  name: 'assignCourses',
  props: ['history', 'lmsCourse'],
  components: {
    Multiselect,
    userProfileAvatar
  },
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null,
      courses: [],
			assignment: {
        filters: [],
        users: []
      },      
      groupFilterTypes: [],
      groupFilterConditions: [],
      typeFilterConditions: [],      
      groupFilterValues: [],
      typeFilterValues: [],
      groupFilterDepartments: [],
      groupFilterTeams: [],
      groupFilterUsers: [],
      groupFilterTargetGroups: [], 
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 10,      
      listViewEnabled: false,
      userAttributes: ['hours_on_contract', 'meyer_briggs', 'disc', 'office_based', 'division', 'language', 'persona_1', 'persona_2', 'persona_3', 'work_location', 'user_function', 'years_of_service'],
      currentEmployeePage: 1,
      currentPageEmployees: {},
      membersPerPage: 9,
      employeePages: 0
    }
  },
  watch: {
    $props: {
      handler() {
        this.resetAssignmentData();
      },
      deep: true,
      immediate: true,
    }
  },  
  methods: {
    assignCourses(assignment) {
      let params = {};
      params.users = assignment.users;

      axios.post(process.env.VUE_APP_API_URL + '/v1/learn/course/' + assignment.course.lms_course_id_external + '/assign', params)
      .then(res => {
        let courseName = res.data.data.course_name;
        let totalEmployeesAssigned = res.data.data.total_employees_assigned;        

        if(totalEmployeesAssigned > 0) {
          this.$buefy.toast.open({ message: totalEmployeesAssigned + ' ' + this.$t('learn.employees_assigned_to') + ' ' + courseName, type: 'is-success', duration: 2000 });
        } else {
          this.$buefy.toast.open({ message: this.$t('learn.All_employees_already_assigned_to') + ' ' + courseName, type: 'is-success', duration: 2000 });
        }

        // Reset the data
        this.resetAssignmentData();
        // Close the sidebar
        this.closeSidebarRight();
      })
      .catch(err => {
        console.error(err); 
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      })

    },    
    getCourses() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/learn/courses/multiselect')
      .then(res => {
        this.courses = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getGroupMembers() {
      let params = {};
      params.filters = this.assignment.filters.filter(function (el) {
        return el.type != undefined && el.value != undefined;
      });

      axios.post(process.env.VUE_APP_API_URL + '/v1/core/targetgroup/members', params)
      .then(res => {
        this.assignment.users = res.data.data;
        // Enable the pagination if necessary
        (this.assignment.length > this.perPage) ? this.isPaginated = true : this.isPaginated = false;        
        // Set the pagination to the first page
        this.currentEmployeePage = 1;
        // Reset the employeePages value
        this.employeePages = 0;
            
        for (let i = 0; i < this.assignment.users.length; i = i + this.membersPerPage) {
          this.currentPageEmployees[this.employeePages] = this.assignment.users.slice(i, i + this.membersPerPage);
          this.employeePages++;
        }
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getGroupFilterTypes() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/targetgroups/filters/types')
      .then(res => {      
        this.groupFilterTypes = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    customFilterTypeLabel({type_tag}) {
      return this.$t('filter.' + type_tag);
    },    
    getGroupFilterConditions () {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/targetgroups/filters/conditions')
      .then(res => {
        this.groupFilterConditions = res.data.data;
        // Set the typeFilterConditions array
        this.typeFilterConditions = this.groupFilterConditions;        
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getTypeFilterConditions(filterTypeTag) {
      // Reset the typeFilterConditions array
      this.typeFilterConditions = this.groupFilterConditions;
      // Filter the typeFilterConditions based on the filter type tag
      this.typeFilterConditions = this.typeFilterConditions.filter( i => i.available_for_filter_type_tags.includes(filterTypeTag));
    },
    customFilterConditionLabel({condition_tag}) {
      return this.$t('condition.' + condition_tag);
    },    
    getGroupFilterTypeValues() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/targetgroups/filters/types/values')
      .then(res => {
        this.groupFilterValues = res.data.data;
        // Set the typeFilterValues array
        this.typeFilterValues = this.groupFilterValues;        
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getTypeFilterValues(filterTypeTag) {
      // Reset the typeFilterValues array
      this.typeFilterValues = this.typeFilterValues;
      // Filter the typeFilterValues based on the filter type tag
      this.typeFilterValues = this.typeFilterValues.filter( i => i.available_for_filter_type_tags.includes(filterTypeTag));
    },
    customFilterTypeValueLabel({type_value_tag}) {
      return this.$t('filter_value.' + type_value_tag);
    },
    addFilterOption() {
      this.assignment.filters.push({});
    },
    removeFilterOption(index) {
      this.assignment.filters.splice(index, 1);
      this.getGroupMembers();
    },    
    resetAssignmentData() {
      this.assignment = {
        course: null,
        users: [],
        filters: []
      };

      // Set the course if the prop is provided
      if(this.lmsCourse && this.lmsCourse.lms_course_id_external && this.lmsCourse.course_name) {
        this.assignment.course = {
          lms_course_id_external: this.lmsCourse.lms_course_id_external,
          label: this.lmsCourse.course_name
        }
      }       
    },
    asyncFindDepartment(query) {
      let searchTerm = query;
      this.groupFilterDepartments = [];
      
      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/departments/' + searchTerm)
        .then(res => {      
          this.groupFilterDepartments = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },
    asyncFindTeam(query) {
      let searchTerm = query;
      this.groupFilterTeams = [];

      if(searchTerm.length >= 2) {
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/teams/' + searchTerm)
        .then(res => {      
          this.groupFilterTeams = res.data.data;    
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },        
    asyncFindUser(query) {
      let searchTerm = query;
      this.groupFilterUsers = [];
      
      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/users/' + searchTerm)
        .then(res => {      
          this.groupFilterUsers = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },
    asyncFindTargetGroup(query) {
      let searchTerm = query;
      this.groupFilterTargetGroups = [];
      
      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/targetgroup/' + searchTerm)
        .then(res => {      
          this.groupFilterTargetGroups = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    }
  },
  mounted: function() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');

    this.getGroupFilterTypes();
    this.getGroupFilterConditions();
    this.getGroupFilterTypeValues();
    this.getCourses();
  }  
}
</script>