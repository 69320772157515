<template>
  <div>
    <div class="sidebar_container">
      <div class="sidebar_header">
        <CRow class="m-0">
          <CCol cols="10" lg="10" md="10" sm="10" class="text-left">
            <span v-if="history.length > 1" class="pointer" @click="removeSidebarHistoryStep()">
              <i class="fas fa-arrow-circle-left mr-1"/>
            </span>
            <span class="sidebar_subject">
              {{!onboardingPackageContent.onboarding_package_content_id_external ? $t('learn.New_onboarding_package_content') : $t('learn.Edit_onboarding_package_content')}}
            </span>
          </CCol>
          <CCol cols="2" lg="2" md="2" sm="2" class="text-right">
            <span @click="closeSidebarRight();"><i class="fas fa-times"/></span>    
          </CCol>      
        </CRow>
      </div>
      <div class="sidebar_content">
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <label>{{$t('learn.Onboarding_package')}}</label>
            <multiselect class="data_table"
                         v-model="onboardingPackageContent.onboarding_package" 
                         :options="onboardingPackages"
                         :multiple="false"
                         :hide-selected="true"
                         :close-on-select="true"
                         :placeholder="$t('learn.Select_onboarding_package')"
                         track-by="onboarding_package_id_external" 
                         label="package_name"
                         :show-labels="false"
                         disabled>
              <span slot="noResult">{{ $t('learn.No_onboarding_packages_found') }}</span>
            </multiselect>                        
          </CCol>
        </CRow>
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <label>{{$t('learn.Onboarding_package_content_type')}}</label>
            <multiselect class="data_table"
                        v-model="onboardingPackageContent.content_type" 
                        :options="onboardingPackageContentTypes" 
                        :multiple="false"
                        :hide-selected="true"
                        :close-on-select="true"
                        :placeholder="$t('learn.Select_onboarding_package_content_type')"
                        track-by="onboarding_package_content_type_tag" 
                        label="onboarding_package_content_type"
                        :show-labels="false"
                        :disabled="onboardingPackageContent.onboarding_package_content_id_external !== null">
              <span slot="noResult">{{ $t('learn.No_onboarding_package_content_types_found') }}</span>
            </multiselect>                        
          </CCol>
        </CRow>
        <CRow v-if="onboardingPackageContent.content_type && onboardingPackageContent.content_type.onboarding_package_content_type_tag == 'learn_course'" class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <label>{{$t('learn.Onboarding_package_learn_course')}}</label>
            <multiselect class="data_table"
                        v-model="onboardingPackageContent.course"
                        :options="onboardingPackageContentValues" 
                        :multiple="false"
                        :placeholder="$t('common.Select_learn_course')" 
                        :selectLabel="$t('common.Add_course')" 
                        :selectedLabel="$t('Added')"
                        :deselectLabel="$t('common.Remove_course')"
                        track-by="lms_course_id_external" 
                        label="course_name"
                        @search-change="asyncFindLearnCourse"
                        :disabled="onboardingPackageContent.onboarding_package_content_id_external !== null">>
              <span slot="noResult">{{ $t('common.no_courses_found') }}</span>
              <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
            </multiselect>                        
          </CCol>
        </CRow>
        <CRow v-if="onboardingPackageContent.content_type && onboardingPackageContent.content_type.onboarding_package_content_type_tag == 'knowledge_item'" class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <label>{{$t('learn.Onboarding_package_knowledge_item')}}</label>
            <multiselect class="data_table"
                        v-model="onboardingPackageContent.item" 
                        :options="onboardingPackageContentValues" 
                        :multiple="false"                
                        :placeholder="$t('common.Select_knowledge_item')" 
                        :selectLabel="$t('common.Add_item')" 
                        :selectedLabel="$t('Added')"
                        :deselectLabel="$t('common.Remove_item')"
                        track-by="kb_intent_id_external" 
                        label="kb_intent_title"
                        @search-change="asyncFindKnowledgeItem"
                        :disabled="onboardingPackageContent.onboarding_package_content_id_external !== null">
              <span slot="noResult">{{ $t('common.no_items_found') }}</span>
              <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
            </multiselect>                        
          </CCol>
        </CRow>
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0 pb-0">
            <CInput type="text" :label="$t('learn.Onboarding_package_content_name')" class="m-0" v-model="onboardingPackageContent.content_name"/>
          </CCol>                                      
        </CRow>
      </div>
      <div class="sidebar_footer">
        <CRow class="m-0">
          <CCol cols="12" lg="12">
						<div v-if="!onboardingPackageContentIdExternal">
							<CButton color="primary" @click="insertOnboardingPackageContent(onboardingPackageContent);"><i class="fas fa-check mr-1"/>{{$t('Save')}}</CButton>
							<CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{$t('Dismiss')}}</CButton>
						</div> 						   
						<div v-else>
							<CButton color="primary" @click="updateOnboardingPackageContent(onboardingPackageContent);"><i class="fas fa-check mr-1"/>{{$t('Save')}}</CButton>
							<CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{$t('Dismiss')}}</CButton>
          	</div>  
          </CCol>
        </CRow>    
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import QuickEdit from 'vue-quick-edit';

export default {
  name: 'onboardingPackageContent',
  props: ['history', 'onboardingPackage', 'onboardingPackageContentIdExternal'],
  components: {
    Multiselect,
    QuickEdit
  },
  watch: {
    $props: {
      handler() {
        this.getOnboardingPackageContentDetails();
      },
      deep: true,
      immediate: true,
    }
  },
  data() {
    return {
      onboardingPackageContent: {},
      onboardingPackageContentTypes: [],
      onboardingPackageContentValues: [],
      onboardingPackages: []
    }
  },
  methods: {
    getOnboardingPackageContentDetails() {
      if(this.onboardingPackageContentIdExternal !== null) {
        axios.get(process.env.VUE_APP_API_URL + '/v1/learn/onboarding/package/content/' + this.onboardingPackageContentIdExternal)
        .then(res => {
          this.onboardingPackageContent = res.data.data;       
        })
        .catch(err => {
          console.error(err); 
        });
      } else {
        // Reset the onboarding package
        this.resetOnboardingPackageContentData();      
      }
    },
    validateOnboardingPackageContent(params) {
      let content_type = params.onboardingPackageContentData.content_type;
      let course = params.onboardingPackageContentData.course;
      let item = params.onboardingPackageContentData.item;

      // Check if base information for the package content is available
      if(content_type) {        
        // If tag is 'learn_course', check if there is a Learn course set
        // If tag is 'knowledge_item', check if there is a Knowledge item set
        if(content_type.onboarding_package_content_type_tag === 'learn_course' && course && course.lms_course_id_external) {
          return true;
        } else if(content_type.onboarding_package_content_type_tag === 'knowledge_item' && item && item.kb_intent_id_external) {
          return true;        
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    insertOnboardingPackageContent(data) {
      let params = {};
      params.onboardingPackageContentData = data;
      params.onboardingPackageContentData.onboarding_package_id_external = this.onboardingPackage.onboarding_package_id_external;
      if(params.onboardingPackageContentData.content_name === '') params.onboardingPackageContentData.content_name = null;           


      if(this.validateOnboardingPackageContent(params) === true) {
        axios.post(process.env.VUE_APP_API_URL + '/v1/learn/onboarding/package/content', params)
        .then(res => {
          // Show a success notice
          this.$buefy.toast.open({ message: this.$t('learn.Onboarding_package_added'), type: 'is-success', duration: 3000 });
          // Emit the update_onboarding_package_content event
          this.$bus.$emit('update_onboarding_package_content', this.onboardingPackage); 
          // Reset the data
          this.resetOnboardingPackageContentData();
          // Close the sidebar
          this.closeSidebarRight();          
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }
    },
    updateOnboardingPackageContent(data) {
      let params = {}
      params.onboardingPackageContentData = data;
      params.onboardingPackageContentData.onboarding_package_id_external = this.onboardingPackage.onboarding_package_id_external;
      if(params.onboardingPackageContentData.content_name === '') params.onboardingPackageContentData.content_name = null;          

      if(this.validateOnboardingPackageContent(params) === true) {
        axios.put(process.env.VUE_APP_API_URL + '/v1/learn/onboarding/package/content/' + this.onboardingPackageContentIdExternal, params)
        .then(res => {
          // Show a success notice
          this.$buefy.toast.open({ message: this.$t('learn.Onboarding_package_updated'), type: 'is-success', duration: 2000 });
          // Emit the update_onboarding_package_content_details event
          this.$bus.$emit('update_onboarding_package_content_details', this.onboardingPackageContentIdExternal);           
          // Reset the data
          this.resetOnboardingPackageContentData();
          // Close the sidebar
          this.closeSidebarRight();     
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }        
    },      
    getOnboardingPackages() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/learn/onboarding/packages/multiselect')
      .then(res => {    
        this.onboardingPackages = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    getOnboardingPackageContentTypes() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/learn/onboarding/packages/content/types/multiselect')
      .then(res => {
        this.onboardingPackageContentTypes = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    }, 
    resetOnboardingPackageContentData() {
      this.onboardingPackageContent = {
        onboarding_package_content_id_external: null,
        content_type: null,
        content_name: null,
        course: null,
        item: null
      };

      if(this.onboardingPackage && this.onboardingPackage.onboarding_package_id_external && this.onboardingPackage.package_name) {
        this.onboardingPackageContent.onboarding_package = {
          onboarding_package_id_external: this.onboardingPackage.onboarding_package_id_external,
          package_name: this.onboardingPackage.package_name
        }
      }      
    },
    asyncFindKnowledgeItem(searchTerm) {
      this.onboardingPackageContentValues = [];
      
      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/knowledge/items/' + searchTerm)
        .then(res => {      
          this.onboardingPackageContentValues = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },
    asyncFindLearnCourse(searchTerm) {
      this.onboardingPackageContentValues = [];
      
      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/learn/courses/' + searchTerm)
        .then(res => {      
          this.onboardingPackageContentValues = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    }            
  },
  mounted: function() {
    this.getOnboardingPackages();
    this.getOnboardingPackageContentTypes();
  }    
}
</script>