<template>
  <v-popover offset="10"
             :placement="popoverPlacement"
             trigger="hover"
             :delay="{ show: 0, hide: 0 }"
             popoverClass="dashboard_popover"
             popoverWrapperClass="dashboard_popover_wrapper"
             popoverInnerClass="dashboard_popover_content fit-content"
             boundariesElement=".popover_trigger">                  
            
    <div class="popover_trigger d-flex align-items-center">      
      <span>
        <i class="fas mr-1" v-bind:class="{'fa-globe' : popoverType === 'countries', 'fa-wallet' : popoverType === 'currencies', 'fa-coins' : popoverType === 'variations'}"/>{{popoverData.length}}
      </span>
    </div>

    <template slot="popover">
      <CCard  class="mb-0">
        <CCardBody class="pt-1 pb-2 pr-2 pl-2">
          <CRow>
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <div v-if="popoverType === 'countries'" class="country_flags">
                <span v-for="country in popoverData" v-bind:key="country.abbr" v-html="getTwemoji(country.flag)"></span>
              </div>
              <div v-if="popoverType === 'currencies'" class="item_tags">
                <b-tag v-for="(currency, index) in popoverData" v-bind:key="index" class="mt-1 info">{{currency.code}}</b-tag>
              </div>
              <div v-if="popoverType === 'variations'" class="item_tags">
                <b-tag v-for="(variation, index) in popoverData" v-bind:key="index" class="mt-1 info">
                  <span v-html="variation.name"></span>
                </b-tag>
              </div>                                          
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </template>
  </v-popover>
</template>

<script>

export default {
  name: 'partnerProductPopover',
  props: ['popoverType', 'popoverData', 'popoverPlacement'],
  methods: {
    getTwemoji(emoji) {
      return twemoji.parse(emoji);
    }
  }    
}
</script>