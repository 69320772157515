<template>
  <div class="dropdown account">
    <CDropdown inNav class="c-header-nav-items" placement="bottom-end" add-menu-classes="pt-0">
      <template #toggler>
        <CHeaderNavLink class="p-0">
          <div v-if="user.profile_image && user.original_company_id_external && user.original_company_id_external !== user.company_id_external"
                class="profile_image d-inline-block align-middle"           
                v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile-original/' + user.user_id_external + '/' + user.profile_image + '/' + clientToken + ')' }">
          </div>
          <div v-else-if="user.profile_image"
                class="profile_image d-inline-block align-middle"           
                v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + user.user_id_external + '/' + user.profile_image + '/' + clientToken + ')' }">
          </div>
          <div v-else-if="user.name"
               class="profile_icon d-inline-flex align-middle">
            <userProfileAvatar :username="user.name" :size="20"/>
          </div>
        </CHeaderNavLink>
      </template>

      <CDropdownItem class="pb-0">
        <TheHeaderLanguage/>
      </CDropdownItem>

      <CDropdownItem class="pb-0">
        <CButton class="w-100 m-0 howto_link" color="primary" @click="openHowTo()">
          {{$t('common.need_help')}}
        </CButton>
          
      </CDropdownItem>

      <CDropdownItem class="pb-0" @click="openSidebarRight('user_profile', { user_id_external: user.user_id_external });">        
        <span>{{$t('common.Show_my_profile')}}</span>
      </CDropdownItem> 

      <CDropdownItem @click="logout()">        
        <span>{{$t('logout')}}</span>
      </CDropdownItem>      

    </CDropdown>
  </div>
</template>

<script>
import axios from 'axios'
import TheHeaderLanguage from './TheHeaderLanguage'
import userProfileAvatar from "@/components/common/userProfileAvatar.vue";

export default {
  name: 'TheHeaderAccount',
  components: {
    TheHeaderLanguage,
    userProfileAvatar
  },
  data() {
    return {
      apiBaseUrl: null,
      cdnBaseUrl: null,
      clientToken: null,
      user: []
    }
  },
  methods: {
    getUserDetails() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/user/me')
      .then(res => {
        this.user = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },  
    openHowTo() {
      let helpUrl = null;
      // Set the helpUrl value based on the environment
      if(this.user.environment_tag === 'harryhr') {
        helpUrl = 'https://harryhr.com/how-to';
      } else if(this.user.environment_tag === 'employalty') {
        helpUrl = 'https://employalty.app/contact/';
      }

      var win = window.open(helpUrl, '_blank');
      win.focus();
    },
    logout: function () {
      this.$store.dispatch('logout')
      .then(() => {
        this.$router.push('/login')
      })    
    }   
  },
  mounted: function() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.cdnBaseUrl = process.env.VUE_APP_CDN_URL;
    this.clientToken = localStorage.getItem('token');

    this.getUserDetails();
  }
}
</script>