<template>
  <div>
    <div class="sidebar_container">
      <div class="sidebar_header">
        <CRow class="m-0">
          <CCol cols="10" lg="10" md="10" sm="10" class="text-left">
            <span v-if="history.length > 1" class="pointer" @click="removeSidebarHistoryStep()">
              <i class="fas fa-arrow-circle-left mr-1"/>
            </span>            
            <span class="sidebar_subject">
							{{$t('common.Preview')}}
            </span>
          </CCol>
          <CCol cols="2" lg="2" md="2" sm="2" class="text-right">
            <span @click="closeSidebarRight();"><i class="fas fa-times"/></span>        
          </CCol>
        </CRow>
      </div>
      <div class="sidebar_content">
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <knowledgeItem v-if="item"
                           :itemData="item"
                           :navigatable="false"
                           :editable="true"
                           :preview="true"
                           :showLikes="true"
                           :scrollableContent="true"/>
          </CCol>           
        </CRow>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import knowledgeItem from '@/components/knowledge/knowledgeItem.vue';

export default {
  name: 'itemPreview',
  props: ['history', 'kbIntentIdExternal'],
  components: {
    knowledgeItem
  },
  watch: {
    $props: {
      handler() {
        this.getItemDetails();
      },
      deep: true,
      immediate: true,
    }
  },   
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null,      
			item: null
    }
  },
  methods: {
    setContentImageUrls() {
      // Get the DOM element with the content
      var knowledgeItemContent = document.querySelector('#knowledgeItemContent');
      // Check if the DOM element exists
      if(knowledgeItemContent) {     
        // Gather the img elements within the DOM element       
        var images = knowledgeItemContent.querySelectorAll('img');
        // Check if there are img elements available
        if(images && images.length > 0) {            
          // Loop through the array with img elements
          for (var i = 0; i < images.length; ++i) {
            // Get the filename of the image
            let imageFileName = images[i].src.substring((images[i].src.lastIndexOf("/")) + 1);
            // Update the source for the necessary images
            if(imageFileName.endsWith(".png") || imageFileName.endsWith(".jpg") || imageFileName.endsWith(".jpeg")) {
              if(this.item.from_marketplace === 'N') {
                images[i].src = this.apiBaseUrl + '/v1/common/cdn/file/image/knowledge-item/' + this.kbIntentIdExternal + '/' + imageFileName + '/' + this.clientToken;
              } else if(this.item.from_marketplace === 'Y') {
                images[i].src = this.apiBaseUrl + '/v1/common/cdn/file/image/knowledge-item-mp/' + this.kbIntentIdExternal + '/' + imageFileName + '/' + this.clientToken;
              }
            }
          }
        }
      }     
    },
    getItemDetails() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/knowledge/item/' + this.kbIntentIdExternal)
      .then(res => {
        this.item = res.data.data;

        setTimeout(function(){
          this.setContentImageUrls();
        }.bind(this), 50);        
      })
      .catch(err => {
        console.error(err); 
      });
    }
  },
  mounted: function() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');
  }  
}
</script>