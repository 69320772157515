<template>
  <div ref="dashboardContainer" class="c-app" @scroll="checkDashboardScrollPosition()">
    <TheSidebar ref="theSidebar" :platformRoles="platformRoles"/>
    <div class="c-wrapper" v-bind:class="{'admin_company_set' : adminCompanySet}">
      <div class="concentric-circles"></div>
      <div v-if="adminCompanySet" class="d-flex align-items-center justify-content-center notice">
        <span><i class="fa-solid fa-triangle-exclamation mr-1"></i>{{$t('admin.Current_admin_company')}}: <b @click="openSuperAdminPage()" class="pointer">{{user.company_name}}</b></span>
      </div>
      <TheHeader :showHeaderLogo="getHeaderLogoVisibility()" :showHeaderBackground="showHeaderBackground"/>
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade">
              <router-view></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
      <TheFooter/>
    </div>
    <TheSidebarRight/>
  </div>
</template>

<script>
import axios from 'axios'
import TheSidebar from './TheSidebar'
import TheHeader from './TheHeader'
import TheFooter from './TheFooter'
import TheSidebarRight from './TheSidebarRight'

export default {
  name: 'TheContainer',
  components: {
    TheSidebar,
    TheHeader,
    TheFooter,
    TheSidebarRight
  },
  data() {
    return {
      user: {},
      platformRoles: [],
      adminCompanySet: false,
      showHeaderBackground: false
    }
  },  
  methods: {
    checkDashboardScrollPosition() {
      let dashboardScrollPosition = this.$refs.dashboardContainer.scrollTop;
      // Update the showHeaderBackground value based on the scoll position
      (dashboardScrollPosition > 0) ? this.showHeaderBackground = true : this.showHeaderBackground = false;
    },
    getHeaderLogoVisibility() {
      if(this.$refs && this.$refs.theSidebar) {
        let showSidebar = this.$refs.theSidebar.show;

        if(showSidebar !== false) return false;
        else return true;
      }
    },
    getUserDetails() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/user/me')
      .then(res => {
        this.user = res.data.data;
        // Check if the current user is currently acting as another company
        if(this.user.original_company_id_external && this.user.original_company_id_external !== this.user.company_id_external) {
          this.adminCompanySet = true;
        }
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getPlatformRoles() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/roles')
      .then(res => {      
        this.platformRoles = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    openSuperAdminPage() {
      if(this.adminCompanySet) this.$router.push({path: '/admin'});
    }    
  },
  mounted: function() {
    this.getUserDetails();
    this.getPlatformRoles();
    this.checkDashboardScrollPosition();

    this.$bus.$on('toggle_sidebar_menu', () => {
      this.getHeaderLogoVisibility();
    });    
  },
  beforeDestroy() {
    this.$bus.$off('toggle_sidebar_menu');    
  }    
}
</script>

<style scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
</style>
