<template>
  <div class="dropdown filter">
    <CDropdown inNav class="c-header-nav-items" placement="bottom-end" add-menu-classes="pt-0">
      <template #toggler>
        <!-- <CHeaderNavItem class="d-md-down-none"> -->
        <CHeaderNavItem>
          <CHeaderNavLink>
            <i class="fas fa-filter"/>
          </CHeaderNavLink>
        </CHeaderNavItem>
      </template>
      <CDropdownItem class="filter pb-0">    
        <div class="label w-100 mb-2">
          <span>{{$t('common.filter_on')}} {{$t('common.departments')}} {{$t('or')}} {{$t('common.teams')}}</span>
        </div>
        <div class="item w-100">
          <multiselect
            class="filter"
            v-model="filter.teams" 
            :options="departments" 
            :multiple="true"
            :group-select="true"
            group-values="teams" 
            group-label="department_name"            
            :placeholder="$t('select_a') + ' ' + $t('common.team')" 
            :selectLabel="$t('common.Add_team')" 
            :selectGroupLabel="$t('common.Add_department')"
            :selectedLabel="$t('Added')"
            :deselectLabel="$t('common.Remove_team')"
            :deselectGroupLabel="$t('common.Remove_department')"
            track-by="team_name" 
            label="team_name"
            @input="updateUserFilter()">
            <span slot="noResult">{{$t('common.no_teams_found')}}</span>
          </multiselect>
        </div>    
      </CDropdownItem>    
      <CDropdownItem class="filter pb-0">
        <div class="label w-100 mb-2">
          <span>{{$t('common.filter_on')}} {{$t('common.gender')}}</span>
        </div>
        <div class="item w-100">
          <CButtonGroup class="w-100" :vertical="buttonGroupVertical">
            <CButton color="outline-secondary" v-for="(value, name, key) in ['F','N','M']" :key="key" :pressed="value === filter.gender ? true : false" @click="filter.gender = value; updateUserFilter();">
              <i v-if="value === 'F' || value === 'N'" class="icon vertical m-0 fas fa-female"/>
              <i v-if="value === 'M' || value === 'N'" class="icon vertical m-0 fas fa-male"/>
            </CButton>
          </CButtonGroup>
        </div>
      </CDropdownItem>
      <CDropdownItem class="filter">
        <div class="label w-100 mb-2">
          <span>{{$t('common.filter_on')}} {{$t('common.age_category')}}</span>
        </div>
        <div class="item w-100">
          <CButtonGroup class="w-100" :vertical="buttonGroupVertical">
            <CButton color="outline-secondary" v-for="(value, key) in ['all-ages', 'below-25', '25-35', '35-45', '45-55', 'above-55']" :key="key" :pressed="value === filter.age_category ? true : false" @click="filter.age_category = value; updateUserFilter();">            
              <span v-if="value === 'all-ages'">{{$t('common.All_ages')}}</span>
              <span v-else-if="value === 'below-25'">{{$t('common.Below_25')}}</span>
              <span v-else-if="value === 'above-55'">{{$t('common.Above_55')}}</span>
              <span v-else>{{value}}</span>
            </CButton>
          </CButtonGroup>
        </div>
      </CDropdownItem>
    </CDropdown>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import axios from 'axios'

export default {
  name: 'TheHeaderFilters',
  components: {
    Multiselect
  },
  data () {
    return {
      filter: {
        age_category: 'all-ages',
        gender: 'N',
        teams: []
      },
      departments: [],
      buttonGroupVertical: false
    }
  },
  methods: {
    updateUserFilter() {
      // Save filter to localStorage
      localStorage.setItem('userFilter', JSON.stringify(this.filter));

      // Set gender as Axios default header
      if(this.filter.gender === 'F') {
        axios.defaults.headers.common['Gender'] = 'F';
      } else if(this.filter.gender === 'M') {
        axios.defaults.headers.common['Gender'] = 'M';
      } else {
        axios.defaults.headers.common['Gender'] = 'N';
      }

      // Set bounds of age category as Axios default headers
      if(this.filter.age_category === 'all-ages') {
        axios.defaults.headers.common['Min-Age'] = 0;
        axios.defaults.headers.common['Max-Age'] = 70;
      } else if(this.filter.age_category === 'below-25') {
        axios.defaults.headers.common['Min-Age'] = 0;
        axios.defaults.headers.common['Max-Age'] = 24;
      } else if(this.filter.age_category === '25-35') {
        axios.defaults.headers.common['Min-Age'] = 25;
        axios.defaults.headers.common['Max-Age'] = 34;
      } else if(this.filter.age_category === '35-45') {
        axios.defaults.headers.common['Min-Age'] = 35;
        axios.defaults.headers.common['Max-Age'] = 44;
      } else if(this.filter.age_category === '45-55') {
        axios.defaults.headers.common['Min-Age'] = 45;
        axios.defaults.headers.common['Max-Age'] = 54;
      } else if(this.filter.age_category === 'above-55') {
        axios.defaults.headers.common['Min-Age'] = 55;
        axios.defaults.headers.common['Max-Age'] = 70;
      }

      // Push ID's of multiselect teams to new array
      let selectedTeams = [];
      for(let i = 0; i < this.filter.teams.length; i++) {
        selectedTeams.push(this.filter.teams[i].team_id);
      }
      // Set new array as Axios default header
      axios.defaults.headers.common['Teams'] = JSON.stringify(selectedTeams);

      this.$bus.$emit('filter_updated');
    },
  },
  mounted: function() {
    // If available, set the filter according to localStorage data
    if(localStorage.getItem('userFilter') !== null) {
      this.filter = JSON.parse(localStorage.getItem('userFilter'));      
    }    

    // Update filter to set Axios headers on mount
    this.updateUserFilter();  

    axios.get(process.env.VUE_APP_API_URL + '/v1/core/organisation/departmentTeams/my')
      .then(res => {
        // Get management result from API response                
        var departmentTeams = res.data.data;

        // Empty departments array
        this.departments = [];        

        // Loop through management result and add unique departments to departments array
        var lookup = {};
        for (var departmentTeam, index = 0; departmentTeam = departmentTeams[index++];) {
          var departmentId = departmentTeam.department_id;

          if (!(departmentId in lookup)) {
            lookup[departmentId] = 1;
            this.departments.push({
              department_id: departmentTeam.department_id,
              department_name: departmentTeam.department_name,
              teams: []
            });
          }
        }

        for(var d = 0; d < this.departments.length; d++) {
          // Loop through management result
          for(var t = 0; t < departmentTeams.length; t++) {
            // Check if a management result is part of a department
            if(this.departments[d].department_id === departmentTeams[t].department_id) {
              // If so, add the management result data into the teams array of this department
              this.departments[d].teams.push({
                team_id: departmentTeams[t].team_id,
                team_name: departmentTeams[t].team_name,
              });
            }
          }
        }
      })
      .catch(err => {
        console.error(err); 
    });    
  }
}
</script>