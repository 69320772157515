<template>
  <CCard class="pointer navigation_card" @click="openSidebarRight('onboarding_package_details', { onboarding_package_id_external: packageData.onboarding_package_id_external });">
    <CCardBody>
      <div class="d-flex align-items-center">
        <div class="item_icon d-inline-block align-middle mr-2">
          <i class="fas fa-box"/>               
        </div>                        
        <div class="flex-grow-1">        
          <span><b>{{packageData.package_name}}</b></span>
        </div>
      </div>
    </CCardBody>
  </CCard>
</template>

<script>

export default {
  name: 'onboardingPackageCard',
  props: ['onboardingPackage'],
  watch: {
    $props: {
      handler() {
        if(this.onboardingPackage) this.packageData = this.onboardingPackage;
      },
      deep: true,
      immediate: true,
    }
  },
  data() {
    return {
      packageData: {}
    }
  }  
}
</script>
