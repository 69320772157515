<template>
  <span v-if="momentsAgo" v-line-clamp="1" class="moments_ago">
    {{ momentsAgo }} {{$t('ago')}}
  </span>
</template>

<script>
import Vue from "vue";
import moment from "moment";
Vue.prototype.moment = moment;

export default {
  name: "momentsAgo",
  props: ['date'],
  data() {
    return {
      epochs: ["year", "month", "day", "hour", "minute"],
      year: 31536000,
      month: 2592000,
      day: 86400,
      hour: 3600,
      minute: 60,
      humanReadable: "",
      humanDifference: 0,
      humanWord: "moment",
      momentsAgo: this.$t('A_few_moments')
    };
  },
  methods: {
    getPlural() {
      if(this.humanDifference === 0) {
        this.momentsAgo =  this.$t('A_few') + ' ' + this.$t(this.humanWord + 's');
      } else if (this.humanDifference > 1) {
        this.momentsAgo =  this.humanDifference + ' ' + this.$t(this.humanWord + 's');
      } else {
        this.momentsAgo =  this.humanDifference + " " + this.$t(this.humanWord);
      }
    },
    getSeconds(date) {
      let seconds = moment().diff(moment(date), "seconds");
      this.humanReadable = this.getDuration(seconds);

      if(this.humanReadable) {
        this.humanDifference = this.humanReadable.interval;
        this.humanWord = this.humanReadable.humanEpoch;
        this.getPlural();
      }
    },
    getDuration(seconds) {      
      let epoch, interval;
      let humanEpoch;
      for (let i = 0; i < this.epochs.length; i++) {
        epoch = this.epochs[i];
        humanEpoch = this.epochs[i];

        interval = Math.floor(seconds / this[epoch]);
        if(interval >= 1) {
          return { interval: interval, humanEpoch: humanEpoch };
        }
      }
    }
  },
  mounted() {
    setInterval(() => {
      this.getSeconds(this.date);
    }, 1000);
  }  
};
</script>