<template>
  <CCard class="socialwall mb-0" v-bind:class="{'preview' : preview === true}">
    <CCardBody class="pt-0 pb-0">

      <CRow v-if="postData.is_breaking_news" class="breaking_news">
        <CCol class="p-0 text-center">
          {{$t('common.BREAKING_NEWS')}} <i class="fas fa-exclamation-triangle"/>
        </CCol>
      </CRow>

      <CRow>
        <CCol class="meta" v-bind:class="{ 'pb-0' : ((postData.post_type_tag === 'article' || postData.post_type_tag === 'normal') && postData.post_uses_gallery === false && postData.image == null) ||
                                                    ((postData.post_type_tag === 'article' || postData.post_type_tag === 'normal') && postData.post_uses_gallery === true && postData.new_post_images.length == 0) }">                    

          <div v-if="postData.sender">
            <userProfilePopover ref="userProfilePopover"
                                mode="sender"
                                :userName="postData.sender.sender_alias"
                                :userProfileImage="postData.sender.profile_image_id + '.jpg'"
                                :userIdExternal="userData.user_id_external"
                                :senderIdExternal="postData.sender.deliver_sender_id_external"
                                :popoverPlacement="preview === true ? 'bottom' : 'right'"
                                class="user_image mr-2"/>           
          </div>
          <div v-else-if="userData">
            <userProfilePopover ref="userProfilePopover" mode="user" :userName="userData.name" :userProfileImage="userData.profile_image" :userIdExternal="userData.user_id_external" :senderIdExternal="null" :popoverPlacement="preview === true ? 'bottom' : 'right'" class="user_image mr-2"/>
          </div>
          
          <div class="posted_by pt-0" v-bind:class="{ 'pb-0' : postData.image == null }">
            <span class="d-block">
              <b>{{userData.name}}</b>
              <span v-if="postData.sender" class="ml-1">({{$t('connect.posted_as')}} {{postData.sender.sender_alias}})</span>
            </span>
            <span class="posted_in_at">
              <span class="d-flex align-items-center">
                <span class="material-icons mr-1">folder_open</span> {{postData.sw_group.sw_group_name}}
              </span>           
              <span class="d-flex align-items-center ml-1">
                <span class="material-icons mr-1">alarm_add</span>
                <span>{{$t('A_few_moments')}} {{$t('ago')}}</span>
              </span>
            </span>
          </div>
        </CCol>
      </CRow>

      <CRow>
        <div v-if="(postData.post_type_tag === 'article' || postData.post_type_tag === 'normal') && postData.post_uses_gallery === true && postData.new_post_images.length > 0" class="content_picture_column w-100">
          <CCol class="p-0">
            <div class="image_grid" v-bind:class="{'grid_1' : postData.new_post_images.length == 1, 'grid_2' : postData.new_post_images.length == 2, 'grid_3' : postData.new_post_images.length >= 3}">

              <img v-if="postData.new_post_images.length == 1" :src="postData.new_post_images[0].image_url" class="w-100 image_grid_background"/>
            
              <div v-for="(image, index) in postData.new_post_images.slice(0, maxGridImages)" v-bind:key="index" v-bind:style="{ backgroundImage: 'url(' + image.image_url + ')' }" @click="openImageLightbox(postData.new_post_images, index)" class="image_grid_item">
                <div v-if="index == maxGridImages - 1 && postData.new_post_images.length > 3" class="grid_more h-100 w-100">
                  <span>{{postData.new_post_images.length - maxGridImages}}+</span>
                </div>
              </div>
            </div>
            <CoolLightBox :items="lightboxImages" :index="lightboxActiveIndex" :slideshow="false" :enableScrollLock="true" @close="lightboxActiveIndex = null"></CoolLightBox>
          </CCol>
        </div>
        <div v-if="postData.post_type_tag === 'video'" class="content_video_column w-100">            
          <div v-if="videoData.new_post_videos.length > 0">
            <div v-for="(video, index) in videoData.new_post_videos.slice(0, 1)" v-bind:key="index">
              <vue-plyr ref="plyr_preview">
                <video controls crossorigin playsinline>
                  <source :src="video.video_url"/>
                </video>
              </vue-plyr>            
            </div>
          </div>
          <div v-else-if="postData.video_id">
            <youtube :video-id="postData.video_id" ref="youtube" :player-vars="playerVars" :fitParent="true" class="w-100"></youtube>            
          </div>
        </div>

        <div class="content_text_column w-100"
             v-bind:class="{'full_width' : ((postData.post_type_tag === 'article' || postData.post_type_tag === 'normal') && postData.post_uses_gallery === false && postData.image == null) ||
                                           ((postData.post_type_tag === 'article' || postData.post_type_tag === 'normal') && postData.post_uses_gallery === true && postData.new_post_images.length == 0) }">
                              
          <CCol v-if="(postData.post_type_tag === 'article' || postData.post_type_tag === 'normal' || postData.post_type_tag === 'video') && postData.post_content" class="content_text" v-bind:class="{'pb-0' : postData.post_content, 'p-0' : !postData.post_content}">
            <div v-if="showAllContent === true">
              <p class="m-0" v-bind:class="{'scrollable' : scrollableContent === true}" v-html="postData.post_content"></p>            
            </div>
            <div v-else>
              <truncate class="content mb-0" action-class="read_more_btn" collapsed-text-class="collapsed" :length="maxContentLength" type="html" :text="postData.post_content"></truncate>
              <div v-if="postData.post_content.length > maxContentLength" class="read_more_link mt-3">
                <a @click="showAllContent = true">{{ $t('sw.read_more') }}</a>
              </div>            
            </div>
          </CCol>
          
          <CCol v-if="postData.post_type_tag === 'vote' && postData.post_content" class="p-0 content_text social_vote">
            <div class="social_vote_content">
              <p class="m-0" v-html="postData.post_content"></p>
            </div>
          </CCol>

          <CCol v-if="postData.post_type_tag === 'vote'" class="p-3 pb-0 social_vote">
            <div class="d-flex align-items-center social_vote_buttons">
              <div class="vote_down">
                <div class="d-flex align-items-center justify-content-center">
                  <span class="material-icons mr-1">thumb_down</span>
                  <span>{{socialVoteResult.down_votes}}</span>
                </div>             
              </div>
              <div class="flex-grow-1 text-center social_vote_count">
                <b>{{(socialVoteResult.up_votes - socialVoteResult.down_votes) | abbr}}</b>
              </div>
              <div class="vote_up">
                <div class="d-flex align-items-center justify-content-center">
                  <span class="material-icons mr-1">thumb_up</span>
                  <span>{{socialVoteResult.up_votes}}</span>
                </div>  
              </div>
            </div>
          </CCol>

          <CCol v-if="modules.satisquestions === 1 && modules.social_wall_satisquestions == 1 && postData.add_satisquestion_to_answer && postQuestion" class="p-0">
            <swPostQuestion :satisquestionData="postQuestion"/>
          </CCol>          

          <CCol class="content_buttons pt-0 pb-0">
            <div v-if="modules.social_kb == 1 && postData.add_reference_to_kb_item && postData.intent_reference" class="mt-2">
              <CButton class="icon_button m-0 w-100 d-flex align-items-center" color="primary" @click="openItem(postData.intent_reference)">
                <i class="fa-solid fa-lines-leaning button_icon"/>              
                <span v-line-clamp="1" class="button_text">{{postData.intent_reference.kb_intent_title}}</span>
              </CButton>
            </div>
            <div v-if="modules.academy == 1 && postData.add_reference_to_lms_course && postData.course_reference" class="mt-2">
              <CButton class="icon_button m-0 w-100 d-flex align-items-center" color="primary" @click="openCourse(postData.course_reference)">
                <i class="fa-solid fa-user-graduate button_icon"/>
                <span v-line-clamp="1" class="button_text">{{postData.course_reference.course_name}}</span>
              </CButton>
            </div>
            <div v-if="modules.employee_loyalty_programme == 1 && postData.add_reference_to_loyalty_store_product && postData.product_reference" class="mt-2">
              <CButton class="icon_button m-0 w-100 d-flex align-items-center" color="primary" @click="openProduct(postData.product_reference)">
                <i class="fa-solid fa-gift button_icon"/>
                <span v-line-clamp="1" class="button_text">{{postData.product_reference.product_name}}</span>
              </CButton>
            </div>            
            <div v-if="postData.external_url" class="mt-2" @click="openExternalLink(postData.external_url)">
              <CButton class="icon_button m-0 w-100 d-flex align-items-center" color="primary">
                <i class="fa-solid fa-link button_icon"></i>
                <span v-line-clamp="1" class="button_text">{{postData.external_url_name !== null ? postData.external_url_name : $t('sw.external_url')}}</span>
              </CButton>
            </div>
            <div v-if="postData.new_pdf_attachments.length > 0" class="mt-2">
              <CButton class="icon_button m-0 w-100 d-flex align-items-center" color="primary" @click="openExternalLink(postData.new_pdf_attachments[0].pdf_url)">
                <i class="fa-solid fa-paperclip button_icon"></i>
                <span v-line-clamp="1" class="button_text">{{postData.pdf_attachment_name !== null ? postData.pdf_attachment_name : $t('sw.pdf_attachment')}}</span>
              </CButton>
            </div>
            <div v-if="postData.new_audio_attachments.length > 0" class="mt-2">
              <audioPlayer :standalone="false"
                           :audioFileName="postData.audio_attachment_name ? postData.audio_attachment_name : $t('common.Audio_file')"
                           :audioSourceUrl="postData.new_audio_attachments[0].audio_url">
              </audioPlayer>
            </div>
          </CCol>
        </div>
      </CRow>

      <CRow v-if="modules.social_wall_post_actions === 1" class="pt-0 activity_bar">
        <CCol cols="8" sm="8" md="8" lg="8" class="post_likes">
          <div class="mr-2 type likes">
            <span class="mr-1" v-html="getTwemoji('👍')"></span>
            <span>0</span>           
          </div>
          <div class="mr-2 type dislikes">
            <span class="mr-1" v-html="getTwemoji('👎')"></span>
            <span>0</span>        
          </div>
          <div class="mr-2 type waves">
            <span class="mr-1" v-html="getTwemoji('👏')"></span>
            <span>0</span>           
          </div>
          <div class="type hearts">
            <span class="mr-1" v-html="getTwemoji('💖')"></span>
            <span>0</span>
          </div>
        </CCol>
        <CCol cols="4" sm="4" md="4" lg="4" class="post_reactions text-right">          
          <span class="position-relative">
            <span class="material-icons d-inline-block align-middle">chat_bubble_outline</span>
            <i v-if="postData.disable_reactions === true" class="fas fa-slash"></i>    
            <span v-if="postData.disable_reactions === false" class="ml-1">0</span>
          </span>          
        </CCol>
      </CRow>
      <CRow v-if="modules.social_wall_post_actions == 0" class="p-2_5 activity_bar">
        <CCol cols="12" class="p-0 text-left">
          <div class="read_more_link">
            <a>{{$t('sw.read_more')}}</a>
          </div>   
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import axios from 'axios'
import truncate from 'vue-truncate-collapsed';
import userProfilePopover from '@/components/common/userProfilePopover.vue';
import audioPlayer from '@/components/common/audioPlayer.vue';

import swPostQuestion from "@/components/connect/swPostQuestion.vue";

export default {
  name: 'swPostPreviewCard',
  props: ['postData', 'videoData', 'modules', 'preview', 'scrollableContent'],
  components: {
    truncate,
    userProfilePopover,
    audioPlayer,
    swPostQuestion
  },
  watch: {
    $props: {
      handler() {      
        // Get the SatisQuestion data for post SatisQuestions
        if(this.postData.add_satisquestion_to_answer && this.postData.satisquestion_to_answer) {
          this.getPostQuestion(this.postData.satisquestion_to_answer.sq_question_id_external);
        }
      },
      deep: true,
      immediate: true,
    }
  },      
  data() {
    return {
      appBaseUrl: null,
      apiBaseUrl: null,
      clientToken: null,      
      companyIdExternal: null,
      companyImageId: null,
      userData: {},
      playerVars: { 
        autoplay: 0, 
        controls: 0,
        playsinline: 1
      },
      maxGridImages: 3,
      maxContentLength: 200,
      lightboxImages: [],
      lightboxActiveIndex: null,
      socialVoteResult: {
        up_votes: 0,
        down_votes: 0
      },
      postQuestion: null,
      showAllContent: false     
    } 
  },
  filters: {
    abbr: function(points) {
      if(String(points).length >= 7) {
        if(points % 1 != 0) {
          return (points/1000000).toFixed(1) + 'M';
        } else {
          return (points/1000000) + 'M';
        }
      } else if(String(points).length >= 4) {
        if(points % 1 != 0) {
          return (points/1000).toFixed(1) + 'K';
        } else {
          return (points/1000) + 'K';
        }        
      } else {
        return points;
      }
    }
  },  
  methods: {  
    openImageLightbox(images, index) {
      let lightboxImages = [];
      // Loop through the images
      for(var i = 0; i < images.length; i++) {
        lightboxImages.push(images[i].image_url);
      }
      // Set the images
      this.lightboxImages = lightboxImages;
      // Set the active index
      this.lightboxActiveIndex = index;
    },
    getTwemoji(emoji) {
      return twemoji.parse(emoji);
    },
    openItem(data) {
      window.open(this.appBaseUrl + '/knowledge/library/item/' + data.kb_intent_id_external, '_blank');            
    },
    openCourse(data) {
      window.open(this.appBaseUrl + '/learn/catalog/course/' + data.lms_course_id_external, '_blank');      
    },
    openProduct(data) {
      window.open(this.appBaseUrl + '/loyalty/store/product/' + data.loyalty_store_product_id_external, '_blank');      
    },    
    openExternalLink(url) {
      window.open(url, '_blank');
    },
    getPostQuestion(questionIdExternal) {
      axios.get(process.env.VUE_APP_API_URL + '/v1/connect/socialwall/post/satisquestion/' + questionIdExternal)
      .then(res => {
        this.postQuestion = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getUserData() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/user/me')
      .then(res => {
        this.userData = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    }    
  },
  mounted: function() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.appBaseUrl = process.env.VUE_APP_URL;
    this.clientToken = localStorage.getItem('token');    
    if(localStorage.getItem('companyIdExternal') !== null) this.companyIdExternal = localStorage.getItem('companyIdExternal');
    if(localStorage.getItem('companyImageId') !== null) this.companyImageId = localStorage.getItem('companyImageId');

    this.getUserData();
  }
}
</script>