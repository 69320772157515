<template>
  <div>
    <div class="sidebar_container">
      <div class="sidebar_header">
        <CRow class="m-0">
          <CCol cols="10" lg="10" md="10" sm="10" class="text-left">
            <span v-if="history.length > 1" class="pointer" @click="removeSidebarHistoryStep()">
              <i class="fas fa-arrow-circle-left mr-1"/>
            </span>            
            <span class="sidebar_subject">
							{{!message.signage_ticker_message_id_external ? $t('signage.New_ticker_message') : $t('signage.Edit_ticker_message')}}
            </span>
          </CCol>
          <CCol cols="2" lg="2" md="2" sm="2" class="text-right">
            <span @click="closeSidebarRight();"><i class="fas fa-times"/></span>        
          </CCol>
        </CRow>
      </div>
      <div class="sidebar_content">
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0 pb-0">                          
            <CTextarea @input="countdown()" rows="3" :maxlength="maxCount" :label="$t('signage.Ticker_message')" class="mb-0" v-model="message.ticker_message" required was-validated/>
            <p class="countdown mt-1 mb-0" v-if="remainingCount > 0">{{remainingCount}} {{ $t('common.characters_remaining') }}</p>              
          </CCol>
        </CRow>
      </div>
      <div class="sidebar_footer">
        <CRow class="m-0">
          <CCol cols="12" lg="12">
            <div v-if="!message.signage_ticker_message_id_external">
              <CButton color="primary" @click="insertTickerMessage(message);"><i class="fas fa-check mr-1"/>{{$t('Save')}}</CButton>
              <CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{$t('Dismiss')}}</CButton>
            </div>
            <div v-else>
              <CButton color="primary" @click="updateTickerMessage(message);"><i class="fas fa-check mr-1"/>{{$t('Update')}}</CButton>
              <CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{$t('Dismiss')}}</CButton>
            </div>  
          </CCol>
        </CRow>
      </div>
    </div>
  </div>  
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import QuickEdit from 'vue-quick-edit';

export default {
  name: 'tickerMessage',
  props: ['history', 'tickerMessageIdExternal'],
  components: {
    Multiselect,
    QuickEdit,
  },
  watch: {
    $props: {
      handler() {
        this.getTickerMessageDetails  ();
      },
      deep: true,
      immediate: true,
    }
  }, 
  data() {
    return {
			message: {},
      maxCount: 150,
      remainingCount: 150      
    }
  },
  methods: {
    getTickerMessageDetails() {
      if(this.tickerMessageIdExternal !== null) {
          axios.get(process.env.VUE_APP_API_URL + '/v1/connect/signage/ticker/message/' + this.tickerMessageIdExternal)
          .then(res => {
            this.message = res.data.data;
            this.countdown();
          })
          .catch(err => {
            console.error(err); 
          });   
      } else {
        // Reset the message
        this.resetMessageData();
        this.countdown();
      }
    },    
    insertTickerMessage(data) {
      let params = {};
      params = data;

      let ticker_message = params.ticker_message;    

      if(ticker_message) {
        axios.post(process.env.VUE_APP_API_URL + '/v1/connect/signage/ticker/message', params)
        .then(res => {
          // Show a success notice
          this.$buefy.toast.open({ message: this.$t('signage.Ticker_message_added'), type: 'is-success', duration: 2000 });
          // Emit the update_ticker_messages event
          this.$bus.$emit('update_ticker_messages');        
          // Reset the message data
          this.resetMessageData();
          // Close the sidebar
          this.closeSidebarRight();          
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }
    },    
    updateTickerMessage(data) {
      let params = {}
      params = data;
      (params.is_active === true) ? params.active = 'Y' : params.active = 'N';
      
      axios.put(process.env.VUE_APP_API_URL + '/v1/connect/signage/ticker/message/' + this.tickerMessageIdExternal, params)
      .then(res => {
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('signage.Ticker_message_updated'), type: 'is-success', duration: 2000 });
        // Emit the update_ticker_message_details event
        this.$bus.$emit('update_ticker_message_details', this.tickerMessageIdExternal);        
        // Reset the message data
        this.resetMessageData();
        // Close the sidebar
        this.closeSidebarRight();              
      })
      .catch(err => {
        console.error(err); 
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      })
    },    
    resetMessageData() {
      this.message = { ticker_message: '' }
    },
    countdown() {
      this.remainingCount = this.maxCount - this.message.ticker_message.length;
    }      				
  }
}
</script>