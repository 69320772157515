<template>
  <div>
    <div class="sidebar_container">
      <div class="sidebar_header">
        <CRow class="m-0">
          <CCol cols="10" lg="10" md="10" sm="10" class="pb-0 text-left">
            <span v-if="history.length > 1" class="pointer" @click="removeSidebarHistoryStep()">
              <i class="fas fa-arrow-circle-left mr-1"/>
            </span>            
            <span class="sidebar_subject">
							{{$t('common.Preview')}}
            </span>
          </CCol>
          <CCol cols="2" lg="2" md="2" sm="2" class="pb-0 text-right">
            <span @click="closeSidebarRight();"><i class="fas fa-times"/></span>        
          </CCol>
        </CRow>
      </div>
      <div class="sidebar_content socialwall">
        <CRow v-if="post" class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <swPostCard :postData="post"
                        :modules="modules"
                        :platformPermissions="platformPermissions"
                        :navigatable="true"
                        :editable="true"
                        :previewable="false"
                        :preview="true"
                        :showReplies="true"
                        :showInsights="true"
                        :scrollableContent="true"
                        :openedInExp="true">
            </swPostCard>
          </CCol>           
        </CRow>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import swPostCard from '@/components/connect/swPostCard.vue';

export default {
  name: 'swPostPreview',
  props: ['history', 'postIdExternal'],
  components: {
    swPostCard
  },
  watch: {
    $props: {
      handler() {
        this.checkModules();
        this.getPostDetails();
      },
      deep: true,
      immediate: true,
    }
  },   
  data() {
    return {
      modules: {
        social_wall: 0,
        academy: 0,
        social_kb: 0,
        employee_loyalty_programme: 0
      },
      platformPermissions: [],    
			post: null
    }
  },
  methods: {
    getPostDetails() {       
      axios.get(process.env.VUE_APP_API_URL + '/v1/connect/socialwall/post/' + this.postIdExternal)
      .then(res => {
        this.post = res.data.data;       
      })
      .catch(err => {
        console.error(err); 
      });   
    },
    checkModules() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/modules')
      .then(res => {      
        this.modules = res.data.data;    
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }     
  },
  mounted: function() {
    this.getPlatformPermissions();

    this.$bus.$on('sw_reply_posted', (postIdExternal) => {      
      this.getPostDetails();
    })

    this.$bus.$on('sw_reply_deleted', (postIdExternal) => {      
      this.getPostDetails();
    })
  },
  beforeDestroy() {
    this.$bus.$off('sw_reply_posted');
    this.$bus.$off('sw_reply_deleted');
  }
}
</script>