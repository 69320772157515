<template>
  <div class="dropdown actions">
    <CDropdown inNav class="c-header-nav-items" placement="bottom-end" add-menu-classes="pt-0">
      <template #toggler>
        <CHeaderNavItem>
          <CHeaderNavLink>
            <span class="material-icons">control_point</span>
          </CHeaderNavLink>
        </CHeaderNavItem>
      </template>
      <CDropdownItem>
        <CRow>  
          <CCol v-if="checkPermission('connect.sw.neweditpost') && modules.social_wall == 1" cols="4" lg="4">
            <div class="d-flex flex-column align-items-center action"
                 @click="openSidebarRight('sw_post_details', { post_id_external:  null, schedule_for_datetime: null, back_to_preview: false });">
              <i class="fas fa-calendar-week"/>
              <span class="mt-2 text-center">{{$t('actions.New_connect_post')}}</span>
            </div>
          </CCol>
          <CCol v-if="checkPermission('connect.deliver.addeditcampaign') && modules.social_wall == 1" cols="4" lg="4">
            <div class="d-flex flex-column align-items-center action"
                 @click="showNewCampaign();">
              <i class="fas fa-mail-bulk"/>
              <span class="mt-2 text-center">{{$t('actions.New_email_campaign')}}</span>
            </div>
          </CCol>                    
          <CCol v-if="checkPermission('measure.bm.addedit') && modules.satisquestions == 1 && modules.burning_matters == 1" cols="4" lg="4">
            <div class="d-flex flex-column align-items-center action"
                 @click="openSidebarRight('burning_matter_details', { bm_question_id_external:  null });">
              <i class="fa-solid fa-fire"></i>
              <span class="mt-2 text-center">{{$t('actions.New_burning_matter')}}</span>
            </div>
          </CCol>
          <CCol v-if="checkPermission('knowledge.library.additem') && modules.social_kb == 1" cols="4" lg="4">
            <div class="d-flex flex-column align-items-center action"
                 @click="openSidebarRight('knowledge_item_details', { kb_intent_subcategory: null, kb_intent_id_external: null, back_to_preview: false });">
              <i class="fas fa-book"/>
              <span class="mt-2 text-center">{{$t('actions.New_knowledge_item')}}</span>
            </div>
          </CCol>         
          <CCol v-if="checkPermission('learn.assigncourses') && modules.academy == 1" cols="4" lg="4">
            <div class="d-flex flex-column align-items-center action"
                 @click="openSidebarRight('assign_courses', { lms_course:  null });">
              <i class="fas fa-book-medical"/>
              <span class="mt-2 text-center">{{$t('actions.Assign_courses')}}</span>
            </div>
          </CCol>
          <CCol v-if="checkPermission('loyalty.moments.templates') && checkPermission('loyalty.moments.send') && modules.employee_loyalty_programme == 1 && modules.loyalty_moments == 1" cols="4" lg="4">
            <div class="d-flex flex-column align-items-center action"
                 @click="showMomentsSendPage();">
              <i class="fa-solid fa-envelope-open-text"/>
              <span class="mt-2 text-center">{{$t('actions.Send_loyalty_moment')}}</span>
            </div>
          </CCol>          
          <CCol v-if="checkPermission('core.targetgroups.add')" cols="4" lg="4">
            <div class="d-flex flex-column align-items-center action"
                 @click="openSidebarRight('target_group', { group_id_external: null });">
              <i class="fas fa-users-cog"/>
              <span class="mt-2 text-center">{{$t('actions.New_target_group')}}</span>
            </div>
          </CCol>
          <CCol v-if="checkPermission('core.organization.employees')" cols="4" lg="4">
            <div class="d-flex flex-column align-items-center action"
                 @click="openSidebarRight('user', { user_id_external: null, team: null});">
              <i class="fa-solid fa-user-plus"></i>
              <span class="mt-2 text-center">{{$t('actions.New_user')}}</span>
            </div>
          </CCol>        
        </CRow>
      </CDropdownItem>
    </CDropdown>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'TheHeaderActions',
  data () {
    return {
      modules: {},
      platformPermissions: [],
    }
  },
  methods: {
    showNewCampaign() {
      this.$router.push({path: '/connect/deliver/campaign/new'});
    },
    showMomentsSendPage() {
      this.$router.push({path: '/loyalty/moments/send'});
    },   
    checkModules() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/modules')
      .then(res => {      
        this.modules = res.data.data;      
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }
  },
  created: function() {
    this.checkModules();
    this.getPlatformPermissions();
  }
}
</script>