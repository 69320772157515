<template>
  <div>
    <div class="sidebar_container">
      <div class="sidebar_header">
        <CRow class="m-0">
          <CCol cols="10" lg="10" md="10" sm="10" class="text-left">
            <span v-if="history.length > 1" class="pointer" @click="removeSidebarHistoryStep()">
              <i class="fas fa-arrow-circle-left mr-1"/>
            </span>            
            <span class="sidebar_subject">
              {{!topic.topic_id_external ? $t('topics.New_topic') : $t('topics.Edit_topic')}}          
            </span>
          </CCol>
          <CCol cols="2" lg="2" md="2" sm="2" class="text-right">
            <span @click="closeSidebarRight();"><i class="fas fa-times"/></span>        
          </CCol>
        </CRow>
      </div>
      <div class="sidebar_content">
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <quick-edit type="text" mode="ignore" :buttonOkText="$t('Save')" :buttonCancelText="$t('Dismiss')" v-model="topic.title">                        
              <h2 class="m-0">{{topic.title ? topic.title : $t('common.Click_to_set_name')}}<i class="icon edit fas fa-pen ml-1"/></h2>
            </quick-edit>            
          </CCol>
        </CRow>
        <CRow class="m-0">           
          <CCol cols="12" lg="12" class="pt-0">
            <quick-edit type="textarea" rows="5" mode="ignore" :buttonOkText="$t('Save')" :buttonCancelText="$t('Dismiss')" v-model="topic.description">
              <span>{{topic.description ? topic.description : $t('common.Click_to_set_description')}}<i class="icon edit fas fa-pen ml-1"/></span>
            </quick-edit>             
          </CCol>            
        </CRow>
        <CRow class="m-0">           
          <CCol cols="12" lg="12" class="pt-0">
            <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="topic.is_active" size="is-small">{{ $t('is_active') }}</b-switch>            
          </CCol>            
        </CRow>              
      </div>
      <div class="sidebar_footer">
        <CRow class="m-0">
          <CCol cols="12" lg="12">
            <div v-if="!topic.topic_id_external">
              <CButton color="primary" @click="insertTopic();"><i class="fas fa-check mr-1"/>{{$t('Save')}}</CButton>
              <CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{ $t('Dismiss') }}</CButton>
            </div>
            <div v-else>
              <CButton color="primary" @click="updateTopic(topic);"><i class="fas fa-check mr-1"/>{{$t('Update')}}</CButton>
              <CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{ $t('Dismiss') }}</CButton>
            </div>      
          </CCol>
        </CRow>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import QuickEdit from 'vue-quick-edit';

export default {
  name: 'topicDetails',
  props: ['history', 'topicIdExternal'],
  components: {
    QuickEdit,
  },
  watch: {
    $props: {
      handler() {
        this.getTopicDetails();
      },
      deep: true,
      immediate: true,
    }
  }, 
  data() {
    return {
      topic: {},
    }
  },
  methods: {
    getTopicDetails() {
      if(this.topicIdExternal !== null) {
        axios.get(process.env.VUE_APP_API_URL + '/v1/connect/topic/' + this.topicIdExternal)
        .then(res => {
          this.topic = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });                  
      } else {
        // Reset the topic data
        this.resetTopicData();
      }
    },
    insertTopic () {
      let params = {};
      params = this.topic;
      (params.is_active === true) ? params.active = 'Y' : params.active = 'N';

      let title = params.title;    

      if(title) {
        axios.post(process.env.VUE_APP_API_URL + '/v1/connect/topic', params)
        .then(res => {
          // Show a success notice
          this.$buefy.toast.open({ message: this.$t('topics.Topic_added'), type: 'is-success', duration: 2000 });
          // Emit the update_topics event
          this.$bus.$emit('update_topics');
          // Reset the group data
          this.resetTopicData();
          // Close the sidebar
          this.closeSidebarRight();	
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }
    },    
    updateTopic(data) {
      let params = {}
      params = data;
      (params.is_active === true) ? params.active = 'Y' : params.active = 'N';

      let title = params.title;      

      if(title) {
        axios.put(process.env.VUE_APP_API_URL + '/v1/connect/topic/' + this.topicIdExternal, params)
        .then(res => {
          // Show a success notice
          this.$buefy.toast.open({ message: this.$t('topics.Topic_updated'), type: 'is-success', duration: 2000 });
          // Emit the update_topic_details event
          this.$bus.$emit('update_topic_details', this.topicIdExternal);
          // Reset the group data
          this.resetTopicData();          
          // Close the sidebar
          this.closeSidebarRight();                   
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }            
    },
    resetTopicData () {
      this.topic = {
        title: '',
        description: ''
      }
    }
  }
}
</script>