<template>
  <div>
    <div class="sidebar_container">
      <div class="sidebar_header">
        <CRow class="m-0">
          <CCol cols="10" lg="10" md="10" sm="10" class="text-left">
            <span v-if="history.length > 1" class="pointer" @click="removeSidebarHistoryStep()">
              <i class="fas fa-arrow-circle-left mr-1"/>
            </span>            
            <span class="sidebar_subject">
              {{team.team_id === null || team.team_id === undefined ? $t('organisation.New_team') : $t('organisation.Edit_team')}}
            </span>
          </CCol>
          <CCol cols="2" lg="2" md="2" sm="2" class="text-right">
            <span @click="closeSidebarRight();"><i class="fas fa-times"/></span>    
          </CCol>      
        </CRow>
      </div>
      <div class="sidebar_content">
        <CRow class="m-0">
          <CCol cols="6" lg="6" class="pt-0">
            <CSelect :label="$t('common.Department')" class="mb-0" :value.sync="team.department_id" :options="departments" :placeholder="$t('select_a') + $t('common.department')" required was-validated disabled/>
          </CCol>                     
          <CCol cols="6" lg="6" class="pt-0">
            <CInput type="text" :label="$t('organisation.Team_name')" class="mb-0" v-model="team.team_name" required was-validated/>
          </CCol>         
        </CRow>

        <CRow class="m-0">  
          <CCol cols="12" lg="12" class="pt-0">          
            <label>{{$t('organisation.Team_managers')}}</label>
            <multiselect
              class="data_table"
              v-model="team.team_managers"                       
              label="label" 
              track-by="user_id_external" 
              :placeholder="$t('Search_for_a') + ' ' + $t('common.manager')"
              open-direction="bottom" 
              :options="users" 
              :multiple="true" 
              :searchable="true" 
              :loading="false" 
              :internal-search="false"
              :clear-on-select="true"
              :close-on-select="true"
              :options-limit="300"
              :max-height="600"
              :show-no-results="true"
              :hide-selected="false"
              @search-change="asyncFindUser">
              <span slot="noResult">{{ $t('common.no_users_found') }}</span>
              <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>                
            </multiselect>
          </CCol>
        </CRow>
      </div>
      <div class="sidebar_footer">
        <CRow class="m-0">
          <CCol cols="12" lg="12">
            <div v-if="team.team_id === null || team.team_id === undefined">
              <CButton color="primary" @click="insertTeam();"><i class="fas fa-check mr-1"/>{{ $t('Save') }}</CButton>
              <CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{ $t('Dismiss') }}</CButton>
            </div>
            <div v-else>
              <CButton color="primary" @click="updateTeam(team.team_id, team);"><i class="fas fa-check mr-1"/>{{ $t('Update') }}</CButton>
              <CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{ $t('Dismiss') }}</CButton>
            </div>        
          </CCol>
        </CRow>    
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import QuickEdit from 'vue-quick-edit';

export default {
  name: 'teamDetailsPane',
  props: ['history', 'teamId', 'departmentId', 'departmentName'],
  components: {
    Multiselect,
    QuickEdit,
  },
  watch: {
    $props: {
      handler() {
        this.getTeamDetails();
      },
      deep: true,
      immediate: true,
    }
  },
  data() {
    return {
      team: {},
      departments: [],
      users: []
    }
  },
  methods: {
    getTeamDetails() {
      if(this.teamId !== null) {
        axios.get(process.env.VUE_APP_API_URL + '/v1/core/organisation/team/' + this.teamId)
        .then(res => {
          this.team = res.data.data;
          // Reset the users
          this.resetUsersData();
        })
        .catch(err => {
          console.error(err); 
        });
      } else {
        // Reset the team
        this.resetTeamData();
        // Reset the users
        this.resetUsersData();
      }
    },
    insertTeam () {
      let params = {};
      params.teamData = this.team;
      
      let team_name = params.teamData.team_name;

      if(team_name) {
        axios.post(process.env.VUE_APP_API_URL + '/v1/core/organisation/team', params)
        .then(res => {
          // Show a success notice
          this.$buefy.toast.open({ message: this.$t('organisation.Team_added'), type: 'is-success', duration: 3000 });
          // Emit the update_department_teams event
          if(this.team && this.team.department_id && this.team.department_name) {           
            this.$bus.$emit('update_department_teams', this.team);
          }
          // Reset the team
          this.resetTeamData();
          // Reset the users
          this.resetUsersData();          
          // Close the sidebar
          this.closeSidebarRight();
        })
        .catch(err => {
          // Show correct error message according to error
          if(err.response.data.error === 'Team already exists') {          
            this.$buefy.toast.open({ message: this.$t('organisation.Team_already_exists'), type: 'is-danger', duration: 2000 });
          } else {
            this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
          }
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }
    },    
    updateTeam(teamId, data) {
      let params = {}
      params.teamData = data;     
            
      axios.put(process.env.VUE_APP_API_URL + '/v1/core/organisation/team/' + teamId, params)
      .then(res => {
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('organisation.Team_updated'), type: 'is-success', duration: 2000 });
        // Emit the update_department_teams event
        if(this.team && this.team.department_id && this.team.department_name) {           
          this.$bus.$emit('update_department_teams', this.team);
        }
        // Reset the users
        this.resetUsersData();
        // Close the sidebar
        this.closeSidebarRight();          
      })
      .catch(err => {
        // Show correct error message according to error
        if(err.response.data.error === 'Team already exists') {          
          this.$buefy.toast.open({ message: this.$t('organisation.Team_already_exists'), type: 'is-danger', duration: 2000 });
        } else {
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        }
      })
    },
    getDepartments() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/organisation/departments/select')
      .then(res => {
        this.departments = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    asyncFindUser (query) {
      let searchTerm = query;
      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/users/' + searchTerm)
        .then(res => {      
          this.users = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }    
    },
    resetTeamData() {
      this.team = {
        department_id: this.departmentId,
        department_name: this.departmentName,
        team_name: null,
        team_managers: []
      };
      // Force update
      this.$forceUpdate();
    },    
    resetUsersData() {
      this.users = [];
    }    
  },
  mounted: function(){
    this.getDepartments();   
  }
}
</script>