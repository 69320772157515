<template>
  <div>
    <div class="sidebar_container">
      <div class="sidebar_header">
        <CRow class="m-0">
          <CCol cols="10" lg="10" md="10" sm="10" class="text-left">
            <span v-if="history.length > 1" class="pointer" @click="removeSidebarHistoryStep()">
              <i class="fas fa-arrow-circle-left mr-1"/>
            </span>            
            <span class="sidebar_subject">
							{{$t('points.Grant_points_to_user')}}
            </span>
          </CCol>
          <CCol cols="2" lg="2" md="2" sm="2" class="text-right">
            <span @click="closeSidebarRight();"><i class="fas fa-times"/></span>        
          </CCol>
        </CRow>
      </div>
      <div class="sidebar_content">
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <label><b>{{ $t('common.Specify_employees') }}</b></label>
            <CRow v-for="(filter, index) in newPoints.filters" v-bind:key="index">
              <CCol cols="12" lg="12" class="pt-0 pb-0">
                <div class="mb-2 d-flex filters">
                  <div>
                    <multiselect class="data_table open_absolute"
                                 v-model="filter.type" 
                                 :options="groupFilterTypes" 
                                 :multiple="false"                
                                 :placeholder="$t('common.Select_filter_type')" 
                                 :selectLabel="$t('common.Add_filter_type')" 
                                 :selectedLabel="$t('Added')"
                                 :deselectLabel="$t('common.Remove_filter_type')"
                                 track-by="type_tag" 
                                 label="type_name"
                                 :custom-label="customFilterTypeLabel"
                                 :disabled="filter.type !== null && filter.type !== undefined"
                                 @input="getTypeFilterConditions(filter.type.type_tag);">                      
                      <span slot="noResult">{{ $t('common.no_types_found') }}</span>
                      <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                    </multiselect>
                  </div>

                  <div v-if="filter.type" class="ml-2" v-bind:class="{'mr-2' : filter.condition}">
                    <multiselect class="data_table open_absolute"
                                  v-model="filter.condition" 
                                  :options="typeFilterConditions" 
                                  :multiple="false"                
                                  :placeholder="$t('common.Select_filter_condition')" 
                                  :selectLabel="$t('common.Add_filter_condition')" 
                                  :selectedLabel="$t('Added')"
                                  :deselectLabel="$t('common.Remove_filter_condition')"
                                  track-by="condition_tag" 
                                  label="condition_name"
                                  :custom-label="customFilterConditionLabel"
                                  :disabled="filter.condition !== null && filter.condition !== undefined">
                      <span slot="noResult">{{ $t('common.no_conditions_found') }}</span>
                      <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                    </multiselect>
                  </div>

                  <div v-if="filter.type && filter.condition">
                    <div v-if="filter.type.type_tag == 'department'">
                      <multiselect class="data_table open_absolute"
                                   v-model="filter.value" 
                                   :options="groupFilterDepartments" 
                                   :multiple="false"                
                                   :placeholder="$t('common.Select_department')"
                                   :selectLabel="$t('common.Add_department')" 
                                   :selectedLabel="$t('Added')"
                                   :deselectLabel="$t('common.Remove_department')"
                                   track-by="department_id" 
                                   label="department_name"
                                   @search-change="asyncFindDepartment"
                                   @input="getGroupMembers();">
                        <span slot="noResult">{{ $t('common.no_departments_found') }}</span>
                        <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                      </multiselect>
                    </div>
                    <div v-if="filter.type.type_tag == 'team'">
                      <multiselect class="data_table open_absolute"
                                   v-model="filter.value" 
                                   :options="groupFilterTeams" 
                                   :multiple="false"                
                                   :placeholder="$t('common.Select_team')" 
                                   :selectLabel="$t('common.Add_team')" 
                                   :selectedLabel="$t('Added')"
                                   :deselectLabel="$t('common.Remove_team')"
                                   track-by="team_id" 
                                   label="team_name"
                                   @search-change="asyncFindTeam"
                                   @input="getGroupMembers();">
                        <span slot="noResult">{{ $t('common.no_teams_found') }}</span>
                        <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                      </multiselect>
                    </div>
                    <div v-if="filter.type.type_tag == 'user'">
                      <multiselect class="data_table open_absolute"
                                   v-model="filter.value" 
                                   :options="groupFilterUsers" 
                                   :multiple="false"                
                                   :placeholder="$t('common.Select_user')" 
                                   :selectLabel="$t('common.Add_user')" 
                                   :selectedLabel="$t('Added')"
                                   :deselectLabel="$t('common.Remove_user')"
                                   track-by="user_id_external" 
                                   label="label"
                                   @search-change="asyncFindUser"
                                   @input="getGroupMembers();">
                        <span slot="noResult">{{ $t('common.no_users_found') }}</span>
                        <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                      </multiselect>  
                    </div>
                    <div v-if="filter.type.type_tag == 'target_group'">
                      <multiselect class="data_table open_absolute"
                                   v-model="filter.value" 
                                   :options="groupFilterTargetGroups" 
                                   :multiple="false"                
                                   :placeholder="$t('common.Select_group')" 
                                   :selectLabel="$t('common.Add_group')" 
                                   :selectedLabel="$t('Added')"
                                   :deselectLabel="$t('common.Remove_group')"
                                   track-by="group_id" 
                                   label="title"
                                   @search-change="asyncFindTargetGroup"
                                   @input="getGroupMembers();">
                        <span slot="noResult">{{ $t('common.no_groups_found') }}</span>
                        <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                      </multiselect>  
                    </div>
                    <div v-if="filter.type.type_tag == 'date_started' || filter.type.type_tag == 'date_left'">
                      <multiselect class="data_table open_absolute"
                                   v-model="filter.value" 
                                   :options="groupFilterValues" 
                                   :multiple="false"                
                                   :placeholder="$t('common.Select_filter_value')" 
                                   :selectLabel="$t('common.Add_filter_value')" 
                                   :selectedLabel="$t('Added')"
                                   :deselectLabel="$t('common.Remove_filter_value')"
                                   track-by="type_value_tag" 
                                   label="type_value_name"
                                   :custom-label="customFilterTypeValueLabel"
                                   @input="getGroupMembers();">
                        <span slot="noResult">{{ $t('common.no_values_found') }}</span>
                      </multiselect>   
                    </div>                  
                    <div v-if="userAttributes.includes(filter.type.type_tag)">
                      <CInput v-debounce:1s="getGroupMembers"
                              type="text" 
                              class="attribute mb-0"
                              v-model="filter.value">
                      </CInput>
                    </div>
                  </div>

                  <CButton class="ml-2 mr-0 d-inline-block" color="primary" @click="removeFilterOption(index);">
                    <i class="fas fa-times"/>
                  </CButton>
                </div>
              </CCol>
            </CRow>
            <CRow>
              <CCol cols="12" lg="12" class="pt-0">
                <CButton color="primary" @click="addFilterOption();">
                  <i class="fas fa-plus mr-1"/>
                  <span>{{ $t('common.Add_filter') }}</span>
                </CButton>
              </CCol>
            </CRow>
            <CRow>
              <CCol cols="12" lg="12" class="pt-0">
                <label><b>{{$t('points.Amount')}}</b></label>
                <CInput type="number" step="5" v-model="newPoints.amount" class="mb-0" required was-validated/>
              </CCol>
            </CRow>
            <CRow>
              <CCol cols="12" lg="12" class="pt-0">
                <b-switch class="mb-0 mt-1 mt-xl-0" v-model="newPoints.award_to_team" size="is-small">{{$t('points.Grant_points_to_teams')}}</b-switch>
              </CCol>
            </CRow>
            <CRow>           
              <CCol cols="12" lg="12" class="pt-0 pb-0">
                <label><b>{{$t('points.Message_to_users')}}</b></label>
                <CTextarea @input="countdown()" class="mb-0" rows="2" :maxlength="maxCount.new_points_description" v-model="newPoints.description"/>
                <p class="countdown mt-1 mb-0" v-if="remainingCount.new_points_description > 0">{{remainingCount.new_points_description}} {{ $t('points.characters_remaining') }}</p>
              </CCol>
            </CRow>

            <CRow v-if="modules.social_wall == 1" class="socialwall">
              <CCol cols="12" lg="12" class="pb-0">
                <label><b>{{$t('sw.Social_wall')}}</b></label>
                <div>
                  <b-switch class="mb-0 mt-1 mt-xl-0" v-model="newPoints.addToSocialWall" size="is-small">{{$t('points.post_on_sw')}}</b-switch>
                </div>
                <CRow v-if="newPoints.addToSocialWall === true" class="mt-2">
                  <CCol cols="12" lg="12" class="pt-0">
                    <label><b>{{$t('sw.select_groups')}}</b></label>
                    <multiselect
                      class="data_table"
                      v-model="newPostData.sw_groups" 
                      :options="swGroups" 
                      :multiple="true"
                      :close-on-select="false"
                      :placeholder="$t('common.search_group')" 
                      :selectLabel="$t('common.Add_group')" 
                      :selectedLabel="$t('Added')"
                      :deselectLabel="$t('Remove')"
                      track-by="sw_group_name" 
                      label="sw_group_name">
                      <span slot="noResult">{{$t('sw.no_groups_found')}}</span>
                    </multiselect>
                  </CCol>
                  <CCol cols="12" lg="12" class="pt-0">
                    <label><b>{{$t('sw.message')}}</b></label>
                    <div class="wysiwyg">
                      <ckeditor @input="countdown()" :editor="editor" v-model="newPostData.content" :config="editorConfig"></ckeditor>
                      <p v-if="remainingCount.new_post_content > 0" class="countdown mt-1 mb-0">{{remainingCount.new_post_content}} {{ $t('points.characters_remaining') }}</p>
                      <p v-if="remainingCount.new_post_content < 0" class="countdown warning mt-1 mb-0">{{newPostData.content.length - maxCount.new_post_content}} {{ $t('points.characters_too_much') }}</p>                    
                    </div>  
                  </CCol>
                  <CCol cols="6" lg="6" class="pt-0 pb-0">
                    <label><b>{{$t('sw.upload_image')}}</b></label>
                    <div class="images_preview" v-bind:class="{ 'mb-3' : newPostData.new_post_images.length > 0 }">
                      <div v-for="(image, index) in newPostData.new_post_images" v-bind:key="index" class="image mr-2">
                        <img :src="image.image_url">
                        <i class="fas fa-times" @click="removePostImage(index)"/>
                      </div>
                    </div>
                    <div>
                      <input id="imageUpload" multiple type="file" accept="image/*" :value="newPostData.uploaded_post_images" @change="onPostImagesChange" hidden>
                      <CButton color="primary" @click="pickPostImages();"><i class="fas fa-image mr-1"/>{{$t('sw.upload_pictures')}}</CButton>
                    </div>
                  </CCol>
                </CRow>                
              </CCol>        
            </CRow>

          </CCol>
        </CRow>
        <hr class="m-0">
        <div class="group_members">
          <CRow class="m-0">
            <CCol cols="6" lg="6" class="text-left" v-bind:class="{'pb-0' : !listViewEnabled}">
              <span class="sidebar_subject">
                {{ $t("common.Employees") }} <span v-if="newPoints.users.length > 0">({{newPoints.users.length}})</span>
              </span>
            </CCol>
            <CCol cols="6" lg="6" class="text-right" v-bind:class="{'pb-0' : !listViewEnabled}">
              <b-switch class="mb-0 mt-1 mt-xl-0 enable_search" v-model="listViewEnabled" size="is-small">{{ $t('common.activate_list_view') }}</b-switch>
            </CCol>
          </CRow>          
          <CRow>
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <div v-if="listViewEnabled">
                <CRow>
                  <CCol cols="12" sm="12" md="12" lg="12" class="pt-0" v-bind:class="{ 'pb-0' : listViewEnabled === true }">
                    <b-table ref="groupTable" class="data_table" :data="newPoints.users" :striped="true" :hoverable="true" :mobile-cards="true" :paginated="isPaginated" :per-page="perPage" :current-page.sync="currentPage" :pagination-simple="isPaginationSimple" :pagination-position="paginationPosition">
                      <template slot-scope="props">
                        <b-table-column field="name" :label="$t('common.Name')">
                          <div @click="openSidebarRight('user_profile', { user_id_external:  props.row.user_id_external });" class="pointer">
                            <div v-if="props.row.profile_image" class="profile_image d-inline-block align-middle mr-2" v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + props.row.user_id_external + '/' + props.row.profile_image + '/' + clientToken + ')' }"></div>
                            <div v-else class="profile_icon d-inline-flex align-middle mr-2">
                              <userProfileAvatar :username="props.row.name" :size="25"/>
                            </div>
                            <b>{{props.row.name}}</b>
                          </div>                          
                        </b-table-column>
                        <b-table-column field="department_name" :label="$t('common.Department')">
                          <span>{{props.row.department_name ? props.row.department_name : '-'}}</span>
                        </b-table-column>                        
                        <b-table-column field="team_name" :label="$t('common.Team')">
                          <span>{{props.row.team_name ? props.row.team_name : '-'}}</span>
                        </b-table-column>                        
                        <b-table-column field="function" :label="$t('common.Function')">
                          <span>{{props.row.function !== null && props.row.function !== '' ? props.row.function : '-'}}</span>
                        </b-table-column>
                      </template>
                      <template slot="empty">
                        <div class="text-center">
                          <div v-if="newPoints.filters.length > 0">
                            {{ $t('groups.No_users_based_on_filters') }}
                          </div>
                          <div v-else>
                            {{ $t('groups.Add_filters_to_show_members') }}
                          </div>
                        </div>                
                      </template>                
                    </b-table>                    
                  </CCol>
                </CRow>
              </div>
              <div v-else>
                <div v-if="newPoints.users.length > 0">
                  <CRow class="group_member_container tile-container m-0">
                    <CCol cols="4" xl="4" lg="4" md="4" sm="4" v-for="employee in currentPageEmployees[currentEmployeePage - 1]" v-bind:key="employee.user_id_external" class="member_column p-0">
                      <CCard class="member">
                        <CCardBody>
                          <CRow>
                            <CCol cols="12" sm="12" md="12" lg="12" class="pt-0 pb-0">
                              <center>
                                <div v-if="employee.profile_image" class="profile_image mb-2" v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + employee.user_id_external + '/' + employee.profile_image + '/' + clientToken + ')' }"></div>
                                <div v-else class="profile_icon mb-2">
                                  <userProfileAvatar :username="employee.name" :size="50"/>
                                </div>
                              </center>
                            </CCol>                      
                            <CCol cols="12" sm="12" md="12" lg="12" class="pt-0 pb-0 text-center">
                              <a @click="openSidebarRight('user_profile', { user_id_external:  employee.user_id_external });" class="d-block"><b>{{employee.name}}</b></a>
                              <p v-if="employee.team_name" class="m-0">{{employee.team_name}} ({{employee.department_name}})</p>
                              <p class="m-0">{{employee.function !== null && employee.function !== '' ? employee.function : '-'}}</p>
                            </CCol>                                                
                          </CRow>
                        </CCardBody>
                      </CCard>
                    </CCol>
                  </CRow>
                  <hr v-if="newPoints.users.length > membersPerPage" class="m-0">
                  <CRow v-if="newPoints.users.length > membersPerPage" class="m-0">
                    <CCol cols="12" md="12">
                      <v-pagination class="justify-content-end" v-model="currentEmployeePage" :length="employeePages" :total-visible="9" prev-icon="mdi-chevron-left" next-icon="mdi-chevron-right"></v-pagination>
                    </CCol>
                  </CRow>
                </div>
                <div v-else>
                  <CRow class="m-0">
                    <CCol cols="12" lg="12" class="pt-0">
                      <div v-if="newPoints.filters.length > 0">
                        {{ $t('common.No_employees_based_on_filters') }}
                      </div>
                      <div v-else>
                        {{ $t('learn.Add_filters_to_show_assignees') }}
                      </div>
                    </CCol>
                  </CRow>                  
                </div>
              </div>
            </CCol>                      
          </CRow>             
        </div>
      </div>
      <div class="sidebar_footer">
        <CRow class="m-0">
          <CCol cols="12" lg="12">
            <CButton color="primary" @click="grantPoints();"><i class="fas fa-check mr-1"/>{{$t('points.Grant_points')}}</CButton>
            <CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{$t('Dismiss')}}</CButton>
          </CCol>
        </CRow>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect';
import ImageUploader from 'vue-image-upload-resize';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { v4 as uuidv4 } from 'uuid';

import userProfileAvatar from "@/components/common/userProfileAvatar.vue";

export default {
  name: 'grantPoints',
  props: ['history'],
  components: {
    Multiselect,
    ImageUploader,
    ClassicEditor,
    userProfileAvatar
  },
  data() {
    return {
      modules: {
        social_wall: 0
      },
      apiBaseUrl: null,
      clientToken: null,
      courses: [],
			newPoints: {
        filters: [],
        users: []
      },      
      groupFilterTypes: [],
      groupFilterConditions: [],
      typeFilterConditions: [],      
      groupFilterValues: [],
      typeFilterValues: [],
      groupFilterDepartments: [],
      groupFilterTeams: [],
      groupFilterUsers: [],
      groupFilterTargetGroups: [], 
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 10,      
      listViewEnabled: false,
      userAttributes: ['hours_on_contract', 'meyer_briggs', 'disc', 'office_based', 'division', 'language', 'persona_1', 'persona_2', 'persona_3', 'work_location', 'user_function', 'years_of_service'],
      currentEmployeePage: 1,
      currentPageEmployees: {},
      membersPerPage: 9,
      employeePages: 0,
      maxCount: {
        new_points_description: 250,
        new_post_content: 5000
      },
      remainingCount: {
        new_points_description: 250,
        new_post_content: 5000
      },
      newPostData: {},
      newPostImageUploaded: false,
      swGroups: [],
      defaultSWGroupId: null,
      defaultSWGroupName: null,      
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [ 'heading', '|', 'bold', 'italic', 'bulletedList', 'numberedList', '|', 'undo', 'redo' ]         
      }
    }
  },
  watch: {
    $props: {
      handler() {
        this.resetNewPointsData();
        this.resetNewPostData();
      },
      deep: true,
      immediate: true,
    }
  },  
  methods: {  
    grantPoints () {
      let params = {};
      let postParams = {};

      params = this.newPoints;
      params.amount = parseInt(this.newPoints.amount);

      if(this.newPoints.addToSocialWall === true) {
        postParams = this.newPostData; 

        (this.newPostData.is_breaking_news === false) ? postParams.breaking_news = 0 : postParams.breaking_news = 1;

        if(postParams.sw_groups.length == 0) {
          postParams.sw_groups.push({
            sw_group_id: this.defaultSWGroupId,
            sw_group_name: this.defaultSWGroupName
          });
        }

        for(var g = 0; g < postParams.sw_groups.length; g++) {
          postParams.sw_groups[g].post_external_id_for_group = uuidv4();
        }
      }   

      if(this.validateGranting(params, postParams) === true) {
        axios.post(process.env.VUE_APP_API_URL + '/v1/loyalty/points/grant', params)
        .then(res => {
          this.$buefy.toast.open({ message: this.$t('points.Points_granted'), type: 'is-success', duration: 2000 });                           
          this.resetNewPointsData();
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })

        if(this.newPoints.addToSocialWall === true) {         
          axios.post(process.env.VUE_APP_API_URL + '/v1/connect/socialwall/post', postParams)
          .then(res => {
            // Handle the file upload if the post contains files
            if(postParams.post_type_tag === 'normal' && postParams.new_post_images.length > 0) {         
              // Loop through the sw groups of this post
              for(var g = 0; g < postParams.sw_groups.length; g++) {
                // Get the external ID for the post in the sw group
                let externalIdForGroup = postParams.sw_groups[g].post_external_id_for_group;
                // Create new formdata              
                const formData = new FormData();
                // Loop through the images and append the images to the formdata
                for(var i = 0; i < postParams.new_post_images.length; i++) {
                  formData.append("image_" + i, postParams.new_post_images[i]);
                }
                // Upload the files
                axios.post('v1/common/upload/files/sw/' + externalIdForGroup, formData, {
                  headers: { 'Content-Type': 'multipart/form-data' }
                })
                .then(res => {
                  this.$buefy.toast.open({ message: this.$t('common.Posted_on_sw'), type: 'is-success', duration: 2000 });
                  this.resetNewPostData();
                })
                .catch(err => {
                  console.error(err); 
                });
              }                            
            } else {
              this.$buefy.toast.open({ message: this.$t('common.Posted_on_sw'), type: 'is-success', duration: 2000 });
              this.resetNewPostData();  
            }
          })
          .catch(err => {
            console.error(err); 
            this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
          })
        }
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }    
    },
    validateGranting (params, postParams) {      
      let users = params.users;
      let amount = params.amount;
      let description = params.description;

      let content = postParams.content;
      let sw_groups = postParams.sw_groups;

      // Check if base information for new points is available
      if(users.length > 0 && amount && description) {
        // Check if a social wall post must be created
        if(params.addToSocialWall === true) {
          // Check if necessary post data is available
          if(content && sw_groups.length > 0) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    getGroupMembers() {
      let params = {};
      params.filters = this.newPoints.filters.filter(function (el) {
        return el.type != undefined && el.value != undefined;
      });

      axios.post(process.env.VUE_APP_API_URL + '/v1/core/targetgroup/members', params)
      .then(res => {
        this.newPoints.users = res.data.data;
        // Enable the pagination if necessary
        (this.newPoints.users.length > this.perPage) ? this.isPaginated = true : this.isPaginated = false;        
        // Set the pagination to the first page
        this.currentEmployeePage = 1;
        // Reset the employeePages value
        this.employeePages = 0;
            
        for (let i = 0; i < this.newPoints.users.length; i = i + this.membersPerPage) {
          this.currentPageEmployees[this.employeePages] = this.newPoints.users.slice(i, i + this.membersPerPage);
          this.employeePages++;
        }
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getGroupFilterTypes() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/targetgroups/filters/types')
      .then(res => {      
        this.groupFilterTypes = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    customFilterTypeLabel({type_tag}) {
      return this.$t('filter.' + type_tag);
    },    
    getGroupFilterConditions () {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/targetgroups/filters/conditions')
      .then(res => {
        this.groupFilterConditions = res.data.data;
        // Set the typeFilterConditions array
        this.typeFilterConditions = this.groupFilterConditions;        
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getTypeFilterConditions(filterTypeTag) {
      // Reset the typeFilterConditions array
      this.typeFilterConditions = this.groupFilterConditions;
      // Filter the typeFilterConditions based on the filter type tag
      this.typeFilterConditions = this.typeFilterConditions.filter( i => i.available_for_filter_type_tags.includes(filterTypeTag));
    },
    customFilterConditionLabel({condition_tag}) {
      return this.$t('condition.' + condition_tag);
    },    
    getGroupFilterTypeValues() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/targetgroups/filters/types/values')
      .then(res => {
        this.groupFilterValues = res.data.data;
        // Set the typeFilterValues array
        this.typeFilterValues = this.groupFilterValues;        
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getTypeFilterValues(filterTypeTag) {
      // Reset the typeFilterValues array
      this.typeFilterValues = this.typeFilterValues;
      // Filter the typeFilterValues based on the filter type tag
      this.typeFilterValues = this.typeFilterValues.filter( i => i.available_for_filter_type_tags.includes(filterTypeTag));
    },
    customFilterTypeValueLabel({type_value_tag}) {
      return this.$t('filter_value.' + type_value_tag);
    },         
    addFilterOption() {
      this.newPoints.filters.push({});
    },
    removeFilterOption(index) {
      this.newPoints.filters.splice(index, 1);
      this.getGroupMembers();
    },
    getSWGroups() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/connect/flows/multiselect')
      .then(res => {
        this.swGroups = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getCompanyDefaultSWGroup() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/connect/flow/default')
      .then(res => {
        this.defaultSWGroupId = res.data.data.sw_group_id;
        this.defaultSWGroupName = res.data.data.sw_group_name;
      })
      .catch(err => {
        console.error(err); 
      });   
    },    
    asyncFindDepartment(query) {
      let searchTerm = query;
      this.groupFilterDepartments = [];
      
      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/departments/' + searchTerm)
        .then(res => {      
          this.groupFilterDepartments = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },
    asyncFindTeam(query) {
      let searchTerm = query;
      this.groupFilterTeams = [];

      if(searchTerm.length >= 2) {
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/teams/' + searchTerm)
        .then(res => {      
          this.groupFilterTeams = res.data.data;    
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },        
    asyncFindUser(query) {
      let searchTerm = query;
      this.groupFilterUsers = [];
      
      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/users/' + searchTerm)
        .then(res => {      
          this.groupFilterUsers = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },   
    asyncFindTargetGroup(query) {
      let searchTerm = query;
      this.groupFilterTargetGroups = [];
      
      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/targetgroup/' + searchTerm)
        .then(res => {      
          this.groupFilterTargetGroups = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },    
    resetNewPointsData() {
      this.newPoints = {
        points_type_tag: 'points_granted',
        awarded_by_user: true,
        award_to_team: true,
        description: '',
        amount: 0,        
        users: [],
        filters: [],
        addToSocialWall: false
      };     
    },
    resetNewPostData () {
      this.newPostData = {
        post_id_external: null,
        title: null,
        post_type_tag: 'normal',
        type_id: null,   
        content: '',
        sw_groups: [],
        is_breaking_news: false,
        is_created_from_dashboard: true,
        new_post_images: [],
        schedule_post: false,
        scheduled_datetime: null
      };      
    },
    onPostImagesChange(e) {
      let files = e.target.files;      
      // Loop through the files
      for(var i = 0; i < files.length; i++) {
        // Create an image URL for the file
        files[i].image_url = URL.createObjectURL(files[i]);
        // Add the file to the new_post_images array of the post modal data
        this.newPostData.new_post_images.push(files[i]);
      }
    },
    pickPostImages() {
      document.getElementById("imageUpload").click()
    },
    removePostImage(index) {
      this.newPostData.new_post_images.splice(index, 1);  
    },    
    setpostModalDataImage (output) {
      this.newPostImageUploaded = true;
      this.newPostData.post_image = output.dataUrl;
    },       
    countdown () {
      this.remainingCount.new_points_description = this.maxCount.new_points_description - this.newPoints.description.length;
      this.remainingCount.new_post_content = this.maxCount.new_post_content - this.newPostData.content.length;        
    },
    checkModules() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/modules')
      .then(res => {      
        this.modules = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    }    
  },
  mounted: function() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');
    
    this.checkModules();
    this.getGroupFilterTypes();
    this.getGroupFilterConditions();
    this.getGroupFilterTypeValues();
    this.getSWGroups();
  }  
}
</script>