<template>
  <div>
    <div class="sidebar_container">
      <div class="sidebar_header">
        <CRow class="m-0">
          <CCol cols="10" lg="10" md="10" sm="10" class="text-left">
            <span v-if="history.length > 1" class="pointer" @click="removeSidebarHistoryStep()">
              <i class="fas fa-arrow-circle-left mr-1"/>
            </span>
            <span class="sidebar_subject">
              {{defaultEventConfigIdExternal ? $t('admin.Edit_default_config') : $t('admin.New_default_config')}}
            </span>
          </CCol>
          <CCol cols="2" lg="2" md="2" sm="2" class="text-right">
            <span @click="closeSidebarRight();"><i class="fas fa-times"/></span>    
          </CCol>      
        </CRow>
      </div>
      <div class="sidebar_content">
        <CRow class="m-0">
          <CCol cols="6" lg="6" class="pt-0">
            <CInput type="text" :label="$t('communications.Moment_name')" class="mb-0" v-model="momentConfig.description" required was-validated/>
          </CCol>
          <CCol cols="6" lg="6" class="pt-0">
            <CInput type="text" :label="$t('communications.Moment_tag')" class="mb-0" v-model="momentConfig.tag" required was-validated/>
          </CCol>         
        </CRow>
        <!-- Icon -->
        <CRow class="m-0">
          <CCol cols="6" lg="6" class="pt-0">
            <label>
              <span>{{$t('communications.Upload_moment_icon')}}</span>
            </label>
            <image-uploader class="image_upload" :preview="true" :className="['fileinput', { 'fileinput--loaded': momentConfigImageUploaded }]" capture="environment" :debug="0" doNotResize="gif" :autoRotate="true" outputFormat="verbose" @input="setMomentConfigImage">
              <label for="fileInput" slot="upload-label" class="m-0">
                <span class="upload-caption btn btn-primary m-0"><i class="fas fa-file-upload mr-1"/>{{momentConfigImageUploaded ? $t('common.Replace_image') : $t('common.Upload_icon')}}</span>
              </label>
            </image-uploader>
          </CCol>
          <CCol v-if="momentConfig.event_type_image_id" cols="12" lg="12" class="pt-0">
            <label>{{$t('common.Current_icon')}}</label>
            <img v-if="momentConfig.custom_event === 0" :src="apiBaseUrl + '/v1/common/cdn/file/image/core-auto-moment-icon/global/' + momentConfig.event_type_image_id + '/' + clientToken" class="current_image"/>
            <img v-if="momentConfig.custom_event === 1" :src="apiBaseUrl + '/v1/common/cdn/file/image/core-auto-moment-custom/' + momentConfig.tag + '/' + momentConfig.event_type_image_id + '/' + clientToken" class="current_image"/>
          </CCol>          
        </CRow>
        <!-- Product -->
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0 locale-changer">
            <label>{{$t('communications.Moment_product')}}</label>
            <multiselect class="open_absolute"
                         v-model="momentConfig.product" 
                         :options="products" 
                         :multiple="false"
                         :hideSelected="false"
                         :closeOnSelect="true"
                         track-by="product_tag" 
                         label="product_name">
            </multiselect>
          </CCol>
        </CRow>          
        <!-- Countries -->
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0 locale-changer">
            <label>{{$t('communications.Moment_countries')}}</label>
            <multiselect class="open_absolute"
                         v-model="momentConfig.countries" 
                         :options="countries" 
                         :multiple="true"
                         :hideSelected="false"
                         :closeOnSelect="true"
                         track-by="country_id_external" 
                         label="name"
                         :show-labels="false">
              <span slot="noResult">{{$t('common.no_countries_found')}}</span>

              <template slot="singleLabel" slot-scope="country">
                <div class="d-flex align-items-center">
                  <span v-if="country.option.flag" class="language_flag" v-html="getTwemoji(country.option.flag)"></span>
                  <span class="language_name">{{ country.option.name }}</span>
                </div>
              </template>

              <template slot="option" slot-scope="country">
                <div class="d-flex align-items-center">
                  <span v-if="country.option.flag" class="language_flag" v-html="getTwemoji(country.option.flag)"></span>
                  <span class="language_name">{{ country.option.name }}</span>
                </div>
              </template>
            </multiselect>
          </CCol>
        </CRow>        
        <!-- General -->
        <CRow class="m-0">
          <CCol cols="6" lg="6" class="pt-0">
            <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="momentConfig.is_globally_available" size="is-small">{{ $t('communications.Moment_globally_available') }}</b-switch>
          </CCol>
          <CCol cols="6" lg="6" class="pt-0">
            <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="momentConfig.is_active" size="is-small">{{ $t('communications.Moment_active') }}</b-switch>
          </CCol>
          <CCol cols="6" lg="6" class="pt-0">
            <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="momentConfig.is_required" size="is-small">{{ $t('communications.Moment_required') }}</b-switch>
          </CCol>         
        </CRow>
        <!-- Email -->
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <span><b>{{$t('common.Email')}}</b></span>            
          </CCol>       
          <CCol cols="6" lg="6" class="pt-0">
            <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="momentConfig.email_is_available" size="is-small">{{ $t('communications.Moment_email_available') }}</b-switch>
          </CCol>
          <CCol cols="6" lg="6" class="pt-0">
            <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="momentConfig.email_is_required" size="is-small">{{ $t('communications.Moment_email_required') }}</b-switch>
          </CCol>
          <CCol cols="6" lg="6" class="pt-0">
            <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="momentConfig.email_is_active" size="is-small">{{ $t('communications.Moment_email_active') }}</b-switch>
          </CCol>
          <CCol cols="12" lg="12" class="pt-0">
            <label>{{$t('communications.Email_content_tags')}}</label>
            <div class="meta mb-2">
              <span class="mr-1">{{$t('communications.Tags_currently_supported')}}</span>
              <span>{$platform_name}, {$email_signatory}, {$name}</span>
              <span @click="copyToClipboard('email');" class="pointer ml-1">
                <i class="fas fa-copy"/>
              </span>
            </div>
            <CInput type="text"
                    v-model="momentConfig.email_content_tags"
                    :required="momentConfig.email_is_available && momentConfig.email_is_active"
                    :was-validated="momentConfig.email_is_available && momentConfig.email_is_active"
                    class="mb-0">
            </CInput>
          </CCol>
          <CCol v-if="!defaultEventConfigIdExternal" cols="12" lg="12" class="pt-0">
            <label>{{$t('admin.Environment_event_email_content')}}</label>
            <div>
              <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="momentConfig.environment_specific_email_content" size="is-small">{{ $t('admin.Event_has_specific_email_content') }}</b-switch>
            </div>
          </CCol>          
        </CRow>
        <!-- Loyalty points -->
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <span><b>{{$t('communications.Loyalty_points')}}</b></span>            
          </CCol>
          <CCol cols="6" lg="6" class="pt-0">
            <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="momentConfig.points_are_available" size="is-small">{{ $t('communications.Moment_points_available') }}</b-switch>
          </CCol>
          <CCol cols="6" lg="6" class="pt-0">
            <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="momentConfig.points_are_active" size="is-small">{{ $t('communications.Moment_points_active') }}</b-switch>
          </CCol>
          <CCol v-if="momentConfig.points_are_available && momentConfig.points_are_active" cols="12" lg="12" class="pt-0">
            <CInput type="number" 
                    v-model="momentConfig.points"
                    :label="$t('communications.points_received_for_momentConfig')"
                    :required="momentConfig.points_are_available && momentConfig.points_are_active"
                    :was-validated="momentConfig.points_are_available && momentConfig.points_are_active"
                    class="mb-0">
            </CInput>                    
          </CCol>
        </CRow>
        <!-- Send on date -->
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <span><b>{{$t('communications.Send_on_date')}}</b></span>            
          </CCol>
          <CCol cols="6" lg="6" class="pt-0">
            <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="momentConfig.send_on_date_is_available" size="is-small">{{ $t('communications.Moment_send_on_date_available') }}</b-switch>
          </CCol>
          <CCol cols="6" lg="6" class="pt-0">
            <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="momentConfig.send_on_date_is_required" size="is-small">{{ $t('communications.Moment_send_on_date_required') }}</b-switch>
          </CCol>
          <CCol cols="6" lg="6" class="pt-0">
            <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="momentConfig.send_on_date_is_active" size="is-small">{{ $t('communications.Moment_send_on_date_active') }}</b-switch>
          </CCol>          
          <CCol v-if="momentConfig.send_on_date_is_available && momentConfig.send_on_date_is_active" cols="12" lg="12" class="pt-0">
            <label>{{$t('communications.Send_communication_on_date')}}</label>
            <b-datetimepicker v-model="momentConfig.send_on_date" :placeholder="$t('common.click_to_select')" editable :datepicker="datepickerOptions" :timepicker="timepickerOptions"></b-datetimepicker>
          </CCol>
        </CRow>

        <b-collapse class="panel show m-0" :open="false" position="is-bottom">
          <template #trigger="props">
            <span :aria-expanded="props.open">{{!props.open ? $t('common.Show_more') : $t('common.Show_less')}}</span>
          </template>
          <div class="panel-block p-0">
            <!-- Connect posts -->
            <CRow class="m-0">
              <CCol cols="12" lg="12" class="pt-0">
                <span><b>{{$t('communications.Connect_posts')}}</b></span>            
              </CCol>
              <CCol cols="6" lg="6" class="pt-0">
                <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="momentConfig.sw_post_is_available" size="is-small">{{ $t('communications.Moment_sw_post_available') }}</b-switch>
              </CCol>
              <CCol cols="6" lg="6" class="pt-0">
                <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="momentConfig.sw_post_is_required" size="is-small">{{ $t('communications.Moment_sw_post_required') }}</b-switch>
              </CCol>
              <CCol cols="6" lg="6" class="pt-0">
                <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="momentConfig.sw_post_is_active" size="is-small">{{ $t('communications.Moment_sw_post_active') }}</b-switch>
              </CCol>
              <CCol cols="6" lg="6" class="pt-0">
                <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="momentConfig.images_available" size="is-small">{{ $t('communications.Moment_has_images') }}</b-switch>
              </CCol>          
              <CCol cols="12" lg="12" class="pt-0">
                <label>{{$t('communications.Post_content_tags')}}</label>
                <div class="meta mb-2">
                  <span class="mr-1">{{$t('communications.Tags_currently_supported')}}</span>
                  <span>[NAME]</span>
                  <span @click="copyToClipboard('post');" class="pointer ml-1">
                    <i class="fas fa-copy"/>
                  </span>                      
                </div>
                <CInput type="text"
                        v-model="momentConfig.sw_post_content_tags"
                        :required="momentConfig.sw_post_is_available && momentConfig.sw_post_is_active"
                        :was-validated="momentConfig.sw_post_is_available && momentConfig.sw_post_is_active"                        
                        class="mb-0">
                </CInput>                                                  
              </CCol>
            </CRow>
            <!-- Push notifications -->
            <CRow class="m-0">
              <CCol cols="12" lg="12" class="pt-0">
                <span><b>{{$t('communications.Push_notifications')}}</b></span>            
              </CCol>
              <CCol cols="6" lg="6" class="pt-0">
                <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="momentConfig.push_is_available" size="is-small">{{ $t('communications.Moment_push_available') }}</b-switch>
              </CCol>
              <CCol cols="6" lg="6" class="pt-0">
                <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="momentConfig.push_is_required" size="is-small">{{ $t('communications.Moment_push_required') }}</b-switch>
              </CCol>
              <CCol cols="6" lg="6" class="pt-0">
                <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="momentConfig.push_is_active" size="is-small">{{ $t('communications.Moment_push_active') }}</b-switch>
              </CCol>
            </CRow>
            <!-- Send after days -->
            <CRow class="m-0">
              <CCol cols="12" lg="12" class="pt-0">
                <span><b>{{$t('communications.Send_after_days')}}</b></span>            
              </CCol>
              <CCol cols="6" lg="6" class="pt-0">
                <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="momentConfig.send_after_days_is_available" size="is-small">{{ $t('communications.Moment_send_after_days_available') }}</b-switch>
              </CCol>
              <CCol cols="6" lg="6" class="pt-0">
                <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="momentConfig.send_after_days_is_required" size="is-small">{{ $t('communications.Moment_send_after_days_required') }}</b-switch>
              </CCol>
              <CCol cols="6" lg="6" class="pt-0">
                <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="momentConfig.send_after_days_is_active" size="is-small">{{ $t('communications.Moment_send_after_days_active') }}</b-switch>
              </CCol>          
              <CCol v-if="momentConfig.send_after_days_is_available && momentConfig.send_after_days_is_active" cols="12" lg="12" class="pt-0">
                <CInput type="text"
                        v-model="momentConfig.send_after_days"
                        :label="$t('communications.Send_moment_communication_after_days')" 
                        :required="momentConfig.send_after_days_is_available && momentConfig.send_after_days_is_active"
                        :was-validated="momentConfig.send_after_days_is_available && momentConfig.send_after_days_is_active"
                        class="mb-0">
                 </CInput>
              </CCol>
            </CRow>
          </div>
        </b-collapse>
      </div>
      <div class="sidebar_footer">
        <CRow class="m-0">
          <CCol cols="12" lg="12">
            <div v-if="!defaultEventConfigIdExternal">
              <CButton color="primary" @click="insertDefaultMomentConfig();"><i class="fas fa-check mr-1"/>{{ $t('Save') }}</CButton>
              <CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{ $t('Dismiss') }}</CButton>
            </div>
            <div v-else>
              <CButton color="primary" @click="updateDefaultMomentConfig();"><i class="fas fa-check mr-1"/>{{$t('Update')}}</CButton>
              <CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{ $t('Dismiss') }}</CButton>
            </div>
          </CCol>
        </CRow>    
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import QuickEdit from 'vue-quick-edit';
import ImageUploader from 'vue-image-upload-resize';

export default {
  name: 'productAutoMomentDetails',
  props: ['history', 'defaultEventConfigIdExternal'],
  watch: {
    $props: {
      handler() {
        this.getDefaultMomentConfig();
      },
      deep: true,
      immediate: true,
    }
  },  
  components: {
    Multiselect,
    QuickEdit,
    ImageUploader
  },
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null,      
      momentConfig: {},
      momentConfigImageUploaded: false,
      products: [
        { product_name: 'System', product_tag: 'system'},
        { product_name: 'Measure', product_tag: 'measure'},
        { product_name: 'Loyalty', product_tag: 'loyalty'},
        { product_name: 'Connect', product_tag: 'connect'},
        { product_name: 'Knowledge', product_tag: 'knowledge'},
        { product_name: 'Learn', product_tag: 'learn'},
      ],
      environments: [],
      countries: [],
      datepickerOptions: {
        yearsRange: [0, 10],
        firstDayOfWeek: 1,
        showWeekNumber: true
      },
      timepickerOptions: {
        incrementHours: 1,
        incrementMinutes: 15
      }
    }
  },
  methods: {
    getDefaultMomentConfig() {
      if(this.defaultEventConfigIdExternal) {
        axios.get(process.env.VUE_APP_API_URL + '/v1/superadmin/events/default/config/' + this.defaultEventConfigIdExternal)
        .then(res => {
          this.momentConfig = res.data.data;
  
          if(this.momentConfig.send_on_date_is_available && this.momentConfig.send_on_date_is_active) {
            this.momentConfig.send_on_date = new Date(this.momentConfig.send_on_date);
          }          
        })
        .catch(err => {
          console.error(err); 
        });
      } else {
        this.resetMomentConfigData();
      }
    },
    insertDefaultMomentConfig() {
      let params = {};
      params.configData = this.momentConfig;
      (params.configData.is_active === true) ? params.configData.active = 1 : params.configData.active = 0;
      (params.configData.is_required === true) ? params.configData.required = 1 : params.configData.required = 0;
      (params.configData.is_globally_available === true) ? params.configData.globally_available = 1 : params.configData.globally_available = 0;
      (params.configData.email_is_active === true) ? params.configData.email_active = 1 : params.configData.email_active = 0;
      (params.configData.email_is_available === true) ? params.configData.email_available = 1 : params.configData.email_available = 0;
      (params.configData.email_is_required === true) ? params.configData.email_required = 1 : params.configData.email_required = 0;
      (params.configData.sw_post_is_active === true) ? params.configData.sw_post_active = 1 : params.configData.sw_post_active = 0;
      (params.configData.sw_post_is_available === true) ? params.configData.sw_post_available = 1 : params.configData.sw_post_available = 0;
      (params.configData.sw_post_is_required === true) ? params.configData.sw_post_required = 1 : params.configData.sw_post_required = 0;        
      (params.configData.push_is_active === true) ? params.configData.push_active = 1 : params.configData.push_active = 0;
      (params.configData.push_is_available === true) ? params.configData.push_available = 1 : params.configData.push_available = 0;
      (params.configData.push_is_required === true) ? params.configData.push_required = 1 : params.configData.push_required = 0;
      (params.configData.images_available === true) ? params.configData.has_images = 1 : params.configData.has_images = 0;  
      (params.configData.points_are_active === true) ? params.configData.points_active = 1 : params.configData.points_active = 0;
      (params.configData.points_are_available === true) ? params.configData.points_available = 1 : params.configData.points_available = 0;
      (params.configData.send_after_days_is_active === true) ? params.configData.send_after_days_active = 1 : params.configData.send_after_days_active = 0;
      (params.configData.send_after_days_is_available === true) ? params.configData.send_after_days_available = 1 : params.configData.send_after_days_available = 0;
      (params.configData.send_after_days_is_required === true) ? params.configData.send_after_days_required = 1 : params.configData.send_after_days_required = 0;
      (params.configData.send_on_date_is_active === true) ? params.configData.send_on_date_active = 1 : params.configData.send_on_date_active = 0;
      (params.configData.send_on_date_is_available === true) ? params.configData.send_on_date_available = 1 : params.configData.send_on_date_available = 0;
      (params.configData.send_on_date_is_required === true) ? params.configData.send_on_date_required = 1 : params.configData.send_on_date_required = 0;

      if(!params.configData.email_is_available && !params.configData.email_is_active) {
        params.configData.email_content_tags = null;
      }

      if(!params.configData.sw_post_is_available && !params.configData.sw_post_is_active) {
        params.configData.sw_post_content_tags = null;
      }      

      if(!params.configData.points_are_available && !params.configData.points_are_active) {
        params.configData.points = null;
      }

      if(!params.configData.send_after_days_is_available && !params.configData.send_after_days_is_active) {
        params.configData.send_after_days = null;
      }

      if(!params.configData.send_on_date_is_available && !params.configData.send_on_date_is_active) {
        params.configData.send_on_date = null;
      }

      if(params.configData.send_on_date) {
        params.configData.event_send_on_date = this.$luxon(params.configData.send_on_date.toISOString(), "yyyy-MM-dd HH:mm:ss");
      } else {
        params.configData.event_send_on_date = null;
      }
      
      if(this.validateDefaultMomentConfig(params) === true) {
        axios.post(process.env.VUE_APP_API_URL + '/v1/superadmin/events/default', params)
        .then(res => {
          // Show a success notice
          this.$buefy.toast.open({ message: this.$t('admin.Default_config_added'), type: 'is-success', duration: 3000 });
          // Emit the default_event_added
          this.$bus.$emit('default_event_added');
          // Reset the data
          this.resetMomentConfigData();
          // Close the sidebar
          this.closeSidebarRight();          
        })
        .catch(err => {
          if(err.response.data.code === 'Doublure Event Tag') {
            this.$buefy.toast.open({ message: this.$t('communications.Moment_tag_already_exists'), type: 'is-danger', duration: 2000 });
          } else {
            this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
          }
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }
    },
    updateDefaultMomentConfig() {
      let params = {};
      params.configData = this.momentConfig;
      (params.configData.is_active === true) ? params.configData.active = 1 : params.configData.active = 0;
      (params.configData.is_required === true) ? params.configData.required = 1 : params.configData.required = 0;
      (params.configData.is_globally_available === true) ? params.configData.globally_available = 1 : params.configData.globally_available = 0;
      (params.configData.email_is_active === true) ? params.configData.email_active = 1 : params.configData.email_active = 0;
      (params.configData.email_is_available === true) ? params.configData.email_available = 1 : params.configData.email_available = 0;
      (params.configData.email_is_required === true) ? params.configData.email_required = 1 : params.configData.email_required = 0;
      (params.configData.sw_post_is_active === true) ? params.configData.sw_post_active = 1 : params.configData.sw_post_active = 0;
      (params.configData.sw_post_is_available === true) ? params.configData.sw_post_available = 1 : params.configData.sw_post_available = 0;
      (params.configData.sw_post_is_required === true) ? params.configData.sw_post_required = 1 : params.configData.sw_post_required = 0;        
      (params.configData.push_is_active === true) ? params.configData.push_active = 1 : params.configData.push_active = 0;
      (params.configData.push_is_available === true) ? params.configData.push_available = 1 : params.configData.push_available = 0;
      (params.configData.push_is_required === true) ? params.configData.push_required = 1 : params.configData.push_required = 0;
      (params.configData.images_available === true) ? params.configData.has_images = 1 : params.configData.has_images = 0;  
      (params.configData.points_are_active === true) ? params.configData.points_active = 1 : params.configData.points_active = 0;
      (params.configData.points_are_available === true) ? params.configData.points_available = 1 : params.configData.points_available = 0;
      (params.configData.send_after_days_is_active === true) ? params.configData.send_after_days_active = 1 : params.configData.send_after_days_active = 0;
      (params.configData.send_after_days_is_available === true) ? params.configData.send_after_days_available = 1 : params.configData.send_after_days_available = 0;
      (params.configData.send_after_days_is_required === true) ? params.configData.send_after_days_required = 1 : params.configData.send_after_days_required = 0;
      (params.configData.send_on_date_is_active === true) ? params.configData.send_on_date_active = 1 : params.configData.send_on_date_active = 0;
      (params.configData.send_on_date_is_available === true) ? params.configData.send_on_date_available = 1 : params.configData.send_on_date_available = 0;
      (params.configData.send_on_date_is_required === true) ? params.configData.send_on_date_required = 1 : params.configData.send_on_date_required = 0;

      if(!params.configData.email_is_available && !params.configData.email_is_active) {
        params.configData.email_content_tags = null;
      }

      if(!params.configData.sw_post_is_available && !params.configData.sw_post_is_active) {
        params.configData.sw_post_content_tags = null;
      }      

      if(!params.configData.points_are_available && !params.configData.points_are_active) {
        params.configData.points = null;
      }

      if(!params.configData.send_after_days_is_available && !params.configData.send_after_days_is_active) {
        params.configData.send_after_days = null;
      }

      if(!params.configData.send_on_date_is_available && !params.configData.send_on_date_is_active) {
        params.configData.send_on_date = null;
      }

      if(params.configData.send_on_date) {
        params.configData.event_send_on_date = this.$luxon(params.configData.send_on_date.toISOString(), "yyyy-MM-dd HH:mm:ss");
      }

      if(this.validateDefaultMomentConfig(params) === true) {
        axios.put(process.env.VUE_APP_API_URL + '/v1/superadmin/events/default/config/' + this.defaultEventConfigIdExternal, params)
        .then(res => {
          // Show a success notice
          this.$buefy.toast.open({ message: this.$t('admin.Default_config_updated'), type: 'is-success', duration: 3000 });
          // Emit the default_event_config_updated
          this.$bus.$emit('default_event_config_updated');
          // Close the sidebar
          this.closeSidebarRight();          
        })
        .catch(err => {
          if(err.response.data.code === 'Doublure Event Tag') {
            this.$buefy.toast.open({ message: this.$t('communications.Moment_tag_already_exists'), type: 'is-danger', duration: 2000 });
          } else {
            this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
          }
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }
    },
    validateDefaultMomentConfig(params) {
      let description = params.configData.description;
      let tag = params.configData.tag;
      let product = params.configData.product;
      let image = params.configData.image;
      let event_type_image_id = params.configData.event_type_image_id;
      let email_is_active = params.configData.email_is_active;
      let email_is_available = params.configData.email_is_available;
      let email_content_tags = params.configData.email_content_tags;
      let sw_post_is_active = params.configData.sw_post_is_active;
      let sw_post_is_available = params.configData.sw_post_is_available;
      let sw_post_content_tags = params.configData.sw_post_content_tags;
      let points = params.configData.points;
      let points_are_active = params.configData.points_are_active;
      let points_are_available = params.configData.points_are_available;
      let send_after_days = params.configData.send_after_days;
      let send_after_days_is_active = params.configData.send_after_days_is_active;
      let send_after_days_is_available = params.configData.send_after_days_is_available;
      let send_on_date = params.configData.send_on_date;
      let send_on_date_is_active = params.configData.send_on_date_is_active;
      let send_on_date_is_available = params.configData.send_on_date_is_available;

      // Check if base information for the moment is available
      if(description && tag && product) {
        // Check if the icon is set for the moment
        if((!this.defaultEventConfigIdExternal && !image) || (this.defaultEventConfigIdExternal && !event_type_image_id)) {
          return false;
        }
        // Check if the config settings are set correctly
        if((email_is_available && email_is_active && !email_content_tags) || 
           (sw_post_is_available && sw_post_is_active && !sw_post_content_tags) ||
           (points_are_available && points_are_active && !points) ||
           (send_after_days_is_available && send_after_days_is_active && !send_after_days) ||
           (send_on_date_is_available && send_on_date_is_active && !send_on_date)) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },        
    resetMomentConfigData() {
      this.momentConfig = {
        description: null,
        tag: null,
        image: null,
        event_type_image_id: null,
        countries: [],
        is_active: true,
        is_required: false,
        email_is_active: true,
        email_is_required: true,
        email_is_available: true,
        email_content_tags: '{$platform_name}, {$email_signatory}, {$name}',
        sw_post_is_active: false,
        sw_post_is_required: false,
        sw_post_is_available: false,
        sw_post_content_tags: null,
        push_is_active: false,
        push_is_required: false,
        push_is_available: false,
        images_available: false,
        points: 50,
        points_description: null,
        points_are_active: false,
        points_are_available: false,
        send_after_days: null,
        send_after_days_is_active: false,
        send_after_days_is_required: false,
        send_after_days_is_available: false,
        send_on_date: null,
        send_on_date_is_active: false,
        send_on_date_is_required: false,
        send_on_date_is_available: false
      };
    },
    setMomentConfigImage(output) {
      this.momentConfigImageUploaded = true;
      this.momentConfig.image = output.dataUrl;
    }, 
    getCountries() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/common/countries/multiselect')
      .then(res => {      
        this.countries = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getTwemoji(emoji) {
      return twemoji.parse(emoji);
    },
    copyToClipboard(source) {
      let copyValue = "";
      // Update copyValue value based on the source
      if(source === 'email') copyValue = "{$platform_name}, {$email_signatory}, {$name}";
      else if(source === 'post') copyValue = "[NAME]";      
      // Create temporary textarea for copy
      var copy = document.createElement("textarea");
      document.body.appendChild(copy);
      copy.value = copyValue;
      copy.select();
      document.execCommand("copy");
      document.body.removeChild(copy);
    }
  },
  mounted: function() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');
    this.getCountries();
  }  
}
</script>