<template>
  <div>
    <div class="sidebar_container">
      <div class="sidebar_header">
        <CRow class="m-0">
          <CCol cols="10" lg="10" md="10" sm="10" class="text-left">
            <span v-if="history.length > 1" class="pointer" @click="removeSidebarHistoryStep()">
              <i class="fas fa-arrow-circle-left mr-1"/>
            </span>            
            <span class="sidebar_subject">
							{{!courseIdExternal ? $t('lms.new_course') : $t('lms.edit_course')}}
            </span>
          </CCol>
          <CCol cols="2" lg="2" md="2" sm="2" class="text-right">
            <span @click="closeSidebarRight();"><i class="fas fa-times"/></span>        
          </CCol>
        </CRow>
      </div>
      <div class="sidebar_content">
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <quick-edit type="text" mode="ignore" :buttonOkText="$t('Save')" :buttonCancelText="$t('Dismiss')" v-model="course.course_name">                        
              <h2 class="m-0">{{course.course_name ? course.course_name : $t('common.Click_to_set_name')}}<i class="icon edit fas fa-pen ml-1"/></h2>
            </quick-edit>
          </CCol>
        </CRow>
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <label><b>{{$t('common.Category')}}</b></label>
            <multiselect
              class="data_table"
              v-model="course.category" 
              :options="categories" 
              :multiple="false"
              :hide-selected="true"
              :close-on-select="true"
              :placeholder="$t('common.Select_category')" 
              :selectLabel="$t('common.Add_category')"
              :deselectLabel="$t('common.Remove_category')"
              track-by="lms_course_category_id_external" 
              label="label">
              <span slot="noResult">{{ $t('common.no_categories_found') }}</span>
            </multiselect>
          </CCol>
        </CRow>        

        <div v-if="courseIdExternal">
          <CRow class="m-0">
            <CCol cols="12" lg="12" class="pt-0">
              <label><b>{{ $t('lms.course_explanation') }}</b></label>
              <div class="wysiwyg">
                <ckeditor :editor="editor" v-model="course.description" :config="editorConfig" :placeholder="$t('lms.course_explanation')"></ckeditor>
              </div>
            </CCol>
          </CRow>
          <CRow class="m-0">
            <CCol cols="12" lg="12" class="pt-0">
              <label><b>{{ $t('lms.learning_objectives') }}</b></label>
              <div class="wysiwyg">
                <ckeditor :editor="editor" v-model="course.objectives" :config="editorConfig" :placeholder="$t('lms.learning_objectives')"></ckeditor>
              </div>
            </CCol>
          </CRow>
          <CRow class="m-0">
            <CCol cols="6" lg="6" class="pt-0">
              <label>{{$t('lms.upload_course_picture')}}</label>
              <image-uploader class="image_upload" :preview="true" :className="['fileinput', { 'fileinput--loaded': courseImageUploaded }]" capture="environment" :debug="0" doNotResize="gif" :autoRotate="true" outputFormat="verbose" @input="setCourseImage">
                <label for="fileInput" slot="upload-label" class="m-0">
                  <span class="upload-caption btn btn-primary m-0"><i class="fas fa-file-upload mr-1"/>{{courseImageUploaded ? $t('common.Replace_image') : $t('common.Upload_image')}}</span>
                </label>
              </image-uploader>
            </CCol>
            <CCol v-if="course.lms_course_id_external && course.course_image_id !== null" cols="6" lg="6" class="pt-0">
              <label>{{$t('common.Current_image')}}</label>
              <img :src="apiBaseUrl + '/v1/common/cdn/file/image/learn-course/' + course.lms_course_id_external + '/' + course.course_image_id + '.jpg' + '/' + clientToken" class="current_image"/>
            </CCol>
          </CRow>          
        </div>

        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <label><b>{{ $t('common.Points') }}</b></label>
            <CInput type="number" step="5" min="0" max="500" v-model="course.points" class="m-0" required was-validated/>
          </CCol>
        </CRow>
        <CRow class="m-0">           
          <CCol cols="12" lg="12" class="pt-0">
            <label><b>{{$t('common.Specified_for_groups')}}</b></label>
            <multiselect
              class="data_table"
              v-model="course.target_groups" 
              :options="targetGroups" 
              :multiple="true"
              :placeholder="$t('common.Select_group')" 
              :selectLabel="$t('common.Add_group')" 
              :selectedLabel="$t('Added')"
              :deselectLabel="$t('common.Remove_group')"
              track-by="group_id" 
              label="title">
              <span slot="noResult">{{ $t('common.no_groups_found') }}</span>
            </multiselect> 
          </CCol>
        </CRow>
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <label><b>{{$t('common.Select_subtopic')}}</b></label>
            <multiselect
              class="data_table"
              v-model="course.subtopic" 
              :options="subtopics"
              :multiple="false"
              :close-on-select="true"
              :placeholder="$t('common.Select_subtopic')" 
              :selectLabel="$t('common.Add_subtopic')" 
              :selectedLabel="$t('Added')"
              :deselectLabel="$t('common.Remove_subtopic')"
              track-by="subtopic_id_external" 
              label="label">
              <span slot="noResult">{{ $t('common.no_subtopics_found') }}</span>
            </multiselect>
          </CCol>            
        </CRow>
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="course.is_active" size="is-small">{{ $t('is_active') }}</b-switch>
          </CCol>            
        </CRow>      
      </div>
      <div class="sidebar_footer">
        <CRow class="m-0">
          <CCol cols="12" lg="12">
						<div v-if="!courseIdExternal">
							<CButton color="primary" @click="insertCourse(course);"><i class="fas fa-check mr-1"/>{{$t('Save')}}</CButton>
							<CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{$t('Dismiss')}}</CButton>
						</div> 						   
						<div v-else>
							<CButton color="primary" @click="updateCourse(course);"><i class="fas fa-check mr-1"/>{{$t('Save')}}</CButton>
							<CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{$t('Dismiss')}}</CButton>
          	</div>
          </CCol>
        </CRow>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import QuickEdit from 'vue-quick-edit';
import ImageUploader from 'vue-image-upload-resize';

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { UploadAdapter } from '@/components/upload/upload-adapter';

export default {
  name: 'courseDetails',
  props: ['history', 'lmsCategory', 'lmsCourseIdExternal'],
  components: {
    Multiselect,
    QuickEdit,
    ImageUploader
  },
  watch: {
    $props: {
      handler() {
        if(this.lmsCourseIdExternal) {
          this.courseIdExternal = this.lmsCourseIdExternal;
          this.editorConfig.upload.externalId = this.courseIdExternal; // Set the external ID for the wysiwyg uploader
        }
        // Get the course details
        this.getCourseDetails();
      },
      deep: true,
      immediate: true,
    }
  }, 
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null,
      courseIdExternal: null,
			course: {},
      courseImageUploaded: false,
      categories: [],
      targetGroups: [],
      subtopics: [],
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [ 'heading', '|', 'bold', 'italic', 'bulletedList', 'numberedList', '|', 'imageUpload', '|', 'undo', 'redo' ],
        extraPlugins: [this.wysiwygUpload],
        upload: { externalId: null, source: 'lms' }        
      }      
    }
  },
  methods: {
    getCourseDetails() {
      if(this.courseIdExternal !== null) {
				axios.get(process.env.VUE_APP_API_URL + '/v1/learn/course/' + this.courseIdExternal)
				.then(res => {
					this.course = res.data.data;
				})
				.catch(err => {
					console.error(err); 
				});    
      } else {
        // Reset the data 
        this.resetCourseData();
      }
    },
    insertCourse(data) {
      let params = {};
      params.courseData = data;
      (params.courseData.is_active == true) ? params.courseData.active = 'Y' : params.courseData.active = 'N';
      
      let course_name = params.courseData.course_name;
      let points = params.courseData.points;
      let category = params.courseData.category;
      let target_groups = params.courseData.target_groups;

      if(course_name && points && category && target_groups.length > 0) {
        axios.post(process.env.VUE_APP_API_URL + '/v1/learn/course', params)
        .then(res => {
          this.courseIdExternal = res.data.data.lms_course_id_external;
          // Set the external ID for the wysiwyg uploader
          this.editorConfig.upload.externalId = this.courseIdExternal;          
          // Show a success notice
          this.$buefy.toast.open({ message: this.$t('lms.Course_added'), type: 'is-success', duration: 2000 });                  
          // Emit the update_learn_category_courses event
          if(this.lmsCategory && this.lmsCategory.lms_course_category_id_external && this.lmsCategory.course_category_name) {           
            this.$bus.$emit('update_learn_category_courses', this.lmsCategory);
          }    
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }
    },
    updateCourse(data) {
      let params = {}
      params.courseData = data;
      (params.courseData.is_active == true) ? params.courseData.active = 'Y' : params.courseData.active = 'N';

      let course_name = params.courseData.course_name;
      let points = params.courseData.points;
      let category = params.courseData.category;
      let target_groups = params.courseData.target_groups;

      if(course_name && points && category && target_groups.length > 0) {
        axios.put(process.env.VUE_APP_API_URL + '/v1/learn/course/' + this.courseIdExternal, params)
        .then(res => {
          this.$buefy.toast.open({ message: this.$t('lms.Course_updated'), type: 'is-success', duration: 2000 });
          // Emit the update_learn_category_courses event
          if(this.lmsCategory && this.lmsCategory.lms_course_category_id_external && this.lmsCategory.course_category_name) {           
            this.$bus.$emit('update_learn_category_courses', this.lmsCategory);
          }   
          // Reset the data
          this.resetCourseData();
          // Close the sidebar
          this.closeSidebarRight();  
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }      
    },
    setCourseImage(output) {
      this.courseImageUploaded = true;
      this.course.image = output.dataUrl;
    },
    resetCourseData() {
      this.course = {
        course_name: null,
        description: "",
        objectives: "",
        points: null,
        image: null,
        is_active: false,
        target_groups: []
      };

      if(this.lmsCategory && this.lmsCategory.lms_course_category_id_external && this.lmsCategory.course_category_name) {
        this.course.category = {
          lms_course_category_id_external: this.lmsCategory.lms_course_category_id_external,
          label: this.lmsCategory.course_category_name
        }
      }       
    },
    getCategories() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/learn/categories/multiselect')
      .then(res => {
        this.categories = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },      
    getTargetGroups() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/targetgroups/multiselect')
      .then(res => {
        this.targetGroups = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getSubtopics() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/connect/subtopics/multiselect')
      .then(res => {
        this.subtopics = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    wysiwygUpload(editor) {
      var args = editor.config._config.upload;
      editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
        return new UploadAdapter({ loader, args });
      };
    }    
  },
  mounted: function() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');

    this.getCategories();
    this.getTargetGroups();
    this.getSubtopics();
  }  
}
</script>