<template>
  <div>
    <div class="sidebar_container">
      <div class="sidebar_header">
        <CRow class="m-0">
          <CCol cols="10" lg="10" md="10" sm="10" class="text-left">
            <span v-if="history.length > 1" class="pointer" @click="removeSidebarHistoryStep()">
              <i class="fas fa-arrow-circle-left mr-1"/>
            </span>
            <span class="sidebar_subject">
              {{sender.deliver_sender_id_external === null || sender.deliver_sender_id_external === undefined ? $t('connect.New_sender') : $t('connect.Edit_sender')}}
            </span>
          </CCol>
          <CCol cols="2" lg="2" md="2" sm="2" class="text-right">
            <span @click="closeSidebarRight();"><i class="fas fa-times"/></span>    
          </CCol>      
        </CRow>
      </div>
      <div class="sidebar_content">
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <CInput type="text" :label="$t('common.Name')" class="mb-0" v-model="sender.name" required was-validated/>
          </CCol>
        </CRow>
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <CInput type="text" :label="$t('connect.Email_reply_to')" class="mb-0" v-model="sender.email_reply_to" required was-validated/>
          </CCol>         
        </CRow>
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <label>{{$t('connect.Set_sender_as_alias_for')}}</label>
            <multiselect                        
              class="data_table"
              v-model="sender.aliases" 
              :options="users" 
              :multiple="true"                
              :placeholder="$t('common.Select_users')" 
              :selectLabel="$t('common.Add_user')" 
              :selectedLabel="$t('Added')"
              :deselectLabel="$t('common.Remove_user')"
              track-by="user_id_external" 
              label="label"                        
              @search-change="asyncFindUser">
              <span slot="noResult">{{ $t('common.no_users_found') }}</span>
              <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
            </multiselect>
          </CCol>         
        </CRow>
        <CRow class="m-0">
          <CCol cols="6" lg="6" class="pt-0">
            <label>{{$t('connect.Upload_profile_image_for_sender')}}</label>
            <image-uploader class="image_upload" :preview="true" :className="['fileinput', { 'fileinput--loaded': senderProfileImageUploaded }]" capture="environment" :debug="0" doNotResize="gif" :autoRotate="true" outputFormat="verbose" @input="setSenderProfileImage">
              <label for="fileInput" slot="upload-label" class="m-0">
                <span class="upload-caption btn btn-primary m-0"><i class="fas fa-file-upload mr-1"/>{{senderProfileImageUploaded ? $t('common.Replace_image') : $t('common.Upload_image')}}</span>
              </label>
            </image-uploader>
          </CCol>
          <CCol v-if="deliverSenderIdExternal && sender.profile_image_id !== null" cols="6" lg="6" class="pt-0">
            <label>{{$t('common.Current_image')}}</label>
            <img :src="apiBaseUrl + '/v1/common/cdn/file/image/connect-deliver-sender/' + sender.deliver_sender_id_external + '/' + sender.profile_image_id + '.jpg' + '/' + clientToken" class="current_image"/>
          </CCol>           
        </CRow>                
      </div>
      <div class="sidebar_footer">
        <CRow class="m-0">
          <CCol cols="12" lg="12">
            <div v-if="sender.deliver_sender_id_external === null || sender.deliver_sender_id_external === undefined">
              <CButton color="primary" @click="insertSender();"><i class="fas fa-check mr-1"/>{{ $t('Save') }}</CButton>
              <CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{ $t('Dismiss') }}</CButton>
            </div>
            <div v-else>
              <CButton color="primary" @click="updateSender(sender);"><i class="fas fa-check mr-1"/>{{ $t('Update') }}</CButton>
              <CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{ $t('Dismiss') }}</CButton>
            </div>        
          </CCol>
        </CRow>    
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import QuickEdit from 'vue-quick-edit';
import ImageUploader from 'vue-image-upload-resize';

export default {
  name: 'senderDetails',
  props: ['history', 'deliverSenderIdExternal'],
  components: {
    Multiselect,
    QuickEdit,
    ImageUploader
  },
  watch: {
    $props: {
      handler() {
        this.getSenderDetails();
      },
      deep: true,
      immediate: true,
    }
  },
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null,
      sender: {},
      senderProfileImageUploaded: false,
      users: []
    }
  },
  methods: {
    getSenderDetails() {
      if(this.deliverSenderIdExternal !== null) {
        axios.get(process.env.VUE_APP_API_URL + '/v1/connect/deliver/sender/' + this.deliverSenderIdExternal)
        .then(res => {
          this.sender = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      } else {
        // Reset the users
        this.resetSenderData();
      }
    },
    insertSender() {
      let params = {};
      params.senderData = this.sender;
      
      let name = params.senderData.name;
      let email_reply_to = params.senderData.email_reply_to;

      if(name && email_reply_to) {
        axios.post(process.env.VUE_APP_API_URL + '/v1/connect/deliver/sender', params)
        .then(res => {
          // Show a success notice
          this.$buefy.toast.open({ message: this.$t('connect.Sender_added'), type: 'is-success', duration: 3000 });
          // Emit the update_senders event
          this.$bus.$emit('update_senders'); 
          // Reset the data
          this.resetSenderData();
          // Close the sidebar
          this.closeSidebarRight();          
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }
    },    
    updateSender(data) {
      let params = {}
      params.senderData = data;
      (params.is_active === true) ? params.active = 'Y' : params.active = 'N';
            
      axios.put(process.env.VUE_APP_API_URL + '/v1/connect/deliver/sender/' + this.deliverSenderIdExternal, params)
      .then(res => {
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('connect.Sender_updated'), type: 'is-success', duration: 2000 });
        // Emit the update_sender_details event
        this.$bus.$emit('update_sender_details', this.deliverSenderIdExternal); 
        // Reset the data
        this.resetSenderData();
        // Close the sidebar
        this.closeSidebarRight();        
      })
      .catch(err => {
        console.error(err); 
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      })
    },  
    resetSenderData() {
      this.sender = {
        name: null,
        email_reply_to: null,
        image: null
      };
    },
    setSenderProfileImage(output) {
      this.senderProfileImageUploaded = true;
      this.sender.image = output.dataUrl;
    },    
    asyncFindUser (query) {
      let searchTerm = query;
      this.users = [];
      
      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/users/' + searchTerm)
        .then(res => {      
          this.users = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    }    
  },
  mounted: function() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');
  }    
}
</script>