<template>
  <div v-if="questionData" class="satisquestions post">
    <CRow class="meta m-0">
      <CCol class="pb-0">
        <div class="content question">
          <p class="mt-0"><b>{{questionData.question}}</b></p>
          <span>{{questionData.category_name}} - {{questionData.subcategory_name}}</span> 
        </div>
      </CCol>
    </CRow>
    <CRow class="m-0">
      <CCol class="p-0">
        <div class="answers_container h-100 w-100 p-3">
          <div class="answers">
            <div v-for="option in questionData.answer_options" v-bind:key="option.answer_name" class="option">
              <div v-html="getTwemoji(option.answer_emoji)"></div>
              <div class="definition">{{option.answer_definition}}</div>
            </div>
          </div>
        </div>
      </CCol>
    </CRow>
    <CRow class="m-0">
      <CCol class="pt-0 pb-0 text-center">
        <span style="text-align: center; font-size: 0.8em; color: #a6a6a6;"><i class="fas fa-user-shield mr-1"/>{{$t('sq.all_answers_anonymous')}}</span>
      </CCol>
    </CRow>
  </div> 
</template>

<script>

export default {
  name: 'swPostQuestion',
  props: ['satisquestionData'],
  watch: {
    $props: {
      handler() {
        // Update the questionData value
        if(this.satisquestionData) this.questionData = this.satisquestionData;        
      },
      deep: true,
      immediate: true,
    }
  },  
  data() {
    return {
      questionData: null,      
      selectedOption: null
    }
  },
  methods: {
    getTwemoji(emoji) {
      return twemoji.parse(emoji);
    }       
  }
}

</script>