<template>
  <CCard class="moment" 
        v-bind:class="{'pointer' : parent === 'sidebar' && (!momentData.moment_included || (momentData.moment_included && !momentData.moment_active)), 
                       'moment_included' : parent === 'sidebar' && momentData.moment_included && momentData.moment_active}">         
    <CCardBody>
      <div v-if="parent === 'overview' && !momentData.is_required" class="status_toggle">
        <b-switch v-model="momentData.is_active" @input="updateAutoMomentStatus()" class="m-0" size="is-small"></b-switch>
      </div>
      <div class="text-center">
        <div class="d-flex align-items-center justify-content-center moment_icon">
          <img v-if="momentData.event_type_image_id && !momentData.custom_event" :src="apiBaseUrl + '/v1/common/cdn/file/image/core-auto-moment-icon/global/' + momentData.event_type_image_id + '/' + clientToken"/>
          <img v-else-if="momentData.event_type_image_id && momentData.custom_event" :src="apiBaseUrl + '/v1/common/cdn/file/image/core-auto-moment-custom/' + momentData.tag + '/' + momentData.event_type_image_id + '/' + clientToken"/>
          <img v-else :src="apiBaseUrl + '/v1/common/cdn/file/image/core-auto-moment-icon/common/calendar.png/' + clientToken"/>
        </div>
        <div class="auto_moment_info">
          <span v-line-clamp="1"><b>{{momentData.description}}</b></span>
          <div v-if="parent === 'sidebar'" class="d-flex align-items-center justify-content-center mt-1">            
            <div v-if="momentData.send_on_date" class="d-flex align-items-center mr-2 date">
              <i class="fa-regular fa-calendar mr-1_5"></i>
              <span>{{momentData.send_on_date | moment("DD-MM")}}</span>         
            </div>            
            <div v-if="momentData.points || momentData.default_points" class="d-flex align-items-center mr-2 credits">
              <img v-if="environmentTag === 'harryhr'" src="@/assets/img/harry_hr_credit.png"/>
              <img v-if="environmentTag === 'employalty'" src="@/assets/img/employalty_credit.png"/>
              <img v-if="environmentTag === 'vitaalbegrip'" src="@/assets/img/vitaal_begrip_credit.png"/>
              <span>{{momentData.points ? momentData.points : momentData.default_points}}</span>
            </div>
            <div v-if="momentData.countries.length > 0" class="countries">
              <span v-for="country in momentData.countries" v-bind:key="country.abbr" v-html="getTwemoji(country.flag)"></span>
            </div>
            <div v-else class="d-flex align-items-center global">
              <i class="fa-solid fa-globe"></i>
            </div>
            <div v-if="momentData.moment_included && momentData.moment_active" class="ml-1 d-flex align-items-center moment_included">
              <i class="fa-solid fa-check"></i>
            </div>
          </div>
        </div>
      </div>
      <div v-if="parent === 'overview'">
        <div class="d-flex auto_moment_meta">
          <div v-if="momentData.send_on_date" class="d-flex align-items-center flex-grow-1 date">
            <i class="fa-regular fa-calendar mr-1_5"></i>
            <span>{{momentData.send_on_date | moment("DD-MM-YYYY HH:mm")}}</span>        
          </div>
          <div v-else-if="!momentData.send_on_date && momentDataTags.includes(momentData.tag)" class="d-flex align-items-center flex-grow-1 date">
            <i class="fa-regular fa-calendar mr-1_5"></i>
            <!-- Loyalty -->
            <span v-if="momentData.tag === 'birthday'">{{$t('communications.Every_birthday')}}</span>
            <span v-if="momentData.tag === 'compliment'">{{$t('communications.With_every_compliment')}}</span>
            <!-- Connect -->
            <span v-if="momentData.tag === 'breaking_news'">{{$t('communications.With_every_important_update')}}</span>
            <span v-if="momentData.tag === 'first_day'">{{$t('communications.For_every_first_working_day')}}</span>
            <span v-if="momentData.tag === 'introduction'">{{$t('communications.A_week_before_employee_starts')}}</span>            
            <!-- Knowledge -->
            <span v-if="momentData.tag === 'item_evaluation_reminder'">{{$t('communications.A_week_before_evaluation_date')}}</span>
            <!-- Measure -->
            <span v-if="momentData.tag === 'new_burning_matter'">{{$t('communications.New_burning_matters_available')}}</span>
            <span v-if="momentData.tag === 'new_satisquestions'">{{$t('communications.New_satisquestions_available')}}</span>  
            <!-- Learn -->
            <span v-if="momentData.tag === 'course_completion'">{{$t('communications.For_every_course_completion')}}</span>
            <!-- System -->
            <span v-if="momentData.tag === 'verification'">{{$t('communications.Every_new_employee')}}</span>
            <span v-if="momentData.tag === 'reset'">{{$t('communications.With_every_password_reset')}}</span>            
            <span v-if="momentData.tag === 'user_inactivity_first_check'">{{$t('communications.No_activity_from_employee', { days : momentData.send_after_days ? momentData.send_after_days : '...' })}}</span>
            <span v-if="momentData.tag === 'user_inactivity_second_check'">{{$t('communications.No_activity_from_employee', { days : momentData.send_after_days ? momentData.send_after_days : '...' })}}</span>
            <span v-if="momentData.tag === 'user_inactivity_third_check'">{{$t('communications.No_activity_from_employee', { days : momentData.send_after_days ? momentData.send_after_days : '...' })}}</span>
          </div>        
          <div v-if="momentData.points" class="pl-2 d-flex align-items-center credits">
            <img v-if="environmentTag === 'harryhr'" src="@/assets/img/harry_hr_credit.png"/>
            <img v-if="environmentTag === 'employalty'" src="@/assets/img/employalty_credit.png"/>
            <img v-if="environmentTag === 'vitaalbegrip'" src="@/assets/img/vitaal_begrip_credit.png"/>
            <span>{{momentData.points}}</span>
          </div>        
        </div>
        <div v-if="momentData.preview_available || showEditButton" class="d-flex auto_moment_actions">
          <CRow :gutters="true">
            <CCol v-if="momentData.preview_available" cols="6" lg="6" md="6" sm="6" class="pt-0 pb-sm-0" v-bind:class="{'action_left' : momentData.preview_available}">
              <CButton @click="showMomentEmailPreview(momentData.product_tag, momentData.tag);" class="w-100 m-0" color="secondary">
                <span v-line-clamp="1"><i class="fas fa-search mr-1"/>{{$t('communications.Preview_moment_email')}}</span>                  
              </CButton>
            </CCol>
            <CCol v-if="showEditButton" cols="6" lg="6" md="6" sm="6" class="pt-0 pb-0" v-bind:class="{'action_left' : !momentData.preview_available, 'action_right' : momentData.preview_available}">
              <CButton v-if="momentData.product_tag !== 'system'" :to="'/' + momentData.product_tag + '/auto-moment/' + momentData.platform_event_type_id_external" class="w-100 m-0" color="primary">
                <span v-line-clamp="1"><i class="fa-solid fa-pen mr-1"></i>{{$t('communications.Edit_loyalty_moment')}}</span>
              </CButton>
              <CButton v-else :to="'/core/system-communication/' + momentData.platform_event_type_id_external" class="w-100 m-0" color="primary">
                <span v-line-clamp="1"><i class="fa-solid fa-pen mr-1"></i>{{$t('communications.Edit_loyalty_moment')}}</span>
              </CButton>              
            </CCol>
          </CRow>
        </div>
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Moment',
  props: ['momentData', 'showEditButton', 'parent'],
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null,
      environmentTag: null,
      updateApiPath: null,
      updateApiMessage: null,
      momentDataTags: ['verification', 'reset', 'birthday', 'compliment', 'breaking_news', 'first_day', 'introduction', 'item_evaluation_reminder', 'course_completion', 'new_burning_matter', 'new_satisquestions', 'user_inactivity_first_check', 'user_inactivity_second_check', 'user_inactivity_third_check']
    }
  },  
  methods: {
    updateAutoMomentStatus() {
      if(this.momentData.is_active) {
        this.updateApiPath = this.momentData.product_tag + '/' + this.momentData.platform_event_type_id_external + '/activate';
        this.updateApiMessage = this.$t('communications.Auto_moment_enabled');
      } else {
        this.updateApiPath = this.momentData.product_tag + '/' + this.momentData.platform_event_type_id_external + '/deactivate';
        this.updateApiMessage = this.$t('communications.Auto_moment_disabled');
      }

      axios.put(process.env.VUE_APP_API_URL + '/v1/core/event/' + this.updateApiPath)
      .then(res => {
        this.$buefy.toast.open({ message: this.updateApiMessage, type: 'is-success', duration: 2000 });
      })
      .catch(err => {
        console.error(err); 
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      })
    },   
    showMomentEmailPreview(productTag, eventTypeTag) {
      let momentEmailPreviewData = {product_tag: productTag, tag: eventTypeTag}
      // Emit the show_moment_email_preview event 
      this.$bus.$emit('show_moment_email_preview', momentEmailPreviewData);      
    },      
    getTwemoji(emoji) {
      return twemoji.parse(emoji);
    }   
  },
  mounted: function() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');
    if(localStorage.getItem('environmentTag') !== null) this.environmentTag = localStorage.getItem('environmentTag');
  }  
}
</script>