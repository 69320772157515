<template>
  <div>
    <div class="sidebar_container">
      <div class="sidebar_header">
        <CRow class="m-0">
          <CCol cols="10" lg="10" md="10" sm="10" class="text-left">
            <span v-if="history.length > 1" class="pointer" @click="removeSidebarHistoryStep()">
              <i class="fas fa-arrow-circle-left mr-1"/>
            </span>            
            <span class="sidebar_subject">
							{{!sqQuestionIdExternal ? $t('sq.new_satisquestion') : $t('sq.Edit_satisquestion')}}
            </span>
          </CCol>
          <CCol cols="2" lg="2" md="2" sm="2" class="text-right">
            <span @click="closeSidebarRight();"><i class="fas fa-times"/></span>        
          </CCol>
        </CRow>
      </div>
      <div class="sidebar_content">
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <CInput class="mb-0" type="text" v-bind:label="$t('sq.Question_you_like_to_ask')" v-model="satisquestion.question" required was-validated/>
          </CCol>
        </CRow>
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <label>{{$t('sq.Which_subpillar')}}</label>                    
            <multiselect
              class="data_table"
              v-model="satisquestion.subcategory"
              :options="subcategories" 
              :multiple="false"
              :hide-selected="true"
              :close-on-select="true"
              :placeholder="$t('measure.Search_subpillar')" 
              :selectLabel="$t('measure.Select_subpillar')" 
              track-by="sq_subcategory_id_external" 
              label="label">
              <span slot="noResult">{{$t('sq.no_subpillars_found')}}</span>
            </multiselect>
          </CCol>
        </CRow>
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <div>
              <span>{{ $t('sq.Shape_your_answers') }}</span>
            </div>
            <div class="answer_option mt-2" v-for="(answer_option, index) in satisquestion.answer_options" v-bind:key="answer_option.number">
              <span class="mb-2">{{ $t('sq.Answer') }} {{answer_option.number}}</span>
              <div class="answer_emoji text-center">
                <CTextarea class="mb-0" v-model="answer_option.emoji" :required="index < 2" :was-validated="index < 2" :disabled="sqQuestionIdExternal"/>
                <span>{{ $t('Emoji') }}</span>
                <emoji-picker v-if="!sqQuestionIdExternal" :search="search">
                  <div class="emoji-invoker" slot="emoji-invoker" slot-scope="{events: {click: clickEvent}}" @click.stop="clickEvent">
                    <i class="icon fas fa-plus"/>
                  </div>
                  <div slot="emoji-picker" slot-scope="{ emojis }" style="font-size: 2em;">
                    <div class="emoji-picker">
                      <div class="emoji-picker__search">
                        <input type="text" v-model="search" v-focus>
                      </div>
                        <div>
                        <div v-for="(emojiGroup, category) in emojis" :key="category">
                          <h5>{{category}}</h5>
                          <div class="emojis">
                            <span v-for="(emoji, emojiName) in emojiGroup" :key="emojiName" @click="insertAnswerEmoji(emoji, index)" :title="emojiName">{{emoji}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </emoji-picker>
              </div>
              <div class="answer_score text-center">
                <CTextarea class="mb-0" v-model="answer_option.score" :required="index < 2" :was-validated="index < 2" :disabled="sqQuestionIdExternal"/>
                <span>{{ $t('sq.Score') }}</span>
              </div>
              <div class="answer_definition mt-1 text-center">
                <CInput class="mb-0" v-model="answer_option.definition"/>
                <span>{{ $t('Description') }}</span>
              </div>                  
            </div>
          </CCol>
        </CRow>
        <CRow class="m-0">           
          <CCol cols="12" lg="12" class="pt-0 pb-0">
            <b-switch class="mb-0 mt-1 mt-xl-0 align-middle" v-model="satisquestion.is_active" size="is-small">{{ $t('is_active') }}</b-switch>            
          </CCol>            
        </CRow>   
      </div>
      <div class="sidebar_footer">
        <CRow class="m-0">
          <CCol cols="12" lg="12">
						<div v-if="!sqQuestionIdExternal">
							<CButton color="primary" @click="insertSatisQuestion(satisquestion);"><i class="fas fa-check mr-1"/>{{$t('Save')}}</CButton>
							<CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{$t('Dismiss')}}</CButton>
						</div> 						   
						<div v-else>
							<CButton color="primary" @click="updateSatisQuestion(satisquestion);"><i class="fas fa-check mr-1"/>{{$t('Save')}}</CButton>
							<CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{$t('Dismiss')}}</CButton>
          	</div>
          </CCol>
        </CRow>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import QuickEdit from 'vue-quick-edit';
import EmojiPicker from 'vue-emoji-picker';

export default {
  name: 'satisquestionDetails',
  props: ['history', 'sqSubcategory', 'sqQuestionIdExternal'],
  components: {
    Multiselect,
    QuickEdit,
    EmojiPicker
  },
  watch: {
    $props: {
      handler() {
        this.getSatisQuestionDetails();
      },
      deep: true,
      immediate: true,
    }
  },
  directives: {
    focus: {
      inserted(el) {
        el.focus()
      },
    },
  },   
  data() {
    return {
			satisquestion: {},
      subcategories: [],
      input: '',
      search: ''
    }
  },
  methods: {
    getSatisQuestionDetails() {
      if(this.sqQuestionIdExternal !== null) {
				axios.get(process.env.VUE_APP_API_URL + '/v1/measure/satisquestion/' + this.sqQuestionIdExternal)
				.then(res => {
					this.satisquestion = res.data.data;   
				})
				.catch(err => {
					console.error(err); 
				});    
      } else {
        // Reset the data 
        this.resetSatisQuestionData();
      }
    },
    insertSatisQuestion(data) {
      let params = {};
      params.satisquestionData = data;
      (params.satisquestionData.is_active == true) ? params.satisquestionData.active = 'Y' : params.satisquestionData.active = 'N';

      let question = params.satisquestionData.question;
      let subcategory = params.satisquestionData.subcategory;
      let answer1 = params.satisquestionData.answer_options[0];
      let answer2 = params.satisquestionData.answer_options[1];

      if(question && subcategory && answer1.emoji && answer1.score && answer2.emoji && answer2.score) {
        axios.post(process.env.VUE_APP_API_URL + '/v1/measure/satisquestion', params)
        .then(res => {
          this.$buefy.toast.open({ message: this.$t('sq.Satisquestion_added'), type: 'is-success', duration: 2000 });
          // Emit the update_sq_subcategory_items event
          if(this.sqSubcategory && this.sqSubcategory.sq_subcategory_id_external && this.sqSubcategory.subcategory_name) {           
            this.$bus.$emit('update_sq_subcategory_items', this.sqSubcategory);
          }
          // Reset the data
          this.resetSatisQuestionData();
          // Close the sidebar
          this.closeSidebarRight();
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }
    },
    updateSatisQuestion(data) { 
      let params = {};
      params.satisquestionData = data;
      (params.satisquestionData.is_active == true) ? params.satisquestionData.active = 'Y' : params.satisquestionData.active = 'N';
    
      let question = params.satisquestionData.question;
      let subcategory = params.satisquestionData.subcategory;

      if(question && subcategory) {
        // Set the answer definitions
        for(var i = 1; i <= params.satisquestionData.answer_options.length; i++) {        
          params.satisquestionData['answer_' + i + '_definition'] = params.satisquestionData.answer_options[i-1].definition;
        }
            
        axios.put(process.env.VUE_APP_API_URL + '/v1/measure/satisquestion/' + this.sqQuestionIdExternal, params)
        .then(res => {
          this.$buefy.toast.open({ message: this.$t('sq.Satisquestion_updated'), type: 'is-success', duration: 2000 });
          // Emit the update_sq_subcategory_items event
          if(this.sqSubcategory && this.sqSubcategory.sq_subcategory_id_external && this.sqSubcategory.category_name && this.sqSubcategory.subcategory_name) {           
            this.$bus.$emit('update_sq_subcategory_items', this.sqSubcategory);
          } else {
            this.$bus.$emit('update_satisquestion', this.sqQuestionIdExternal);
          }
          // Reset the data
          this.resetSatisQuestionData();
          // Close the sidebar
          this.closeSidebarRight();
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }      
    },
    insertAnswerEmoji(emoji, index) {
      this.satisquestion.answer_options[index].emoji = emoji;
    },
    getSubcategories() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/measure/satisquestions/subcategories/multiselect')
      .then(res => {
        this.subcategories = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    resetSatisQuestionData() {
      this.satisquestion = {
        question: null,
        is_active: true,
        answer_options: [
          {number: 1, emoji: null, score: null, definition: null},
          {number: 2, emoji: null, score: null, definition: null},
          {number: 3, emoji: null, score: null, definition: null},
          {number: 4, emoji: null, score: null, definition: null}
        ],
      };

      if(this.sqSubcategory && this.sqSubcategory.sq_subcategory_id_external && this.sqSubcategory.category_name && this.sqSubcategory.subcategory_name) {
        this.satisquestion.subcategory = {
          sq_subcategory_id_external: this.sqSubcategory.sq_subcategory_id_external,
          label: this.sqSubcategory.category_name + ' - ' + this.sqSubcategory.subcategory_name
        }
      }    
    }				
  },
  mounted: function() {
    this.getSubcategories();
  }  
}
</script>