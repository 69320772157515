import * as axios from 'axios';

function upload(formData, courseIdExternal) {
  const url = 'v1/learn/h5p/course/' + courseIdExternal;
  return axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
    // get data
    .then(x => x.data)
    // add url field
    // .then(x => x.map(img => Object.assign({},
    //   img, { url: `${BASE_URL}/images/${img.id}` })));
}

export { upload }