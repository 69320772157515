<template>
  <div>
    <div class="sidebar_container">
      <div class="sidebar_header">
        <CRow class="m-0">
          <CCol cols="10" lg="10" md="10" sm="10" class="text-left">
            <span v-if="history.length > 1" class="pointer" @click="removeSidebarHistoryStep()">
              <i class="fas fa-arrow-circle-left mr-1"/>
            </span>            
            <span class="sidebar_subject">
              {{$t('connect.New_push_notification')}}         
            </span>
          </CCol>
          <CCol cols="2" lg="2" md="2" sm="2" class="text-right">
            <span @click="closeSidebarRight();"><i class="fas fa-times"/></span>
          </CCol>
        </CRow>
      </div>
      <div class="sidebar_content">
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <CInput @input="countdown()" rows="2" maxlength="20" :label="$t('communications.Title_for_push')" class="mb-0" v-model="newPushMessage.title"/>
            <p class="countdown mt-1 mb-0" v-if="remainingCount.title > 0">{{remainingCount.title}} {{ $t('common.characters_remaining') }}</p>
          </CCol>            
        </CRow>                
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <CInput @input="countdown()" rows="2" maxlength="60" :label="$t('communications.Message_for_push')" class="mb-0" v-model="newPushMessage.message"/>
            <p class="countdown mt-1 mb-0" v-if="remainingCount.message > 0">{{remainingCount.message}} {{ $t('common.characters_remaining') }}</p>
          </CCol>
        </CRow>
        <CRow class="m-0">           
          <CCol cols="12" lg="12" class="pt-0">
            <label>{{$t('connect.Subtopic_for_push_notification')}}</label>
            <multiselect
              class="data_table"
              v-model="newPushMessage.subtopic" 
              :options="subtopics"
              :multiple="false"
              :hideSelected="false"
              :closeOnSelect="true"                  
              :placeholder="$t('common.Select_subtopic')" 
              :selectLabel="$t('common.Add_subtopic')" 
              :selectedLabel="$t('Selected')"
              :deselectLabel="$t('common.Remove_subtopic')"
              track-by="subtopic_id_external" 
              label="label">
              <span slot="noResult">{{ $t('common.no_subtopics_found') }}</span>
            </multiselect>            
          </CCol>            
        </CRow>                        
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <label>{{ $t('communications.Select_group') }}</label>
            <multiselect
              class="data_table"
              v-model="newPushMessage.target_group" 
              :options="targetGroups" 
              :multiple="false"
              :hide-selected="true"
              :close-on-select="true"
              :placeholder="$t('common.Select_group')" 
              :selectLabel="$t('common.Add_group')" 
              :selectedLabel="$t('Added')"
              track-by="group_id" 
              label="title">
              <span slot="noResult">{{ $t('common.no_groups_found') }}</span>
            </multiselect>                                    
          </CCol>           
        </CRow>                   
      </div>
      <div class="sidebar_footer">
        <CRow class="m-0">
          <CCol cols="12" lg="12">
            <div>
              <CButton color="primary" @click="sendPushMessage();"><i class="fas fa-paper-plane mr-1"/>{{ $t('communications.Send_notification') }}</CButton>
              <CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{$t('Dismiss')}}</CButton>
            </div>
          </CCol>
        </CRow>
      </div>
    </div>
  </div>               
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect';

export default {
  name: 'Resources',
  props: ['history'],
  components: {
    Multiselect
  },
  data() {
    return {          
      targetGroups: [],
      subtopics: [],
      newPushMessage: {
        message: '',
        target_group: []
      },
      maxCount: {
        title: 20,
        message: 60
      },
      remainingCount: {
        title: 20,
        message: 60
      }
    }
  },
  methods: {
    countdown () {
      this.remainingCount.title = this.maxCount.title - this.newPushMessage.title.length;
      this.remainingCount.message = this.maxCount.message - this.newPushMessage.message.length;
    },        
    sendPushMessage () {
      let params = {};
      params.notificationData = this.newPushMessage;

      let title = params.notificationData.title;
      let message = params.notificationData.message;
      let target_group = params.notificationData.target_group;

      if(title && message && target_group) {
        axios.post(process.env.VUE_APP_API_URL + '/v1/connect/deliver/pushnotification/send', params)
        .then(res => {
          this.$buefy.toast.open({ message: this.$t('communications.push_notification_sent'), type: 'is-success', duration: 2000 });
          // Emit the update_push_notifications event
          this.$bus.$emit('update_push_notifications');            
          // Reset the push message data
          this.resetPushMessageData();
          // Close the sidebar
          this.closeSidebarRight();          
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.Empty_required_fields'), type: 'is-danger', duration: 2000 });
      }
    },
    getTargetGroups() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/targetgroups/multiselect')
      .then(res => {
        this.targetGroups = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getSubtopics() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/connect/subtopics/multiselect')
      .then(res => {
        this.subtopics = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },        
    resetPushMessageData () {
      this.newPushMessage = {
        title: '',
        message: '',
        target_group: null
      };
    }
  },
  mounted: function(){
    this.resetPushMessageData();
    this.getTargetGroups();
    this.getSubtopics();
  }
}
</script>