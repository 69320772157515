<template>
  <div>
    <div class="sidebar_container">
      <div class="sidebar_header">
        <CRow class="m-0">
          <CCol cols="10" lg="10" md="10" sm="10" class="text-left">
            <span v-if="history.length > 1" class="pointer" @click="removeSidebarHistoryStep()">
              <i class="fas fa-arrow-circle-left mr-1"/>
            </span>            
            <span class="sidebar_subject">
							{{!loyaltyCompanyWkrIdExternal ? $t('wkr.New_wkr_data') : $t('wkr.Edit_wkr_data')}}
            </span>
          </CCol>
          <CCol cols="2" lg="2" md="2" sm="2" class="text-right">
            <span @click="closeSidebarRight();"><i class="fas fa-times"/></span>        
          </CCol>
        </CRow>
      </div>
      <div class="sidebar_content">
        <CRow class="m-0">
          <CCol cols="6" lg="6" class="pt-0">
            <label>{{$t('wkr.Fiscal_salary_sum')}} (&euro;)</label>
            <quick-edit type="number" :step="1" :min="0" mode="ignore" :buttonOkText="$t('Save')" :buttonCancelText="$t('Dismiss')" v-model="wkrData.fiscal_salary_sum">                        
              <h2 class="m-0 display-flex align-items-center">{{wkrData.fiscal_salary_sum ? '€' + formatToCurrency(wkrData.fiscal_salary_sum) : $t('wkr.Click_to_set_salary_sum')}}<i class="icon edit fas fa-pen"/></h2>
            </quick-edit>
          </CCol>
          <CCol cols="6" lg="6" class="pt-0">
            <label>{{$t('common.Year')}}</label>            
            <quick-edit type="number" :step="1" :min="0" mode="ignore" :buttonOkText="$t('Save')" :buttonCancelText="$t('Dismiss')" v-model="wkrData.year">                        
              <h2 class="m-0 display-flex align-items-center">{{wkrData.year ? wkrData.year : $t('common.Click_to_set_year')}}<i class="icon edit fas fa-pen"/></h2>
            </quick-edit>            
          </CCol>                    
        </CRow>
        <CRow class="m-0">
          <CCol cols="6" lg="6" class="pt-0">
            <label>{{$t('wkr.Available_percentage')}} (%)</label>
            <quick-edit type="number" :step="1" :min="0" mode="ignore" :buttonOkText="$t('Save')" :buttonCancelText="$t('Dismiss')" v-model="wkrData.available_percentage">                        
              <h2 class="m-0 display-flex align-items-center">{{wkrData.available_percentage ? wkrData.available_percentage + '%' : $t('common.Click_to_set_percentage')}}<i class="icon edit fas fa-pen"/></h2>
            </quick-edit>
          </CCol>
          <CCol cols="6" lg="6" class="pt-0">
            <label>{{$t('wkr.Expected_mutations')}} (FTE)</label>
            <quick-edit type="number" :step="1" :min="0" mode="ignore" :buttonOkText="$t('Save')" :buttonCancelText="$t('Dismiss')" v-model="wkrData.expected_mutations">                        
              <h2 class="m-0 display-flex align-items-center">{{wkrData.expected_mutations ? wkrData.expected_mutations + ' FTE' : $t('wkr.Click_to_set_mutation')}}<i class="icon edit fas fa-pen"/></h2>
            </quick-edit>            
          </CCol>           
        </CRow>
        <CRow class="m-0">
          <CCol cols="12" lg="12" class="pt-0">
            <CTextarea @input="countdown()" :maxlength="maxCount" :label="$t('common.Comment')" rows="5" class="mb-0" v-model="wkrData.comment"/>
            <p class="countdown mt-1 mb-0" v-if="remainingCount > 0">{{remainingCount}} {{ $t('common.characters_remaining') }}</p>
          </CCol>           
        </CRow>                               
      </div>
      <div class="sidebar_footer">
        <CRow class="m-0">
          <CCol cols="12" lg="12">
						<div v-if="!loyaltyCompanyWkrIdExternal">
							<CButton color="primary" @click="insertCompanyWkrData(wkrData);"><i class="fas fa-check mr-1"/>{{$t('Save')}}</CButton>
							<CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{$t('Dismiss')}}</CButton>
						</div> 						   
						<div v-else>
							<CButton color="primary" @click="updateCompanyWkrData(wkrData);"><i class="fas fa-check mr-1"/>{{$t('Save')}}</CButton>
							<CButton color="secondary" @click="closeSidebarRight();"><i class="fas fa-times mr-1"/>{{$t('Dismiss')}}</CButton>
          	</div>
          </CCol>
        </CRow>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import QuickEdit from 'vue-quick-edit';

export default {
  name: 'companyWkrDetails',
  props: ['history', 'loyaltyCompanyWkrIdExternal'],
  components: {
    QuickEdit
  },
  watch: {
    $props: {
      handler() {
        this.getCompanyWkrDetails();
      },
      deep: true,
      immediate: true,
    }
  }, 
  data() {
    return {
			wkrData: {},
      maxCount: 1000,
      remainingCount: 1000
    }
  },
  methods: {
    getCompanyWkrDetails() {
      if(this.loyaltyCompanyWkrIdExternal !== null) {
				axios.get(process.env.VUE_APP_API_URL + '/v1/loyalty/company/wkr/data/' + this.loyaltyCompanyWkrIdExternal + '/details')
				.then(res => {
					this.wkrData = res.data.data;
          // Trigger countdown method
          this.countdown();          
				})
				.catch(err => {
					console.error(err); 
				});    
      } else {
        // Reset the data 
        this.resetCompanyWkrData();
        // Trigger countdown method
        this.countdown();
      }
    },
    insertCompanyWkrData(data) {
      let params = {};
      params.wkrData = data;
      if(params.wkrData.comment === '') params.wkrData.comment = null;
      if(params.wkrData.expected_mutations === '') params.wkrData.expected_mutations = null;
      
      let fiscal_salary_sum = params.wkrData.fiscal_salary_sum;
      let available_percentage = params.wkrData.available_percentage;
      let year = params.wkrData.year;

      if(fiscal_salary_sum && available_percentage && year) {
        axios.post(process.env.VUE_APP_API_URL + '/v1/loyalty/company/wkr/data', params)
        .then(res => {
          this.$buefy.toast.open({ message: this.$t('wkr.Company_wkr_data_added'), type: 'is-success', duration: 2000 });
          // Emit the update_company_wkr_data event
          this.$bus.$emit('update_company_wkr_data');
          // Reset the data
          this.resetCompanyWkrData();
          // Close the sidebar
          this.closeSidebarRight();         
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }
    },
    updateCompanyWkrData(data) {
      let params = {}
      params.wkrData = data;
      if(params.wkrData.comment === '') params.wkrData.comment = null;
      if(params.wkrData.expected_mutations === '') params.wkrData.expected_mutations = null;
           
      let fiscal_salary_sum = params.wkrData.fiscal_salary_sum;
      let available_percentage = params.wkrData.available_percentage;
      let year = params.wkrData.year;

      if(fiscal_salary_sum && available_percentage && year) {
        axios.put(process.env.VUE_APP_API_URL + '/v1/loyalty/company/wkr/data/' + this.loyaltyCompanyWkrIdExternal, params)
        .then(res => {
          this.$buefy.toast.open({ message: this.$t('wkr.Company_wkr_data_updated'), type: 'is-success', duration: 2000 });
          // Emit the update_company_wkr_data event
          this.$bus.$emit('update_company_wkr_data');
          // Reset the data
          this.resetCompanyWkrData();
          // Close the sidebar
          this.closeSidebarRight();  
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }      
    },   
    resetCompanyWkrData() {
      this.wkrData = {
        fiscal_salary_sum: null,
        available_percentage: 100,
        expected_mutations: null,
        year: new Date().getFullYear(),
        comment: null
      };
    },
    formatToCurrency(data) {
      return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 0 }).format(parseInt(data));
    },
    countdown() {
      if(this.wkrData.comment) this.remainingCount = this.maxCount - this.wkrData.comment.length;
    }        
  }
}
</script>