<template>
  <div class="file_upload">
    <form enctype="multipart/form-data" novalidate v-if="isInitial || isSaving">
      <div class="w-100 d-table dropbox">
        <input type="file" :name="uploadFieldName" :disabled="isSaving" @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length" class="input-file" :accept="acceptedTypes">
        <p v-if="isInitial" class="mb-0 d-table-cell align-middle">{{$t('upload.Drop_or_select_files')}}</p>
        <p v-if="isSaving" class="mb-0 d-table-cell align-middle">{{$t('upload.Uploading_file')}}</p>  
      </div>
    </form>

    <div v-if="isSuccess">
      <p class="mb-0">{{$t('upload.Upload_succesful')}}</p>
    </div>

    <div v-if="isFailed">
      <p class="mb-0">{{$t('upload.Upload_failed')}}. <a href="javascript:void(0)" @click="reset()">{{$t('upload.Try_again')}}</a>.</p> 
    </div>
  </div>
</template>

<script>
// import axios from 'axios'
import { upload } from '@/components/upload/h5p-upload.service';

const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;

export default {
  name: 'FileUpload',
  props: ['courseIdExternal'],
  watch: {
    $props: {
      handler() {
        if(this.courseIdExternal) this.uploadForCourseIdExternal = this.courseIdExternal;
      },
      deep: true,
      immediate: true,
    }
  },  
  data() {
    return {
      uploadForCourseIdExternal: null,
      uploadedFiles: [],
      uploadError: null,
      currentStatus: null,
      uploadFieldName: 'file',
      acceptedTypes: ''
    }
  },
  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    }
  },  
  methods: {
    reset() {
      // Reset form to initial state
      this.currentStatus = STATUS_INITIAL;
      this.uploadedFiles = [];
      this.uploadError = null;
    },
    save(formData) {
      // Upload data to the server
      this.currentStatus = STATUS_SAVING;
      // Use the upload function from the service
      upload(formData, this.uploadForCourseIdExternal)
      .then(x => {
        this.uploadedFiles = [].concat(x);
        this.currentStatus = STATUS_SUCCESS;
        // Emit file uploaded event to parent
        this.$emit('h5p_uploaded', this.uploadedFiles[0].data);
      })
      .catch(err => {
        this.uploadError = err.response;
        this.currentStatus = STATUS_FAILED;
      });
    },
    filesChange(fieldName, fileList) {
      // Handle file changes
      const formData = new FormData();
      // Return if no files
      if (!fileList.length) return;
      // Append the files to FormData
      Array.from(Array(fileList.length).keys()).map(x => {
        formData.append(fieldName, fileList[x], fileList[x].name);
      });
      // Save it
      this.save(formData);
    },
    setAcceptedTypes () {
      if(this.attachmentTypeId == 2) {
        this.acceptedTypes = 'image/png, image/jpeg';
      } else if(this.attachmentTypeId == 3) {
        this.acceptedTypes = 'application/pdf';
      }
    }
  },
  mounted: function() {
    this.reset();
    this.setAcceptedTypes();    
  }
}
</script>